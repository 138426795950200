import React from "react";
import moment from "moment";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Avatar,
} from "@material-ui/core";

import Info from "@material-ui/icons/Info";
import Accepted from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import Errored from "@material-ui/icons/Warning";
import Unknown from "@material-ui/icons/Error";

import { formatPaymentAmount } from "./../../utils/FormatUtil";

export default class PaymentHistoryCard extends React.Component {
  render() {
    const paymentItem = this.props.paymentItem;

    const formattedAmount = formatPaymentAmount(paymentItem.get("amount"));
    const formattedFee = formatPaymentAmount(paymentItem.get("feeAmount"));
    const formattedDate = moment(paymentItem.get("postDate")).format("L");

    let icon = (
      <Unknown
        style={{
          color: this.props.styles.get("accent1Color"),
          verticalAlign: "middle",
          paddingBottom: "3px",
        }}
      />
    );

    if (paymentItem.get("status") === "Pending") {
      icon = (
        <Info
          style={{
            color: this.props.styles.get("primary1Color"),
            verticalAlign: "middle",
            paddingBottom: "3px",
          }}
        />
      );
    }
    if (
      paymentItem.get("status") === "Errored" ||
      paymentItem.get("status") === "Declined"
    ) {
      icon = (
        <Errored
          style={{
            color: this.props.styles.get("accent1Color"),
            verticalAlign: "middle",
            paddingBottom: "3px",
          }}
        />
      );
    }
    if (paymentItem.get("status") === "Deleted") {
      icon = (
        <Cancel
          style={{
            color: this.props.styles.get("accent1Color"),
            verticalAlign: "middle",
            paddingBottom: "3px",
          }}
        />
      );
    }
    if (paymentItem.get("status") === "Processed") {
      icon = (
        <Accepted
          style={{
            color: this.props.styles.get("primary1Color"),
            verticalAlign: "middle",
            paddingBottom: "3px",
          }}
        />
      );
    }

    return (
      <Card style={{ margin: "0px 0px 8px 0px" }}>
        <CardHeader
          avatar={
            <Avatar
              style={{
                backgroundColor: "transparent",
                margin: 1,
              }}
            >
              {icon}
            </Avatar>
          }
          title={paymentItem.get("status")}
        />
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{this.props.t("Amount:")}</TableCell>
                <TableCell>${formattedAmount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{this.props.t("Fee:")}</TableCell>
                <TableCell>${formattedFee}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{this.props.t("Date:")}</TableCell>
                <TableCell>{formattedDate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{this.props.t("Confirmation:")}</TableCell>
                <TableCell>{paymentItem.get("transactionNumber")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{this.props.t("Frequency:")}</TableCell>
                {paymentItem.get("mwiSystem") === "AUTO" ? (
                  <TableCell>{this.props.t("Auto-Pay")}</TableCell>
                ) : (
                  <TableCell>{paymentItem.get("frequency")}</TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
        {paymentItem.get("status") === "Pending" ? (
          <CardActions>
            <Button
              onClick={() => {
                this.props.onCancelItem(paymentItem);
              }}
              color="secondary"
            >
              {this.props.t("Cancel Payment")}
            </Button>
          </CardActions>
        ) : null}
      </Card>
    );
  }
}
