import http from "./../utils/http";

export const registerUserAccountRequest = (userData, consortiumId) => {
  return http
    .post(`/api/v1/consortiums/${consortiumId}/users`)
    .send(userData)
    .end();
};

export const getUserAccountsRequest = (consortiumId, userId) => {
  return http
    .get(`/api/v1/consortiums/${consortiumId}/users/${userId}/user-accounts`)
    .end();
};

export const registerSsoUserAccountRequest = (
  userData,
  consortiumId,
  ssoId,
  samlUserId,
  provider
) => {
  let payload = {
    SsoID: ssoId,
    SamlUserId: samlUserId,
    MpayContactInfo: userData,
  };
  return http
    .post(`/api/v1/consortiums/${consortiumId}/saml/${provider}/register`)
    .send(payload)
    .end();
};

export const authenticateUserAccountRequest = (
  username,
  password,
  consortiumId
) => {
  const userAccountRequestData = {
    username: username,
    password: password,
  };

  return http
    .post(`/api/v1/consortiums/${consortiumId}/authenticated-users`)
    .send(userAccountRequestData)
    .end();
};

export const createMpayUserAndSamlUserLinkRequest = (
  consortiumId,
  samlLink
) => {
  return http
    .post(`/api/v1/consortiums/${consortiumId}/user/sso/link`)
    .send(samlLink)
    .end();
};

export const addUserCcPaymentMethodRequest = (
  consortiumId,
  userId,
  paymentMethod
) => {
  return http
    .post(
      `/api/v1/consortiums/${consortiumId}/users/${userId}/cc-payment-methods`
    )
    .send(paymentMethod)
    .end();
};

export const addUserAchPaymentMethodRequest = (
  consortiumId,
  userId,
  paymentMethod
) => {
  return http
    .post(
      `/api/v1/consortiums/${consortiumId}/users/${userId}/ach-payment-methods`
    )
    .send(paymentMethod)
    .end();
};

export const deleteUserCcPaymentMethodRequest = (
  consortiumId,
  userId,
  methodId
) => {
  return http
    .delete(
      `/api/v1/consortiums/${consortiumId}/users/${userId}/cc-payment-methods/${methodId}`
    )
    .end();
};

export const deleteUserAchPaymentMethodRequest = (
  consortiumId,
  userId,
  methodId
) => {
  return http
    .delete(
      `/api/v1/consortiums/${consortiumId}/users/${userId}/ach-payment-methods/${methodId}`
    )
    .end();
};

export const updateContactInfoRequest = (consortiumId, userId, contactInfo) => {
  return http
    .put(`/api/v1/consortiums/${consortiumId}/users/${userId}/contact-info`)
    .send(contactInfo)
    .end();
};

export const getUserPaymentMethodsRequest = (consortiumId, userId) => {
  return http
    .get(`/api/v1/consortiums/${consortiumId}/users/${userId}/payment-methods`)
    .end();
};

export const requestUsernameReminderRequest = (consortiumId, email) => {
  return http
    .post(`/api/v1/consortiums/${consortiumId}/username-reminders`)
    .send({ email })
    .end();
};

export const claimPaymentItemRequest = (
  consortiumId,
  userId,
  transactionNumber
) => {
  return http
    .patch(
      `/api/v1/consortiums/${consortiumId}/users/${userId}/payments/${transactionNumber}`
    )
    .end();
};

export const getStoredSamlPayloadRequest = (consortiumId, ssoRedisKey) => {
  return http
    .get(`/api/v1/consortiums/${consortiumId}/users/ssoid/${ssoRedisKey}`)
    .end();
};
