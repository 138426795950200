import {
  getConsortium,
  getConsortiumName,
  getBcHolidays,
  getBcNextBusinessDay
} from "./../requests/consortium";
import {
  GET_CONSORTIUM,
  GET_CONSORTIUM_NAME,
  GET_BC_HOLIDAYS,
  GET_BC_NEXT_BUSINESS_DAY
} from "./actionTypes";

export const getConsortiumAction = id => (dispatch, getState) => {
  const promise = getConsortium(id);

  dispatch({
    type: GET_CONSORTIUM,
    payload: { promise }
  });

  // By returning the promise, we can dispatch another action when this one ends
  return promise;
};

export const getConsortiumNameAction = id => (dispatch, getState) => {
  const promise = getConsortiumName(id);

  dispatch({
    type: GET_CONSORTIUM_NAME,
    payload: { promise }
  });

  return promise;
};

export const getBcHolidaysAction = bankNo => (dispatch, getState) => {
  const promise = getBcHolidays(bankNo);

  dispatch({
    type: GET_BC_HOLIDAYS,
    payload: { promise }
  });

  return promise;
};

export const getBcNextBusinessDayAction = (bankNo, compNo, accessNumber) => (
  dispatch,
  getState
) => {
  const promise = getBcNextBusinessDay(bankNo, compNo);

  dispatch({
    type: GET_BC_NEXT_BUSINESS_DAY,
    meta: { accessNumber },
    payload: { promise }
  });

  return promise;
};
