import React, { Component } from "react";
import { withRouter } from "react-router";
import { ListItem } from "@material-ui/core";

class FocusableListItem extends Component {
  constructor(props) {
    super(props);

    this.buttonRef = React.createRef();
    this.focusInput = this.focusInput.bind(this);
  }
  componentDidMount() {
    if (this.props.shouldFocusOnMount) {
      this.focusInput();
    }
  }
  focusInput() {
    this.buttonRef.current.focus();
  }
  render() {
    // We need to remove the prop that we don't care about so react doesn't yell at us
    // eslint-disable-next-line
    let { shouldFocusOnMount, ...otherProps } = this.props;
    return (
      <ListItem button {...otherProps} buttonRef={this.buttonRef}>
        {this.props.children}
      </ListItem>
    );
  }
}

export default withRouter(FocusableListItem);
