import React from "react";
import PropTypes from "prop-types";

import FormsyPasswordField from "./FormsyPasswordField.jsx";

class FormsyNewPasswordFields extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };
  render() {
    return (
      <div>
        <FormsyPasswordField
          name="password"
          floatingLabelText={this.props.t("Password")}
          onChange={this.props.onPasswordInput}
          value={this.props.password}
          validations={{
            minLength: 8,
            isValidPassword: true
          }}
          validationErrors={{
            isDefaultRequiredValue: this.props.t("Password is required"),
            minLength: "Password must be at least 8 characters",
            isValidPassword: this.props.t(
              "Password must contain a capital letter, lowercase letter, and a special character or number"
            )
          }}
          required
          shouldFocusOnMount={this.props.shouldFocusOnMount}
        />
        <FormsyPasswordField
          name="passwordConfirm"
          floatingLabelText={this.props.t("Confirm Password")}
          onChange={this.props.onPasswordConfirmInput}
          value={this.props.passwordConfirm}
          validations={{
            equalsField: "password"
          }}
          validationErrors={{
            isDefaultRequiredValue: this.props.t(
              "Password confirmation is required"
            ),
            equalsField: this.props.t("Passwords do not match")
          }}
          required
        />
      </div>
    );
  }
}

export default FormsyNewPasswordFields;
