import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PasswordTokenForm from "./PasswordTokenForm.jsx";

import { Button } from "@material-ui/core";

import { validatePasswordResetToken } from "./../../../api/actions/user-accounts/passwordReset";
import {
  showLoadingAction,
  hideLoadingAction
} from "./../../../api/actions/loading";
import {
  PASSWORD_RESET_VERIFICATION,
  FAILURE
} from "./../../../api/actions/actionTypes";

export class PasswordToken extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pin: "",
      token: "",
      valid: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }
  static propTypes = {
    t: PropTypes.func.isRequired
  };
  componentWillReceiveProps(nextProps, nextState) {
    if (!nextProps.pending && this.props.pending) {
      this.props.hideLoading();

      if (!nextProps.lastRequestFailed) {
        this.props.onNext();
      }
    }
  }
  onSubmit(model) {
    this.props.showLoading(this.props.t("Verifying Reset Pin and Token..."));

    const consortiumId = this.props.consortiumId;
    if (this.props.pin) {
      this.props.submitResetValidation(consortiumId, {
        ...model,
        pin: this.props.pin
      });
      return;
    }

    if (this.props.resetToken) {
      this.props.submitResetValidation(consortiumId, {
        ...model,
        resetToken: this.props.resetToken
      });
      return;
    }

    this.props.submitResetValidation(consortiumId, model);
  }
  render() {
    return (
      <div>
        {this.props.pin ? (
          <div>
            <p style={{ textAlign: "center" }}>
              {this.props.t("Your password reset PIN is")}
            </p>
            <h2 style={{ textAlign: "center" }}>{this.props.pin}</h2>
            <br />
            <p>
              {this.props.t(
                "Please write down this PIN number if you intend on navigating away from this page, it will be needed to complete your password reset."
              )}
            </p>
            <p>
              {this.props.t(
                "Once you have received the email, please enter the included reset token below."
              )}
            </p>
          </div>
        ) : null}

        {this.props.resetToken ? (
          <div>
            <p>
              {this.props.t(
                "Please enter the PIN number you received after requesting a password reset."
              )}
            </p>
            <div>
              {this.props.t("Don't have your PIN?")}{" "}
              <div style={{ textAlign: "center" }}>
                <Button color="primary" onClick={this.props.onRestart}>
                  {this.props.t("Start a new password reset.")}
                </Button>
              </div>
            </div>
          </div>
        ) : null}

        {!this.props.resetToken && !this.props.pin ? (
          <div>
            <p>
              {this.props.t(
                "Please enter the PIN number you received after requesting a password reset and the Reset Token included in the email you received."
              )}
            </p>
            <div>
              {this.props.t("Don't have your PIN or Token?")}{" "}
              <div style={{ textAlign: "center" }}>
                <Button color="primary" onClick={this.props.onRestart}>
                  {this.props.t("Start a new password reset.")}
                </Button>
              </div>
            </div>
          </div>
        ) : null}

        <PasswordTokenForm
          t={this.props.t}
          havePin={this.props.pin}
          haveToken={this.props.resetToken}
          onSubmit={this.onSubmit}
          disabled={this.props.pending}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pin: state.passwordReset.get("pin"),
    pending: !!state.httpRequests.getIn([
      PASSWORD_RESET_VERIFICATION,
      "pending"
    ]),
    lastRequestFailed:
      state.httpRequests.getIn([PASSWORD_RESET_VERIFICATION, "lastStatus"]) ===
      FAILURE,
    styles: state.style.get("palette")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showLoading: titleText => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: titleText => {
      dispatch(hideLoadingAction());
    },
    submitResetValidation: (consortiumId, pinAndToken) => {
      dispatch(validatePasswordResetToken(consortiumId, pinAndToken));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordToken);
