import React from "react";
import ManageAutoPay from "./ManageAutoPay.jsx";


export const ManageAutoPayContainer = (props, context) => {
  return (
    <div>
      <div className="no-print">
        <ManageAutoPay {...props} />
      </div>
      {/* <div className="only-print">
        todo make a print only view
      </div> */}
    </div>
  );
};

export default ManageAutoPayContainer;
