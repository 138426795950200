import React, { Component } from "react";
import Button from "@material-ui/core/Button";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import AccountsForm from "./AccountsForm.jsx";
import AccountsList from "./AccountsList.jsx";
import NoAccountsAlert from "./NoAccountsAlert.jsx";
import UserSignInPrompt from "./../user-accounts/UserSignInPrompt.jsx";
import { setPageInfoAction } from "./../../api/actions/pageInfo";

import {
  GET_ACCOUNTS,
  ADD_USER_ACCOUNT,
  FAILURE,
} from "./../../api/actions/actionTypes";
import {
  getAccountsAction,
  deleteAccountAction,
  selectAccountAction,
} from "./../../api/actions/accounts";
import {
  showLoadingAction,
  hideLoadingAction,
} from "./../../api/actions/loading";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { ResponsiveUtil } from "./../../utils/ResponsiveUtil";

import RouteHelper from "./../../utils/RouteHelper";
import { _t } from "./../../text/locale";
import PaymentStepper from "../../components/PaymentStepper.jsx";

let t;
const mobileStyle = {
  width: "100%",
  maxWidth: "none",
  marginBottom: "10px",
};
const isMobile = ResponsiveUtil.isMobile();
let pageTitle = "Accounts";

class Accounts extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onContinue = this.onContinue.bind(this);

    this.routeToAccountPayment = this.routeToAccountPayment.bind(this);
    this.routeToAccountSelect = this.routeToAccountSelect.bind(this);

    this.deleteAccount = this.deleteAccount.bind(this);
    this.buttonText = this.buttonText.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.pending === true) {
      this.props.showLoading(t("Retrieving your account information..."));
    } else {
      this.props.hideLoading();
    }
  }
  onSubmit(accountNumber, secondaryValidation) {
    this.props.getAccounts(
      this.props.params.accessNumber,
      accountNumber,
      secondaryValidation,
      this.props.params.consortiumId,
      this.props.loggedIn,
      this.props.userId
    );
  }
  deleteAccount(accountId, accountNumber, accessNumber) {
    this.props.deleteAccount(
      this.props.params.consortiumId,
      this.props.userId,
      accountId,
      accountNumber,
      accessNumber
    );
  }
  routeToAccountPayment() {
    this.props.selectAccount(this.props.accounts.get(0));

    const route = RouteHelper.getRelativeCustomerRoute(this.props.location)(
      "/payment/select-method"
    );
    this.props.router.push(route);
  }
  routeToAccountSelect() {
    const route = RouteHelper.getRelativeCustomerRoute(this.props.location)(
      "/accounts/select"
    );
    this.props.router.push(route);
  }
  onContinue() {
    if (this.props.accounts.size === 1) {
      this.routeToAccountPayment();
    } else {
      this.routeToAccountSelect();
    }
  }
  buttonText() {
    if (this.props.accounts.size === 1) {
      return t("Select Payment Method");
    }
    return t("Select Account");
  }
  render() {
    const noAccounts = this.props.accounts.isEmpty();
    const allOnCooldown = this.props.accounts.every(
      (a) =>
        a.has("paymentCooldownRemaining") &&
        a.get("paymentCooldownRemaining").as("seconds") > 0
    );
    const allAccountsBlocked = this.props.accounts.every((a) =>
      a.get("isBlocked")
    );

    return (
      <div>
        <div className="stepper">
          <PaymentStepper
            step="account-select"
            currentLocation={this.props.location}
          />
        </div>
        <div className="flex-container">
          <div className="flex-content">
            <AccountsForm
              t={t}
              onSubmit={this.onSubmit}
              showSecondaryValidation={this.props.showSecondaryValidation}
              accountFieldLengths={this.props.fieldLengths}
              accountFieldSeparators={this.props.fieldSeparators}
              lastRequestFailed={this.props.lastRequestFailed}
              lastRequestPending={this.props.pending}
            />
            <br />
            <p>{t("You have the following accounts:")}</p>
            {noAccounts ? (
              <NoAccountsAlert t={t} />
            ) : (
              <AccountsList
                t={t}
                accounts={this.props.accounts}
                loggedIn={this.props.loggedIn}
                onClickDelete={this.deleteAccount}
                bcPaymentCooldown={this.props.bcPaymentCooldown}
              />
            )}
            <Button
              variant="contained"
              type="submit"
              color="primary"
              default={true}
              disabled={noAccounts || allAccountsBlocked || allOnCooldown}
              style={isMobile ? mobileStyle : null}
              onClick={this.onContinue}
            >
              {this.buttonText()}
            </Button>
          </div>
          <div
            className="flex-tipBox"
            aria-label="Account Tip Box"
            role="region"
          >
            <UserSignInPrompt t={t} location={this.props.location} />
            <AlertInfo
              text={t("TIP_BOX_ACCOUNTS")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pending:
      !!state.httpRequests.getIn([GET_ACCOUNTS, "pending"]) ||
      !!state.httpRequests.getIn([ADD_USER_ACCOUNT, "pending"]),
    accounts: state.billing
      .getIn(["accounts", ownProps.params.accessNumber])
      .toList(),
    showSecondaryValidation: state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "requireSecondaryValdiation",
    ]),
    fieldLengths: state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "accountFieldLengths",
    ]),
    fieldSeparators: state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "accountFieldSeparators",
    ]),
    bcPaymentCooldown: state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "paymentCooldown",
    ]),
    loggedIn: state.auth.get("authenticated"),
    userId: state.user.get("id"),
    lastRequestFailed:
      state.httpRequests.getIn([GET_ACCOUNTS, "lastStatus"]) === FAILURE ||
      state.httpRequests.getIn([ADD_USER_ACCOUNT, "lastStatus"]) === FAILURE,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoading: (titleText) => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
    getAccounts: (
      accessNumber,
      accountNumber,
      secondaryValidation,
      consortiumId,
      loggedIn,
      userId
    ) => {
      dispatch(
        getAccountsAction(
          accessNumber,
          accountNumber,
          secondaryValidation,
          consortiumId,
          loggedIn,
          userId
        )
      );
    },
    deleteAccount: (
      consortiumId,
      userId,
      accountId,
      accountNumber,
      accessNumber
    ) => {
      dispatch(
        deleteAccountAction(
          consortiumId,
          userId,
          accountId,
          accountNumber,
          accessNumber
        )
      );
    },
    selectAccount: (account) => {
      dispatch(selectAccountAction(account));
    },
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Accounts)
);
