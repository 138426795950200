import {
  getRecurringPayments,
  deleteRecurringPayments
} from "./../requests/manageRecurring";
import {
  GET_RECURRING_PAYMENTS,
  CANCEL_PAYMENT,
  SELECT_RECURRING_PAYMENT_ACCOUNT
} from "./actionTypes";
import { getRecentPaymentsAction } from "./paymentHistory";

export const cancelPaymentAction = (
  bankNumber,
  companyNumber,
  transactionNumber
) => (dispatch, getState) => {
  const promise = deleteRecurringPayments(
    bankNumber,
    companyNumber,
    transactionNumber
  );

  dispatch({
    type: CANCEL_PAYMENT,
    payload: { promise }
  });

  // By returning the promise, we can dispatch another action when this one ends
  return promise;
};

export const getRecurringPaymentsAction = (
  consortiumId,
  userIdo,
  bankNumber,
  companyNumber,
  accountNumber,
  pageNumber
) => (dispatch, getState) => {
  const promise = getRecurringPayments(
    consortiumId,
    userIdo,
    bankNumber,
    companyNumber,
    accountNumber,
    pageNumber
  );

  dispatch({
    type: GET_RECURRING_PAYMENTS,
    payload: { promise },
    meta: { pageNumber }
  });

  // By returning the promise, we can dispatch another action when this one ends
  return promise;
};

export const selectRecurringPaymentAccountAction = account => (
  dispatch,
  getState
) => {
  const state = getState();
  const bc = state.consortium.getIn(["customers", account.get("accessNumber")]);
  const bankNumber = bc.get("bankNumber");
  const companyNumber = bc.get("companyNumber");

  dispatch({
    type: SELECT_RECURRING_PAYMENT_ACCOUNT,
    payload: account,
    meta: { bankNumber, companyNumber }
  });
};

export const cancelPaymentAndUpdateHistoryAction = (
  bankNumber,
  companyNumber,
  transactionNumber
) => (dispatch, getState) => {
  dispatch(
    cancelPaymentAction(bankNumber, companyNumber, transactionNumber)
  ).then(result => {
    const state = getState();
    const userid = state.user.get("id");

    const billing = state.billing;
    const accountPath = billing.getIn(["accounts", "current"]);
    const account = billing.getIn(accountPath);
    const accessNumber = account.get("accessNumber");

    const bc = state.consortium.getIn(["customers", accessNumber]);
    const consortiumId = bc.get("consortiumId");

    return dispatch(getRecentPaymentsAction(consortiumId, userid));
  });
};
