import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  showLoadingAction,
  hideLoadingAction
} from "./../../api/actions/loading";
import { updateContactInfoAction } from "./../../api/actions/user";
import { UPDATE_CONTACT_INFO, FAILURE } from "./../../api/actions/actionTypes";
import ContactForm from "./ContactForm.jsx";
import RouteHelper from "./../../utils/RouteHelper";
import { _t } from "./../../text/locale";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { setPageInfoAction } from "./../../api/actions/pageInfo";

let t;
let pageTitle = "Contact Information";

class ContactInfo extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.pending === true) {
      this.props.showLoading(t("Updating account information..."));
    } else if (
      !nextProps.pending &&
      this.props.pending &&
      !nextProps.lastRequestFailed
    ) {
      this.props.hideLoading();
      const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
        "/"
      );
      this.props.router.push(path);
    } else {
      this.props.hideLoading();
    }
  }

  onSubmit(formData) {
    this.props.updateUser(this.props.params.consortiumId, formData);
  }

  render() {
    return (
      <div>
        <div className="flex-container">
          <div className="flex-content" style={{ marginTop: "16px" }}>
            <ContactForm
              onSubmit={this.onSubmit}
              t={t}
              {...this.props.required}
              nextAction={t("Update Information")}
              contactInfo={this.props.contactInfo}
              disabled={this.props.pending}
              shouldFocusOnMount
              allowMpayTextReminders={this.props.allowMpayTextReminders}
              loggedIn={this.props.loggedIn}
            />
          </div>
          <div
            className="flex-tipBox"
            aria-label="Contact Information Tip Box"
            role="region"
          >
            <AlertInfo
              text={t("TIP_BOX_CONTACT_INFORMATION")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pending: !!state.httpRequests.getIn([UPDATE_CONTACT_INFO, "pending"]),
    lastRequestFailed:
      state.httpRequests.getIn([UPDATE_CONTACT_INFO, "lastStatus"]) === FAILURE,
    required: state.consortium.get("newUserRequirements").toJS(),
    contactInfo: state.user.get("contact"),
    allowMpayTextReminders: state.consortium.get("customers").some(c => c.get("allowMpayTextReminders")),
    loggedIn: state.auth.get("authenticated")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (consortiumId, contactInfo) => {
      dispatch(updateContactInfoAction(consortiumId, contactInfo));
    },
    showLoading: titleText => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
    dispatchSetPageInfo: pageInfoData => {
      dispatch(setPageInfoAction(pageInfoData));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactInfo)
);
