import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { List, ListItemText } from "@material-ui/core";
import FocusableListItem from "../../components/FocusableListItem.jsx";
import ChevronRight from "@material-ui/icons/ChevronRight";
import RouteHelper from "./../../utils/RouteHelper";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { setPageInfoAction } from "./../../api/actions/pageInfo";

import { _t } from "./../../text/locale";

const t = _t();
let pageTitle = "Loan Accounts";
class ManageUserAccountsLanding extends Component {
  constructor(props) {
    super(props);

    this.shouldFocus = this.shouldFocus.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  shouldFocus(entry, bcs) {
    const currentKey = entry.get("accessNumber");
    const firstKey = bcs.first().get("accessNumber");

    return currentKey === firstKey;
  }
  render() {
    const makeListItem = c => {
      const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
        `/user/accounts/${c.get("accessNumber")}`
      );
      return (
        <li key={c}>
          <FocusableListItem
            key={c}
            onClick={event => {
              this.props.router.push(path);
            }}
            shouldFocusOnMount={this.shouldFocus(c, this.props.customers)}
          >
            <ListItemText primary={c.get("serviceDescription")} />
            <ChevronRight />
          </FocusableListItem>
        </li>
      );
    };
    let customerList = this.props.customers.map(c => makeListItem(c));
    return (
      <div>
        <h2>{t("Manage Accounts")}</h2>
        <div className="flex-container">
          <div className="flex-content">
            <List>{customerList}</List>
          </div>
          <div
            className="flex-tipBox"
            aria-label="Manage Account Tip Box"
            role="region"
          >
            <AlertInfo
              text={t("TIP_BOX_BILLING_ACCOUNTS")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    customers: state.consortium.get("customers").toList()
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetPageInfo: pageInfoData => {
      dispatch(setPageInfoAction(pageInfoData));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageUserAccountsLanding)
);
