import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ListItemText, Avatar, ListItemAvatar } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { formatCardNetwork } from "./FormatCardNetwork.js";
import CreditCard from "@material-ui/icons/CreditCard";
import AccountBalance from "@material-ui/icons/AccountBalance";
import Delete from "@material-ui/icons/Delete";
import Warning from "@material-ui/icons/Warning";
import FocusableListItem from "../../../components/FocusableListItem.jsx";

class EditPaymentOption extends Component {
  formatPrimaryText(isBlocked, title) {
    let style = {};

    if (isBlocked) {
      style = { color: grey[600] };
    }

    return <span style={style}>{title}</span>;
  }

  formatSecondaryTextIf(isBlocked, message) {
    if (isBlocked) {
      let style = { color: grey[600] };
      return <span style={style}>{this.props.t(message)}</span>;
    }

    return "";
  }

  ccBlockedMessage(binBlocked, creditCardTypeBlocked, network) {
    if (creditCardTypeBlocked) {
      return formatCardNetwork(network) + " debit card allowed.";
    }
    if (binBlocked) {
      return "Credit card issuer blocked";
    }

    return "";
  }

  achBlockedMessage(blockedEnum) {
    switch (blockedEnum) {
      case "InvalidRoutingNumber":
        return this.props.t("Routing number blocked by financial institution.");
      case "None":
        return "";
      default:
        return "";
    }
  }

  render() {
    const creditAvatar = (
      <Avatar style={this.props.avatarStyle}>
        <CreditCard bgcolor="primary.contrastText" />
      </Avatar>
    );
    const bankAvatar = (
      <Avatar style={this.props.avatarStyle}>
        <AccountBalance bgcolor="primary.contrastText" />
      </Avatar>
    );
    const deleteAvatar = (
      <Avatar style={this.props.avatarStyle}>
        <Delete bgcolor="primary.contrastText" />
      </Avatar>
    );
    const warningAvatar = (
      <Avatar style={this.props.avatarStyle}>
        <Warning bgcolor="primary.contrastText" />
      </Avatar>
    );

    let isBlocked = false;
    let message = "";

    if (this.props.bankAccount) {
      isBlocked = this.props.isBlocked;
      message = this.achBlockedMessage(this.props.blockedMessage);
    } else {
      isBlocked = this.props.isBlocked || this.props.creditCardTypeBlocked;
      message = this.ccBlockedMessage(
        this.props.isBlocked,
        this.props.creditCardTypeBlocked,
        this.props.network
      );
    }

    let leftAvatar;
    if (this.props.isExpired || isBlocked) {
      leftAvatar = warningAvatar;
    } else {
      leftAvatar = this.props.bankAccount ? bankAvatar : creditAvatar;
    }

    const rightAvatar = this.props.isEdit ? (
      <ListItemAvatar aria-label="Delete payment method">
        {deleteAvatar}
      </ListItemAvatar>
    ) : null;

    return (
      <FocusableListItem
        onClick={this.props.onClick}
        disabled={isBlocked && !this.props.isEdit}
        shouldFocusOnMount={this.props.shouldFocusOnMount}
      >
        <ListItemAvatar>{leftAvatar}</ListItemAvatar>
        <ListItemText
          primary={this.formatPrimaryText(isBlocked, this.props.title)}
          secondary={this.formatSecondaryTextIf(isBlocked, message)}
        />
        {rightAvatar}
      </FocusableListItem>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loggedIn: state.auth.get("authenticated"),
    styles: state.style.get("palette")
  };
};

export default withRouter(connect(mapStateToProps, null)(EditPaymentOption));
