import React from "react";
import { connect } from "react-redux";
import { ConnectedPaymentReceipt } from "./PaymentReceipt.jsx";
import { ConnectedAutoPayEnrollmentReceipt } from "./AutoPayEnrollmentReceipt.jsx";
import ThankYou from "./ThankYou.jsx";

export const ThankYouContainer = (props, context) => {
  return (
    <div>
      <div className="no-print">
        <ThankYou {...props} />
      </div>
      <div className="only-print">
        {props.isAutoPay ? (
          <ConnectedAutoPayEnrollmentReceipt {...props} />
        ) : (
          <ConnectedPaymentReceipt {...props} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isAutoPay: state.payments.getIn(["current", "isAutoPay"]),
  };
};

export default connect(mapStateToProps)(ThankYouContainer);
