import React from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";
import Button from "@material-ui/core/Button";
import ContactNameFormFields from "./ContactNameFormFields.jsx";
import AddressFormFields from "./AddressFormFields.jsx";
import { ResponsiveUtil } from "./../../utils/ResponsiveUtil";

const mobileStyle = {
  width: "100%",
  maxWidth: "none",
  marginBottom: "10px"
};
const isMobile = ResponsiveUtil.isMobile();

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      cellPhone: "",
      mpayTextRemindersOptIn: "",
      email: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      valid: false
    };

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);

    this.onInput = this.onInput.bind(this);
  }
  static propTypes = {
    t: PropTypes.func.isRequired
  };
  UNSAFE_componentWillMount() {
    if (this.props.contactInfo) {
      this.setState({
        name: this.props.contactInfo.get("name"),
        phone: this.props.contactInfo.get("phone"),
        cellPhone: this.props.contactInfo.get("cellPhone"),
        mpayTextRemindersOptIn: this.props.contactInfo.get("mpayTextRemindersOptIn"),
        email: this.props.contactInfo.get("email"),
        address: this.props.contactInfo.get("address"),
        city: this.props.contactInfo.get("city"),
        state: this.props.contactInfo.get("state"),
        zip: this.props.contactInfo.get("zip")
      });
    }
  }
  onValidSubmit() {
    // Copy our form model and delete form-related properties
    // This is less ~effort~ than reinitializing an object with all the properties.
    const model = Object.assign({}, this.state);
    delete model.valid;

    this.props.onSubmit(model);
  }
  onValid() {
    this.setState({ valid: true });
  }
  onInvalid() {
    this.setState({ valid: false });
  }
  onInput(name) {
    return value => {
      let update = {};
      update[name] = value;
      this.setState(update);
    };
  }
  render() {
    return (
      <div role="form" aria-label="Contact Information">
        <Formsy
          noValidate
          onValidSubmit={this.onValidSubmit}
          onValid={this.onValid}
          onInvalid={this.onInvalid}
        >
          <div>
            <ContactNameFormFields
              t={this.props.t}
              onInput={this.onInput}
              formData={this.state}
              requireEmail={this.props.requireEmail}
              requirePhone={this.props.requirePhone}
              shouldFocusOnMount={this.props.shouldFocusOnMount}
              allowMpayTextReminders={this.props.allowMpayTextReminders}
              loggedIn={this.props.loggedIn}
            />
          </div>
          <div>
            <AddressFormFields
              t={this.props.t}
              onInput={this.onInput}
              formData={this.state}
              requireAddress={this.props.requireAddress}
              style={isMobile ? mobileStyle : null}
            />
          </div>
          <div>
            <Button
              variant="contained"
              type="submit"
              disabled={!this.state.valid || this.props.disabled}
              style={isMobile ? mobileStyle : null}
              color="primary"
            >
              {this.props.nextAction}
            </Button>
          </div>
        </Formsy>
      </div>
    );
  }
}

export default ContactForm;
