import React from "react";
import { List } from "@material-ui/core";
import AccountDetailItem from "./AccountDetailItem.jsx";

export default class AccountsList extends React.Component {
  render() {
    const accountItems = this.props.accounts.map(a => (
      <li key={a}>
        <AccountDetailItem
          t={this.props.t}
          key={a}
          account={a}
          loggedIn={this.props.loggedIn}
          onClickDelete={this.props.onClickDelete}
          fromManagePage={this.props.fromManagePage}
        />
      </li>
    ));
    return <List>{accountItems}</List>;
  }
}
