// This file is for generating relative links for routing around the web app.
// React-Router doesn't support relative links so we gotta homebrew it with regex
import { List } from "immutable";

export const shouldRouteToLanding = (location) => {
  const allowedEntryPoints = List.of(
    "/wip",
    "/user/sso/link",
    "/user/sso/landing",
    "/user/password-reset",
    "/user/sign-in",
    "/user/register",
    "/user/username-reminder",
    "/terms-and-conditions",
    "/privacy-policy"
  );

  return !allowedEntryPoints.some((a) => location.pathname.includes(a));
};

export const getRelativeAppViewRoute = (location) => (targetPath) => {
  const fullPath = location.pathname;
  // We care about the query string because it lets us keep debug session information in the url during development
  const queryString = location.search ? location.search : "";

  // The step query parameter is specific to password resets, which is not relevant to customer routes so it's only here.
  // That said, manually removing the parameter here with a regex is gross.
  let queryWithStepRemoved = queryString.replace(
    new RegExp("(\\?|\\&)?step=(\\d+)(\\&)?"),
    ""
  );
  if (queryWithStepRemoved !== "" && queryWithStepRemoved[0] !== "?") {
    queryWithStepRemoved = "?" + queryWithStepRemoved;
  }

  const regex = new RegExp("(/v2)?/c/(\\d+)");
  const basePath = fullPath.match(regex)[0];
  return basePath + targetPath + queryWithStepRemoved;
};

export const getRelativeCustomerRoute = (location) => (targetPath) => {
  const fullPath = location.pathname;
  // We care about the query string because it lets us keep debug session information in the url during development
  const queryString = location.search ? location.search : "";

  const regex = new RegExp("(/v2)?/c/(\\d+)/business/(\\d+)");
  const basePath = fullPath.match(regex)[0];
  return basePath + targetPath + queryString;
};

export default {
  shouldRouteToLanding,
  getRelativeAppViewRoute,
  getRelativeCustomerRoute,
};
