import { getAuthorizationToken, getSsoAuthorizationToken } from "./../requests/auth";
import { AUTHENTICATE_GUEST, AUTHENTICATE_SSO } from "./actionTypes";

export const getGuestAuthorizationAction = consortiumId => (
  dispatch,
  getState
) => {
  const promise = getAuthorizationToken(consortiumId);
  const action = { type: AUTHENTICATE_GUEST, payload: { promise } };
  dispatch(action);

  return promise;
};

export const getSsoAuthorizationAction = (consortiumId, accessTokenId, ssoType) => {
  return (dispatch) => {
    const promise = getSsoAuthorizationToken(consortiumId, accessTokenId, ssoType);
    const action = { type: AUTHENTICATE_SSO, payload: { promise } };
    dispatch(action);

    return promise;  
  };
};
