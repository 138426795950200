import React from "react";
import { connect } from "react-redux";
import { AppBar, Toolbar, Avatar, Drawer } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
  getInitialState,
  getSsoInitialState,
} from "./../api/actions/initialize";
import { SUCCESS, PENDING } from "./../api/actions/actionTypes";
import { setPageInfoAction } from "./../api/actions/pageInfo";

import SidebarContent from "./../components/SidebarContent.jsx";
import SessionManager from "./../components/SessionManager.jsx";
import LoadingModal from "./../components/LoadingModal.jsx";
import NotificationModal from "./../components/NotificationModal.jsx";
import LoadFailure from "./errors/LoadFailure.jsx";

import { ResponsiveUtil } from "../utils/ResponsiveUtil";

import RouteHelper from "./../utils/RouteHelper";
import { withRouter } from "react-router";

import queryString from "query-string";

import { _t } from "./../text/locale";

const t = _t();
class AppView extends React.Component {
  constructor(props) {
    super(props);

    this.updateDimensions = this.updateDimensions.bind(this);
    this.openDrawer = this.openDrawer.bind(this);
    this.focusMainContent = this.focusMainContent.bind(this);
    this.onSelectRoute = this.onSelectRoute.bind(this);
    this.routeToLanding = this.routeToLanding.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.updateDimensions();

    if (RouteHelper.shouldRouteToLanding(this.props.location)) {
      this.routeToLanding();
    }

    if (this.props.initialized) {
      document.getElementById("loading-spinner").style.display = "none";
      if (typeof this.props.title !== "undefined") {
        document.getElementsByTagName("title")[0].innerText = t(
          this.props.title + " Payments"
        );
      }
      return;
    }

    const queryParamValue = queryString.parse(this.props.location.search);
    const consortiumId = this.props.params.consortiumId;

    if (queryParamValue.type === "saml2") {
      this.props.dispatchGetSsoInitialState(
        consortiumId,
        queryParamValue.id,
        queryParamValue.type
      );
      this.props.router.push(`/v2/c/${consortiumId}`);
      return;
    }

    this.props.dispatchGetInitialState(this.props.params.consortiumId);
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  componentWillReceiveProps(nextProps) {
    if (!this.props.loaded && nextProps.loaded) {
      // If we use this in a third place make it a function in an external file
      document.getElementById("loading-spinner").style.display = "none";
      if (typeof this.props.title !== "undefined") {
        document.getElementsByTagName("title")[0].innerText = t(
          this.props.title + " Payments"
        );
      }
    }
  }
  updateDimensions() {
    if (ResponsiveUtil.isMedium()) {
      this.setState({
        docked: true,
        drawerOpen: true,
        showMenuIconButton: false,
      });
    } else {
      this.setState({
        docked: false,
        drawerOpen: false,
        showMenuIconButton: true,
      });
    }
  }
  openDrawer(isOpen) {
    this.setState({ drawerOpen: isOpen });
  }
  onSelectRoute(route) {
    this.openDrawer(false);
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      route
    );
    this.props.router.push(path);
  }
  focusMainContent() {
    if (!ResponsiveUtil.isMobile()) {
      this.refs.returnToNavigationLink.focus();
    }
  }
  routeToLanding() {
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)("/");
    this.props.router.push(path);
  }
  render() {
    if (!this.props.initialized && this.props.loaded) {
      return <LoadFailure />;
    }

    if (!this.props.loaded) {
      return <div />;
    }

    const sidebarContent = (
      <SidebarContent
        onSelectRoute={this.onSelectRoute}
        location={this.props.location}
      />
    );

    const avatarStyle = {
      backgroundColor: this.props.styles.get("primary1Color"),
    };

    const menuAvatar = (
      <Avatar style={avatarStyle}>
        <MenuIcon bgcolor="primary.contrastText" />
      </Avatar>
    );

    const chevronAvatar = (
      <Avatar style={avatarStyle}>
        <ChevronLeftIcon bgcolor="primary.contrastText" />
      </Avatar>
    );

    const leftAvatar = this.state.showMenuIconButton ? (
      <IconButton
        aria-label="open side menu"
        onClick={() => this.openDrawer(true)}
      >
        {menuAvatar}
      </IconButton>
    ) : (
      <div />
    );

    const closeDrawerAvatar = this.state.showMenuIconButton ? (
      <IconButton
        aria-label="close side menu"
        onClick={() => this.openDrawer(false)}
      >
        {chevronAvatar}
      </IconButton>
    ) : (
      <div />
    );

    const drawerWidth = "225px";

    const drawerStyles = {
      width: drawerWidth,
      height: "400%",
      background: this.props.sidebarColor,
    };

    let mainContentStyles = {};

    if (ResponsiveUtil.isMedium()) {
      mainContentStyles = {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth})`,
      };
    }

    const drawerType = ResponsiveUtil.isMedium() ? "permanent" : "temporary";
    return (
      <div>
        <Drawer
          open={this.state.drawerOpen}
          className="no-print"
          variant={drawerType}
          onClick={() => this.openDrawer(false)}
        >
          <div
            style={{
              background: this.props.sidebarColor,
              textAlign: "right",
            }}
          >
            {closeDrawerAvatar}
          </div>
          <div style={drawerStyles}>{sidebarContent}</div>
        </Drawer>
        <div className="no-print" style={{ left: drawerWidth }}>
          {/* Static components */}
          <SessionManager onTimeout={this.routeToLanding} />
          <LoadingModal />
          <NotificationModal focusCallback={this.focusMainContent} />
          {/* Header */}
          <AppBar
            color="primary"
            position="relative"
            style={{ zIndex: 1, ...mainContentStyles }}
          >
            <Toolbar>
              {leftAvatar}
              <h1 style={{ marginTop: "8px", fontSize: "24px" }}>
                {this.props.pageTitle}
              </h1>
            </Toolbar>
          </AppBar>
        </div>
        {/* Body */}
        <div style={mainContentStyles} aria-live="polite">
          <main id="maincontent" className="main-content">
            <div className="skip">
              <a href="#mainmenu" ref="returnToNavigationLink">
                {t("Return to navigation menu")}
              </a>
            </div>
        {this.props.children}
          </main>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const initialized = state.initialize.get("state") === SUCCESS;
  const loaded = state.initialize.get("state") !== PENDING;
  const title = state.consortium.get("consortiumName");
  const logoPath = state.style.get("logoPath");
  const pageTitle = state.session.get("pageData");

  return {
    initialized: initialized,
    loaded: loaded,
    title: title,
    sidebarColor: state.style.getIn(["palette", "primary3Color"]),
    styles: state.style.get("palette"),
    logoPath: logoPath,
    pageTitle: pageTitle,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchGetInitialState: (id) => {
      dispatch(getInitialState(id));
    },
    dispatchGetSsoInitialState: (consortiumId, accessTokenId, ssoType) => {
      dispatch(getSsoInitialState(consortiumId, accessTokenId, ssoType));
    },
    dispatchSetPageInfo: (pageData) => {
      dispatch(setPageInfoAction(pageData));
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppView)
);
