import React from "react";
import { ListItemText } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Blocked from "@material-ui/icons/Block";
import FocusableListItem from "../../components/FocusableListItem.jsx";

export default class ClickableAccountDetailItem extends React.Component {
  UNSAFE_componentWillMount() {
    this.onClick = this.props.onClick;
  }
  formatPrimaryText(account, isDisabled) {
    let style = {};
    if (isDisabled) {
      style = { color: grey[600] };
    }

    let accountTitle;
    if (account.get("name")) {
      accountTitle =
        account.get("formattedAccountNumber") + " " + account.get("name");
    } else {
      accountTitle = account.get("formattedAccountNumber");
    }
    return <div style={style}>{accountTitle}</div>;
  }
  formatSecondaryText(account, shouldDisplayPaymentCooldown) {
    let style = {
      color: grey[600]
    };
    let isBlocked = account.get("isBlocked");
    if (isBlocked) {
      return <span style={style}>{this.props.t("BLOCKED ACCOUNT")}</span>;
    }
    let cooldownLine = null;
    if (shouldDisplayPaymentCooldown) {
      const paymentCooldownRemaining = account.get("paymentCooldownRemaining");
      cooldownLine = (
        <span style={{ color: grey[600] }}>
          {this.props.t(
            "Next payment allowed in approximately {{COOLDOWN_REMAINING}}.",
            {
              COOLDOWN_REMAINING: paymentCooldownRemaining.humanize()
            }
          )}
        </span>
      );
    }
    return <span style={style}>{cooldownLine}</span>;
  }
  render() {
    const account = this.props.account;
    const shouldDisplayPaymentCooldown =
      this.props.enforcePaymentCooldown &&
      this.props.account.has("paymentCooldownRemaining") &&
      this.props.account.get("paymentCooldownRemaining").as("seconds") > 0;
    const isDisabled = account.get("isBlocked") || shouldDisplayPaymentCooldown;
    return (
      <FocusableListItem
        onClick={() => this.onClick(account)}
        disabled={isDisabled}
        shouldFocusOnMount={this.props.shouldFocusOnMount}
      >
        <ListItemText
          primary={this.formatPrimaryText(account, isDisabled)}
          secondary={this.formatSecondaryText(
            account,
            shouldDisplayPaymentCooldown
          )}
        />
        {isDisabled ? <Blocked color={grey[600]} /> : <ChevronRight />}
      </FocusableListItem>
    );
  }
}
