import React from "react";
import Cleave from "cleave.js/dist/cleave-react.min";
import { withFormsy } from "formsy-react";
import { TextField } from "@material-ui/core";

class CardExpirationFormatterInput extends React.Component {
  render() {
    const { inputRef, onChange, value, ...otherProps } = this.props;
    return (
      <Cleave
        {...otherProps}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        options={{
          date: true,
          datePattern: ["m", "y"]
        }}
        onChange={e => onChange(e.currentTarget.value)}
        value={value}
      />
    );
  }
}

class FormsyCardExpirationInternal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { touched: false };
    this.onBlur = this.onBlur.bind(this);
  }
  onBlur() {
    this.setState({ touched: true });
  }
  render() {
    return (
      <TextField
        id={this.props.name}
        name="cardExpiration"
        label={this.props.floatingLabelText}
        error={this.state.touched && !this.props.isValid()}
        helperText={
          this.state.touched && !this.props.isValid()
            ? this.props.getErrorMessage()
            : null
        }
        onBlur={this.onBlur}
        value={this.props.value}
        InputProps={{ inputComponent: CardExpirationFormatterInput }}
        // "inputProps" gives the props object to the inputComponent of "InputProps". It is not a duplicate prop. Thank material-ui for the terrible naming.
        // eslint-disable-next-line
        inputProps={{
          onChange: this.props.onChange,
          value: this.props.value
        }}
      />
    );
  }
}

const HOCFormsyCardExpiration = withFormsy(FormsyCardExpirationInternal);

// The HOC function causes validations to exist on the component.
class FormsyCardExpiration extends React.Component {
  render() {
    return (
      <HOCFormsyCardExpiration
        {...this.props}
        validations="isExpDate"
        validationErrors={{
          isDefaultRequiredValue: this.props.t("Expiration date is required"),
          isExpDate: this.props.t("Please enter a valid expiration date")
        }}
      />
    );
  }
}

export default FormsyCardExpiration;
