import * as Formsy from "formsy-react";
import numeral from "numeral";
import { isValidCreditCard, getCardTypeString } from "./CreditCardValidator";
import { isValidRoutingNumber } from "./BankAccountValidator";
import moment from "moment";
import { getCurrentDateTime } from "./DateUtils";

const moneyRegex = /^\d+(?:\.\d{1,2}){0,1}$/; // Positive number with two decimals
const phoneNumberRegex = /^\(?\d{3}\)?\s?\d{3}-?\d{4}$/; // A valid phone number is just 10 digits, but maybe has special characters
const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/; // Accepts 5 digit zip code or 9 digit zip code: 49544 or 49544-2551

// At least 8 characters, contains a lowercase letter, an uppercase letter, and a number or symbol
// TODO3: Fix instead of ignoring the warning
// eslint-disable-next-line
const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d\s~`!@#\$%\^&\*\(\)\-_\+=\{\}\[\]\|\;:\""\\<>,./\?'])[A-Za-z\d\s~`!@#\$%\^&\*\(\)\-_\+=\{\}\[\]\|\;:\""\\<>,./\?']{8,}$/;

const stateList = [
  "AK",
  "AL",
  "AR",
  "AS",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MH",
  "MI",
  "MN",
  "MO",
  "MP",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "PW",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY"
];

export const isMoneyValidationRule = (values, value) => {
  if (!value) {
    return false;
  }

  // We want a positive, non-zero payment amount.
  // Eventually we may want to include some kind of minimum allowed payment
  const val = numeral(value).value();
  if (val <= 0) {
    return false;
  }

  return value.match(moneyRegex);
};

export const isPhoneValidationRule = (values, value) => {
  if (!value) {
    return true;
  }

  return !!value.match(phoneNumberRegex);
};

export const isZipCode = (values, value) => {
  if (!value) {
    return true;
  }

  return !!value.match(zipCodeRegex);
};

export const isPasswordRegex = (values, value) => {
  if (!value) {
    return true;
  }

  return !!value.match(passwordRegex);
};

export const isCreditCard = (values, value) => {
  if (!value) {
    return true;
  }

  return isValidCreditCard(value);
};

export const isRoutingNumber = (values, value) => {
  if (!value) {
    return true;
  }

  return isValidRoutingNumber(value);
};

export const isExpDate = (values, value) => {
  // If YY is above 68, moment assumes that it's 19XX
  // So 68 -> 2068, 69 -> 1969
  // This will probably be updated in moment within the next 50 years.
  let inputDate = moment(value, "MMYY");
  let now = getCurrentDateTime();

  return inputDate.isSameOrAfter(now, "month");
};

export const isAcceptedType = (values, value, acceptedCardTypes) => {
  // If bc only accepts debit, make sure the bins for different networks are still valid until card token is requested
  let cardType = getCardTypeString(value);
  if (
    cardType === "mastercard" &&
    acceptedCardTypes.includes("mastercard debit card")
  ) {
    return true;
  }
  if (cardType === "visa" && acceptedCardTypes.includes("visa debit card")) {
    return true;
  }

  return acceptedCardTypes.includes(cardType);
};

export const isValidStateRule = (value, stateValue) => {
  if (!value) {
    return true;
  }

  return stateList.includes(stateValue);
};

export const isNotBlocked = (values, value, blockedList) => {
  if (!value) {
    return true;
  }

  return !blockedList.includes(value);
};

export const isAboveMinimum = (values, amountPaid, minAmount) => {
  return amountPaid >= minAmount;
};

export const isBelowMaximum = (values, amountPaid, maxAmount) => {
  return amountPaid <= maxAmount;
};

export default class FormsyUtil {
  static initializeFormsyRules() {
    Formsy.addValidationRule("isMoney", isMoneyValidationRule);
    Formsy.addValidationRule("isPhoneNumber", isPhoneValidationRule);
    Formsy.addValidationRule("isZipCode", isZipCode);
    Formsy.addValidationRule("isValidPassword", isPasswordRegex);
    Formsy.addValidationRule("isCreditCard", isCreditCard);
    Formsy.addValidationRule("isExpDate", isExpDate);
    Formsy.addValidationRule("isAcceptedType", isAcceptedType);
    Formsy.addValidationRule("isRoutingNumber", isRoutingNumber);
    Formsy.addValidationRule("isState", isValidStateRule);
    Formsy.addValidationRule("isNotBlocked", isNotBlocked);
    Formsy.addValidationRule("isAboveMinimum", isAboveMinimum);
    Formsy.addValidationRule("isBelowMaximum", isBelowMaximum);
  }
}
