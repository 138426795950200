import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Step,
  Stepper,
  StepButton,
  StepConnector,
  MobileStepper,
  Button,
  withStyles,
} from "@material-ui/core";
import RouteHelper from "./../utils/RouteHelper";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { ResponsiveUtil } from "../utils/ResponsiveUtil";
import { _t } from "./../text/locale";

const t = _t();

class PaymentStepper extends Component {
  constructor(props) {
    super(props);

    this.selectRoute = this.selectRoute.bind(this);
  }

  selectRoute(route, currentLocation) {
    if (route === "/") {
      const fullRoute = RouteHelper.getRelativeAppViewRoute(currentLocation)(
        route
      );
      this.props.router.push(fullRoute);
    } else {
      const fullRoute = RouteHelper.getRelativeCustomerRoute(currentLocation)(
        route
      );
      this.props.router.push(fullRoute);
    }
  }

  render() {
    const StyledButton = withStyles({
      root: {
        "&:hover": {
          textDecoration: "underline",
        },
        "&:focus": {
          textDecoration: "underline",
        },
      },
    })(StepButton);

    let isMobile = ResponsiveUtil.isMobile();

    const steps = [];
    steps.push({
      name: "account-select",
      title: t("Account Select"),
      route: "/accounts",
    });
    steps.push({
      name: "payment-method",
      title: t("Payment Method"),
      route: "/payment/select-method",
    });

    // Auto-Pay Only or Guest users don't get to choose a payment option
    // Also ignore payment options if one time is the only option.
    if (
      !this.props.autopayOnly &&
      this.props.isSignedIn &&
      !(
        this.props.paymentOptions.count() === 1 &&
        this.props.paymentOptions.first().option === "oneTime"
      )
    ) {
      steps.push({
        name: "payment-options",
        title: t("Payment Options"),
        route: "/payment/options",
      });
    }

    // Auto-Pay Only or Auto-Pay Allowed don't get to set a payment amount
    if (!this.props.autopayOnly && !this.props.autopayAllowedSelected) {
      // This is currently duplicated from PaymentOptions.jsx (sans auto-pay, because you shouldn't have this option as auto-pay)
      // If we need it in a third location, refactor it into a method and deal with any extra handling
      let routeTarget = "";
      switch (this.props.selectedPaymentType) {
        case "ccRecurring":
        case "achRecurring":
          routeTarget = "/payment/recurring";
          break;
        case "oneTime":
        default:
          routeTarget = "/payment/one-time";
          break;
      }

      steps.push({
        name: "payment-amount",
        title: t("Payment Amount"),
        route: routeTarget,
      });
    }

    steps.push({
      name: "contact-information",
      title: t("Contact Information"),
      route: "/checkout/contact-info",
    });

    steps.push({
      name: "payment-confirmation",
      title: this.props.autopayAllowedSelected
        ? t("Auto-Pay Confirmation")
        : t("Payment Confirmation"),
      route: "/payment/confirm-payment",
    });

    steps.push({
      name: "payment-receipt",
      title: this.props.autopayAllowedSelected
        ? t("Enrollment Receipt")
        : t("Payment Receipt"),
      route: "/thank-you",
    });

    // Current step
    const index = steps.findIndex((s) => s.name === this.props.step);

    // The step this component is told we are on isn't in our list.
    // Don't render anything and fail gracefully.
    // This is used because we still (briefly) hit the payment options page even
    // if we don't put it in the stepper, for paths that "skip" that page.
    if (index === -1) {
      return null;
    }

    if (isMobile) {
      return (
        <MobileStepper
          role="navigation"
          aria-label="Payment Process Breadcrumbs"
          variant="dots"
          steps={steps.length}
          position="static"
          activeStep={index}
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "transparent",
          }}
          backButton={
            <Button
              color="primary"
              onClick={() =>
                this.selectRoute(
                  index === 0 ? "/" : `${steps[index - 1].route}`,
                  this.props.currentLocation
                )
              }
            >
              <ChevronLeft />
              {index === 0 ? t("Home") : `Return to ${steps[index - 1].title}`}
            </Button>
          }
        />
      );
    }
    return (
      <Stepper
        role="navigation"
        aria-label="Payment Process Breadcrumbs"
        activeStep={index}
        orientation={"horizontal"}
        connector={<StepConnector />}
      >
        {steps.map((step) => {
          return (
            <Step key={step.title}>
              <StyledButton
                onClick={() =>
                  this.selectRoute(step.route, this.props.currentLocation)
                }
              >
                {step.title}
              </StyledButton>
            </Step>
          );
        })}
      </Stepper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const billingProcessType = state.consortium.getIn([
    "customers",
    ownProps.params.accessNumber,
    "billingProcessType",
  ]);

  const paymentOptions = state.consortium.getIn([
    "customers",
    ownProps.params.accessNumber,
    "paymentOptions",
  ]);

  return {
    isSignedIn: !!state.auth.get("authenticated"),
    paymentOptions: paymentOptions,
    selectedPaymentType: state.payments.getIn(["current", "frequency"]),
    autopayOnly: billingProcessType === 98,
    // Auto-Pay Allowed only matters if they have selected it
    autopayAllowedSelected:
      billingProcessType === 99 &&
      state.payments.getIn(["current", "isAutoPay"]),
  };
};

export default withRouter(connect(mapStateToProps)(PaymentStepper));
