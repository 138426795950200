import React from "react";
import { connect } from "react-redux";
import { List as ImmutableList } from "immutable";

import PaymentMethodList from "./options/PaymentMethodList.jsx";

import { showChoiceNotificationAction } from "./../../api/actions/notification";
import { deleteUserPaymentMethodAction } from "./../../api/actions/user";
import {
  showLoadingAction,
  hideLoadingAction,
} from "./../../api/actions/loading";
import { DELETE_USER_PAYMENT_METHOD } from "./../../api/actions/actionTypes";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { setPageInfoAction } from "./../../api/actions/pageInfo";

import { withRouter } from "react-router";
import RouteHelper from "./../../utils/RouteHelper";

import { _t } from "./../../text/locale";
const t = _t();
let pageTitle = "Manage Payment Methods";

export class InternalManagePaymentMethods extends React.Component {
  constructor(props) {
    super(props);

    this.routeTo = this.routeTo.bind(this);
    this.confirmItemDelete = this.confirmItemDelete.bind(this);
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.pending === true) {
      this.props.showLoading(t("Removing payment information..."));
    } else if (!nextProps.pending && this.props.pending) {
      this.props.hideLoading();
    }
  }
  UNSAFE_componentWillMount() {
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  routeTo(path) {
    return () => {
      const target = RouteHelper.getRelativeAppViewRoute(this.props.location)(
        path
      );
      this.props.router.push(target);
    };
  }
  confirmItemDelete(type) {
    return (item, description) => {
      var deleteCcContent = {
        title: t("Delete Credit Card"),
        message: `${t("Delete")} ${description}?`,
      };
      var deleteAchContent = {
        title: t("Delete Bank Account"),
        message: `${t("Delete")} ${description}?`,
      };

      this.props.showChoiceNotificationAction(
        type === "cc" ? deleteCcContent : deleteAchContent,
        {
          yes: () =>
            this.props.deletePaymentMethod(
              this.props.params.consortiumId,
              type,
              item.get("id")
            ),
          no: () => {
            /*noop*/
          },
        }
      );
    };
  }
  render() {
    return (
      <div>
        <div className="flex-container">
          <div className="flex-content">
            <PaymentMethodList
              t={t}
              isEdit
              routeTo={this.routeTo}
              creditCards={this.props.creditCards}
              ach={this.props.ach}
              onTapNewCard={this.routeTo(
                "/user/payment-methods/add-credit-card"
              )}
              onTapNewAch={this.routeTo(
                "/user/payment-methods/add-bank-account"
              )}
              onTapCard={this.confirmItemDelete("cc")}
              onTapAch={this.confirmItemDelete("ach")}
              avatarStyle={{
                backgroundColor: this.props.styles.get("primary1Color"),
              }}
              color="secondary"
              acceptsCreditCardPayments={this.props.acceptsCreditCardPayments}
              visaCreditCardBlocked={this.props.visaDebitOnly}
              mastercardCreditCardBlocked={this.props.masterCardDebitOnly}
              isCreditCardOnly={this.props.isCreditCardOnly}
            />
          </div>
          <div
            className="flex-tipBox"
            aria-label="Manage Payment Methods Tip Box"
            role="region"
          >
            <AlertInfo
              text={t("TIP_BOX_MANAGE_PAYMENT_METHODS")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    creditCards: state.user.getIn(["paymentMethods", "cc"]) || ImmutableList(),
    ach: state.user.getIn(["paymentMethods", "ach"]) || ImmutableList(),
    styles: state.style.get("palette"),
    pending: !!state.httpRequests.getIn([
      DELETE_USER_PAYMENT_METHOD,
      "pending",
    ]),
    acceptsCreditCardPayments: !state.consortium.get("cardTypes").isEmpty(),
    visaDebitOnly: state.consortium
      .get("cardTypes")
      .find((c) => c.includes("visa debit card")),
    masterCardDebitOnly: state.consortium
      .get("cardTypes")
      .find((c) => c.includes("mastercard debit card")),
    isCreditCardOnly: state.consortium
      .get("customers")
      .every((c) => c.get("isCreditCardOnly")),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    showChoiceNotificationAction: (content, callbacks) => {
      dispatch(showChoiceNotificationAction(content, callbacks));
    },
    deletePaymentMethod: (consortium, type, id) => {
      dispatch(deleteUserPaymentMethodAction(consortium, type, id));
    },
    showLoading: (titleText) => {
      dispatch(showLoadingAction(titleText));
    },
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InternalManagePaymentMethods)
);
