import { GET_STYLE_CUSTOMIZATION } from "./actionTypes";

import { getCustomStyles } from "./../requests/style";

export const getStyleCustomization = consortiumId => (dispatch, getState) => {
  const promise = getCustomStyles(consortiumId);

  dispatch({
    type: GET_STYLE_CUSTOMIZATION,
    payload: { promise }
  });

  return promise;
};
