import React from "react";
import { connect } from "react-redux";
import { ResponsiveUtil } from "./../../utils/ResponsiveUtil";

const isMobile = ResponsiveUtil.isMobile();

class AlertWarning extends React.Component {
  render() {
    const style = {
      backgroundColor: this.props.palette.get("accent1Color"),
      color: this.props.palette.get("alternateTextColor"),
      borderColor: this.props.palette.get("accent1Color"),
      padding: "10px",
      marginTop: "10px",
      marginBottom: "10px"
    };
    let mobileStyle = {
      width: "100%",
      maxWidth: "none",
      marginTop: "10px",
      marginBottom: "10px",
      backgroundColor: this.props.palette.get("accent1Color"),
      color: this.props.palette.get("alternateTextColor"),
      borderColor: this.props.palette.get("accent1Color")
    };
    return (
      <div
        className="alert"
        role="alert"
        style={isMobile ? mobileStyle : style}
      >
        {this.props.children}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    palette: state.style.getIn(["palette"])
  };
};

export default connect(mapStateToProps)(AlertWarning);
