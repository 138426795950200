import React from "react";
import { Grid } from "@material-ui/core";
import ManageAutoPayCard from "./ManageAutoPayCard.jsx";
import {
  DEACTIVATE_AUTO_PAY_ENROLLMENT,
  FAILURE,
} from "./../../api/actions/actionTypes";
import { showChoiceNotificationAction } from "./../../api/actions/notification";
import {
  showLoadingAction,
  hideLoadingAction,
} from "./../../api/actions/loading";
import {
  deactivateAutoPayEnrollmentAction,
} from "../../api/actions/autoPay";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { _t } from "./../../text/locale";
import AlertWarning from "./../errors/AlertWarning.jsx";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { setPageInfoAction } from "./../../api/actions/pageInfo";
import BackButton from "../../components/BackButton.jsx";
import { ResponsiveUtil } from "./../../utils/ResponsiveUtil";

let t;
let pageTitle = "Auto-Pay";
class ManageAutoPay extends React.Component {
  constructor(props) {
    super(props);

    this.deactivateAutoPayEnrollment = this.deactivateAutoPayEnrollment.bind(this);
    this.renderAutoPayEnrollment = this.renderAutoPayEnrollment.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.loadDeleting === true) {
      this.props.showLoading(t("Deactivating Auto-Pay enrollment..."));
    } else {
      this.props.hideLoading();
    }
  }

  deactivateAutoPayEnrollment(autoPayEnrollment) {
    this.props.showChoiceNotificationAction(
      {
        title: "Deactivate Auto-Pay Enrollment",
        message: `Do you want to deactivate this Auto-Pay enrollment?`,
      },
      {
        yes: () => {
          this.props.deactivateAutoPayEnrollment(
            this.props.params.consortiumId,
            this.props.userId,
            autoPayEnrollment.get("bankNumber"),
            autoPayEnrollment.get("companyNumber"),
            this.props.billingAccountNumber
          );
        },
        no: () => {
          /*noop*/
        },
      }
    );
  }

  renderAutoPayEnrollment(autoPayEnrollment) {
    let isMobile = ResponsiveUtil.isMobile();
    if (autoPayEnrollment.size === 0) {
      return (
        <Grid item xs={12} sm={8}>
          <div aria-label="Auto Pay Warning Box" role="region">
            <AlertWarning>
              <strong>{t("No Auto-Pay Enrollment found.")}&nbsp;</strong>
              <br className="visible-xs" />
              {t("Please add an Auto-Pay Enrollment.")}
            </AlertWarning>
          </div>
        </Grid>
      );
    }

    return (
      <div className="flex-container">
        {!isMobile ? (
          <Grid container spacing={8}>
            <div className="flex-content">
              <Grid item xs={12}>
                <ManageAutoPayCard
                  autoPayEnrollment={autoPayEnrollment}
                  t={t}
                  deleteOnClick={this.deactivateAutoPayEnrollment}
                  styles={this.props.styles}
                />
                <br />
              </Grid>
            </div>
            <div className="flex-content">
              <Grid item xs={12}>
                <div aria-label="Auto Pay Warning Box" role="region">
                  <AlertInfo
                    text={t("TIP_BOX_MANAGE_AUTO_PAY")}
                    location={this.props.location}
                  />
                </div>
              </Grid>
            </div>
          </Grid>
        ) : (
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <div aria-label="Auto Pay Warning Box" role="region">
                  <AlertInfo
                    text={t("TIP_BOX_MANAGE_AUTO_PAY")}
                    location={this.props.location}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <ManageAutoPayCard
                  autoPayEnrollment={autoPayEnrollment}
                  t={t}
                  deleteOnClick={this.deactivateAutoPayEnrollment}
                  styles={this.props.styles}
                />
                <br />
              </Grid>
            </Grid>
          )}
      </div>
    );
  }
  render() {
    return (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <div role="navigation" aria-label="Go Back">
            <BackButton
              route="/user/auto-pay/select"
              currentLocation={this.props.location}
              backText={t("Account Select")}
              shouldFocusOnMount
            />
          </div>
          <h2>
            {t(`Auto-Pay Enrollment for: `)}
            {this.props.billingAccountNumber}
          </h2>
          {this.renderAutoPayEnrollment(this.props.autoPayEnrollment)}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    autoPayEnrollment: state.autoPay.get("enrollment"),
    bankNumber: state.autoPay.getIn(["enrollment", "bankNumber"]),
    companyNumber: state.autoPay.getIn(["enrollment", "companyNumber"]),
    billingAccountNumber: state.autoPay.getIn(["enrollment", "billingAccountNumber"]),
    loadDeleting: !!state.httpRequests.getIn([DEACTIVATE_AUTO_PAY_ENROLLMENT, "pending"]),
    lastDeletingFailed:
      state.httpRequests.getIn([DEACTIVATE_AUTO_PAY_ENROLLMENT, "lastStatus"]) === FAILURE,
    userId: state.user.get("id"),
    styles: state.style.get("palette"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deactivateAutoPayEnrollment: (
      consortiumId,
      userId,
      bankNumber,
      companyNumber,
      billingAccountNumber
    ) => {
      dispatch(
        deactivateAutoPayEnrollmentAction(
          consortiumId,
          userId,
          bankNumber,
          companyNumber,
          billingAccountNumber
        )
      );
    },
    showChoiceNotificationAction: (content, callbacks) => {
      dispatch(showChoiceNotificationAction(content, callbacks));
    },
    showLoading: (titleText) => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageAutoPay)
);
