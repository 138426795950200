import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  GET_CARD_TOKEN,
  ADD_USER_PAYMENT_METHOD,
  FAILURE,
  TOKENIZE_AND_ADD_USER_PAYMENT_METHOD,
  VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD,
} from "./../../api/actions/actionTypes";
import {
  showLoadingAction,
  hideLoadingAction,
} from "./../../api/actions/loading";

import { submitCardInfoAction } from "./../../api/actions/payments";
import {
  addUserCcPaymentMethodFromPaymentWorkflowAction,
  addUserCcPaymentMethodAction,
} from "./../../api/actions/user";
import { formatCardNetworkForDatabase } from "./options/FormatCardNetwork.js";
import { getCardTypeString } from "./../../utils/CreditCardValidator";

import AddNewCardForm from "./AddNewCardForm.jsx";
import UserSignInPrompt from "./../user-accounts/UserSignInPrompt.jsx";
import { _t } from "./../../text/locale";
import AlertInfo from "./../errors/AlertInfo.jsx";
import RouteHelper from "./../../utils/RouteHelper";
import { setPageInfoAction } from "./../../api/actions/pageInfo";
import PaymentStepper from "../../components/PaymentStepper.jsx";
import BackButton from "../../components/BackButton.jsx";

let t;
let pageTitle = "New Credit / Debit Card";
class AddNewCard extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.pending === true) {
      this.props.showLoading(t("Securely storing credit card..."));
    } else if (
      !nextProps.pending &&
      this.props.pending &&
      !nextProps.lastRequestFailed
    ) {
      this.props.hideLoading();
      const confirmPaymentPath = this.props.params.accessNumber
        ? RouteHelper.getRelativeCustomerRoute(this.props.location)(
            "/payment/options"
          )
        : RouteHelper.getRelativeAppViewRoute(this.props.location)(
            "/user/payment-methods"
          );

      this.props.router.push(confirmPaymentPath);
    } else {
      this.props.hideLoading();
    }
  }
  onSubmit(formData) {
    // Get the card type and convert that to the cards network to be saved on the users payment info
    const network = formatCardNetworkForDatabase(
      getCardTypeString(formData.cardNumber)
    );

    // Remove slash from expiration date
    const expiration = formData.cardExpiration.replace("/", "");

    // If there is an access number, we are currently in the workflow for making a payment
    if (this.props.params.accessNumber) {
      if (this.props.loggedIn) {
        // This is called when there is a user logged in and adding a new credit card through the payment workflow
        this.props.addUserCcPaymentMethodFromPaymentWorkflow(
          this.props.params.consortiumId,
          formData.cardholderName,
          formData.cardNumber,
          expiration,
          network
        );
      } else {
        // This gets called if the payment is being made by a guest
        this.props.saveCardInfo(
          this.props.accessNumber,
          formData.cardNumber,
          expiration,
          formData.cardholderName
        );
      }
    } else {
      // If we are on the "Manage Payment Methods" page, this is called
      this.props.addUserPaymentMethodAction(
        this.props.params.consortiumId,
        formData.cardholderName,
        formData.cardNumber,
        expiration,
        network
      );
    }
  }
  render() {
    const nextAction = this.props.params.accessNumber
      ? "Continue to checkout"
      : "Add Credit Card";

    return (
      <div>
        <div className="stepper">
          {this.props.params.accessNumber ? (
            <PaymentStepper
              step="payment-method"
              currentLocation={this.props.location}
            />
          ) : (
            <BackButton
              route="/user/payment-methods"
              currentLocation={this.props.location}
              backText={t("Manage Payment Methods")}
            />
          )}
        </div>
        <div className="flex-container">
          <div className="flex-content">
            <AddNewCardForm
              onSubmit={this.onSubmit}
              t={t}
              companyPaymentTypes={this.props.companyPaymentTypes}
              submitAction={t(nextAction)}
              disabled={this.props.pending}
            />
          </div>
          <div
            className="flex-tipBox"
            aria-label="New Credit Card Tip Box"
            role="region"
          >
            <UserSignInPrompt t={t} location={this.props.location} />
            <AlertInfo
              text={t("TIP_BOX_NEW_CREDIT_DEBIT_CARD")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const paymentTypes = ownProps.params.accessNumber
    ? state.consortium.getIn([
        "customers",
        ownProps.params.accessNumber,
        "acceptedCardTypes",
      ])
    : state.consortium.getIn(["cardTypes"]);

  const distinctAcceptedCreditCardTypes = paymentTypes
    .map((s) => {
      // Remove the "debit card" from payment types
      if (s.includes("debit card")) {
        return s.split(" ")[0];
      }
      return s;
    })
    // Distinct the list
    .toSet()
    .toList();

  return {
    companyPaymentTypes: distinctAcceptedCreditCardTypes,
    loggedIn: state.auth.get("authenticated"),
    accessNumber: ownProps.params.accessNumber,
    pending:
      !!state.httpRequests.getIn([GET_CARD_TOKEN, "pending"]) ||
      !!state.httpRequests.getIn([
        TOKENIZE_AND_ADD_USER_PAYMENT_METHOD,
        "pending",
      ]) ||
      !!state.httpRequests.getIn([
        VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD,
        "pending",
      ]),
    lastRequestFailed:
      state.httpRequests.getIn([GET_CARD_TOKEN, "lastStatus"]) === FAILURE ||
      state.httpRequests.getIn([ADD_USER_PAYMENT_METHOD, "lastStatus"]) ===
        FAILURE ||
      state.httpRequests.getIn([
        VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD,
        "lastStatus",
      ]) === FAILURE,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveCardInfo: (
      accessNumber,
      cardNumber,
      cardExpiration,
      cardholderName
    ) => {
      dispatch(
        submitCardInfoAction(
          accessNumber,
          cardNumber,
          cardExpiration,
          cardholderName
        )
      );
    },
    addUserCcPaymentMethodFromPaymentWorkflow: (
      consortium,
      name,
      cardNumber,
      cardExpiration,
      network
    ) => {
      dispatch(
        addUserCcPaymentMethodFromPaymentWorkflowAction(
          consortium,
          name,
          cardNumber,
          cardExpiration,
          network
        )
      );
    },
    addUserPaymentMethodAction: (
      consortium,
      name,
      cardNumber,
      cardExpiration,
      network
    ) => {
      dispatch(
        addUserCcPaymentMethodAction(
          consortium,
          name,
          cardNumber,
          cardExpiration,
          network
        )
      );
    },
    showLoading: (titleText) => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddNewCard)
);
