import React from "react";
import { withFormsy } from "formsy-react";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";

class FormsyRadio extends React.Component {
  constructor(props) {
    super(props);

    this.state = { touched: false };

    this.onBlur = this.onBlur.bind(this);
  }
  onBlur() {
    this.setState({ touched: true });
  }
  render() {
    return (
      <div style={{ ...this.props.style }}>
        <RadioGroup
          label={this.props.name}
          value={this.props.value}
          onChange={e => this.props.onChange(e.currentTarget.value)}
        >
          {this.props.choices.map(c => (
            <FormControlLabel
              style={{
                float: "left"
              }}
              key={c}
              value={c.get("value")}
              label={c.get("label")}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </div>
    );
  }
}

export default withFormsy(FormsyRadio);
