import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import RouteHelper from "./../utils/RouteHelper";
import { withStyles } from "@material-ui/core/styles";
import { ListItem, Divider, Typography } from "@material-ui/core";
import { recordLocationAction } from "./../api/actions/session";
import { _t } from "./../text/locale";

const t = _t();
const bold = {
  fontWeight: "600",
};

class SidebarContent extends React.Component {
  constructor(props) {
    super(props);

    this.onSelectRoute = this.onSelectRoute.bind(this);
    this.userAccountSidebarItems = this.userAccountSidebarItems.bind(this);
    this.userSignOut = this.userSignOut.bind(this);
    this.userSignIn = this.userSignIn.bind(this);
    this.userRegister = this.userRegister.bind(this);
    this.showLogo = this.showLogo.bind(this);
    this.showTitle = this.showTitle.bind(this);
  }
  showTitle() {
    return (
      <div
        style={{
          padding: "16px 16px 10px 16px",
          fontSize: "18px",
          fontWeight: "bold",
          color: this.props.titleColor,
        }}
      >
        {t(this.props.title)}
      </div>
    );
  }
  showLogo() {
    if (this.props.logoPath) {
      return (
        <div>
          <img
            src={this.props.logoPath}
            alt={t(`Company logo for ${this.props.title}`)}
            style={{ margin: "10px auto", maxWidth: "200px", display: "block" }}
          />
        </div>
      );
    }
    return <div />;
  }
  onSelectRoute(name) {
    return () => {
      this.props.onSelectRoute(name);
    };
  }
  userSignOut() {
    // Refresh the page
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)("/");
    window.location.href = path;
  }
  userSignIn() {
    // Dump the session location information before routing
    this.props.clearLocation();
    this.props.recordLocation(this.props.location.pathname);
    this.onSelectRoute("/user/sign-in")();
  }
  userRegister() {
    this.props.clearLocation();
    this.props.recordLocation(this.props.location.pathname);
    this.onSelectRoute("/user/register")();
  }
  userAccountSidebarItems(loggedIn) {
    const StyledListText = withStyles({
      root: {
        color: this.props.linkColor,
      },
    })(Typography);

    const StyledListHeader = withStyles({
      root: {
        color: this.props.linkColor,
        padding: 16,
      },
    })(Typography);

    if (loggedIn) {
      return (
        <div>
          <Divider aria-hidden="true" />
          <StyledListHeader style={bold}>{t("PAYMENTS")}</StyledListHeader>
          <ListItem button onClick={this.onSelectRoute("/")}>
            <StyledListText>{t("Quick Pay")}</StyledListText>
          </ListItem>
          {this.props.showAutoPay ? (
            <ListItem
              button
              onClick={this.onSelectRoute("/user/auto-pay/select")}
            >
              <StyledListText>
                {t("Manage Auto-Pay Enrollments")}
              </StyledListText>
            </ListItem>
          ) : null}
          {this.props.showRecurring ? (
            <ListItem
              button
              onClick={this.onSelectRoute("/user/recurring/select")}
              style={{ whiteSpace: "nowrap" }}
            >
              <StyledListText>{t("Review Recurring Payments")}</StyledListText>
            </ListItem>
          ) : null}
          <ListItem button onClick={this.onSelectRoute("/user/history/select")}>
            <StyledListText>{t("View Payment History")}</StyledListText>
          </ListItem>
          {/* <ListItem button onClick={this.onSelectRoute("/user/auto-pay/select")}>
            <StyledListText>{t("View Auto-Pay Enrollments")}</StyledListText>
          </ListItem> */}

          {this.props.showStatementView ? (
            <ListItem
              button
              onClick={this.onSelectRoute("/user/statements/select")}
            >
              <StyledListText>{t("View Statement")}</StyledListText>
            </ListItem>
          ) : null}

          <Divider aria-hidden="true" />
          <StyledListHeader style={bold}>{t("MANAGEMENT")}</StyledListHeader>

          <ListItem
            button
            onClick={this.onSelectRoute("/user/payment-methods")}
          >
            <StyledListText>{t("Payment Methods")}</StyledListText>
          </ListItem>
          <ListItem button onClick={this.onSelectRoute("/user/accounts")}>
            <StyledListText>{t("Loan Accounts")}</StyledListText>
          </ListItem>
          <ListItem button onClick={this.onSelectRoute("/user/contact-info")}>
            <StyledListText>{t("Contact Information")}</StyledListText>
          </ListItem>
          <ListItem
            button
            onClick={this.onSelectRoute("/user/password-change")}
          >
            <StyledListText>{t("Password Change")}</StyledListText>
          </ListItem>
          <ListItem button onClick={this.userSignOut}>
            <StyledListText>{t("Sign Out")}</StyledListText>
          </ListItem>
        </div>
      );
    }

    // If the consortium is set to guest only mode, do not show sign in or register options.
    if (!this.props.guestOnly) {
      return (
        <div>
          <ListItem button onClick={this.userSignIn}>
            <StyledListText>{t("Sign In")}</StyledListText>
          </ListItem>
          <ListItem button onClick={this.userRegister}>
            <StyledListText>{t("Register")}</StyledListText>
          </ListItem>
        </div>
      );
    }
  }
  render() {
    const footerStyles = {
      fontSize: "12px",
      marginTop: "12px",
      textAlign: "center",
    };

    const StyledLink = withStyles({
      root: {
        cursor: "pointer",
        color: this.props.linkColor,
        justifyContent: "center",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    })(ListItem);

    const StyledListText = withStyles({
      root: {
        color: this.props.linkColor,
      },
    })(Typography);

    return (
      <div>
        <nav id="mainmenu" aria-label="Main Menu">
          {this.showLogo()}
          {this.showTitle()}
          <div style={{ backgroundColor: "transparent", padding: "0" }}>
            <Divider aria-hidden="true" />
            <div className="skip">
              <a href="#maincontent">{t("Skip to main content")}</a>
            </div>
            <ListItem button onClick={this.onSelectRoute("/")}>
              <StyledListText> {t("Home")}</StyledListText>
            </ListItem>
            {this.userAccountSidebarItems(this.props.loggedIn)}
          </div>
        </nav>
        <Divider aria-hidden="true" />
        <footer style={footerStyles}>
          <StyledLink
            button
            onClick={this.onSelectRoute("/terms-and-conditions")}
          >
            {t("Terms and Conditions")}
          </StyledLink>
          <StyledLink button onClick={this.onSelectRoute("/privacy-policy")}>
            {t("Privacy Policy")}
          </StyledLink>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const logoPath = state.style.get("logoPath");
  const title = state.consortium.get("consortiumName");

  const showStatementView =
    state.consortium
      .get("customers")
      .filter((c) => c.get("enableStatementView"))
      .count() > 0;

  const showAutoPay =
    state.consortium
      .get("customers")
      .filter(
        (c) =>
          c.get("billingProcessType") === 98 ||
          c.get("billingProcessType") === 99
      )
      .count() > 0;

  const showRecurring = state.consortium
    .get("customers")
    .some(
      (c) =>
        c.get("ccPaymentFrequency") !== "None" ||
        c.get("achPaymentFrequency") !== "None"
    );

  return {
    sidebarColor: state.style.getIn(["palette", "primary3Color"]),
    loggedIn: state.auth.get("authenticated"),
    linkColor: state.style.getIn(["palette", "accent3Color"]),
    titleColor: state.style.getIn(["palette", "textColor"]),
    logoPath: logoPath,
    title: title,
    showStatementView: showStatementView,
    guestOnly: state.consortium.getIn(["settings", "guestOnly"]),
    showAutoPay: showAutoPay,
    showRecurring: showRecurring,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearLocation: () => {
      dispatch(recordLocationAction(""));
    },
    recordLocation: (location) => {
      dispatch(recordLocationAction(location));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarContent)
);
