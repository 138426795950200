import React from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardContent,
} from "@material-ui/core";

export default class StatementViewCard extends React.Component {
  render() {
    const item = this.props.item;
    return (
      <Card style={{ margin: "0px 0px 8px 0px" }}>
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{this.props.t("Amount:")}</TableCell>
                <TableCell align="right">${item.get("amount")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{this.props.t("Description:")}</TableCell>
                <TableCell align="right">{item.get("description")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{this.props.t("Date:")}</TableCell>
                <TableCell align="right">
                  {moment(item.get("date")).format("L")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{this.props.t("Principal:")}</TableCell>
                <TableCell align="right">${item.get("principal")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{this.props.t("Interest:")}</TableCell>
                <TableCell align="right">${item.get("interest")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{this.props.t("Fee:")}</TableCell>
                <TableCell align="right">${item.get("fee")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{this.props.t("Escrow:")}</TableCell>
                <TableCell align="right">${item.get("escrow")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}
