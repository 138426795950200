import { Map } from "immutable";

import { LOADING_SHOW, LOADING_HIDE } from "./../../api/actions/actionTypes";

const getDefaultState = () => {
  return Map();
};

const actions = {};

actions[LOADING_SHOW] = function(state, action) {
  return state.set("title", action.payload).set("show", true);
};
actions[LOADING_HIDE] = function(state, action) {
  return state.set("title", "").set("show", false);
};

export default function(state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  return actions[action.type](state, action);
}
