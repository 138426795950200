import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PasswordChangeForm from "./PasswordChangeForm.jsx";

import { applyPasswordReset } from "./../../../api/actions/user-accounts/passwordReset";
import {
  showLoadingAction,
  hideLoadingAction
} from "./../../../api/actions/loading";
import {
  USER_PASSWORD_UPDATE,
  AUTHENTICATE_USER,
  FAILURE
} from "./../../../api/actions/actionTypes";
import { setPageInfoAction } from "./../../../api/actions/pageInfo";

let pageTitle = "Change Password";
export class PasswordChange extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }
  static propTypes = {
    t: PropTypes.func.isRequired
  };
  UNSAFE_componentWillMount() {
    this.props.dispatchSetPageInfo(this.props.t(pageTitle));
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (!nextProps.pending && this.props.pending) {
      this.props.hideLoading();

      if (!nextProps.lastRequestFailed) {
        this.props.onNext();
      }
    }
  }
  onSubmit(model) {
    this.props.showLoading(this.props.t("Resetting Password..."));
    this.props.submitResetValidation(this.props.consortiumId, model);
  }
  render() {
    return (
      <div>
        <p>
          {this.props.t(
            "Please enter a new password. Passwords must be at least 8 characters long and contain a lowercase letter, an uppercase letter, and a number or symbol."
          )}
        </p>
        <PasswordChangeForm
          t={this.props.t}
          onSubmit={this.onSubmit}
          disabled={this.props.pending}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pin: state.passwordReset.get("pin"),
    pending:
      !!state.httpRequests.getIn([USER_PASSWORD_UPDATE, "pending"]) ||
      !!state.httpRequests.getIn([AUTHENTICATE_USER, "pending"]),
    lastRequestFailed:
      state.httpRequests.getIn([USER_PASSWORD_UPDATE, "lastStatus"]) ===
        FAILURE ||
      state.httpRequests.getIn([AUTHENTICATE_USER, "lastStatus"]) === FAILURE
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showLoading: titleText => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: titleText => {
      dispatch(hideLoadingAction());
    },
    submitResetValidation: (consortiumId, password) => {
      dispatch(applyPasswordReset(consortiumId, password));
    },
    dispatchSetPageInfo: pageInfoData => {
      dispatch(setPageInfoAction(pageInfoData));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange);
