import React from "react";
import { capitalizeFirstLetter } from "./../../utils/FormatUtil";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardContent,
  CardHeader
} from "@material-ui/core";

export default class StatementViewSummaryCard extends React.Component {
  render() {
    const summaryItemIndexLookup = this.props.summaryItems.keySeq();
    const summaryItems = this.props.summaryItems
      .map((value, key) => {
        const index = summaryItemIndexLookup.findIndex(k => k === key);
        return (
          <TableRow key={`mobile-row-${index}`}>
            <TableCell
              style={{ paddingRight: "0px" }}
              key={`mobile-cell-one-${index}`}
            >
              {this.props.t(capitalizeFirstLetter(key))}
            </TableCell>
            <TableCell align="right" key={`mobile-cell-two-${index}`}>
              {this.props.t(value)}
            </TableCell>
          </TableRow>
        );
      })
      .toList()
      .toJS();

    return (
      <Card style={{ margin: "0px 0px 8px 0px" }}>
        <CardContent>
          <CardHeader title={"Account Summary"} />
          <Table>
            <TableBody>{summaryItems}</TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}
