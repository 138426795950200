import React from "react";
import { connect } from "react-redux";
import AlertInfo from "./../errors/AlertInfo.jsx";

class NoAccountsAlert extends React.Component {
  render() {
    return (
      <div aria-label="No Accounts Warning Box" role="region">
        <AlertInfo
          text={this.props.t("TIP_BOX_NO_ACCOUNTS")}
          location={this.props.location}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    palette: state.style.getIn(["palette"])
  };
};
export default connect(mapStateToProps)(NoAccountsAlert);
