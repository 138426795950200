import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import SelectAccountList from "./SelectAccountList.jsx";
import RouteHelper from "./../../utils/RouteHelper";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { Button } from "@material-ui/core";
import { setPageInfoAction } from "./../../api/actions/pageInfo";

import { selectAccountAction } from "./../../api/actions/accounts";

import { _t } from "./../../text/locale";

import PaymentStepper from "./../../../src/components/PaymentStepper.jsx";

let t;
let pageTitle = "Select Account";

class SelectAccount extends Component {
  constructor(props) {
    super(props);

    this.onAccountSelected = this.onAccountSelected.bind(this);
    this.renderManageButton = this.renderManageButton.bind(this);
    this.routeToAccountManagement = this.routeToAccountManagement.bind(this);
    this.routeToAddAccount = this.routeToAddAccount.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  routeToAccountManagement() {
    this.props.selectAccount(this.props.accounts.get(0));

    const route = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/accounts"
    );
    this.props.router.push(route);
  }
  routeToAddAccount() {
    const accountPath = RouteHelper.getRelativeCustomerRoute(
      this.props.location
    )(`/accounts`);
    this.props.router.push(accountPath);
  }
  onAccountSelected(account) {
    const paymentPath = RouteHelper.getRelativeCustomerRoute(
      this.props.location
    )("/payment/select-method");
    this.props.selectAccount(account);
    this.props.router.push(paymentPath);
  }
  renderManageButton(loggedIn) {
    if (loggedIn) {
      return (
        <Button
          variant="contained"
          onClick={this.routeToAccountManagement}
          color="primary"
        >
          {t("Go to Manage Accounts")}
        </Button>
      );
    }
    return <div />;
  }
  renderAddAccountButton() {
    return (
      <Button
        variant="contained"
        onClick={this.routeToAddAccount}
        color="primary"
      >
        {t("Add Account")}
      </Button>
    );
  }
  render() {
    const noAccounts = this.props.accounts.isEmpty();
    return (
      <div>
        <div className="stepper">
          <PaymentStepper
            step="account-select"
            currentLocation={this.props.location}
          />
        </div>
        <div className="flex-container">
          <div className="flex-content">
            <SelectAccountList
              t={t}
              accounts={this.props.accounts}
              onAccountSelected={this.onAccountSelected}
              enforcePaymentCooldown
              shouldFocusOnMount
            />
            {noAccounts
              ? this.renderAddAccountButton()
              : this.renderManageButton(this.props.loggedIn)}
          </div>
          <div
            className="flex-tipBox"
            aria-label="Select Account Tip Box"
            role="region"
          >
            <AlertInfo
              text={t("TIP_BOX_SELECT_ACCOUNT")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    accounts: state.billing
      .getIn(["accounts", ownProps.params.accessNumber])
      .toList(),
    loggedIn: state.auth.get("authenticated")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetPageInfo: pageInfoData => {
      dispatch(setPageInfoAction(pageInfoData));
    },
    selectAccount: account => {
      dispatch(selectAccountAction(account));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectAccount)
);
