import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  SUBMIT_AUTO_PAY_ENROLLMENT,
  SUBMIT_TRANSACTION,
  GET_CARD_FEE,
  FAILURE,
} from "./../../api/actions/actionTypes";

import {
  showLoadingAction,
  hideLoadingAction,
} from "./../../api/actions/loading";
import { recordLocationAction } from "./../../api/actions/session";
import { setPageInfoAction } from "./../../api/actions/pageInfo";

import numeral from "numeral";

import { _t } from "./../../text/locale";
import {
  submitTransactionAction,
  getFeeAction,
} from "./../../api/actions/payments";
import { submitAutoPayEnrollmentAction } from "./../../api/actions/autoPay.js";
import SubmitPaymentForm from "./SubmitPaymentForm.jsx";
import CheckoutConsent from "./CheckoutConsent.jsx";
import PaymentConfirmationTable from "./PaymentConfirmationTable.jsx";
import AutoPayConfirmationTable from "./AutoPayConfirmationTable.jsx";
import { getRecentPaymentsAction } from "./../../api/actions/paymentHistory";

import RouteHelper from "./../../utils/RouteHelper";
import PaymentStepper from "../../components/PaymentStepper.jsx";

let t;

class ConfirmPayment extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmitAutoPayEnrollment = this.onSubmitAutoPayEnrollment.bind(this);
    this.onSubmitPayment = this.onSubmitPayment.bind(this);
    this.routeToTerms = this.routeToTerms.bind(this);
  }

  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);

    let pageTitle = this.props.isAutoPay
      ? "Confirm Auto-Pay Enrollment"
      : "Confirm Payment";

    this.props.dispatchSetPageInfo(t(pageTitle));

    if (!this.props.isAutoPay) {
      this.props.getCardFee(this.props.params.accessNumber);
    }
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.submitPending) {
      if (this.props.isAutoPay) {
        this.props.showLoading(t("Processing Your Enrollment..."));
      }
      else {
        this.props.showLoading(t("Processing Your Payment..."));
      }
    } else if (
      !nextProps.submitPending &&
      this.props.submitPending &&
      !nextProps.requestError
    ) {
      this.props.hideLoading();

      if (!nextProps.requestError) {
        if (this.props.loggedIn) {
          this.props.getRecentPayments(
            this.props.params.consortiumId,
            this.props.userIdo
          );
        }

        const selectPaymentMethodPath = RouteHelper.getRelativeCustomerRoute(
          this.props.location
        )("/thank-you");
        this.props.router.push(selectPaymentMethodPath);
      }
    } else {
      this.props.hideLoading();
    }
  }
  routeToTerms() {
    this.props.recordLocation(this.props.location.pathname);
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/terms-and-conditions"
    );
    this.props.router.push(path);
  }

  onSubmitPayment(formData) {
    this.props.submitTransaction(formData);
  }

  onSubmitAutoPayEnrollment(formData) {
    this.props.submitAutoPayEnrollment(formData);
  }

  render() {
    return (
      <div>
        <div className="stepper">
          <PaymentStepper
            step="payment-confirmation"
            currentLocation={this.props.location}
          />
        </div>
        <div className="flex-container">
          <div className="flex-content">
            {this.props.isAutoPay ? (
              <AutoPayConfirmationTable t={t} account={this.props.account} />
            ) : (
              <PaymentConfirmationTable
                t={t}
                account={this.props.account}
                depositAccountName={this.props.depositAccountName}
                payment={this.props.payment}
                frequency={this.props.frequency}
                amount={this.props.amount}
                feePending={this.props.feePending}
                fee={this.props.fee}
                total={this.props.total}
              />
            )}
            <SubmitPaymentForm
              collectCvv={this.props.collectCvv}
              disabled={
                (!this.props.isAutoPay && this.props.feePending) ||
                this.props.submitPending ||
                (!this.props.captchaCompleted && this.props.enableRecaptcha)
              }
              t={t}
              onSubmit={
                this.props.isAutoPay
                  ? this.onSubmitAutoPayEnrollment
                  : this.onSubmitPayment
              }
              shouldFocusOnMount={!this.props.collectCvv}
              enableRecaptcha={this.props.enableRecaptcha}
              isAutoPay={this.props.isAutoPay}
            />
          </div>
          <div className="flex-tipBox">
            <CheckoutConsent
              t={t}
              onViewTermsClick={this.routeToTerms}
              style={{ color: this.props.styles.get("accent3Color") }}
              isAutoPay={this.props.isAutoPay}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const accountPath = state.billing.getIn(["accounts", "current"]);
  const account = state.billing.getIn(accountPath);
  const payment = state.payments.get("current");
  const frequency = state.payments.getIn(["current", "frequency"]);

  if (!payment) {
    return Map();
  }

  const requestError =
    state.httpRequests.getIn([SUBMIT_TRANSACTION, "lastStatus"]) === FAILURE ||
    state.httpRequests.getIn([SUBMIT_AUTO_PAY_ENROLLMENT, "lastStatus"]) ===
      FAILURE;

  const amount = numeral(payment.get("amount"));
  const fee = numeral(payment.get("fee"));

  // Numeral will mutate amount if we just call .add on it, so we make a copy first.
  const total = amount.clone().add(fee.value());
  const collectCvv = state.payments.getIn(["method", "type"]) === "cc";

  // If a deposit account is selected, get the name
  let depositAccountName = "";
  if (payment.has("depositAccountReferenceId")) {
    const depositAccountReferenceId = payment.get("depositAccountReferenceId");

    const depositAccountList = state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "depositAccounts",
    ]);

    depositAccountName = depositAccountList
      .find((da) => da.get("referenceId") === depositAccountReferenceId)
      .get("name");
  }

  return {
    payment: payment,
    amount: amount,
    collectCvv: collectCvv,
    fee: fee,
    total: total,
    account: account,
    feePending: !!state.httpRequests.getIn([GET_CARD_FEE, "pending"]),
    submitPending:
      !!state.httpRequests.getIn([SUBMIT_TRANSACTION, "pending"]) ||
      !!state.httpRequests.getIn([SUBMIT_AUTO_PAY_ENROLLMENT, "pending"]),
    requestError: requestError,
    frequency: frequency,
    styles: state.style.get("palette"),
    loggedIn: state.auth.get("authenticated"),
    userIdo: state.user.get("id"),
    captchaCompleted: !!state.payments.getIn(["current", "captchaToken"]),
    enableRecaptcha: state.config.get("enableRecaptcha"),
    previousLocation: state.session.get("location"),
    isAutoPay: state.payments.getIn(["current", "isAutoPay"]),
    depositAccountName: depositAccountName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCardFee: (accessNumber) => {
      dispatch(getFeeAction(accessNumber));
    },
    submitTransaction: (formData) => {
      dispatch(submitTransactionAction(formData));
    },
    submitAutoPayEnrollment: (formData) => {
      dispatch(submitAutoPayEnrollmentAction(formData));
    },
    showLoading: (titleText) => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
    getRecentPayments: (consortiumId, userId) => {
      dispatch(getRecentPaymentsAction(consortiumId, userId));
    },
    recordLocation: (location) => {
      dispatch(recordLocationAction(location));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmPayment)
);
