import { USER_PASSWORD_UPDATE } from "./../actionTypes";

import http from "./../../utils/http";

export const changeUserPassword = (consortiumId, model) => (
  dispatch,
  getState
) => {
  const userId = getState().user.get("id");
  const currentPassword = model.currentPassword;
  const password = model.password;
  const confirmPassword = model.passwordConfirm;

  const promise = http
    .patch(`/api/v1/consortiums/${consortiumId}/users/${userId}`)
    .send({
      password: password,
      confirmPassword: confirmPassword,
      challengeType: "Password",
      challenge: currentPassword
    })
    .end();

  dispatch({
    type: USER_PASSWORD_UPDATE,
    payload: { promise }
  });
};
