import { createStore as _createStore, applyMiddleware, compose } from "redux";
import { createPromise } from "redux-promise-middleware";
import thunk from "redux-thunk";
import { persistState } from "redux-devtools";

import { PENDING, SUCCESS, FAILURE } from "../api/actions/actionTypes";

function getDebugSessionKey() {
  // You can write custom logic here!
  // By default we try to read the key from ?debug_session=<key> in the address bar
  const matches = window.location.href.match(/[?&]debug_session=([^&#]+)\b/);
  return matches && matches.length > 0 ? matches[1] : null;
}

// Define configuration logic for the redux store.
function configureStore(data) {
  const middleware = [
    thunk,
    createPromise({
      promiseTypeSuffixes: [PENDING, SUCCESS, FAILURE]
    })
  ];

  let finalCreateStore;

  // We need to know if we're in dev mode to determine whether DevTools should be imported.
  const DEV_MODE = process.env.NODE_ENV === "development"; // eslint-disable-line no-process-env

  if (DEV_MODE) {
    finalCreateStore = compose(
      applyMiddleware(...middleware),
      require("./../components/dev/DevTools").default.instrument(),
      persistState(getDebugSessionKey())
    )(_createStore);
  } else {
    finalCreateStore = applyMiddleware(...middleware)(_createStore);
  }

  const reducer = require("./reducer").default;
  const store = finalCreateStore(reducer, data);

  if (DEV_MODE && module.hot) {
    module.hot.accept("./reducer", () => {
      store.replaceReducer(require("./reducer").default);
    });
  }

  return store;
}

// Create the redux store.
// **NOTE** This will create the store in a way that all instances of "import store" from here WILL
// import the same instance of the store.
const store = configureStore();

export default store;
