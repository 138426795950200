import { Button } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  AUTHENTICATE_USER,
  FAILURE,
  SUCCESS
} from "./../../api/actions/actionTypes";
import {
  hideLoadingAction,
  showLoadingAction
} from "./../../api/actions/loading";
import { setPageInfoAction } from "./../../api/actions/pageInfo";
import { recordLocationAction } from "./../../api/actions/session";
import { userSignInAction } from "./../../api/actions/user";
import { _t } from "./../../text/locale";
import RouteHelper from "./../../utils/RouteHelper";
import AlertInfo from "./../errors/AlertInfo.jsx";
import UserSignInForm from "./UserSignInForm.jsx";

let t;
let pageTitle = "Sign In";

export class UserSignInInternal extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.routeToForgotUsername = this.routeToForgotUsername.bind(this);
    this.routeToForgotPassword = this.routeToForgotPassword.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.pending === true) {
      this.props.showLoading(t("Retrieving your account information..."));
    } else if (
      !nextProps.pending &&
      this.props.pending &&
      !nextProps.lastRequestFailed &&
      nextProps.getUserSuccess
    ) {
      this.props.hideLoading();

      let previousLocation = { pathname: this.props.previousLocation };

      if (this.props.previousLocation.includes("lookup-accounts")) {
        const accountsPath = RouteHelper.getRelativeCustomerRoute(
          previousLocation
        )("/accounts/select");
        this.props.router.push(accountsPath);
        return;
      } else if (
        this.props.previousLocation.includes("add-new-ach") ||
        this.props.previousLocation.includes("add-new-card")
      ) {
        const selectPaymentPath = RouteHelper.getRelativeCustomerRoute(
          previousLocation
        )("/payment/select-method");
        this.props.router.push(selectPaymentPath);
        return;
      } else if (this.props.previousLocation) {
        this.props.clearLocation();
        this.props.router.push(this.props.previousLocation);
        return;
      }
      const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
        "/"
      );
      this.props.router.push(path);
    } else {
      this.props.hideLoading();
    }
  }
  routeToForgotUsername() {
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/username-reminder"
    );
    this.props.router.push(path);
  }
  routeToForgotPassword() {
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/password-reset"
    );
    this.props.router.push(path);
  }
  onSubmit(formData) {
    this.props.signInUser(
      formData.username,
      formData.password,
      this.props.params.consortiumId
    );
  }
  render() {
    return (
      <div className="flex-container">
        <div className="flex-content">
          <div style={{ marginTop: "16px" }}>
            <UserSignInForm
              t={t}
              onSubmit={this.onSubmit}
              disabled={this.props.pending}
            />
            <Button onClick={this.routeToForgotUsername} color="secondary">
              {t("Forgot Username?")}
            </Button>
            <Button onClick={this.routeToForgotPassword} color="secondary">
              {t("Forgot Password?")}
            </Button>
          </div>
        </div>
        <div className="flex-tipBox" aria-label="Sign in Tip Box" role="region">
          <AlertInfo
            text={t("TIP_BOX_SIGN_IN")}
            location={this.props.location}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pending: !!state.httpRequests.getIn([AUTHENTICATE_USER, "pending"]),
    getUserSuccess:
      state.httpRequests.getIn([AUTHENTICATE_USER, "lastStatus"]) === SUCCESS,
    lastRequestFailed:
      state.httpRequests.getIn([AUTHENTICATE_USER, "lastStatus"]) === FAILURE,
    previousLocation: state.session.get("location"),
    pageTitle: state.session.get("pageData"),
    linkColor: state.style.getIn(["palette", "accent3Color"])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showLoading: titleText => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
    signInUser: (username, password, consortiumId) => {
      dispatch(userSignInAction(username, password, consortiumId));
    },
    clearLocation: () => {
      dispatch(recordLocationAction(""));
    },
    dispatchSetPageInfo: pageData => {
      dispatch(setPageInfoAction(pageData));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserSignInInternal)
);
