import React from "react";
import {
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar
} from "@material-ui/core";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from "moment";

import { grey } from "@material-ui/core/colors";
import Delete from "@material-ui/icons/Delete";
import Timer from "@material-ui/icons/Timer";
import { formatPaymentAmount } from "./../../utils/FormatUtil";
import { getCurrentDateTime } from "./../../utils/DateUtils";

class AccountDetailItem extends React.Component {
  formatPrimaryText(account) {
    let style = {};
    if (account.get("isBlocked")) {
      style = { color: grey[600] };
    }

    //needed so that accounts without a billing file do not display "null"
    if (account.get("name") !== null) {
      return (
        <div style={style}>
          {account.get("formattedAccountNumber") + " " + account.get("name")}
        </div>
      );
    }

    return <div style={style}>{account.get("accountNumber")}</div>;
  }

  formatSecondaryText(
    account,
    shouldDisplayPaymentCooldown
  ) {
    const dueDate = account.get("dueDate");
    const currBal = formatPaymentAmount(account.get("currentBalance"));
    const loggedIn = this.props.loggedIn;
    const amountDue = formatPaymentAmount(account.get("amountDue"));

    let isBlocked = account.get("isBlocked");
    if (isBlocked) {
      let style = { color: grey[600] };
      return <div style={style}>{this.props.t("BLOCKED ACCOUNT")}</div>;
    }

    if (shouldDisplayPaymentCooldown) {
      const paymentCooldownRemaining = account.get("paymentCooldownRemaining");

      return (
        <span style={{ color: grey[600] }}>
          {this.props.t(
            "Next payment allowed in approximately {{COOLDOWN_REMAINING}}.",
            {
              COOLDOWN_REMAINING: paymentCooldownRemaining.humanize()
            }
          )}
        </span>
      );
    }

    if (account.get("isNormalProcessing")) {
      return null;
    }

    if (!dueDate) {
      return `$${amountDue} Due`;
    }

    const formattedDueDate = moment(dueDate).format("L");

    let style = {};
    const now = getCurrentDateTime();
    const weekFromNow = getCurrentDateTime().add(7, "days");

    if (moment(dueDate).isBefore(now, "day")) {
      style = {
        color: "#c70000"
      };
    } else if (moment(dueDate).isBefore(weekFromNow, "day")) {
      style = {
        color: "#B05E11"
      };
    }
    if (currBal !== "0.00" && loggedIn) {
      return (
        <div>
          <div style={style}>
            ${amountDue} Due on {formattedDueDate}
          </div>
          <div>Outstanding Balance: ${currBal}</div>
        </div>
      );
    }

    return (
      <span style={style}>
        ${amountDue} Due on {formattedDueDate}
      </span>
    );
  }
  deleteAccount() {
    this.props.onClickDelete(
      this.props.account.get("userAccountId"),
      this.props.account.get("accountNumber"),
      this.props.account.get("accessNumber")
    );
  }
  render() {
    const account = this.props.account;
    const shouldDisplayPaymentCooldown =
      this.props.account.has("paymentCooldownRemaining") &&
      this.props.account.get("paymentCooldownRemaining").as("seconds") > 0;

    const avatarStyle = {
      backgroundColor: this.props.styles.get("primary1Color")
    };

    const timerAvatar = (
      <Avatar style={avatarStyle}>
        <Timer bgcolor="primary.contrastText" />
      </Avatar>
    );

    const deleteAvatar = (
      <Avatar style={avatarStyle}>
        <Delete bgcolor="primary.contrastText" />
      </Avatar>
    );

    const rightAvatar = shouldDisplayPaymentCooldown ? (
      <ListItemAvatar>{timerAvatar}</ListItemAvatar>
    ) : null;

    const leftAvatar =
      this.props.loggedIn && this.props.fromManagePage ? (
        <ListItemAvatar aria-label="Delete account">
          {deleteAvatar}
        </ListItemAvatar>
      ) : null;

    return (
      <ListItem
        button
        onClick={() => {
          if (this.props.fromManagePage) {
            this.deleteAccount();
          }
        }}
      >
        {rightAvatar}
        <ListItemText
          primary={this.formatPrimaryText(account)}
          secondary={this.formatSecondaryText(
            account,
            shouldDisplayPaymentCooldown
          )}
        />
        {leftAvatar}
      </ListItem>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    loggedIn: state.auth.get("authenticated"),
    styles: state.style.get("palette")
  };
};

export default withRouter(connect(mapStateToProps, null)(AccountDetailItem));
