import { getStatement } from "./../requests/statement";
import { GET_STATEMENT, SELECT_STATEMENT_ACCOUNT } from "./actionTypes";

export const getStatementAction = (
  bankNumber,
  companyNumber,
  accountNumber,
  pageNumber
) => (dispatch, getState) => {
  const shouldGetSummaryItems = pageNumber === 1;
  const promise = getStatement(
    bankNumber,
    companyNumber,
    accountNumber,
    pageNumber,
    shouldGetSummaryItems
  );

  dispatch({
    type: GET_STATEMENT,
    payload: { promise },
    meta: { pageNumber }
  });

  // By returning the promise, we can dispatch another action when this one ends
  return promise;
};

export const selectStatementAction = account => (dispatch, getState) => {
  const state = getState();
  const bc = state.consortium.getIn(["customers", account.get("accessNumber")]);
  const bankNumber = bc.get("bankNumber");
  const companyNumber = bc.get("companyNumber");

  dispatch({
    type: SELECT_STATEMENT_ACCOUNT,
    payload: account,
    meta: { bankNumber, companyNumber }
  });
};
