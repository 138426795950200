import React from "react";
import { withRouter } from "react-router";
import GetMarked from "./../utils/MarkedUtil";
import { getRelativeAppViewRoute } from "./../utils/RouteHelper";

class TipContent extends React.Component {
  constructor(props) {
    super(props);

    this.routeToRelativePath = this.routeToRelativePath.bind(this);
  }

  routeToRelativePath = relativePath => {
    const target = getRelativeAppViewRoute(this.props.location)(relativePath);
    this.props.router.push(target);
  };

  render() {
    const accentColor = this.props.styles
      ? this.props.styles.get("accent3Color")
      : "";

    let marked = GetMarked(accentColor);
    return (
      <span
        onClick={e => {
          // If the onClick event of a child occurs, and it has an href that does not start with http, attempt to route to it with react-router.
          // If it does start with href, follow it normally.
          if (
            e &&
            e.target &&
            e.target.getAttribute("href") &&
            !e.target.getAttribute("href").startsWith("http")
          ) {
            e.preventDefault();
            this.routeToRelativePath(e.target.getAttribute("href"));
          }
        }}
        dangerouslySetInnerHTML={{ __html: marked(this.props.text) }}
      />
    );
  }
}

export default withRouter(TipContent);
