import React, { Component } from "react";
import { withRouter } from "react-router";
import { Button } from "@material-ui/core";

class FocusableButton extends Component {
  constructor(props) {
    super(props);

    this.buttonRef = React.createRef();
    this.focusButton = this.focusButton.bind(this);
  }
  componentDidMount() {
    if (this.props.shouldFocusOnMount) {
      this.focusButton();
    }
  }
  focusButton() {
    this.buttonRef.current.focus();
  }
  render() {
    // We need to remove the prop that we don't care about so react doesn't yell at us
    // eslint-disable-next-line
    let {shouldFocusOnMount, ...otherProps} = this.props;
    return <Button {...otherProps} buttonRef={this.buttonRef} />;
  }
}

export default withRouter(FocusableButton);
