import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { List, ListItem, ListItemText } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import {
  continueAsGuestAction,
  recordLocationAction
} from "./../../api/actions/session";
import RouteHelper from "./../../utils/RouteHelper";
import { _t } from "./../../text/locale";
import { setPageInfoAction } from "./../../api/actions/pageInfo";

let t;
let pageTitle = "Checkout";

class GuestButton extends React.Component {
  render() {
    return (
      <span>
        <ListItem button onClick={this.props.onClick}>
          <ListItemText primary={t("Continue as Guest")} />
          <ChevronRight />
        </ListItem>
      </span>
    );
  }
}

class LoginButton extends React.Component {
  render() {
    return (
      <span>
        <ListItem button onClick={this.props.onClick}>
          <ListItemText primary={t("Sign in")} />
          <ChevronRight />
        </ListItem>
      </span>
    );
  }
}

class SignInReminder extends React.Component {
  constructor(props) {
    super(props);

    this.onGuestButtonClick = this.onGuestButtonClick.bind(this);
    this.onLoginButtonClick = this.onLoginButtonClick.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  onGuestButtonClick() {
    this.props.continueAsGuest();
    const path = RouteHelper.getRelativeCustomerRoute(this.props.location)(
      "/checkout/contact-info"
    );
    this.props.router.push(path);
  }
  onLoginButtonClick() {
    // Get the checkout path, so when the sign-in page returns to "where it came from" it will really just bump us to the next page in line
    const checkoutPath = RouteHelper.getRelativeCustomerRoute(
      this.props.location
    )("/checkout/contact-info");
    this.props.recordLocation(checkoutPath);

    // Route to the sign-in page
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/sign-in"
    );
    this.props.router.push(path);
  }
  render() {
    return (
      <div className="flex-tipBox" aria-label="Sign in Tip Box" role="region">
        <List>
          <GuestButton t={t} onClick={this.onGuestButtonClick} />
          <LoginButton t={t} onClick={this.onLoginButtonClick} />
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.auth.get("authenticated"),
    continueAsGuestSelected: state.session.get("continueAsGuestSelected")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    continueAsGuest: () => {
      dispatch(continueAsGuestAction());
    },
    recordLocation: location => {
      dispatch(recordLocationAction(location));
    },
    dispatchSetPageInfo: pageInfoData => {
      dispatch(setPageInfoAction(pageInfoData));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignInReminder)
);
