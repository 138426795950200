const getReadablePaymentStatus = function(statusId) {
  switch (statusId) {
    case 1:
      return "Errored";
    case 2:
      return "Deleted";
    case 3:
      return "Pending";
    case 4:
      return "Processed";
    case 5: 
      return "Declined";
    default:
      return "Unknown";
  }
};

const getReadablePaymentFrequency = function(statusId) {
  switch (statusId) {
    case 0:
      return "Once";
    case 1:
      return "Weekly";
    case 2:
      return "Bi-weekly";
    case 4:
      return "Semi-monthly";
    case 8:
      return "Monthly";
    case 16:
      return "Bi-monthly";
    case 32:
      return "Quarterly";
    case 64:
      return "Semi-annually";
    case 128:
      return "Annually";
    default:
      return "Unknown";
  }
};

export default item => {
  const readableStatus = getReadablePaymentStatus(item.get("status"));
  const readableFrequency = getReadablePaymentFrequency(item.get("frequency"));
  return item.set("status", readableStatus).set("frequency", readableFrequency);
};
