import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import StatementViewItem from "./StatementViewItem.jsx";
import StatementViewCard from "./StatementViewCard.jsx";
import { ResponsiveUtil } from "./../../utils/ResponsiveUtil";

export default class StatementViewTable extends React.Component {
  render() {
    let isMobile = ResponsiveUtil.isMobile();
    const paymentItems = this.props.statementViewItems.map((p, index) => (
      <StatementViewItem
        key={index}
        item={p}
        styles={this.props.styles}
        t={this.props.t}
        showFee={this.props.showFee}
        showEscrow={this.props.showEscrow}
      />
    ));

    const paymentItemCards = this.props.statementViewItems.map((p, index) => {
      return (
        <StatementViewCard
          key={index}
          item={p}
          t={this.props.t}
          styles={this.props.styles}
          onCancelItem={this.props.onCancelItem}
        />
      );
    });

    const StyledTableCell = withStyles({
      root: {
        color: this.props.styles.get("textColor"),
      },
    })(TableCell);

    const StyledMonetaryCell = withStyles({
      root: {
        textAlign: "right",
      },
    })(StyledTableCell);

    return (
      <div>
        {!isMobile ? (
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>{this.props.t("Date")}</StyledTableCell>
                <StyledTableCell>{this.props.t("Description")}</StyledTableCell>
                <StyledMonetaryCell>
                  {this.props.t("Amount")}
                </StyledMonetaryCell>
                <StyledMonetaryCell>
                  {this.props.t("Principal")}
                </StyledMonetaryCell>
                <StyledMonetaryCell>
                  {this.props.t("Interest")}
                </StyledMonetaryCell>
                {this.props.showFee ? (
                  <StyledMonetaryCell>{this.props.t("Fee")}</StyledMonetaryCell>
                ) : null}
                {this.props.showEscrow ? (
                  <StyledMonetaryCell>
                    {this.props.t("Escrow")}
                  </StyledMonetaryCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentItems}
              {/* a horizontal table looks bad on mobile, so a second vertically stacked layout is inserted while the horizontal layout above is hidden. */}
            </TableBody>
          </Table>
        ) : (
          <div>{paymentItemCards}</div>
        )}
      </div>
    );
  }
}
