/*
jQuery Credit Card Validator

Copyright 2012 Pawel Decowski

This work is licensed under the Creative Commons Attribution-ShareAlike 3.0
Unported License. To view a copy of this license, visit:

http://creativecommons.org/licenses/by-sa/3.0/

or send a letter to:

Creative Commons, 444 Castro Street, Suite 900,
Mountain View, California, 94041, USA.
*/

var cardTypes,
  __indexOf,
  getCardType,
  isValidLength,
  isValidLuhn,
  normalize,
  validateNumber;

__indexOf =
  Array.prototype.indexOf ||
  function(item) {
    var i;
    var l;
    for (i = 0, l = this.length; i < l; i++) {
      if (i in this && this[i] === item) 
        {
          return i;
        }
    }
    return -1;
  };

cardTypes = [
  {
    name: "amex",
    pattern: /^3[47]/,
    validLength: [15]
  },
  {
    name: "diners_club_carte_blanche",
    pattern: /^30[0-5]/,
    validLength: [14]
  },
  {
    name: "diners_club_international",
    pattern: /^36/,
    validLength: [14]
  },
  {
    name: "jcb",
    pattern: /^35(2[89]|[3-8][0-9])/,
    validLength: [16]
  },
  {
    name: "laser",
    pattern: /^(6304|630[69]|6771)/,
    validLength: [16, 17, 18, 19]
  },
  {
    name: "visa",
    pattern: /^4/,
    validLength: [13, 16, 17, 18, 19]
  },
  {
    name: "mastercard",
    pattern: /^(5[1-5]|2[2-7])/,
    validLength: [16, 17, 18, 19]
  },
  {
    name: "mastercard",
    pattern: /^(5[0678]\d\d|6304|6390|67\d\d)/,
    validLength: [12, 13, 14, 15, 16, 17, 18, 19]
  },
  {
    name: "discover",
    pattern: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
    validLength: [16, 17, 18, 19]
  }
];

getCardType = function(number) {
  var cardType, _i, _len;
  for (_i = 0, _len = cardTypes.length; _i < _len; _i++) {
    cardType = cardTypes[_i];
    if (number.match(cardType.pattern)) 
      {
        return cardType;
      }
  }
  return null;
};

isValidLuhn = function(number) {
  var digit, n, sum, _len, _ref;
  sum = 0;
  _ref = number
    .split("")
    .reverse()
    .join("");
  for (n = 0, _len = _ref.length; n < _len; n++) {
    digit = _ref[n];
    digit = +digit;
    if (n % 2) {
      digit *= 2;
      if (digit < 10) {
        sum += digit;
      } else {
        sum += digit - 9;
      }
    } else {
      sum += digit;
    }
  }
  return sum % 10 === 0;
};

isValidLength = function(number, cardType) {
  var _ref;

  if (typeof cardType === "undefined" || cardType === null) {
    return false;
  }
  
  // eslint-disable-next-line
  return (
    (_ref = number.length), __indexOf.call(cardType.validLength, _ref) >= 0
  );
};

validateNumber = function(number) {
  var cardType, lengthValid, luhnValid;
  cardType = getCardType(number);
  luhnValid = false;
  lengthValid = false;

  if (cardType !== null) {
    luhnValid = isValidLuhn(number);
    lengthValid = isValidLength(number, cardType);
  }

  return {
    cardType: cardType,
    luhnValid: luhnValid,
    lengthValid: lengthValid
  };
};

normalize = function(number) {
  return number.replace(/[ \-_]/g, "");
};

function isValidCreditCard(number) {
  number = normalize(number);
  let results = validateNumber(number);

  return results.luhnValid && results.lengthValid;
}

const getCardTypeString = number => {
  let result = getCardType(number);
  if (result !== null) {
    return result.name;
  }

  return "unknown";
};

const isCardTypeDebit = (isDebitCard, isCheckCard, hasMetadata) => {
  return hasMetadata && (isDebitCard || isCheckCard);
};

export { isValidCreditCard, getCardType, getCardTypeString, isCardTypeDebit };
