import { Map, fromJS } from "immutable";
import { getCurrentDateTimeFormatted } from "./../../utils/DateUtils";

import {
  SET_ACCOUNT_PAYMENT,
  SET_AUTO_PAY_ENROLLMENT,
  SUBMIT_AUTO_PAY_ENROLLMENT,
  GET_ACH_TOKENS,
  SET_CARD_INFO,
  GET_CARD_FEE,
  SUBMIT_TRANSACTION,
  ATTACH_CAPTCHA,
  CLEAR_ACCOUNT_PAYMENT,
  SET_DEPOSIT_ACCOUNT_REFERENCE,
  SUCCESS,
  FAILURE,
} from "../../api/actions/actionTypes";

const getDefaultState = () => {
  return Map({});
};

const actions = {};

actions[`${GET_ACH_TOKENS}_${SUCCESS}`] = function (state, action) {
  const accountToken = action.payload[0];
  const routingToken = action.payload[1];

  const achInfo = {
    accountNumber: accountToken.body.token,
    routingNumber: routingToken.body.token,
    type: "ach",
  };

  return state.setIn(["method"], fromJS(achInfo));
};

actions[SET_CARD_INFO] = function (state, action) {
  const cardInfo = {
    ...action.payload,
    ...action.meta,
  };

  var updatedSessionState = state.setIn(
    ["current", "sessionTimeStamp"],
    getCurrentDateTimeFormatted()
  );
  return updatedSessionState.mergeIn(["method"], fromJS(cardInfo));
};

actions[SET_DEPOSIT_ACCOUNT_REFERENCE] = function (state, action) {
  return state.setIn(
    ["current", "depositAccountReferenceId"],
    action.payload.depositAccountReferenceId
  );
};

actions[SET_ACCOUNT_PAYMENT] = function (state, action) {
  const payload = fromJS(action.payload);
  const withTimeStamp = payload.set(
    "sessionTimeStamp",
    getCurrentDateTimeFormatted()
  );

  return state.mergeIn(["current"], withTimeStamp);
};

actions[CLEAR_ACCOUNT_PAYMENT] = function (state, action) {
  return state.set("current", getDefaultState());
};

actions[SET_AUTO_PAY_ENROLLMENT] = function (state, action) {
  const enrollment = fromJS({
    isAutoPay: true,
    sessionTimeStamp: getCurrentDateTimeFormatted(),
  });

  return state.mergeIn(["current"], enrollment);
};

actions[`${SUBMIT_AUTO_PAY_ENROLLMENT}_${SUCCESS}`] = function (state, action) {
  const responseData = Map(action.payload.body);
  const confirmationNumber = responseData.get("confirmationNumber");

  return state
    .mergeIn(["current"], { confirmationNumber })
    .setIn(["current", "response"], responseData);
};

actions[`${SUBMIT_AUTO_PAY_ENROLLMENT}_${FAILURE}`] = function (state, action) {
  // Unset captcha token on a failed attempt.
  return state.setIn(["current", "captchaToken"], "");
};

actions[`${GET_CARD_FEE}_${SUCCESS}`] = function (state, action) {
  const fee = action.payload.body.feeAmount;
  const isDebit = action.payload.body.isDebit;
  return state.mergeIn(["current"], { fee, isDebit });
};

actions[`${SUBMIT_TRANSACTION}_${SUCCESS}`] = function (state, action) {
  const responseData = Map(action.payload.body);
  const confirmationNumber = responseData.get("confirmationNumber");
  const total = responseData.get("amount") + responseData.get("convenienceFee");
  const accessNumber = responseData.get("accessNumber");

  return state
    .mergeIn(["current"], { confirmationNumber, total, accessNumber })
    .setIn(["current", "response"], responseData);
};

actions[`${SUBMIT_TRANSACTION}_${FAILURE}`] = function (state, action) {
  // Unset captcha token on a failed attempt.
  return state.setIn(["current", "captchaToken"], "");
};

actions[ATTACH_CAPTCHA] = function (state, action) {
  const payload = Map(action.payload);
  return state.setIn(["current", "captchaToken"], payload.get("token"));
};

export default function (state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  return actions[action.type](state, action);
}
