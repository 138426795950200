import React from "react";
import CvvWarning from "./CvvWarning.jsx";
import Formsy from "formsy-react";
import FormsyTextField from "./../../components/form-input/FormsyTextField.jsx";
import PayNowWarning from "./PayNowWarning.jsx";
import { ResponsiveUtil } from "./../../utils/ResponsiveUtil";
import CVC2SampleVisaNew from "./../../img/CVC2SampleVisaNew.png";
import FocusableButton from "../../components/FocusableButton.jsx";
import GoogleReCaptcha from "./../utility/GoogleReCaptcha.jsx";

const mobileStyle = {
  width: "100%",
  maxWidth: "none",
  marginBottom: "10px",
};

const isMobile = ResponsiveUtil.isMobile();

export default class SubmitPaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cvv: "",
      valid: !this.props.collectCvv,
    };

    this.handleCvvInput = this.onInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
  }
  onInput(name) {
    return (value) => {
      let update = {};
      update[name] = value;
      this.setState(update);
    };
  }
  onSubmit(e) {
    const model = Object.assign({}, this.state);
    delete model.valid;

    this.props.onSubmit(model);
  }
  onValid() {
    this.setState({ valid: true });
  }
  onInvalid() {
    this.setState({ valid: false });
  }
  render() {
    return (
      <Formsy
        noValidate
        onValidSubmit={this.onSubmit}
        onValid={this.onValid}
        onInvalid={this.onInvalid}
      >
        {this.props.collectCvv ? (
          <div>
            <CvvWarning t={this.props.t} />
            <FormsyTextField
              noValidate
              name="cvv"
              floatingLabelText={this.props.t("CVV")}
              value={this.state.cvv}
              onChange={this.onInput("cvv")}
              validations={{
                maxLength: 4,
              }}
              validationErrors={{
                maxLength: this.props.t(
                  "You cannot type more than 4 characters"
                ),
              }}
              required
              shouldFocusOnMount
            />
            <img
              src={CVC2SampleVisaNew}
              title={
                "CVC2SampleVisaNew by Airodyssey at the English language Wikipedia"
              }
              alt="The CVV code is a 3-digit number that can be found on the back of your card."
            />
            <br />
          </div>
        ) : null}
        <br />
        <PayNowWarning t={this.props.t} isAutoPay={this.props.isAutoPay} />
        {this.props.enableRecaptcha ? <GoogleReCaptcha /> : null}
        <br />
        <FocusableButton
          variant="contained"
          type="submit"
          disabled={!this.state.valid || this.props.disabled}
          style={isMobile ? mobileStyle : null}
          color="primary"
          shouldFocusOnMount={this.props.shouldFocusOnMount}
        >
          {this.props.isAutoPay
            ? this.props.t("Enroll now & agree to terms")
            : this.props.t("Pay now & agree to terms")}
        </FocusableButton>
      </Formsy>
    );
  }
}
