import numeral from "numeral";
import moment from "moment";
import {
  updateTimezoneFormatted,
  getCurrentDateTimeFormatted,
} from "./../../utils/DateUtils.js";

import { fromJS } from "immutable";

import {
  getCardFee,
  getAchFee,
  getCardToken,
  getAchToken,
  submitPayment,
} from "./../requests/payments";

import {
  GET_CARD_TOKEN,
  GET_ACH_TOKENS,
  SET_CARD_INFO,
  GET_CARD_FEE,
  SET_ACCOUNT_PAYMENT,
  SUBMIT_TRANSACTION,
  BLOCKED_BIN,
  VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD,
  INVALID_CARD,
  PENDING,
  SUCCESS,
  ATTACH_CAPTCHA,
  CLEAR_ACCOUNT_PAYMENT,
  SET_DEPOSIT_ACCOUNT_REFERENCE,
  FAILURE,
} from "../../api/actions/actionTypes";

import { formatCardNetworkForDatabase } from "./../../containers/payment-method/options/FormatCardNetwork.js";
import {
  getCardTypeString,
  isCardTypeDebit,
} from "./../../utils/CreditCardValidator.js";

export const setDepositAccount = (depositAccountReferenceId) => (dispatch) => {
  dispatch({
    type: SET_DEPOSIT_ACCOUNT_REFERENCE,
    payload: {
      depositAccountReferenceId: depositAccountReferenceId,
    },
  });
};

export const getCardFeeAction = (accessNumber) => (dispatch, getState) => {
  const state = getState();

  const isDebit = state.payments.getIn(["method", "isDebit"]);
  const amount = state.payments.getIn(["current", "amount"]);
  const cardNetwork = state.payments.getIn(["method", "cardNetwork"]);
  const promise = getCardFee(accessNumber, isDebit, amount, cardNetwork);

  dispatch({
    type: GET_CARD_FEE,
    payload: promise,
  });

  return promise;
};

export const getAchFeeAction = (accessNumber) => (dispatch, getState) => {
  const state = getState();
  const amount = state.payments.getIn(["current", "amount"]);

  const promise = getAchFee(accessNumber, amount);

  dispatch({
    type: GET_CARD_FEE,
    payload: promise,
  });

  return promise;
};

//TODO3: Do this better
export const getFeeAction = (accessNumber) => (dispatch, getState) => {
  const type = getState().payments.getIn(["method", "type"]);
  if (type === "cc") {
    dispatch(getCardFeeAction(accessNumber));
  }
  if (type === "ach") {
    dispatch(getAchFeeAction(accessNumber));
  }
};

export const getCardTokenAction =
  (accessNumber, cardNumber) => (dispatch, getState) => {
    const promise = getCardToken(accessNumber, cardNumber);

    dispatch({
      type: GET_CARD_TOKEN,
      payload: promise,
    });

    return promise;
  };

export const checkCreditCard = (
  accessNumber,
  cardNumber,
  network,
  methodInfo,
  consortium
) => {
  const visaDebitOnly = consortium
    .getIn(["customers", accessNumber, "acceptedCardTypes"])
    .includes("visa debit card");
  const mastercardDebitOnly = consortium
    .getIn(["customers", accessNumber, "acceptedCardTypes"])
    .includes("mastercard debit card");

  // It's possible for us to get back a partial success from the token endpoint, where we fail to get card metadata (the metadata body will have an error on it).
  // In this case, we are going to treat the entered card as a credit card since there are rare cases where a card number can
  // exist without metadata.
  const isDebit = isCardTypeDebit(
    methodInfo.get("isDebitCard"),
    methodInfo.get("isCheckCard"),
    !methodInfo.get("error")
  );

  if (visaDebitOnly && network === "VISN" && !isDebit) {
    return false;
  } else if (mastercardDebitOnly && network === "MCRD" && !isDebit) {
    return false;
  }

  return true;
};

export const getAchTokensAction =
  (accessNumber, accountNumber, routingNumber) => (dispatch, getState) => {
    const accountPromise = getAchToken(
      accessNumber,
      accountNumber,
      "accountNumber"
    );
    const routingPromise = getAchToken(
      accessNumber,
      routingNumber,
      "routingNumber"
    );

    const promise = Promise.all([accountPromise, routingPromise]);

    dispatch({
      type: GET_ACH_TOKENS,
      payload: promise,
    });

    return promise;
  };

export const setAccountPaymentAction =
  (amountString, date, frequency, customData) => (dispatch) => {
    const amount = numeral(amountString).value();

    dispatch({
      type: SET_ACCOUNT_PAYMENT,
      payload: { amount, date, frequency, customData },
    });
  };

export const submitCardInfoAction =
  (accessNumber, cardNumber, cardExpiration, cardholderName) =>
  (dispatch, getState) => {
    const state = getState();
    const accountPath = state.billing.getIn(["accounts", "current"]);
    const account = state.billing.getIn(accountPath);
    const bankNumber = account.get("bankNumber");
    const companyNumber = account.get("companyNumber");
    const networkFromCardNumber = formatCardNetworkForDatabase(
      getCardTypeString(cardNumber)
    );

    const enteredBin = cardNumber.replace(" ", "").substr(0, 6);
    if (state.consortium.get("blockedBins").includes(enteredBin)) {
      dispatch({
        type: BLOCKED_BIN,
      });

      return;
    }

    dispatch({
      type: `${VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD}_${PENDING}`,
    });

    getCardTokenAction(accessNumber, cardNumber)(dispatch, getState)
      .then((res) => {
        const network = !res.body.metadata.error
          ? res.body.metadata.network
          : networkFromCardNumber;
        const cardIsAccepted = checkCreditCard(
          accessNumber,
          cardNumber,
          network,
          fromJS(res.body.metadata),
          state.consortium
        );
        if (!cardIsAccepted) {
          dispatch({
            type: INVALID_CARD,
            payload: { network },
          });

          return Promise.reject();
        }

        dispatch({
          type: SET_CARD_INFO,
          payload: {
            cardNumber: res.body.token,
            cardNetwork: network,
            // It's possible for us to get back a partial success from the token endpoint, where we fail to get card metadata (the metadata body will have an error on it).
            // In this case, we are going to treat the entered card as a credit card since there are rare cases where a card number can
            // exist without metadata.
            isDebit: isCardTypeDebit(
              res.body.metadata.isDebitCard,
              res.body.metadata.isCheckCard,
              !res.body.metadata.error
            ),
            cardExpiration,
            cardholderName,
            type: "cc",
          },
          meta: {
            bankNumber,
            companyNumber,
          },
        });
      })
      .then(() => {
        dispatch({
          type: `${VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD}_${SUCCESS}`,
        });
      })
      .catch(() => {
        dispatch({
          type: `${VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD}_${FAILURE}`,
        });
      });
  };

export const setGuestAchInfoAction =
  (accessNumber, achData) => (dispatch, getState) => {
    const state = getState();
    const accountPath = state.billing.getIn(["accounts", "current"]);
    const account = state.billing.getIn(accountPath);
    const bankNumber = account.get("bankNumber");
    const companyNumber = account.get("companyNumber");

    dispatch(
      getAchTokensAction(
        accessNumber,
        achData.accountNumber,
        achData.routingNumber
      )
    ).then(() => {
      dispatch({
        type: SET_CARD_INFO,
        payload: {
          name: achData.name,
          accountType: achData.accountType,
          type: "ach",
        },
        meta: {
          bankNumber,
          companyNumber,
        },
      });
    });
  };

export const useSelectedMethod = (method, type) => (dispatch) => {
  const paymentMethod = method.toJS();
  dispatch({
    type: SET_CARD_INFO,
    payload: {
      ...paymentMethod,
      type: type,
    },
  });
};

const determinePaymentType = (type, isSaved) => {
  const prependSave = isSaved ? "Saved" : "";
  const typeDesc = type === "cc" ? "CreditCard" : "Ach";
  return `${prependSave}${typeDesc}`;
};

const generatePaymentMethodPayload = (method, type, id, cvv) => {
  if (!!id && type === "cc") {
    return { cvv, id };
  }

  if (type === "cc") {
    return {
      cardToken: method.get("cardNumber"),
      cvv: cvv,
      cardholderName: method.get("cardholderName"),
      cardExpiration: method.get("cardExpiration"),
      cardMeta: {
        network: method.get("cardNetwork"),
        isDebit: method.get("isDebit"),
      },
    };
  }

  if (!!id && type === "ach") {
    return { id };
  }

  if (type === "ach") {
    return {
      name: method.get("name"),
      accountNumber: method.get("accountNumber"),
      routingNumber: method.get("routingNumber"),
      accountType: method.get("accountType"),
    };
  }

  return {};
};

export const attachCaptchaToken = (token) => (dispatch) => {
  dispatch({
    type: ATTACH_CAPTCHA,
    payload: {
      token: token,
    },
  });
};

export const clearAccountPaymentAction = () => (dispatch) => {
  dispatch({
    type: CLEAR_ACCOUNT_PAYMENT,
  });
};

export const submitTransactionAction = (data) => (dispatch, getState) => {
  const state = getState();

  const billing = state.billing;
  const accountPath = billing.getIn(["accounts", "current"]);
  const account = billing.getIn(accountPath);
  const accessNumber = account.get("accessNumber");

  const bc = state.consortium.getIn(["customers", accessNumber]);
  const bankNumber = bc.get("bankNumber");
  const companyNumber = bc.get("companyNumber");
  const consortiumId = bc.get("consortiumId");

  const paymentsStore = state.payments;
  const userStore = state.user;
  const payment = paymentsStore.get("current");

  const userId = userStore.get("id");

  const contact = userStore.get("contact");

  const method = paymentsStore.get("method");
  const methodId = method.get("id");
  const paymentMethod = generatePaymentMethodPayload(
    method,
    method.get("type"),
    methodId,
    data.cvv
  );
  const paymentType = determinePaymentType(method.get("type"), !!methodId);

  // Grab the deposit account reference id out of state, if it was set
  // If it was not set, default to zero
  let depositAccountReferenceId = 0;

  if (payment.has("depositAccountReferenceId")) {
    depositAccountReferenceId = payment.get("depositAccountReferenceId");
  }

  // If we are dealing with company type 115, we need to format
  // thier custom "quarter" data to be YYYYMMDD for the last day
  // of the selected quarter. Up til now, we stored the customdata
  // for them as a moment, so
  let comments = null;
  if (
    payment.has("customData") &&
    payment.get("customData") &&
    bc.get("companyType") === "115"
  ) {
    comments = moment(payment.get("customData")).format("YYYYMMDD");
  }

  const payload = {
    bankNumber: bankNumber,
    companyNumber: companyNumber,
    consortiumId: consortiumId,
    accountNumber: account.get("accountNumber"),
    accessNumber: accessNumber,
    amount: payment.get("amount"),
    convenienceFee: payment.get("fee"),
    processDate: updateTimezoneFormatted(payment.get("date")),
    sessionTimeStamp: payment.get("sessionTimeStamp"),
    submissionTimeStamp: getCurrentDateTimeFormatted(),
    paymentType: paymentType,
    paymentSource: "Web",
    method: paymentMethod,
    userId: userId,
    contact: contact.toJS(),
    frequency: payment.get("frequency"),
    captchaToken: payment.get("captchaToken"),
    depositAccountReferenceId: depositAccountReferenceId,
    comments: comments,
  };

  dispatch({
    type: SUBMIT_TRANSACTION,
    payload: submitPayment(payload, bankNumber, companyNumber),
    meta: {
      accessNumber: accessNumber,
      paymentCooldown: bc.get("paymentCooldown"),
      paymentCooldownRemaining: account.get("paymentCooldownRemaining"),
    },
  });
};
