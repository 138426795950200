import React from "react";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import StatementViewSummaryCard from "./StatementViewSummaryCard.jsx";
import { ResponsiveUtil } from "./../../utils/ResponsiveUtil";
import { capitalizeFirstLetter } from "./../../utils/FormatUtil";

export default class StatementViewSummary extends React.Component {
  render() {
    let summaryComponent;
    const isMobile = ResponsiveUtil.isMobile();
    if (isMobile) {
      summaryComponent = (
        <StatementViewSummaryCard
          t={this.props.t}
          summaryItems={this.props.summaryItems}
        />
      );
    } else {
      const summaryItemIndexLookup = this.props.summaryItems.keySeq();
      const summaryItems = this.props.summaryItems
        .map((value, key) => {
          const index = summaryItemIndexLookup.findIndex((k) => k === key);
          return (
            <TableRow
              divider={{ opacity: "0%" }}
              style={{ height: "24px" }}
              key={`table-row-${index}`}
            >
              <TableCell
                style={{
                  textAlign: "left",
                  padding: "0px 0px 0px 0px",
                  height: "24px",
                }}
                key={`cell-one-${index}`}
              >
                <h5>{this.props.t(capitalizeFirstLetter(key))}:</h5>
              </TableCell>
              <TableCell
                style={{
                  textAlign: "right",
                  padding: "0px 0px 0px 5px",
                  height: "24px",
                }}
                key={`cell-two-${index}`}
              >
                <h5>{value}</h5>
              </TableCell>
            </TableRow>
          );
        })
        .toList()
        .toJS();

      let chunkSize = Math.ceil(summaryItems.length / 3);
      let result = summaryItems
        .map(function (_, i) {
          return i % chunkSize === 0
            ? summaryItems.slice(i, i + chunkSize)
            : null;
        })
        .filter((i) => i); // Removes null entries

      const summaryColumns = result.map((v, i) => (
        <Grid item xs={4} key={`wrapper-${i}`}>
          <Table>
            <TableHead />
            <TableBody>{v}</TableBody>
          </Table>
          <br />
        </Grid>
      ));
      summaryComponent = (
        <Grid container spacing={16}>
          {summaryColumns}
        </Grid>
      );
    }

    return <div>{summaryComponent}</div>;
  }
}
