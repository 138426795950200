import React from "react";
import { connect } from "react-redux";
import { sessionStartedAction } from "./../api/actions/session";
import { startIdleTimeoutAction } from "./../api/actions/idle";

class SessionManager extends React.Component {
  componentDidMount() {
    this.props.dispatchSessionStarted();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.sessionEnded) {
      this.props.onTimeout();
    }
  }
  render() {
    return <div id="session-manager" />;
  }
}

function mapStateToProps(state) {
  return {
    sessionEnded: state.session.get("sessionEnded")
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSessionStarted: () => {
      dispatch(sessionStartedAction());
      dispatch(startIdleTimeoutAction());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionManager);
