import React from "react";
import Formsy from "formsy-react";
import Button from "@material-ui/core/Button";
import FormsyTextField from "./../../components/form-input/FormsyTextField.jsx";
import AccountField from "./AccountField.jsx";
import { ResponsiveUtil } from "./../../utils/ResponsiveUtil";

const isMobile = ResponsiveUtil.isMobile();
const mobileStyle = {
  width: "100%",
  maxWidth: "none",
  marginBottom: "10px"
};

export default class AccountsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountNumberInput: "",
      secondaryValidationInput: "",
      valid: false,
      resetTouch: false
    };

    this.onInput = this.onInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
  }
  onInput(name) {
    return value => {
      let update = {};
      update[name] = value;
      this.setState(update);
    };
  }
  onSubmit(e) {
    if (this.props.showSecondaryValidation) {
      this.props.onSubmit(
        this.state.accountNumberInput,
        this.state.secondaryValidationInput
      );
      return;
    }
    this.props.onSubmit(this.state.accountNumberInput, null);
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.lastRequestFailed && !nextProps.lastRequestPending) {
      this.setState({
        accountNumberInput: "",
        secondaryValidationInput: "",
        touched: false,
        resetTouch: true
      });
    }
  }
  onValid() {
    this.setState({ valid: true });
  }
  onInvalid() {
    this.setState({ valid: false });
  }
  renderSecondaryValidation() {
    return (
      <span>
        <FormsyTextField
          name="secondaryValidation"
          floatingLabelText={this.props.t("Secondary Account Number")}
          value={this.state.secondaryValidationInput}
          onChange={this.onInput("secondaryValidationInput")}
          validationErrors={{
            isDefaultRequiredValue: this.props.t(
              "Secondary account number is required"
            )
          }}
          required
          resetTouch={this.state.resetTouch}
        />
        <br />
      </span>
    );
  }
  renderMaskedAccountInput() {
    const blocks = this.props.accountFieldLengths
      .filter(a => {
        return a > 0;
      })
      .toArray();
    const delimiters = this.props.accountFieldSeparators
      .filter(a => {
        return a !== "";
      })
      .toArray();
    const expectedLength =
      this.props.accountFieldLengths.reduce((prev, current) => prev + current) +
      delimiters.length;
    return (
      <AccountField
        name="accountNumber"
        label={this.props.t("Account Number")}
        value={this.state.accountNumberInput}
        onChange={this.onInput("accountNumberInput")}
        validations={{ isLength: expectedLength }}
        validationErrors={{
          isLength: this.props.t("Invalid Account Number"),
          isDefaultRequiredValue: this.props.t("Account number is required")
        }}
        blocks={blocks}
        delimiters={delimiters}
        required
        resetTouch={this.state.resetTouch}
        shouldFocusOnMount
      />
    );
  }
  renderPlainAccountInput() {
    return (
      <FormsyTextField
        name="accountNumber"
        floatingLabelText={this.props.t("Account Number")}
        value={this.state.accountNumberInput}
        onChange={this.onInput("accountNumberInput")}
        error={this.state.touched && !this.props.isValid()}
        helperText={
          this.state.touched && !this.props.isValid()
            ? this.props.getErrorMessage()
            : null
        }
        validations={{
          maxLength: 30
        }}
        validationErrors={{
          isDefaultRequiredValue: this.props.t("Account number is required"),
          maxLength: this.props.t("You cannot type more than 30 characters")
        }}
        required
        touched={this.state.touched}
        resetTouch={this.state.resetTouch}
        shouldFocusOnMount
      />
    );
  }
  showMaskedField() {
    // If the provided accountFieldLengths begin with zero, or are empty, we just want to display a normal 30 character input box
    return (
      this.props.accountFieldLengths.first() === "0" ||
      this.props.accountFieldLengths.first() === 0
    );
  }
  render() {
    return (
      <Formsy
        noValidate
        onValidSubmit={this.onSubmit}
        onValid={this.onValid}
        onInvalid={this.onInvalid}
        role="form"
        aria-label="Add new account"
      >
        {this.showMaskedField()
          ? this.renderPlainAccountInput()
          : this.renderMaskedAccountInput()}
        <br />
        {this.props.showSecondaryValidation
          ? this.renderSecondaryValidation()
          : null}
        <br />
        <Button
          variant="contained"
          label={this.props.t("Add Account")}
          type="submit"
          color="primary"
          default={true}
          style={isMobile ? mobileStyle : null}
          disabled={!this.state.valid || this.props.lastRequestPending}
        >
          {this.props.t("Add Account")}
        </Button>
      </Formsy>
    );
  }
}
