import http from "./../utils/http";

export const getRecurringPayments = (
  consortiumId,
  userIdo,
  bankNumber,
  companyNumber,
  accountNumber,
  page
) => {
  return http
    .get(
      `/api/v1/consortiums/${consortiumId}/users/${userIdo}/recurring-payments?bank_number=${bankNumber}&company_number=${companyNumber}&account_number=${accountNumber}&page=${page}&per_page=10`
    )
    .end();
};

export const deleteRecurringPayments = (
  bankNumber,
  companyNumber,
  transactionNumber
) => {
  return http
    .delete(
      `/api/v1/fis/${bankNumber}/bcs/${companyNumber}/payments/${transactionNumber}`
    )
    .end();
};
