import React from "react";
import { withFormsy } from "formsy-react";
import { TextField } from "@material-ui/core";
import MaskedInput from "react-text-mask";

function ZipFormatterInput(props) {
  const { inputRef, ...other } = props;

  const maskFunction = (rawValue) => {
    if (rawValue && rawValue.length >= 5 && rawValue.includes("-")) {
      return [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
    }

    return [/\d/, /\d/, /\d/, /\d/, /\d/];
  };

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={maskFunction}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

class FormsyZipCodeInternal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { touched: false };
    this.onBlur = this.onBlur.bind(this);
    this.onClick = this.onClick.bind(this);
  }
  onBlur() {
    this.setState({ touched: true });
  }
  onClick(e) {
    // The masked input sets the cursor to the end of the input
    // if you click to focus it. We can use setSelectionRange to
    // bump your cursor to the beginning. Since we are relying on TextField's
    // onclick, we have to verify they actually click the textbox, and not the error
    // text or label before we can do this.
    if (e.target.type === "text") {
      e.target.setSelectionRange(0, 0);
    }
  }
  render() {
    return (
      <TextField
        id={this.props.name}
        type="text"
        fullWidth
        name={this.props.name}
        value={this.props.value}
        disabled={this.props.disabled}
        label={this.props.floatingLabelText}
        onChange={(e) => this.props.onChange(e.currentTarget.value)}
        error={this.state.touched && !this.props.isValid()}
        helperText={
          this.state.touched && !this.props.isValid()
            ? this.props.getErrorMessage()
            : null
        }
        onClick={this.onClick}
        onBlur={this.onBlur}
        style={{ bottom: "10px" }}
        required={this.props.required}
        InputProps={{
          inputComponent: ZipFormatterInput,
        }}
      />
    );
  }
}

const HOCFormsyZipCode = withFormsy(FormsyZipCodeInternal);

// We have to wrap our component if we to be able to force validations to always be "isMoney".
// The HOC function causes validations to exist on the component.
class FormsyZipCode extends React.Component {
  render() {
    return (
      <HOCFormsyZipCode
        {...this.props}
        validations="isZipCode"
        validationErrors={{
          isDefaultRequiredValue: this.props.t("Zip Code is required"),
          isZipCode: this.props.t("Please enter a valid zip code"),
        }}
      />
    );
  }
}

export default FormsyZipCode;
