import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { List, Avatar, ListItemText } from "@material-ui/core";
import ContentAdd from "@material-ui/icons/Add";
import ChevronRight from "@material-ui/icons/ChevronRight";
import AlertInfo from "./../errors/AlertInfo.jsx";
import UserSignInPrompt from "./../user-accounts/UserSignInPrompt.jsx";
import RouteHelper from "./../../utils/RouteHelper";
import { setPageInfoAction } from "./../../api/actions/pageInfo";
import { setDepositAccount } from "./../../api/actions/payments";
import { _t } from "./../../text/locale";
import { ResponsiveUtil } from "../../utils/ResponsiveUtil";
import FocusableListItem from "../../components/FocusableListItem.jsx";

const isMobile = ResponsiveUtil.isMobile();
const t = _t();

// If the business customer has a set of deposit accounts a user can chose to pay
// against, we render this page immediately after the landing page. It should show
// a list of "deposit accounts" to select and assign a deposit account reference id
// (payfunc in the database) to the current payment. Otherwise, it acts like the
// landing page in routing people onwards to either sign in or pick an account.
class PaymentTowards extends Component {
  constructor(props) {
    super(props);

    this.shouldFocus = this.shouldFocus.bind(this);
  }
  UNSAFE_componentWillMount() {
    let pageTitle = isMobile ? this.props.title : "Pay To";
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  // Target the first item in the list
  shouldFocus(currentDepositAccountReferenceId, businessCustomer) {
    const firstDepositAccountReferenceId = businessCustomer
      .get("depositAccounts")
      .first()
      .get("referenceId");

    return firstDepositAccountReferenceId === currentDepositAccountReferenceId;
  }
  render() {
    const accessNumber = this.props.params.accessNumber;
    const bc = this.props.consortium.getIn(["customers", accessNumber]);

    const makeListItem = (depositAccount) => {
      const lookUpPath = RouteHelper.getRelativeAppViewRoute(
        this.props.location
      )(`/business/${accessNumber}/lookup-accounts`);
      const accountPath = RouteHelper.getRelativeAppViewRoute(
        this.props.location
      )(`/business/${accessNumber}/accounts/select`);
      let path = "";
      if (this.props.loggedIn || this.props.guestOnly) {
        path = accountPath;
      } else {
        path = lookUpPath;
      }

      return (
        <li key={depositAccount.get("referenceId")}>
          <FocusableListItem
            key={depositAccount.get("referenceId")}
            onClick={() => {
              this.props.dispatchSetDepositAccount(
                depositAccount.get("referenceId")
              );
              this.props.router.push(path);
            }}
            shouldFocusOnMount={this.shouldFocus(
              depositAccount.get("referenceId"),
              bc
            )}
          >
            <Avatar
              style={{
                left: "4px",
                backgroundColor: this.props.styles.get("primary1Color"),
              }}
            >
              <ContentAdd />
            </Avatar>
            <ListItemText primary={depositAccount.get("name")} />
            <ChevronRight />
          </FocusableListItem>
        </li>
      );
    };
    let customerList = bc
      .get("depositAccounts")
      .map((depositAccount) => makeListItem(depositAccount));
    return (
      <div>
        <div className="flex-container">
          <div className="flex-content">
            <List>{customerList}</List>
          </div>
          <div
            className="flex-tipBox"
            aria-label="Home Page Tip Box"
            role="region"
          >
            {this.props.loggedIn ? (
              <AlertInfo
                text={t("TIP_BOX_PAY_TO")}
                location={this.props.location}
              />
            ) : (
              <UserSignInPrompt t={t} location={this.props.location} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    consortium: state.consortium,
    loggedIn: state.auth.get("authenticated"),
    styles: state.style.get("palette"),
    title: state.consortium.get("consortiumName"),
    guestOnly: state.consortium.getIn(["settings", "guestOnly"]),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    // todo add deposit account reference id to current payment model
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
    dispatchSetDepositAccount: (depositAccountReferenceId) => {
      dispatch(setDepositAccount(depositAccountReferenceId));
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentTowards)
);
