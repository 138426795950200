import React from "react";
import PropTypes from "prop-types";

import FormsyTextField from "./../../components/form-input/FormsyTextField.jsx";
import FormsyZipCode from "./../../components/form-input/FormsyZipCode.jsx";

class ContactFormFields extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onInput: PropTypes.func.isRequired
  };
  render() {
    return (
      <div>
        <FormsyTextField
          name="address"
          floatingLabelText={this.props.t("Address")}
          onChange={this.props.onInput("address")}
          value={this.props.formData.address}
          validations={{
            maxLength: 40
          }}
          validationErrors={{
            isDefaultRequiredValue: this.props.t("Address is required"),
            maxLength: this.props.t("You cannot type more than 40 characters")
          }}
          required={this.props.requireAddress}
        />
        <FormsyTextField
          name="city"
          floatingLabelText={this.props.t("City")}
          onChange={this.props.onInput("city")}
          value={this.props.formData.city}
          validationErrors={{
            isDefaultRequiredValue: this.props.t("City is required")
          }}
          required={this.props.requireAddress}
        />
        <FormsyTextField
          name="state"
          floatingLabelText={this.props.t("State")}
          onChange={this.props.onInput("state")}
          value={
            !this.props.formData.state
              ? ""
              : this.props.formData.state.toUpperCase()
          }
          validations={{
            isLength: 2,
            isState: "isState"
          }}
          validationErrors={{
            isDefaultRequiredValue: this.props.t("State is required"),
            isLength: this.props.t("Please enter a 2 character state code."),
            isState: this.props.t(
              "Please enter a valid 2 character state code."
            )
          }}
          required={this.props.requireAddress}
        />
        <FormsyZipCode
          name="zip"
          floatingLabelText={this.props.t("Zip Code")}
          onChange={this.props.onInput("zip")}
          value={this.props.formData.zip}
          validationErrors={{
            isDefaultRequiredValue: this.props.t("Zip code is required")
          }}
          t={this.props.t}
          required={this.props.requireAddress}
        />
      </div>
    );
  }
}

export default ContactFormFields;
