import React from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";
import FormsyTextField from "./../../components/form-input/FormsyTextField.jsx";
import FormsyPasswordField from "./../../components/form-input/FormsyPasswordField.jsx";
import Button from "@material-ui/core/Button";
import { ResponsiveUtil } from "./../../utils/ResponsiveUtil";

const mobileStyle = {
  width: "100%",
  maxWidth: "none",
  marginBottom: "10px",
};
const desktopStyle = {
  marginBottom: "10px",
};

const isMobile = ResponsiveUtil.isMobile();

class SsoLinkForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: props.username ? props.username : "",
      password: "",
      valid: false,
    };

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);

    this.onInput = this.onInput.bind(this);
  }
  static propTypes = {
    t: PropTypes.func.isRequired,
  };
  onValidSubmit() {
    // Copy our form model and delete form-related properties
    // This is less ~effort~ than re-initializing an object with all the properties.
    const model = Object.assign({}, this.state);
    delete model.valid;

    this.props.onSubmit(model);
  }
  onValid() {
    this.setState({ valid: true });
  }
  onInvalid() {
    this.setState({ valid: false });
  }
  onInput(name) {
    return (value) => {
      let update = {};
      update[name] = value;
      this.setState(update);
    };
  }

  render() {
    return (
      <Formsy
        role="form"
        aria-label="Sign In"
        noValidate
        onValidSubmit={this.onValidSubmit}
        onValid={this.onValid}
        onInvalid={this.onInvalid}
      >
        <div style={{ height: "110px" }}>
          <div>
            <FormsyTextField
              name="username"
              floatingLabelText={this.props.t("Username")}
              onChange={this.onInput("username")}
              value={this.state.username}
              validationErrors={{
                isDefaultRequiredValue: this.props.t("Username is required"),
              }}
              required
              shouldFocusOnMount
            />
          </div>
          <div>
            <FormsyPasswordField
              name="password"
              floatingLabelText={this.props.t("Password")}
              onChange={this.onInput("password")}
              value={this.state.password}
              validationErrors={{
                isDefaultRequiredValue: this.props.t("Password is required"),
              }}
              required
            />
          </div>
        </div>
        <div>
          <br />
          <Button
            variant="contained"
            type="submit"
            disabled={!this.state.valid || this.props.disabled}
            style={isMobile ? mobileStyle : desktopStyle}
            color="primary"
          >
            {this.props.t("Submit")}
          </Button>
        </div>
      </Formsy>
    );
  }
}

export default SsoLinkForm;
