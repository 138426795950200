import { Map, fromJS } from "immutable";
import moment from "moment";

import {
  AUTHENTICATE_USER,
  AUTHENTICATE_SSO,
  GET_ACCOUNTS,
  DELETE_USER_ACCOUNT,
  ADD_USER_ACCOUNT,
  CLOSE_NOTIFICATION,
  REGISTER_USER,
  REGISTER_SSO_USER,
  DELETE_USER_PAYMENT_METHOD,
  GET_ACH_TOKENS,
  GET_CARD_TOKEN,
  GET_CARD_FEE,
  ADD_USER_PAYMENT_METHOD,
  SUBMIT_TRANSACTION,
  SESSION_TIMEOUT_WARN,
  SESSION_IDLE_WARN,
  SESSION_TIMEOUT,
  USERNAME_REMINDER,
  PASSWORD_RESET_EMAIL,
  PASSWORD_RESET_VERIFICATION,
  USER_PASSWORD_UPDATE,
  UPDATE_CONTACT_INFO,
  SHOW_CONFIRMATION_DIALOG,
  SUCCESS,
  FAILURE,
  BLOCKED_BIN,
  INVALID_CARD,
  GET_AUTO_PAY_ENROLLMENT,
  SUBMIT_AUTO_PAY_ENROLLMENT,
} from "../../api/actions/actionTypes";

import { getEnglishName } from "./../../containers/payment-method/options/FormatCardNetwork.js";

const getDefaultState = () => {
  return Map({
    content: Map({
      title: "",
      message: "",
      level: "",
    }),
    show: false,
    isChoice: false,
  });
};

const actions = {};

const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case "email-already-exists":
      return "An account with that email address already exists. Would you like to try to sign in?";
    default:
      return "An error occurred. Please try again later.";
  }
};

actions[`${SHOW_CONFIRMATION_DIALOG}`] = function (state, action) {
  const content = Map(action.payload.content);
  const callbacks = Map(action.payload.callbacks);
  return state
    .set("content", content)
    .setIn(["content", "isChoice"], true)
    .set("callbacks", callbacks)
    .set("show", true);
};

actions[`${AUTHENTICATE_USER}_${FAILURE}`] = function (state, action) {
  const notification = Map({
    title: "User Authentication Error",
    message: "Please verify your user information and try again.",
    level: FAILURE,
  });
  return state.set("content", notification).set("show", true);
};

actions[`${REGISTER_USER}_${FAILURE}`] = function (state, action) {
  var message;
  if (!action.payload.response) {
    const notification = Map({
      title: "User Registration Failed",
      message: "Unable to register the user, please try again later.",
      level: FAILURE,
    });
    return state.set("content", notification).set("show", true);
  }

  const responseData = fromJS(action.payload.response.body);

  let errors = responseData.get("errors");
  if (!errors || errors.isEmpty()) {
    const defaultNotification = Map({
      title: "User Registration Failed",
      message:
        "There was an issue registering the user. Please try again later.",
      level: FAILURE,
    });
    return state.set("content", defaultNotification).set("show", true);
  }

  let errorCode = responseData.get("errors").first().get("code");
  switch (errorCode) {
    case "username-already-exists":
      message = "That username is already taken.";
      break;
    case "email-already-exists":
      message = "An account with that email address already exists.";
      break;
    default:
      message = "An error occurred. Please try again later.";
  }

  const notification = Map({
    title: "User Registration Failed",
    message: message,
    level: FAILURE,
  });

  return state.set("content", notification).set("show", true);
};

actions[`${ADD_USER_ACCOUNT}_${FAILURE}`] = function (state, action) {
  const responseData = fromJS(action.payload.response.body);
  let errorCode = responseData.get("errors").first().get("code");

  if (errorCode === "account-has-existing-auto-pay-enrollment") {
    const notification = Map({
      title: "Failed To Add Account",
      message:
        "There is an Auto-Pay enrollment on this account belonging to another user.",
      level: FAILURE,
    });
    return state.set("content", notification).set("show", true);
  }
};

actions[`${REGISTER_SSO_USER}_${FAILURE}`] = function (state, action) {
  if (!action.payload.response) {
    const notification = Map({
      title: "User Registration Failed",
      message: "Unable to register the user, please try again later.",
      level: FAILURE,
    });
    return state.set("content", notification).set("show", true);
  }

  const responseData = fromJS(action.payload.response.body);
  let errors = responseData.get("errors");
  if (!errors || errors.isEmpty()) {
    const defaultNotification = Map({
      title: "User Registration Failed",
      message:
        "There was an issue registering the user. Please try again later.",
      level: FAILURE,
    });
    return state.set("content", defaultNotification).set("show", true);
  }

  let errorCode = responseData.get("errors").first().get("code");

  let message = getErrorMessage(errorCode);

  const notification = Map({
    title: "User Registration Failed",
    message: message,
    level: FAILURE,
    isChoice: true,
  });

  return state
    .set("content", notification)
    .set("callbacks", action.meta)
    .set("show", true);
};



actions[`${GET_ACCOUNTS}_${FAILURE}`] = function (state, action) {
  const status = action.payload.status;

  if (status === 404) {
    const notification = Map({
      title: "Account Not Found",
      message: "Please verify your account information and try again.",
      level: FAILURE,
    });
    return state.set("content", notification).set("show", true);
  }

  const notification = Map({
    title: "Account Lookup Failed",
    message:
      "Unable to lookup your account information. If the issue persists, contact customer service at csr@magicwrighter.com.",
    level: FAILURE,
  });
  return state.set("content", notification).set("show", true);
};

actions[`${DELETE_USER_ACCOUNT}_${FAILURE}`] = function (state, action) {
  const notification = Map({
    title: "Failed To Delete Account",
    message:
      "You have an active auto-pay enrollment on this account. Please deactivate that auto-pay enrollment and try again.",
    level: FAILURE,
  });
  return state.set("content", notification).set("show", true);
};

actions[`${GET_CARD_TOKEN}_${FAILURE}`] = function (state, action) {
  const notification = Map({
    title: "Failed To Verify Credit Card",
    message:
      "Unable to verify your credit card information. Please try submitting again. If the issue persists, contact customer service at csr@magicwrighter.com.",
    level: FAILURE,
  });
  return state.set("content", notification).set("show", true);
};

actions[`${GET_ACH_TOKENS}_${FAILURE}`] = function (state, action) {
  const notification = Map({
    title: "Failed To Verify Bank Account",
    message:
      "Unable to verify your bank account information. Please try submitting again. If the issue persists, contact customer service at csr@magicwrighter.com.",
    level: FAILURE,
  });
  return state.set("content", notification).set("show", true);
};

actions[`${GET_CARD_FEE}_${FAILURE}`] = function (state, action) {
  // TODO3: Retry convenience fee request if we failed because of a server timeout or some other status where a retry would make sense
  const notification = Map({
    title: "Failed To Retrieve Convenience Fee",
    message: "Unable to retrieve the convenience fee for your payment.",
    level: FAILURE,
  });
  return state.set("content", notification).set("show", true);
};

const generateUserPaymentMethodFailureNotification = (errorCode) => {
  let title = "Failed To Save Payment Information";
  let message =
    "Unable to save your payment information. Please try submitting again. If the issue persists, contact customer service at csr@magicwrighter.com.";

  if (
    errorCode === "create-cc-blocked" ||
    errorCode === "credit-card-not-allowed"
  ) {
    title = "Credit Card Blocked";
    message =
      "Your financial institution does not accept credit card payments from this network. Please use a different payment method or contact your financial institution.";
  }

  const notification = Map({
    title: title,
    message: message,
    level: FAILURE,
  });

  return notification;
};

actions[`${ADD_USER_PAYMENT_METHOD}_${FAILURE}`] = function (state, action) {
  const responseData = fromJS(action.payload.response.body);
  let errorCode = responseData.get("errors").first().get("code");

  const notification = generateUserPaymentMethodFailureNotification(errorCode);

  return state.set("content", notification).set("show", true);
};

actions[`${DELETE_USER_PAYMENT_METHOD}_${FAILURE}`] = function (state, action) {
  const responseData = fromJS(action.payload.response.body);
  let errorCode = responseData.get("errors").first().get("code");

  if (errorCode === "payment-method-used-in-auto-pay-enrollment") {
    const notification = Map({
      title: "Failed To Remove Payment Information",
      message:
        "There is an Auto-Pay enrollment using this payment method. Please remove the Auto-Pay enrollment before deleting this payment method.",
      level: FAILURE,
    });

    return state.set("content", notification).set("show", true);
  }

  const notification = Map({
    title: "Failed To Remove Payment Information",
    message:
      "Unable to remove your payment information. Please try submitting again. If the issue persists, contact customer service at csr@magicwrighter.com.",
    level: FAILURE,
  });
  return state.set("content", notification).set("show", true);
};

const generateTransactionFailureNotification = (errorCode, meta) => {
  if (errorCode === "billing-account-at-payment-limit") {
    return Map({
      title: "Loan Account At Payment Limit Error",
      message:
        "This loan account is at it its payment limit. Contact your financial institution if you have any questions.",
      level: FAILURE,
    });
  }
  if (
    errorCode ===
    "cannot-make-payment-on-account-other-user-has-auto-pay-enrollment-on"
  ) {
    return Map({
      title: "Existing Auto-Pay Enrollment Found",
      message:
        "Another user has enrolled this account in Auto-Pay. Additional payments cannot be made against this account.",
      level: FAILURE,
    });
  }

  if (errorCode === "duplicate-payment") {
    return Map({
      title: "Duplicate Payment",
      message:
        "A payment has already been submitted during the current session time.",
      level: FAILURE,
    });
  }
  if (errorCode === "invalid-payment-posting-date") {
    return Map({
      title: "Invalid Payment Date",
      message:
        "This payment date is no longer valid. Please select a later date.",
      level: FAILURE,
    });
  }
  if (
    errorCode === "invalid-card-metadata-network" ||
    errorCode === "card-not-accepted"
  ) {
    return Map({
      title: "Credit Card Not Accepted",
      message:
        "The credit card supplied is not accepted by our payment processor. Please try again with a different credit card.",
      level: FAILURE,
    });
  }
  if (errorCode === "ccauth-timeout") {
    return Map({
      title: "Processing Timeout",
      message:
        "Your payment request timed out. Please try submitting your payment again. If the issue persists, please contact customer support at csr@magicwrighter.com.",
      level: FAILURE,
    });
  }
  if (errorCode === "ccauth-cancelled" || errorCode === "ccauth-declined") {
    return Map({
      title: "Credit Card Declined",
      message:
        "Your credit card was declined. Please try again with a different form of payment.",
      level: FAILURE,
    });
  }
  if (errorCode === "incorrect-convenience-fee") {
    //TODO4: Fix the convenience fee in the spa and tell them to try submitting the payment again.
    return Map({
      title: "Convenience Fee Error",
      message:
        "There was an error confirming the convenience fee for the payment. Please try again with a different form of payment.",
      level: FAILURE,
    });
  }
  if (errorCode === "expiration-date-expired") {
    return Map({
      title: "Expired Credit Card",
      message:
        "Your credit card has expired. Please try again with a different form of payment.",
      level: FAILURE,
    });
  }
  if (errorCode === "billing-account-blocked") {
    return Map({
      title: "Loan Account Blocked",
      message:
        "Your loan account has been blocked. Please contact your financial institution.",
      level: FAILURE,
    });
  }
  if (errorCode === "invalid-routing-number") {
    return Map({
      title: "Invalid Routing Number",
      message:
        "This routing number is blocked by your financial institution. Please try another.",
      level: FAILURE,
    });
  }
  if (errorCode === "routing-number-validation-error") {
    return Map({
      title: "Loan Account Blocked",
      message:
        "There was a problem while attempting to validate the provided routing number. Please try again or contact your financial institution.",
      level: FAILURE,
    });
  }
  if (errorCode === "cc-under-minimum") {
    return Map({
      title: "Payment Amount Error",
      message:
        "Your payment amount is below the minimum payment amount limit. Please try again or contact your financial institution.",
      level: FAILURE,
    });
  }
  if (errorCode === "cc-over-maximum") {
    return Map({
      title: "Payment Amount Error",
      message:
        "Your payment amount is over the maximum payment amount limit. Please try again or contact your financial institution.",
      level: FAILURE,
    });
  }
  if (
    errorCode === "cc-pending-daily-over-maximum" ||
    errorCode === "cc-daily-over-maximum"
  ) {
    return Map({
      title: "Payment Amount Error",
      message:
        "Your current, pending, and previous payment amounts are over the limit set by your financial institution. Please try again or contact your financial institution.",
      level: FAILURE,
    });
  }
  if (errorCode === "ach-under-minimum") {
    return Map({
      title: "Payment Amount Error",
      message:
        "Your payment amount is below the minimum payment amount limit. Please try again or contact your financial institution.",
      level: FAILURE,
    });
  }
  if (errorCode === "ach-over-maximum") {
    return Map({
      title: "Payment Amount Error",
      message:
        "Your payment amount is over the maximum payment amount limit. Please try again or contact your financial institution.",
      level: FAILURE,
    });
  }
  if (
    errorCode === "ach-pending-daily-over-maximum" ||
    errorCode === "ach-daily-over-maximum"
  ) {
    return Map({
      title: "Payment Amount Error",
      message:
        "Your current, pending, and previous payment amounts are over the limit set by your financial institution. Please try again or contact your financial institution.",
      level: FAILURE,
    });
  }
  if (
    errorCode === "credit-card-not-allowed" ||
    errorCode === "create-cc-blocked"
  ) {
    return generateUserPaymentMethodFailureNotification(errorCode);
  }
  if (errorCode === "billing-account-payment-cooldown") {
    let message =
      "A payment has been recently made to this account, please wait for the account to refresh.";

    if (
      meta.has("paymentCooldownRemaining") &&
      moment.isDuration(meta.get("paymentCooldownRemaining")) &&
      meta.has("paymentCooldown") &&
      moment.isDuration(meta.get("paymentCooldown"))
    ) {
      const remainingTime = meta.get("paymentCooldownRemaining").clone();

      message = `You may only make one payment in ${meta
        .get("paymentCooldown")
        .humanize()}. You may submit another payment in approximately ${remainingTime
        .add(1, "h")
        .humanize()}.`;
    }

    return Map({
      title: "Payments Are Temporarily Disabled",
      message: message,
      level: FAILURE,
    });
  }

  if (errorCode === "user-change-date-error") {
    return Map({
      title: "Payment Date Error",
      message:
        "You are not able to change the process date of your payment. Please try again or contact your financial institution.",
      level: FAILURE,
    });
  }

  if (
    errorCode ===
    "recurring-payment-not-allowed-on-account-with-active-auto-pay-enrollment"
  ) {
    return Map({
      title: "Recurring Payment Not Created",
      message:
        "A recurring payment could not be created because Auto-Pay enrollment is enabled on this account.",
      level: FAILURE,
    });
  }

  if (
    errorCode ===
    "payment-not-allowed-on-account-with-active-auto-pay-enrollment"
  ) {
    return Map({
      title: "Payment Not Processed",
      message:
        "Your payment cannot be processed while Auto-Pay enrollment is enabled on this account.",
      level: FAILURE,
    });
  }

  return Map({
    title: "Payment Failed",
    message:
      "There was an issue processing the payment. Please try again later.",
    level: FAILURE,
  });
};

actions[`${SUBMIT_TRANSACTION}_${FAILURE}`] = function (state, action) {
  const responseData = fromJS(action.payload.response.body);
  let errors = responseData.get("errors");
  if (!errors || errors.isEmpty()) {
    const notification = generateTransactionFailureNotification("default");
    return state.set("content", notification).set("show", true);
  }

  let errorCode = responseData.get("errors").first().get("code");

  const meta = fromJS(action.meta);
  const notification = generateTransactionFailureNotification(errorCode, meta);

  return state.set("content", notification).set("show", true);
};

actions[SESSION_TIMEOUT] = function (state, action) {
  const notification = Map({
    title: "Your Session Has Expired",
    message:
      "Any unprocessed payments were lost. Please refresh the page to continue.",
    level: FAILURE,
  });

  return state.set("content", notification).set("show", true);
};

actions[SESSION_TIMEOUT_WARN] = function (state, action) {
  const notification = Map({
    title: "Your Session Will Expire Soon",
    message:
      "Please complete any payments now to ensure they are processed. Any progress will be lost when your session expires.",
    level: FAILURE,
  });

  return state.set("content", notification).set("show", true);
};

actions[SESSION_IDLE_WARN] = function (state, action) {
  const notification = Map({
    title: "Inactivity Warning!",
    message:
      "You will be logged out soon and will lose any progress. Press OK to continue using the site.",
    level: FAILURE,
  });

  return state.set("content", notification).set("show", true);
};

actions[`${USERNAME_REMINDER}_${SUCCESS}`] = function (state, action) {
  const notification = Map({
    title: "Username Reminder Sent",
    message:
      "A username reminder message has been queued to be sent to the provided email address. Please give it a few minutes to appear in your inbox.",
    level: SUCCESS,
  });
  return state.set("content", notification).set("show", true);
};

actions[`${USERNAME_REMINDER}_${FAILURE}`] = function (state, action) {
  const notification = Map({
    title: "Failed To Send Username Reminder",
    message: "Please double check your email address and try again.",
    level: FAILURE,
  });

  return state.set("content", notification).set("show", true);
};

actions[`${PASSWORD_RESET_EMAIL}_${FAILURE}`] = function (state, action) {
  const status = action.payload.status;
  if (status === 404) {
    const notFound = Map({
      title: "Unable To Find Matching User",
      message:
        "Please ensure your username and email are correct and try again.",
      level: FAILURE,
    });

    return state.set("content", notFound).set("show", true);
  }

  const notification = Map({
    title: "Failed To Send Password Reset Email",
    message: "Please ensure your username and email are correct and try again.",
    level: FAILURE,
  });

  return state.set("content", notification).set("show", true);
};

actions[`${PASSWORD_RESET_VERIFICATION}_${FAILURE}`] = function (
  state,
  action
) {
  const notification = Map({
    title: "Pin And Token Verification Failed",
    message:
      "Please ensure the pin and token have been entered correctly and try again.",
    level: FAILURE,
  });

  return state.set("content", notification).set("show", true);
};

actions[`${USER_PASSWORD_UPDATE}_${SUCCESS}`] = function (state, action) {
  const notification = Map({
    title: "Password Update Success",
    message: "Your password has been updated.",
    level: SUCCESS,
  });
  return state.set("content", notification).set("show", true);
};

actions[`${UPDATE_CONTACT_INFO}_${FAILURE}`] = function (state, action) {
  const responseData = fromJS(action.payload.response.body);
  let errorCode = responseData.get("errors").first().get("code");

  if (errorCode === "user-update-email-already-exists") {
    const notification = Map({
      title: "Failed to update contact information",
      message: "Email is already in use. Please enter a new one.",
      level: FAILURE,
    });

    return state.set("content", notification).set("show", true);
  }

  const notification = Map({
    title: "Failed To Update Contact Information",
    message: "Something went wrong. Please try again later.",
    level: FAILURE,
  });

  return state.set("content", notification).set("show", true);
};

actions[`${GET_ACCOUNTS}_${SUCCESS}`] = function (state, action) {
  let account = fromJS(action.payload.body);
  const hasActiveAutoPayEnrollment = account.get("hasActiveAutoPayEnrollment");
  const isClaimed = account.get("userAccountId") !== 0;

  if (hasActiveAutoPayEnrollment && isClaimed) {
    const notification = Map({
      title: "Failed To Add Account",
      message:
        "There is an Auto-Pay enrollment on this account belonging to another user.",
      level: FAILURE,
    });

    return state.set("content", notification).set("show", true);
  }

  if (account.get("isBlocked") === true) {
    const notification = Map({
      title: "Loan Account Blocked",
      message:
        "This loan account has been blocked. Please contact your financial institution.",
      level: FAILURE,
    });
    return state.set("content", notification).set("show", true);
  }

  return state;
};

actions[`${USER_PASSWORD_UPDATE}_${FAILURE}`] = function (state, action) {
  const notification = Map({
    title: "Failed to Update Password",
    message: "Please try re-entering your password and try again.",
    level: FAILURE,
  });

  return state.set("content", notification).set("show", true);
};

actions[CLOSE_NOTIFICATION] = function (state, action) {
  return state.set("show", false).delete("callbacks");
};

actions[BLOCKED_BIN] = function (state, action) {
  const notification = Map({
    title: "Credit Card Blocked",
    message:
      "You may not use a credit card issued by this financial institution.",
    level: FAILURE,
  });

  return state.set("content", notification).set("show", true);
};

actions[INVALID_CARD] = function (state, action) {
  let network = getEnglishName(action.payload.network);
  const notification = Map({
    title: "Debit Card Only",
    message: `You attempted to use a ${network} credit card, which is not currently being accepted. Please make a payment with a ${network} debit card, or a card from another accepted network.`,
    level: FAILURE,
  });

  return state.set("content", notification).set("show", true);
};

actions[`${AUTHENTICATE_SSO}_${FAILURE}`] = function (state, action) {
  const notification = Map({
    title: "Authentication Failed",
    message:
      "Logging you in from your financial institution failed. You are still able to make payments as a guest. However, your payment activity won't appear on your user account.",
    level: FAILURE,
  });

  return state.set("content", notification).set("show", true);
};

actions[`${GET_AUTO_PAY_ENROLLMENT}_${FAILURE}`] = function (state, action) {
  const notification = Map({
    title: "Auto-Pay Enrollment Error",
    message:
      "There was an issue retrieving your Auto-Pay enrollment information. Please try again later or contact your financial institution.",
    level: FAILURE,
  });
  return state.set("content", notification).set("show", true);
};

actions[`${SUBMIT_AUTO_PAY_ENROLLMENT}_${FAILURE}`] = function (state, action) {
  const responseData = fromJS(action.payload.response.body);

  let errorCode = responseData.get("errors").first().get("code");

  if (errorCode === "auto-pay-already-exists") {
    const notification = Map({
      title: "Auto-Pay Enrollment Already Exists",
      message: "An Auto-Pay enrollment already exists for this account.",
      level: FAILURE,
    });

    return state.set("content", notification).set("show", true);
  }

  if (errorCode === "auto-pay-already-exists-with-different-user") {
    const notification = Map({
      title: "Auto-Pay Enrollment Already Exists",
      message:
        "A different user has an existing Auto-Pay enrollment on this account.",
      level: FAILURE,
    });

    return state.set("content", notification).set("show", true);
  }

  if (
    errorCode ===
    "auto-pay-enrollment-not-allowed-on-account-with-active-recurring-payment"
  ) {
    const notification = Map({
      title: "Auto-Pay Enrollment Not Created",
      message:
        "An Auto-Pay enrollment could not be created because a recurring payment is enabled on this account. ",
      level: FAILURE,
    });

    return state.set("content", notification).set("show", true);
  }

  const notification = Map({
    title: "Auto-Pay Enrollment Error",
    message:
      "There was an error creating your Auto-Pay enrollment. Please try again later or contact your financial institution.",
    level: FAILURE,
  });

  return state.set("content", notification).set("show", true);
};

export default function (state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  return actions[action.type](state, action);
}
