import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText
} from "@material-ui/core";
import Warning from "@material-ui/icons/Warning";

class MaxiumumPaymentMethodsWarning extends React.Component {
  render() {
    return (
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={this.props.avatarStyle}>
              <Warning />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={this.props.title} />
        </ListItem>
      </List>
    );
  }
}

export default MaxiumumPaymentMethodsWarning;
