import { LOADING_SHOW, LOADING_HIDE } from "../../api/actions/actionTypes";

const showLoadingAction = title => dispatch => {
  dispatch({
    type: LOADING_SHOW,
    payload: title
  });
};

const hideLoadingAction = () => dispatch => {
  dispatch({
    type: LOADING_HIDE
  });
};

export { showLoadingAction, hideLoadingAction };
