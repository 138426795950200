import React from "react";
import Formsy from "formsy-react";
import Button from "@material-ui/core/Button";
import ContactNameFormFields from "../contact-info/ContactNameFormFields.jsx";
import AddressFormFields from "../contact-info/AddressFormFields.jsx";
import { ResponsiveUtil } from "../../utils/ResponsiveUtil";

const isMobile = ResponsiveUtil.isMobile();

const buttonStyle = {
  width: "100%",
  maxWidth: "none",
  marginTop: "10px",
  marginBottom: "10px"
};

class SsoRegisterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      phone: "(  )    -    ",
      cellPhone: "(  )    -    ",
      mpayTextRemindersOptIn: "",
      email: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      valid: false
    };

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
    this.onInput = this.onInput.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (this.props.contactInfo) {
      this.setState({
        name: this.props.contactInfo.name,
        phone: this.props.contactInfo.phone,
        cellPhone: this.props.contactInfo.cellPhone,
        mpayTextRemindersOptIn: this.props.contactInfo.mpayTextRemindersOptIn,
        email: this.props.contactInfo.email,
        address: this.props.contactInfo.address,
        city: this.props.contactInfo.city,
        state: this.props.contactInfo.state,
        zip: this.props.contactInfo.zip
      });
    }
  }
  onValidSubmit() {
    const model = Object.assign({}, this.state);
    delete model.valid;

    this.props.onSubmit(model);
  }
  onValid() {
    this.setState({ valid: true });
  }
  onInvalid() {
    this.setState({ valid: false });
  }
  onInput(name) {
    return value => {
      let update = {};
      update[name] = value;
      this.setState(update);
    };
  }
  render() {
    return (
      <Formsy
        noValidate
        onValidSubmit={this.onValidSubmit}
        onValid={this.onValid}
        onInvalid={this.onInvalid}
      >
        <div role="form" aria-label="Register Account">
          <div>
            <h2>Please Confirm Contact Information</h2>
            <ContactNameFormFields
              t={this.props.t}
              onInput={this.onInput}
              formData={this.state}
              requireEmail
              {...this.props.required}
              shouldFocusOnMount={false}
            />
            <AddressFormFields
              t={this.props.t}
              onInput={this.onInput}
              formData={this.state}
              requireEmail
              {...this.props.required}
            />
            <Button
              variant="contained"
              type="submit"
              disabled={!this.state.valid || this.props.disabled}
              style={isMobile ? buttonStyle : {}}
              color="primary"
            >
              {this.props.t("Confirm Contact Info")}
            </Button>
          </div>
        </div>
      </Formsy>
    );
  }
}

export default SsoRegisterForm;