import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import Lock from "@material-ui/icons/Lock";

class LockedTextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = { touched: false };

    this.textInput = React.createRef();
    this.focusInput = this.focusInput.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }
  componentDidMount() {
    if (this.props.shouldFocusOnMount) {
      this.focusInput();
    }
  }
  onBlur() {
    this.setState({ touched: true });
  }
  focusInput() {
    this.textInput.current.focus();
  }
  render() {
    return (
      <TextField
        id={this.props.id}
        name={this.props.name}
        fullWidth
        readOnly  
        label={this.props.label}
        defaultValue={this.props.defaultValue}
        onChange={e => this.props.onChange(e.currentTarget.value)}
        InputProps={{
          startAdornment: <InputAdornment position="start"><Lock style={{ width: "18px"}} /></InputAdornment>,
          readOnly: true
        }}
        inputRef={this.textInput}
      />
    );
  }
}

export default LockedTextField;
