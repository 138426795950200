import React, { Component } from "react";
import { withRouter } from "react-router";
import { Button } from "@material-ui/core";
import RouteHelper from "./../utils/RouteHelper";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

class BackButton extends Component {
  constructor(props) {
    super(props);

    this.selectRoute = this.selectRoute.bind(this);
    this.buttonRef = React.createRef();
    this.focusInput = this.focusInput.bind(this);
  }
  componentDidMount() {
    if (this.props.shouldFocusOnMount) {
      this.focusInput();
    }
  }
  selectRoute(route, currentLocation) {
    const fullRoute = RouteHelper.getRelativeAppViewRoute(currentLocation)(
      route
    );
    this.props.router.push(fullRoute);
  }
  focusInput() {
    this.buttonRef.current.focus();
  }
  render() {
    return (
      <Button
        color="primary"
        style={{ paddingLeft: 0, marginTop: 10 }}
        onClick={() =>
          this.selectRoute(this.props.route, this.props.currentLocation)
        }
        buttonRef={this.buttonRef}
      >
        <ChevronLeft />
        {this.props.backText}
      </Button>
    );
  }
}

export default withRouter(BackButton);
