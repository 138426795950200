import React from "react";
import AlertWarning from "./../errors/AlertWarning.jsx";

export default class PayNowWarning extends React.Component {
  render() {
    return (
      <div aria-label="Terms and Conditions Warning Box" role="region">
        <AlertWarning>
          {this.props.isAutoPay
            ? this.props.t(
                'By clicking "Enroll Now", I confirm the above listed Auto-Pay Enrollment information is correct, agree to any fees, the terms of service, and the privacy policy.'
              )
            : this.props.t(
                'By clicking "Pay Now", I confirm the above listed payment is correct, agree to the convenience fee, the terms of service, and the privacy policy.'
              )}
        </AlertWarning>
      </div>
    );
  }
}
