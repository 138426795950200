import { Map, List, fromJS } from "immutable";

import {
  GET_CONSORTIUM,
  GET_CONSORTIUM_NAME,
  GET_BC_HOLIDAYS,
  GET_BC_NEXT_BUSINESS_DAY,
  SUCCESS
} from "../../api/actions/actionTypes";

import moment from "moment";

const getDefaultState = () => {
  return Map({ customers: List() });
};

const actions = {};

actions[`${GET_CONSORTIUM}_${SUCCESS}`] = function (state, action) {
  const body = action.payload.body;
  const customerList = fromJS(body);

  const cleanUpCustomer = (businessCustomer) => {
    const trimedOrDefault = (s) => {
      return s ? s.trim() : "";
    };

    const ccFreq = List(businessCustomer.get("ccPaymentFrequency").split(", "));
    const achFreq = List(
      businessCustomer.get("achPaymentFrequency").split(", ")
    );

    let paymentOptions = List([
      {
        option: "oneTime",
        displayName: "One Time",
        description: "Submit a single payment.",
      },
    ]);
    if (
      businessCustomer.get("billingProcessType") === 99 ||
      businessCustomer.get("billingProcessType") === 98
    ) {
      paymentOptions = paymentOptions.push({
        option: "autoPay",
        displayName: "Auto-Pay",
        description: "Enroll your account for automatic payments.",
      });
    }
    // We only display one of these, based on the payment method they have selected
    if (!ccFreq.includes("None") && !ccFreq.isEmpty()) {
      paymentOptions = paymentOptions.push({
        option: "ccRecurring",
        displayName: "Recurring",
        description:
          "Schedule a recurring payment with a post date of your choosing.",
      });
    }
    if (!achFreq.includes("None") && !ccFreq.isEmpty()) {
      paymentOptions = paymentOptions.push({
        option: "achRecurring",
        displayName: "Recurring",
        description:
          "Schedule a recurring payment with a post date of your choosing.",
      });
    }

    return (
      businessCustomer
        // It's more convenient to store payment frequency as a list
        .set("ccPaymentFrequencyList", ccFreq)
        .set(
          "achPaymentFrequencyList",
          achFreq
            // default companyWebsite to "" if it's falsey. Otherwise store the value, trimmed.
            .set(
              "companyWebsite",
              trimedOrDefault(businessCustomer.get("companyWebsite"))
            )
            // we were trimming the company name in the thank you page before, might as well just do it when we get the data
            .set(
              "companyName",
              trimedOrDefault(businessCustomer.get("companyName"))
            )
            .set(
              "paymentCooldown",
              moment.duration(businessCustomer.get("paymentCooldown"))
            )
        )
        .set("paymentOptions", paymentOptions)
    );
  };

  const customerMap = Map(
    customerList.map((c) => [c.get("accessNumber"), cleanUpCustomer(c)])
  );

  const defaultAccessNumber = customerList.first().get("accessNumber");

  const newUserRequirements = Map({
    requirePhone: !!customerList.find((c) => c.get("requirePhone") === true),
    requireAddress: !!customerList.find(
      (c) => c.get("requireAddress") === true
    ),
  });

  //  .toSet().toList() is equivalent to distinct
  const acceptedCardTypes = customerList
    .map((c) => c.get("acceptedCardTypes"))
    .flatten()
    .toSet()
    .toList();

  // create a list of unique blocked bins for the consortium
  const consortiumBlockedBins = customerList
    .map((c) => c.get("blockedBins"))
    .flatten()
    .toSet()
    .toList();

  // create a list of unique blocked routing numbers for the consortium
  const blockedRoutingNumbers = customerList
    .map((c) => c.get("blockedRoutingNumbers"))
    .flatten()
    .toSet()
    .toList();

  return state
    .set("customers", customerMap)
    .set("newUserRequirements", newUserRequirements)
    .set("cardTypes", acceptedCardTypes)
    .set("defaultAccessNumber", defaultAccessNumber)
    .set("blockedBins", consortiumBlockedBins)
    .set("blockedRoutingNumbers", blockedRoutingNumbers);
};

actions[`${GET_CONSORTIUM_NAME}_${SUCCESS}`] = function (state, action) {
  const body = action.payload.body;
  const consortiumName = fromJS(body).get("name");
  const settings = fromJS(body).get("settings");

  return state.set("consortiumName", consortiumName).set("settings", settings);
};

actions[`${GET_BC_HOLIDAYS}_${SUCCESS}`] = function (state, action) {
  const holidays = action.payload.body;

  let momentHolidays = holidays.map(function (d) {
    return moment(d);
  });

  return state.set("holidays", momentHolidays);
};

actions[`${GET_BC_NEXT_BUSINESS_DAY}_${SUCCESS}`] = function (state, action) {
  const nextBusinessDay = action.payload.body;
  let momentBusinessDay = moment(nextBusinessDay);

  return state.setIn(
    ["customers", action.meta.accessNumber, "nextBusinessDay"],
    momentBusinessDay
  );
};

export default function (state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  return actions[action.type](state, action);
}
