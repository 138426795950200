import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import RouteHelper from "./../../utils/RouteHelper";
import SelectAccountList from "./../accounts/SelectAccountList.jsx";
import {
  selectPaymentHistoryAccountAction,
  getPaymentHistoryAction
} from "./../../api/actions/paymentHistory";
import AlertWarning from "./../errors/AlertWarning.jsx";
import { _t } from "./../../text/locale";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { setPageInfoAction } from "./../../api/actions/pageInfo";
import { List } from "immutable";

let t;
let pageTitle = "Select Payment History";

class PaymentHistoryAccountSelect extends React.Component {
  constructor(props) {
    super(props);

    this.routeToPaymentHistory = this.routeToPaymentHistory.bind(this);
    this.selectPaymentHistoryAccount = this.selectPaymentHistoryAccount.bind(
      this
    );
    this.shouldFocus = this.shouldFocus.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  selectPaymentHistoryAccount(account) {
    this.props.selectPaymentHistoryAccount(account);
    const businessCustomer = this.props.consortium.getIn([
      "customers",
      account.get("accessNumber")
    ]);
    this.props.getPaymentHistory(
      this.props.params.consortiumId,
      this.props.userId,
      businessCustomer.get("bankNumber"),
      businessCustomer.get("companyNumber"),
      account.get("accountNumber"),
      this.props.pageNumber
    );
    this.routeToPaymentHistory();
  }
  routeToPaymentHistory() {
    const historyPath = RouteHelper.getRelativeAppViewRoute(
      this.props.location
    )("/user/history/view");
    this.props.router.push(historyPath);
  }
  warnIfNoAccounts(accounts) {
    if (accounts.length === 0) {
      return (
        <AlertWarning>
          <strong>{t("No loan accounts have been added.")}&nbsp;</strong>
          <br className="visible-xs" />
          {t("Please add an account.")}
        </AlertWarning>
      );
    }

    return "";
  }
  shouldFocus(bc, bcs) {
    const firstKey = Object.keys(bcs)[0];

    return bc === firstKey;
  }
  renderAccountsLists(accounts) {
    let bcs = {};
    let SelectAccountLists = [];
    accounts.forEach(account => {
      let accessNumber = account.get("accessNumber");
      if (!(bcs[accessNumber] instanceof Array)) {
        bcs[accessNumber] = [];
      }
      bcs[accessNumber].push(account);
    });
    for (let bc in bcs) {
      if (bcs.hasOwnProperty(bc)) {
        SelectAccountLists.push(
          <div key={bc}>
            <h2>
              {this.props.consortium.getIn([
                "customers",
                bc,
                "serviceDescription"
              ])}
            </h2>
            <SelectAccountList
              t={t}
              accounts={List(bcs[bc])}
              onAccountSelected={this.selectPaymentHistoryAccount}
              shouldFocusOnMount={this.shouldFocus(bc, bcs)}
            />
          </div>
        );
      }
    }
    return <div>{SelectAccountLists}</div>;
  }
  render() {
    return (
      <div>
        <div className="flex-container">
          <div className="flex-content">
            <p>
              {t(
                "Select an account to view your payment history. You have the following accounts:"
              )}
            </p>
            {this.renderAccountsLists(this.props.accounts)}
            {this.warnIfNoAccounts(this.props.accounts)}
          </div>
          <div
            className="flex-tipBox"
            aria-label="Payment History Tip Box"
            role="region"
          >
            <AlertInfo
              text={t("TIP_BOX_VIEW_PAYMENT_HISTORY")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const consortium = state.consortium;
  const bcs = state.billing.get("accounts").toList();
  let accounts = [];
  bcs.forEach(function(bc) {
    if (!(bc instanceof Array)) {
      bc.forEach(function(account) {
        accounts.push(account);
      });
    }
  });

  return {
    accounts: accounts,
    consortium: consortium,
    pageNumber: 1,
    userId: state.user.get("id")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectPaymentHistoryAccount: account => {
      dispatch(selectPaymentHistoryAccountAction(account));
    },
    dispatchSetPageInfo: pageInfoData => {
      dispatch(setPageInfoAction(pageInfoData));
    },
    getPaymentHistory: (
      consortiumId,
      userIdo,
      bankNumber,
      companyNumber,
      accountNumber,
      page
    ) => {
      dispatch(
        getPaymentHistoryAction(
          consortiumId,
          userIdo,
          bankNumber,
          companyNumber,
          accountNumber,
          page
        )
      );
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentHistoryAccountSelect)
);
