import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import RouteHelper from "./../../utils/RouteHelper";
import SelectAccountList from "./../accounts/SelectAccountList.jsx";
import {
  selectStatementAction,
  getStatementAction
} from "./../../api/actions/statementView";
import AlertWarning from "./../errors/AlertWarning.jsx";
import { _t } from "./../../text/locale";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { setPageInfoAction } from "./../../api/actions/pageInfo";
import { List } from "immutable";

let t;
let pageTitle = "Select Statement";

class StatementViewAccountSelect extends React.Component {
  constructor(props) {
    super(props);

    this.routeToStatementView = this.routeToStatementView.bind(this);
    this.selectStatementViewAccount = this.selectStatementViewAccount.bind(
      this
    );
    this.shouldFocus = this.shouldFocus.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  selectStatementViewAccount(account) {
    this.props.selectStatementViewAccount(account);
    const bc = this.props.consortium.getIn([
      "customers",
      account.get("accessNumber")
    ]);
    this.props.getStatementView(
      bc.get("bankNumber"),
      bc.get("companyNumber"),
      account.get("accountNumber"),
      this.props.pageNumber
    );
    this.routeToStatementView();
  }
  routeToStatementView() {
    const statementPath = RouteHelper.getRelativeAppViewRoute(
      this.props.location
    )("/user/statements/view");
    this.props.router.push(statementPath);
  }
  warnIfNoAccounts(accounts) {
    if (accounts.length === 0) {
      return (
        <AlertWarning>
          <strong>{t("No loan accounts have been added.")}&nbsp;</strong>
          <br className="visible-xs" />
          {t("Please add an account.")}
        </AlertWarning>
      );
    }

    return "";
  }
  shouldFocus(bc, bcs) {
    const firstKey = Object.keys(bcs)[0];

    return bc === firstKey;
  }
  renderAccountsLists(accounts) {
    let bcs = {};
    let SelectAccountLists = [];
    accounts.forEach(account => {
      let accessNumber = account.get("accessNumber");
      if (!(bcs[accessNumber] instanceof Array)) {
        bcs[accessNumber] = [];
      }
      bcs[accessNumber].push(account);
    });
    for (let bc in bcs) {
      if (bcs.hasOwnProperty(bc)) {
        SelectAccountLists.push(
          <div key={bc}>
            <h2>
              {this.props.consortium.getIn([
                "customers",
                bc,
                "serviceDescription"
              ])}
            </h2>
            <SelectAccountList
              t={t}
              accounts={List(bcs[bc])}
              onAccountSelected={this.selectStatementViewAccount}
              shouldFocusOnMount={this.shouldFocus(bc, bcs)}
            />
          </div>
        );
      }
    }
    return <div>{SelectAccountLists}</div>;
  }
  render() {
    return (
      <div>
        <div className="flex-container">
          <div className="flex-content">
            <p>
              {t(
                "Select an account to view your statement. You have the following accounts:"
              )}
            </p>
            {this.renderAccountsLists(this.props.accounts)}
            {this.warnIfNoAccounts(this.props.accounts)}
          </div>
          <div
            className="flex-tipBox"
            aria-label="Statement View Tip Box"
            role="region"
          >
            <AlertInfo
              text={t("TIP_BOX_STATEMENT_SELECT")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const consortium = state.consortium;
  const accountsByBc = state.billing.get("accounts");
  let accounts = [];

  accountsByBc.keySeq().forEach(accessNumber => {
    if (accessNumber === "current") {
      return;
    }

    let usesStatementView = consortium
      .getIn(["customers", accessNumber])
      .get("enableStatementView");

    // If the business customer does not participate in statement view, don't add the account to the list
    if (usesStatementView) {
      let accountList = state.billing.getIn(["accounts", accessNumber]);
      accountList.forEach(function(account) {
        accounts.push(account);
      });
    }
  });

  return {
    accounts: accounts,
    consortium: consortium,
    pageNumber: state.statement.get("pageNumber")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getStatementView: (bankNumber, companyNumber, accountNumber, page) => {
      dispatch(
        getStatementAction(bankNumber, companyNumber, accountNumber, page)
      );
    },
    selectStatementViewAccount: account => {
      dispatch(selectStatementAction(account));
    },
    dispatchSetPageInfo: pageInfoData => {
      dispatch(setPageInfoAction(pageInfoData));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StatementViewAccountSelect)
);
