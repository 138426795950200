import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import UsernameReminderForm from "./UsernameReminderForm.jsx";

import { USERNAME_REMINDER, FAILURE } from "./../../../api/actions/actionTypes";
import { requestUsernameReminderAction } from "./../../../api/actions/user";
import AlertInfo from "./../../errors/AlertInfo.jsx";
import {
  showLoadingAction,
  hideLoadingAction
} from "./../../../api/actions/loading";
import { setPageInfoAction } from "./../../../api/actions/pageInfo";

import RouteHelper from "./../../../utils/RouteHelper";

import { _t } from "./../../../text/locale";

let t;
let pageTitle = "Forgot Username";

class ForgotUsername extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      email: "",
      valid: false
    };
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (!nextProps.pending && this.props.pending) {
      this.props.hideLoading();
      if (!nextProps.lastRequestFailed) {
        const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
          "/user/sign-in"
        );
        this.props.router.push(path);
      }
    }
  }
  onSubmit(formData) {
    this.props.requestUsernameReminder(
      this.props.params.consortiumId,
      formData.email
    );
    this.props.showLoading(t("Sending Username Reminder..."));
  }
  render() {
    return (
      <div>
        <div className="flex-container">
          <div className="flex-content">
            <UsernameReminderForm
              t={t}
              onSubmit={this.onSubmit}
              disabled={this.props.pending}
            />
          </div>
          <div
            className="flex-tipBox"
            aria-label="User name Tip Box"
            role="region"
          >
            <AlertInfo
              text={t("TIP_BOX_ENTER_USERNAME")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pending: !!state.httpRequests.getIn([USERNAME_REMINDER, "pending"]),
    lastRequestFailed:
      state.httpRequests.getIn([USERNAME_REMINDER, "lastStatus"]) === FAILURE
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showLoading: titleText => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
    dispatchSetPageInfo: pageInfoData => {
      dispatch(setPageInfoAction(pageInfoData));
    },
    requestUsernameReminder: (consortiumId, email) => {
      dispatch(requestUsernameReminderAction(consortiumId, email));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ForgotUsername)
);
