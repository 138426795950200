import React from "react";
import { List } from "@material-ui/core";
import ClickableAccountDetailItem from "./ClickableAccountDetailItem.jsx";

export default class SelectAccountList extends React.Component {
  constructor(props) {
    super(props);

    this.shouldFocus = this.shouldFocus.bind(this);
  }
  shouldFocus(shouldFocusOnMount, entry, accounts) {
    if (!shouldFocusOnMount) {
      return false;
    }
    const currentKey = entry.get("id");
    const firstKey = accounts.first().get("id");

    return currentKey === firstKey;
  }
  render() {
    const accountItems = this.props.accounts.map(a => (
      <li key={a.get("accountNumber")}>
        <ClickableAccountDetailItem
          t={this.props.t}
          key={a.get("accountNumber")}
          account={a}
          onClick={this.props.onAccountSelected}
          enforcePaymentCooldown={this.props.enforcePaymentCooldown}
          shouldFocusOnMount={this.shouldFocus(
            this.props.shouldFocusOnMount,
            a,
            this.props.accounts
          )}
        />
      </li>
    ));
    return <List>{accountItems}</List>;
  }
}
