import React, { Component } from "react";
import { connect } from "react-redux";
import { _t } from "../../text/locale";
import { ResponsiveUtil } from "../../utils/ResponsiveUtil";
import { setPageInfoAction } from "../../api/actions/pageInfo";
import Build from "@material-ui/icons/Build";
import Cake from "@material-ui/icons/Cake";

const t = _t();
const isMobile = ResponsiveUtil.isMobile();

class WorkInProgress extends Component {
  UNSAFE_componentWillMount() {
    // If we use this in a third place make it a function in an external file
    // document.getElementById("loading-spinner").style.display = "none";
    let pageTitle = isMobile ? this.props.title : "Work In Progress";
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  render() {
    
    return (
      <div>
        <h1> <Build /> <Cake /> {t("Work In Progress")} <Cake /> <Build /></h1>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetPageInfo: pageInfoData => {
      dispatch(setPageInfoAction(pageInfoData));
    }
  };
}

export default connect(null, mapDispatchToProps)(WorkInProgress);
