import { List, Map, fromJS } from "immutable";

import {
  SET_CONTACT_INFO,
  RETRIEVE_STORED_SAML_PAYLOAD,
  REGISTER_USER,
  REGISTER_SSO_USER,
  AUTHENTICATE_USER,
  USERNAME_REMINDER,
  GET_USER_ACCOUNTS,
  GET_USER_PAYMENT_METHODS,
  ADD_USER_PAYMENT_METHOD,
  DELETE_USER_PAYMENT_METHOD,
  VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD,
  TOKENIZE_AND_ADD_USER_PAYMENT_METHOD,
  UPDATE_CONTACT_INFO,
  REGISTER_NEW_USER_CHAIN,
  CLAIM_PAYMENT_ITEM,
  BLOCKED_BIN,
  INVALID_CARD,
  SUCCESS,
  FAILURE,
  PENDING,
} from "../../api/actions/actionTypes";

import {
  getStoredSamlPayloadRequest,
  createMpayUserAndSamlUserLinkRequest,
  registerUserAccountRequest,
  registerSsoUserAccountRequest,
  authenticateUserAccountRequest,
  requestUsernameReminderRequest,
  getUserPaymentMethodsRequest,
  addUserCcPaymentMethodRequest,
  addUserAchPaymentMethodRequest,
  deleteUserCcPaymentMethodRequest,
  deleteUserAchPaymentMethodRequest,
  updateContactInfoRequest,
  claimPaymentItemRequest,
  getUserAccountsRequest,
} from "./../requests/user";

import { addUserAccountsRequest } from "./../requests/accounts";

import { getRecentPaymentsAction } from "./paymentHistory";
import {
  getCardTokenAction,
  getAchTokensAction,
  useSelectedMethod,
  checkCreditCard,
} from "./payments";

import { isCardTypeDebit } from "../../utils/CreditCardValidator.js";

export const setContactInfoAction = (contactInfo) => (dispatch) => {
  dispatch({
    type: SET_CONTACT_INFO,
    payload: contactInfo,
  });
};

export const updateContactInfoAction = (consortiumId, contactInfo) => (
  dispatch,
  getState
) => {
  const state = getState();
  const userId = state.user.get("id");
  const promise = updateContactInfoRequest(consortiumId, userId, contactInfo);

  dispatch({
    type: UPDATE_CONTACT_INFO,
    payload: promise,
  });

  return promise;
};

const authenticateUserAccountAction = (username, password, consortiumId) => (
  dispatch,
  getState
) => {
  const promise = authenticateUserAccountRequest(
    username,
    password,
    consortiumId
  );

  dispatch({
    type: AUTHENTICATE_USER,
    payload: promise
  });

  return promise;
};

const createMpayUserAndSamlUserLinkAction = (consortiumId, samlLink) => {
  const promise = createMpayUserAndSamlUserLinkRequest(consortiumId, samlLink);

  return promise;
};

export const getUserPaymentMethodsAction = (consortiumId, userId) => (
  dispatch
) => {
  const promise = getUserPaymentMethodsRequest(consortiumId, userId);

  dispatch({
    type: GET_USER_PAYMENT_METHODS,
    payload: promise,
  });

  return promise;
};

const addUserCcPaymentMethod = (
  consortiumId,
  userId,
  name,
  creditCardToken,
  expiration,
  network,
  bankNumber,
  companyNumber,
  isDebit
) => (dispatch, getState) => {
  const promise = addUserCcPaymentMethodRequest(consortiumId, userId, {
    name,
    creditCardToken,
    expiration,
    network,
    bankNumber,
    companyNumber,
    isDebit,
  });

  dispatch({
    type: ADD_USER_PAYMENT_METHOD,
    meta: { type: "cc" },
    payload: promise,
  });

  return promise;
};

export const getUserAccountsAction = (consortiumId, userId) => (
  dispatch,
  getState
) => {
  const promise = getUserAccountsRequest(consortiumId, userId);
  dispatch({
    type: GET_USER_ACCOUNTS,
    payload: promise,
  });

  return promise;
};

// Called when the user is going through the normal payment workflow and adds a new credit card
export const addUserCcPaymentMethodFromPaymentWorkflowAction = (
  consortiumId,
  name,
  cardNumber,
  expiration,
  network
) => (dispatch, getState) => {
  const state = getState();
  const user = state.user;

  const accessNumber = user.get("accessNumber");
  const bankNumber = user.get("bankNumber");
  const companyNumber = user.get("companyNumber");

  const enteredBin = cardNumber.replace(" ", "").substr(0, 6);
  if (state.consortium.get("blockedBins").includes(enteredBin)) {
    dispatch({
      type: BLOCKED_BIN,
    });

    return;
  }

  // dispatch our own action that shows that it started, there are multiple request calls in a row, this is a custom action to mark the beginning of the request chain
  dispatch({
    type: `${VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD}_${PENDING}`,
  });

  // TODO3: Dispatching getCardTokenAction sets values in the payment store when it finishes, and also had error handling that we want
  // We should probably have a unique action that only encapsulates the error handling
  dispatch(getCardTokenAction(accessNumber, cardNumber))
    .then((getCardTokenResult) => {
      const userId = user.get("id");
      const getCardTokenResultBody = getCardTokenResult.body;
      const creditCardToken = getCardTokenResultBody.token;
      const methodInfo = fromJS(getCardTokenResultBody.metadata);

      const cardIsAccepted = checkCreditCard(
        accessNumber,
        cardNumber,
        network,
        methodInfo,
        state.consortium
      );
      if (!cardIsAccepted) {
        dispatch({
          type: INVALID_CARD,
          payload: { network },
        });

        return Promise.reject();
      }

      dispatch(
        addUserCcPaymentMethod(
          consortiumId,
          userId,
          name,
          creditCardToken,
          expiration,
          network,
          bankNumber,
          companyNumber,
          // Vantiv treats debit and check cards differently, but if a card is either debit or check we want to treat them the same
          // It's possible for us to get back a partial success from the token endpoint, where we fail to get card metadata (the metadata body will have an error on it).
          // In this case, we are going to treat the entered card as a credit card since there are rare cases where a card number can
          // exist without metadata.
          isCardTypeDebit(
            methodInfo.get("isDebitCard"),
            methodInfo.get("isCheckCard"),
            !getCardTokenResultBody.metadata.error
          )
        )
      ).then((addUserCcMethodResult) => {
        const addUserCcPaymentMethodBody = Map(addUserCcMethodResult.body);
        dispatch(useSelectedMethod(addUserCcPaymentMethodBody, "cc"));
      });
    })
    .then(() => {
      dispatch({
        type: `${VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD}_${SUCCESS}`,
      });
    })
    .catch(() => {
      dispatch({
        type: `${VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD}_${FAILURE}`,
      });
    });
};

// Called when the user is adding a payment method from the manage payment methods page
export const addUserCcPaymentMethodAction = (
  consortiumId,
  name,
  cardNumber,
  expiration,
  network
) => (dispatch, getState) => {
  const state = getState();
  const user = state.user;

  // use the access number tied to the user. If we're a guest use the default.
  const accessNumber = user.get("accessNumber");
  const bankNumber = user.get("bankNumber");
  const companyNumber = user.get("companyNumber");

  const enteredBin = cardNumber.replace(" ", "").substr(0, 6);
  if (state.consortium.get("blockedBins").includes(enteredBin)) {
    dispatch({
      type: BLOCKED_BIN,
    });

    return;
  }

  dispatch({
    type: `${VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD}_${PENDING}`,
  });

  dispatch(getCardTokenAction(accessNumber, cardNumber))
    .then((res) => {
      const userId = user.get("id");
      const body = res.body;
      const creditCardToken = body.token;
      const methodInfo = fromJS(body.metadata);

      const cardIsAccepted = checkCreditCard(
        accessNumber,
        cardNumber,
        network,
        methodInfo,
        state.consortium
      );
      if (!cardIsAccepted) {
        dispatch({
          type: INVALID_CARD,
          payload: { network },
        });

        return Promise.reject();
      }

      dispatch(
        addUserCcPaymentMethod(
          consortiumId,
          userId,
          name,
          creditCardToken,
          expiration,
          network,
          bankNumber,
          companyNumber,
          // Vantiv treats debit and check cards differently, but if a card is either debit or check we want to treat them the same
          // It's possible for us to get back a partial success from the token endpoint, where we fail to get card metadata (the metadata body will have an error on it).
          // In this case, we are going to treat the entered card as a credit card since there are rare cases where a card number can
          // exist without metadata.
          isCardTypeDebit(
            methodInfo.get("isDebitCard"),
            methodInfo.get("isCheckCard"),
            !body.metadata.error
          )
        )
      );
    })
    .then(() => {
      dispatch({
        type: `${VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD}_${SUCCESS}`,
      });
    })
    .catch(() => {
      dispatch({
        type: `${VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD}_${FAILURE}`,
      });
    });
};

const addUserAchPaymentMethod = (
  consortiumId,
  userId,
  name,
  accountToken,
  routingToken,
  accountType,
  bankNumber,
  companyNumber
) => (dispatch, getState) => {
  const promise = addUserAchPaymentMethodRequest(consortiumId, userId, {
    name,
    accountToken,
    routingToken,
    accountType,
    bankNumber,
    companyNumber,
  });

  dispatch({
    type: ADD_USER_PAYMENT_METHOD,
    meta: { type: "ach" },
    payload: promise,
  });

  return promise;
};

export const addUserAchPaymentMethodFromPaymentWorkflowAction = (
  consortiumId,
  data
) => (dispatch, getState) => {
  const user = getState().user;
  const bankNumber = user.get("bankNumber");
  const companyNumber = user.get("companyNumber");
  const accessNumber = user.get("accessNumber");

  dispatch({
    type: `${TOKENIZE_AND_ADD_USER_PAYMENT_METHOD}_${PENDING}`,
  });

  dispatch(
    getAchTokensAction(accessNumber, data.accountNumber, data.routingNumber)
  )
    .then((results) => {
      var accountRes = results[0];
      var routingRes = results[1];

      const userId = user.get("id");
      const name = data.name;
      const accountToken = accountRes.body.token;
      const routingToken = routingRes.body.token;
      const accountType = data.accountType;
      return dispatch(
        addUserAchPaymentMethod(
          consortiumId,
          userId,
          name,
          accountToken,
          routingToken,
          accountType,
          bankNumber,
          companyNumber
        )
      );
    })
    .then((res) => {
      const body = Map(res.body);
      dispatch(useSelectedMethod(body, "ach"));
    })
    .finally(() => {
      // If any of the intermediate methods fail, there are existing error notifications
      // This is used to indicate that the request chain has ended, and there is no error handling for this action so it is set to "SUCCESS" upon completion
      dispatch({
        type: `${TOKENIZE_AND_ADD_USER_PAYMENT_METHOD}_${SUCCESS}`,
      });
    });
};

export const addUserAchPaymentMethodAction = (consortiumId, data) => (
  dispatch,
  getState
) => {
  const user = getState().user;
  const bankNumber = user.get("bankNumber");
  const companyNumber = user.get("companyNumber");
  const accessNumber = user.get("accessNumber");

  dispatch({
    type: `${TOKENIZE_AND_ADD_USER_PAYMENT_METHOD}_${PENDING}`,
  });

  dispatch(
    getAchTokensAction(accessNumber, data.accountNumber, data.routingNumber)
  )
    .then((results) => {
      var accountRes = results[0];
      var routingRes = results[1];

      const userId = user.get("id");
      const name = data.name;
      const accountToken = accountRes.body.token;
      const routingToken = routingRes.body.token;
      const accountType = data.accountType;
      return dispatch(
        addUserAchPaymentMethod(
          consortiumId,
          userId,
          name,
          accountToken,
          routingToken,
          accountType,
          bankNumber,
          companyNumber
        )
      );
    })
    .finally(() => {
      // If any of the intermediate methods fail, there are existing error notifications
      // This is used to indicate that the request chain has ended, and there is no error handling for this action so it is set to "SUCCESS" upon completion
      dispatch({
        type: `${TOKENIZE_AND_ADD_USER_PAYMENT_METHOD}_${SUCCESS}`,
      });
    });
};

export const deleteUserPaymentMethodAction = (consortiumId, type, methodId) => (
  dispatch,
  getState
) => {
  const userId = getState().user.get("id");

  const promise =
    type === "cc"
      ? deleteUserCcPaymentMethodRequest(consortiumId, userId, methodId)
      : deleteUserAchPaymentMethodRequest(consortiumId, userId, methodId);

  dispatch({
    type: DELETE_USER_PAYMENT_METHOD,
    meta: { type, methodId },
    payload: promise,
  });

  return promise;
};

const registerUser = (userData, consortiumId) => (dispatch) => {
  const promise = registerUserAccountRequest(userData, consortiumId);

  dispatch({
    type: REGISTER_USER,
    payload: promise,
  });

  return promise;
};

const registerSsoUser = (
  userData,
  consortiumId,
  ssoId,
  samlUserId,
  provider,
  yesNoCallback
) => (dispatch) => {
  const promise = registerSsoUserAccountRequest(
    userData,
    consortiumId,
    ssoId,
    samlUserId,
    provider
  );

  dispatch({
    type: REGISTER_SSO_USER,
    meta: yesNoCallback,
    payload: promise,
  });

  return promise;
};

const addUserAccount = (consortiumId, userId, acctObj) => {
  let newUserAccount = {
    accountNumber: acctObj.get("accountNumber"),
    accessNumber: acctObj.get("accessNumber"),
    companyNumber: acctObj.get("companyNumber"),
    bankNumber: acctObj.get("bankNumber"),
    userId: userId,
  };

  return addUserAccountsRequest(consortiumId, userId, newUserAccount);
};

const getAccountListFromAllBcs = (state) => {
  // We need to filter out the "current" array that is sitting in state, then flatten the bcs to their child accounts.
  const accounts = state.billing
    .get("accounts")
    .toList()
    .filter((b) => !(b instanceof Array))
    .flatten(1)
    .toList();

  return accounts;
};

export const claimPaymentItemAction = (
  consortiumId,
  userId,
  transactionNumber
) => (dispatch) => {
  const promise = claimPaymentItemRequest(
    consortiumId,
    userId,
    transactionNumber
  );

  dispatch({
    type: CLAIM_PAYMENT_ITEM,
    payload: promise,
  });

  return promise;
};

export const getStoredSamlPayloadAction = (consortiumId, ssoRedisKey) => (
  dispatch
) => {
  const promise = getStoredSamlPayloadRequest(consortiumId, ssoRedisKey);

  dispatch({
    type: RETRIEVE_STORED_SAML_PAYLOAD,
    payload: promise,
  });

  return promise;
};

// Registering a user is a complicated process.
// 1. Creating a user for the customer
// 2. Authenticating the user, which requires the account to have been created
// 3. Checking if the customer had gotten far enough in the process to have input payment information during this session
// 4. Saving the payment information to the user, which requires the user id from user creation and the token from authenticating
// 5. Saving the contact information to the user, which requires the user id from user creation and the token from authenticating
// 6. Update the most recent payment item to belong to the user
// We wrap the entire chain of events in "REGISTER_NEW_USER_CHAIN" actions, in order to correctly display a loading spinner when we have somewhere between 2 and 5 requests that we might wait on.
export const registerUserAccountAction = (userData, consortiumId) => (
  dispatch,
  getState
) => {
  let state = getState();

  // Dispatch that we have started the registration chain
  dispatch({ type: REGISTER_NEW_USER_CHAIN, payload: PENDING });

  userData.consortiumId = consortiumId;

  // Register a new user
  dispatch(registerUser(userData, consortiumId))
    .then((registerResult) => {
      // Then sign them in, and get an access token
      const authPromise = dispatch(
        authenticateUserAccountAction(
          userData.username,
          userData.password,
          consortiumId
        )
      );

      // In order to provide the userId to the next section of the promise chain, we turn it into a promise that will resolve as a user id
      const userIdPromise = Promise.resolve(registerResult.body.id);
      return Promise.all([userIdPromise, authPromise]);
    })
    .then((authResult) => {
      // Grab the user id from account creation. If they have any payment method stored on the state so far, save them as payment methods on the user.
      const userId = authResult[0];
      // default value when there is no payment method to add
      let addPayMethodPromise = null;
      const paymentInfo = state.payments.get("method");
      if (paymentInfo) {
        if (paymentInfo.get("type") === "cc") {
          const name = paymentInfo.get("cardholderName");
          const creditCardToken = paymentInfo.get("cardNumber");
          const expiration = paymentInfo.get("cardExpiration");
          const isDebit = paymentInfo.get("isDebit");
          const network = paymentInfo.get("cardNetwork");
          const bankNumber = paymentInfo.get("bankNumber");
          const companyNumber = paymentInfo.get("companyNumber");
          addPayMethodPromise = dispatch(
            addUserCcPaymentMethod(
              consortiumId,
              userId,
              name,
              creditCardToken,
              expiration,
              network,
              bankNumber,
              companyNumber,
              isDebit
            )
          );
        }

        if (paymentInfo.get("type") === "ach") {
          const name = paymentInfo.get("name");
          const accountToken = paymentInfo.get("accountNumber");
          const routingToken = paymentInfo.get("routingNumber");
          const accountType = paymentInfo.get("accountType");
          const bankNumber = paymentInfo.get("bankNumber");
          const companyNumber = paymentInfo.get("companyNumber");
          addPayMethodPromise = dispatch(
            addUserAchPaymentMethod(
              consortiumId,
              userId,
              name,
              accountToken,
              routingToken,
              accountType,
              bankNumber,
              companyNumber
            )
          );
        }
      }

      // flattens out the nested billing account objects then makes an array of promises resolving each user-account
      let accounts = getAccountListFromAllBcs(state);
      let accts = List(accounts)
        .map((acct) => addUserAccount(consortiumId, userId, acct))
        .toArray();

      // make a request to change the payment owner to the new user
      const currentPayment = state.payments.get("current");
      let paymentItemPromise = Promise.resolve("");
      if (currentPayment) {
        let confirmationNumber = state.payments
          .get("current")
          .get("confirmationNumber");
        if (confirmationNumber) {
          paymentItemPromise = dispatch(
            claimPaymentItemAction(consortiumId, userId, confirmationNumber)
          );
        }
      }

      // default to an empty promise if there is no payment method to persist
      let payMethodPromise =
        addPayMethodPromise === null
          ? Promise.resolve("")
          : addPayMethodPromise;

      // Resolves any submit payment information requests & all user billing account requests
      return Promise.all([payMethodPromise, paymentItemPromise, ...accts]);
    })
    .then((savePaymentAndAccountsResult) => {
      // If we made it here, the chain has succeeded
      dispatch({ type: REGISTER_NEW_USER_CHAIN, payload: SUCCESS });
    })
    .catch((err) => {
      // If the chain fails, dispatch a failure action and so we can take the necessary actions in the view.
      dispatch({ type: REGISTER_NEW_USER_CHAIN, payload: FAILURE });
    });
};

export const registerSsoUserAccountAction = (
  userData,
  consortiumId,
  ssoId,
  samlUserId,
  provider,
  yesNoCallback
) => (dispatch, getState) => {
  userData.consortiumId = consortiumId;
  dispatch(
    registerSsoUser(
      userData,
      consortiumId,
      ssoId,
      samlUserId,
      provider,
      yesNoCallback
    )
  );
};

export const userSignInAction = (username, password, consortiumId) => (
  dispatch,
  getState
) => {
  // SignIn and grab extra user information.
  dispatch(
    authenticateUserAccountAction(username, password, consortiumId)
  ).then((result) => {
    const userId = result.body.user.id;
    const methodsPromise = getUserPaymentMethodsAction(consortiumId, userId)(
      dispatch,
      getState
    );
    const recentPaymentsPromise = getRecentPaymentsAction(consortiumId, userId)(
      dispatch,
      getState
    );

    return Promise.all([methodsPromise, recentPaymentsPromise]);
  });
};

export const samlUserSignInAction = (
  username,
  password,
  consortiumId,
  storedSamlPayload
) => (dispatch, getState) => {
  // SignIn and grab extra user information.
  dispatch(
    authenticateUserAccountAction(username, password, consortiumId)
  ).then((result) => {
    const userId = result.body.user.id;
    const samlLink = {
      obfuscatedMpayUserId: userId,
      ssoId: storedSamlPayload.get("ssoID"),
      samlUserId: storedSamlPayload.get("samlUserId"),
    };

    const methodsPromise = getUserPaymentMethodsAction(consortiumId, userId)(
      dispatch,
      getState
    );
    const recentPaymentsPromise = getRecentPaymentsAction(consortiumId, userId)(
      dispatch,
      getState
    );
    const linkSamlUser = createMpayUserAndSamlUserLinkAction(
      consortiumId,
      samlLink
    );

    return Promise.all([methodsPromise, recentPaymentsPromise, linkSamlUser]);
  });
};

export const requestUsernameReminderAction = (consortiumId, email) => (
  dispatch
) => {
  dispatch({
    type: USERNAME_REMINDER,
    payload: requestUsernameReminderRequest(consortiumId, email),
  });
};
