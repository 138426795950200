import http from "./../utils/http";

export const getStatement = (
  bankNumber,
  companyNumber,
  accountNumber,
  page,
  shouldGetSummaryItems
) => {
  let request = `/api/v1/fis/${bankNumber}/bcs/${companyNumber}/accounts/${accountNumber}/account-activity-items?page=${page}&per_page=10`;
  if (shouldGetSummaryItems) {
    request += "&expand=summaryItems";
  }

  return http.get(request).end();
};
