import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { List as ImmutableList } from "immutable";
import RouteHelper from "./../../utils/RouteHelper";
import PaymentMethodList from "./options/PaymentMethodList.jsx";
import { setPageInfoAction } from "./../../api/actions/pageInfo";
import UserSignInPrompt from "./../user-accounts/UserSignInPrompt.jsx";
import { useSelectedMethod } from "./../../api/actions/payments";
import { _t } from "./../../text/locale";
import AlertInfo from "./../errors/AlertInfo.jsx";
import PaymentStepper from "../../components/PaymentStepper.jsx";

let t;
let pageTitle = "Select Payment Method";

class SelectPaymentMethod extends React.Component {
  constructor(props) {
    super(props);

    this.addNewCard = this.addNewCard.bind(this);
    this.routeTo = this.routeTo.bind(this);
    this.useMethod = this.useMethod.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  addNewCard() {
    const selectPaymentMethodPath = RouteHelper.getRelativeCustomerRoute(
      this.props.location
    )("/payment/add-new-card");
    this.props.router.push(selectPaymentMethodPath);
  }
  useMethod(type) {
    return (item, desc) => {
      this.props.useSelectedMethod(item, type);

      let target = RouteHelper.getRelativeCustomerRoute(this.props.location)(
        "/payment/options"
      );

      this.props.router.push(target);
    };
  }
  routeTo(path) {
    return () => {
      const target = RouteHelper.getRelativeCustomerRoute(this.props.location)(
        path
      );
      this.props.router.push(target);
    };
  }
  render() {
    return (
      <div>
        <div className="stepper">
          <PaymentStepper
            step="payment-method"
            currentLocation={this.props.location}
          />
        </div>
        <div className="flex-container">
          <div className="flex-content">
            <PaymentMethodList
              t={t}
              routeTo={this.routeTo}
              creditCards={this.props.creditCards}
              ach={this.props.ach}
              onTapNewCard={this.routeTo("/payment/add-new-card")}
              onTapNewAch={this.routeTo("/payment/add-new-ach")}
              onTapCard={this.useMethod("cc")}
              onTapAch={this.useMethod("ach")}
              backgroundColor={this.props.styles.get("primary1Color")}
              acceptsCreditCardPayments={this.props.acceptsCreditCardPayments}
              visaCreditCardBlocked={this.props.visaDebitOnly}
              mastercardCreditCardBlocked={this.props.mastercardDebitOnly}
              isDebit={this.props.isDebit}
              isCreditCardOnly={this.props.isCreditCardOnly}
              avatarStyle={{
                backgroundColor: this.props.styles.get("primary1Color"),
              }}
            />
          </div>
          <div
            className="flex-tipBox"
            aria-label="select payment method tip box"
            role="region"
          >
            <UserSignInPrompt t={t} location={this.props.location} />
            <AlertInfo
              text={t("TIP_BOX_SELECT_PAYMENT_METHOD")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const accessNumber = ownProps.params.accessNumber;

  const paymentTypes = accessNumber
    ? state.consortium.getIn(["customers", accessNumber, "acceptedCardTypes"])
    : state.consortium.getIn(["cardTypes"]);

  const isCreditCardOnly = accessNumber
    ? state.consortium.getIn(["customers", accessNumber, "isCreditCardOnly"])
    : state.consortium.get("customers").every((c) => c.get("isCreditCardOnly"));

  return {
    creditCards: state.user.getIn(["paymentMethods", "cc"]) || ImmutableList(),
    styles: state.style.get("palette"),
    ach: state.user.getIn(["paymentMethods", "ach"]) || ImmutableList(),
    acceptsCreditCardPayments: !state.consortium
      .getIn(["customers", accessNumber, "acceptedCardTypes"])
      .isEmpty(),
    isDebit:
      state.user.getIn(["paymentMethods", "cc", "isDebit"]) ||
      state.payments.getIn(["method", "isDebit"]),
    visaDebitOnly: paymentTypes.find((c) => c.includes("visa debit card")),
    mastercardDebitOnly: paymentTypes.find((c) =>
      c.includes("mastercard debit card")
    ),
    isCreditCardOnly: isCreditCardOnly,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
    useSelectedMethod: (method, type) => {
      dispatch(useSelectedMethod(method, type));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectPaymentMethod)
);
