import React from "react";
import moment from "moment";
import { TableRow, TableCell, Icon } from "@material-ui/core";
import { formatPaymentAmount } from "./../../utils/FormatUtil";
import Button from "@material-ui/core/Button";
import Info from "@material-ui/icons/Info";
import Accepted from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import Errored from "@material-ui/icons/Warning";
import Unknown from "@material-ui/icons/Error";

export default class PaymentHistoryItem extends React.Component {
  render() {
    const paymentItem = this.props.paymentItem;
    let cancelColumn = null;

    const formattedAmount = formatPaymentAmount(paymentItem.get("amount"));
    const formattedFee = formatPaymentAmount(paymentItem.get("feeAmount"));
    const formattedDate = moment(paymentItem.get("postDate")).format("L");

    let icon = (
      <Unknown
        style={{
          color: this.props.styles.get("accent1Color"),
          verticalAlign: "middle",
          paddingBottom: "3px",
        }}
      />
    );

    if (paymentItem.get("status") === "Pending") {
      icon = (
        <Info
          style={{
            color: this.props.styles.get("primary1Color"),
            verticalAlign: "middle",
            paddingBottom: "3px",
          }}
        />
      );
    }
    if (
      paymentItem.get("status") === "Errored" ||
      paymentItem.get("status") === "Declined"
    ) {
      icon = (
        <Errored
          color="error"
          style={{
            // color: this.props.styles.get("accent1Color"),
            verticalAlign: "middle",
            paddingBottom: "3px",
          }}
        />
      );
    }
    if (paymentItem.get("status") === "Deleted") {
      icon = (
        <Cancel
          color="error"
          style={{
            // color: this.props.styles.get("accent1Color"),
            verticalAlign: "middle",
            paddingBottom: "3px",
          }}
        />
      );
    }
    if (paymentItem.get("status") === "Processed") {
      icon = (
        <Accepted
          color="primary"
          style={{
            verticalAlign: "middle",
            paddingBottom: "3px",
          }}
        />
      );
    }

    if (!this.props.hideCancel) {
      if (paymentItem.get("status") === "Pending") {
        cancelColumn = (
          <TableCell>
            <Button
              onClick={() => this.props.onCancelItem(paymentItem)}
              color="secondary"
            >
              {this.props.t("Cancel")}
            </Button>
          </TableCell>
        );
      } else {
        cancelColumn = <TableCell />;
      }
    }

    return (
      <TableRow>
        <TableCell>
          <Icon>{icon}</Icon>
          &nbsp;
          {paymentItem.get("status")}
        </TableCell>
        <TableCell>{formattedDate}</TableCell>
        <TableCell>${formattedAmount}</TableCell>
        <TableCell>${formattedFee}</TableCell>
        <TableCell>{paymentItem.get("transactionNumber")}</TableCell>
        {paymentItem.get("mwiSystem") === "AUTO" ? (
          <TableCell>{this.props.t("Auto-Pay")}</TableCell>
        ) : (
          <TableCell>{paymentItem.get("frequency")}</TableCell>
        )}
        {cancelColumn}
      </TableRow>
    );
  }
}
