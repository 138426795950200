import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { recordLocationAction } from "./../../api/actions/session";
import AlertInfo from "./../errors/AlertInfo.jsx";

export class UserSignInPrompt extends React.Component {
  render() {
    if (this.props.loggedIn || this.props.guestOnly) {
      return <div />;
    }

    return (
      <AlertInfo
        text={this.props.t("TIP_BOX_REGISTRATION_PROMPT")}
        location={this.props.location}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loggedIn: state.auth.get("authenticated"),
    styles: state.style.get("palette"),
    guestOnly: state.consortium.getIn(["settings", "guestOnly"])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    recordLocation: location => {
      dispatch(recordLocationAction(location));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserSignInPrompt)
);
