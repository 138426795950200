// Library Imports.
import React, { Component } from "react";

import AppView from "./AppView.jsx";
import Landing from "./Landing.jsx";
import NotFound from "./errors/NotFound.jsx";

import PaymentTowards from "./payment-towards/PaymentTowards.jsx";

import Accounts from "./accounts/Accounts.jsx";
import LookupAccount from "./accounts/LookUpAccounts.jsx";
import SelectAccount from "./accounts/SelectAccount.jsx";

import ContactInfo from "./contact-info/ContactInfo.jsx";

import SelectPaymentMethod from "./payment-method/SelectPaymentMethod.jsx";
import ManagePaymentMethods from "./payment-method/ManagePaymentMethods.jsx";
import AddNewCard from "./payment-method/AddNewCard.jsx";
import AddNewAch from "./payment-method/AddNewAch.jsx";

import PaymentOptions from "././payment/PaymentOptions.jsx";
import OneTimePayment from "./payment/OneTimePayment.jsx";
import RecurringPayment from "./payment/RecurringPayment.jsx";

import ManageUserAccountsLanding from "./user-accounts/ManageUserAccountsLanding.jsx";
import ManageUserAccounts from "./user-accounts/ManageUserAccounts.jsx";

import ConfirmPayment from "./checkout/ConfirmPayment.jsx";
import ThankYouContainer from "./checkout/ThankYou/ThankYouContainer.jsx";
import SignInReminder from "./checkout/SignInReminder.jsx";

import UserSignIn from "./user-accounts/UserSignIn.jsx";
import UsernameReminder from "./user-accounts/reminder/UsernameReminder.jsx";
import PasswordReset from "./user-accounts/password-reset/PasswordReset.jsx";
import PasswordChange from "./user-accounts/password-change/UserPasswordChange.jsx";
import RegisterUserAccount from "./user-accounts/RegisterUserAccount.jsx";
import UpdateContactInfo from "./contact-info/UpdateContactInfo.jsx";
import PaymentHistoryContainer from "./payment-history/PaymentHistoryContainer.jsx";
import PaymentHistoryAccountSelect from "./payment-history/PaymentHistoryAccountSelect.jsx";
import ConnectedStatementView from "./statement/ConnectedStatementView.jsx";
import StatementViewAccountSelect from "./statement/StatementViewAccountSelect.jsx";
import ManageRecurringContainer from "./manage-recurring/ManageRecurringContainer.jsx";
import ManageRecurringAccountSelect from "./manage-recurring/ManageRecurringAccountSelect.jsx";
import AutoPayAccountSelect from "./auto-pay/AutoPayAccountSelect.jsx";
import ManageAutoPayContainer from "./auto-pay/ManageAutoPayContainer.jsx";
import TermsAndConditions from "./../components/TermsAndConditions.jsx";
import PrivacyPolicy from "./../components/PrivacyPolicy.jsx";
import WorkInProgress from "./utility/WorkInProgress.jsx";
import SsoLink from "./sso/SsoLink.jsx";
import SsoRegister from "./sso/SsoRegister.jsx";
import SsoLanding from "./sso/SsoLanding.jsx";

import { Router, Route, IndexRoute, browserHistory } from "react-router";

class Routes extends Component {
  render() {
    return (
      <Router history={browserHistory}>
        {/*Index redirect to access number entry page, eventually?*/}
        <Route path="(v2/)">
          <IndexRoute component={NotFound} />
          <Route path="c/:consortiumId" component={AppView}>
            <Route path="sso" component={AppView} />
            <Route path="wip" component={WorkInProgress} />
            <Route path="privacy-policy" component={PrivacyPolicy} />
            <Route path="terms-and-conditions" component={TermsAndConditions} />
            <Route path="user">
              <Route path="sso">
                <Route path="landing" component={SsoLanding} />
                <Route path="link" component={SsoLink} />
                <Route path="register" component={SsoRegister} />
              </Route>
              <Route path="sign-in" component={UserSignIn} />
              <Route path="register" component={RegisterUserAccount} />
              <Route path="username-reminder" component={UsernameReminder} />
              <Route path="password-reset" component={PasswordReset} />
              <Route
                path="password-reset/token/:resetToken"
                component={PasswordReset}
              />
              <Route path="password-change" component={PasswordChange} />
              <Route path="contact-info" component={UpdateContactInfo} />
              <Route
                path="history/select"
                component={PaymentHistoryAccountSelect}
              />
              <Route path="history/view" component={PaymentHistoryContainer} />
              <Route
                path="recurring/select"
                component={ManageRecurringAccountSelect}
              />
              <Route
                path="recurring/view"
                component={ManageRecurringContainer}
              />
              <Route path="auto-pay/select" component={AutoPayAccountSelect} />
              <Route path="auto-pay/view" component={ManageAutoPayContainer} />
              <Route
                path="statements/select"
                component={StatementViewAccountSelect}
              />
              <Route
                path="statements/view"
                component={ConnectedStatementView}
              />
              <Route path="payment-methods">
                <IndexRoute component={ManagePaymentMethods} />
                <Route path="add-credit-card" component={AddNewCard} />
                <Route path="add-bank-account" component={AddNewAch} />
              </Route>
              <Route path="accounts">
                <IndexRoute component={ManageUserAccountsLanding} />
                <Route path=":accessNumber" component={ManageUserAccounts} />
              </Route>
            </Route>
            <IndexRoute component={Landing} />
            <Route path="business/:accessNumber">
              <IndexRoute component={NotFound} />
              <Route path="pay-to" component={PaymentTowards} />
              <Route path="accounts" component={Accounts} />
              <Route path="lookup-accounts" component={LookupAccount} />
              <Route path="accounts/select" component={SelectAccount} />
              <Route path="checkout" component={SignInReminder} />
              <Route path="checkout/contact-info" component={ContactInfo} />
              <Route
                path="payment/select-method"
                component={SelectPaymentMethod}
              />
              <Route path="payment/options" component={PaymentOptions} />
              <Route path="payment/one-time" component={OneTimePayment} />
              <Route path="payment/recurring" component={RecurringPayment} />

              <Route path="payment/add-new-card" component={AddNewCard} />
              <Route path="payment/add-new-ach" component={AddNewAch} />
              <Route
                path="payment/confirm-payment"
                component={ConfirmPayment}
              />
              <Route path="thank-you" component={ThankYouContainer} />
            </Route>
          </Route>
          <Route path="*" component={NotFound} />
        </Route>
      </Router>
    );
  }
}

export default Routes;
