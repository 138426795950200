import { isImmutable, Map, List, fromJS } from "immutable";
import {
  GET_PAYMENT_HISTORY,
  GET_RECENT_PAYMENTS,
  SELECT_PAYMENT_HISTORY_ACCOUNT,
  SUCCESS,
  FAILURE,
} from "../../api/actions/actionTypes";
import FormatPaymentItem from "./../../utils/FormatPaymentItem";

const getDefaultState = () => {
  return Map({
    items: List(),
    pageNumber: 1,
    lastPage: true,
    recentPayments: Map(),
    pendingPayments: Map(),
    recentlyDeletedPayments: Map(),
    recurringPayments: Map(),
  });
};

const actions = {};

actions[`${GET_PAYMENT_HISTORY}_${SUCCESS}`] = function (state, action) {
  // Currently, the only zero dollar payment amounts in MPAY are auto-pay enrollments.
  // We don't want them in our payment history view, so we filter them out before storing them in state
  const paymentHistoryItemsWithZeroDollarAmounts = fromJS(action.payload.body);
  const paymentHistoryItems = paymentHistoryItemsWithZeroDollarAmounts.filter(
    (p) => p.get("amount") !== 0
  );

  const meta = action.meta;
  const formattedItems = paymentHistoryItems.map(FormatPaymentItem);

  let lastPage = false;
  if (formattedItems.size < 10) {
    lastPage = true; // todo2 - temporary sloppy fix
  }

  return state
    .set("items", formattedItems)
    .set("pageNumber", meta.pageNumber)
    .set("lastPage", lastPage);
};

const getTotalAmount = function (items) {
  let totalAmount = 0;
  items.forEach((p) => {
    totalAmount += p.get("amount");
  });

  return totalAmount;
};

actions[`${GET_RECENT_PAYMENTS}_${SUCCESS}`] = function (state, action) {
  const paymentHistoryItems = fromJS(action.payload.body);

  // Recurring: the ones whose frequency is not None (0) and are not deleted (status 2)
  const recurringPayments = paymentHistoryItems
    .filter((i) => {
      return i.get("frequency") !== 0 && i.get("status") !== 2;
    })
    .groupBy((k) => k.get("accountNumber"))
    .map((y) => {
      return { total: getTotalAmount(y), count: y.count() };
    });
  // Deleted: status 2
  const deletedPayments = paymentHistoryItems
    .filter((i) => i.get("status") === 2)
    .groupBy((k) => k.get("accountNumber"))
    .map((y) => {
      return { total: getTotalAmount(y), count: y.count() };
    });
  // Processed: status 4
  const recentPayments = paymentHistoryItems
    .filter((i) => {
      return i.get("frequency") === 0 && i.get("status") === 4;
    })
    .groupBy((k) => k.get("accountNumber"))
    .map((y) => {
      return { total: getTotalAmount(y), count: y.count() };
    });
  // Pending: status 3
  const pendingPayments = paymentHistoryItems
    .filter((i) => {
      return i.get("frequency") === 0 && i.get("status") === 3;
    })
    .groupBy((k) => k.get("accountNumber"))
    .map((y) => {
      return { total: getTotalAmount(y), count: y.count() };
    });

  return state.merge({
    recentPayments: recentPayments,
    pendingPayments: pendingPayments,
    recentlyDeletedPayments: deletedPayments,
    recurringPayments: recurringPayments,
  });
};

actions[`${GET_PAYMENT_HISTORY}_${FAILURE}`] = function (state, action) {
  // todo2 - temporary sloppy fix
  return state.set("lastPage", true);
};

actions[SELECT_PAYMENT_HISTORY_ACCOUNT] = function (state, action) {
  const bankNumber = action.meta.bankNumber;
  const companyNumber = action.meta.companyNumber;
  const selectedAccount = isImmutable(action.payload)
    ? action.payload
    : fromJS(action.payload);

  const accountWithBankCompany = selectedAccount
    .set("bankNumber", bankNumber)
    .set("companyNumber", companyNumber);
  return state.set("selectedAccount", accountWithBankCompany);
};

export default function (state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  return actions[action.type](state, action);
}
