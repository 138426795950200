import React from "react";
import { withFormsy } from "formsy-react";
import { TextField } from "@material-ui/core";
import MaskedInput from "react-text-mask";

function AccountFormatterInput(props) {
  const { inputRef, mask, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={"\u2000"}
    />
  );
}

class AccountField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
      resetTouch: false
    };

    this.textInput = React.createRef();
    this.focusInput = this.focusInput.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.buildMask = this.buildMask.bind(this);
  }
  componentWillReceiveProps(newProps) {
    if (newProps.resetTouch) {
      this.setState({ touched: false, resetTouch: newProps.resetTouch });
    }
  }
  componentDidMount() {
    if (this.props.shouldFocusOnMount) {
      this.focusInput();
    }
  }
  onBlur() {
    this.setState({ touched: true, resetTouch: false });
  }
  buildMask(blocks, delimiters) {
    const regexAnyChar = /./;

    let billingAccountMask = [];

    for (let i = 0; i < blocks.length; i++) {
      for (let j = 0; j < blocks[i]; j++) {
        billingAccountMask.push(regexAnyChar);
      }

      if (delimiters[i]) {
        billingAccountMask.push(delimiters[i]);
      }
    }

    return billingAccountMask;
  }
  focusInput() {
    this.textInput.current.focus();
  }
  render() {
    return (
      <TextField
        id={this.props.label}
        label={this.props.label}
        fullWidth
        error={this.state.touched && !this.props.isValid()}
        helperText={
          this.state.touched && !this.props.isValid()
            ? this.props.getErrorMessage()
            : null
        }
        onBlur={this.onBlur}
        style={{ bottom: "10px" }}
        onChange={e => this.props.onChange(e.currentTarget.value)}
        value={this.props.value}
        InputProps={{
          inputComponent: AccountFormatterInput
        }}
        // "inputProps" gives the props object to the inputComponent of "InputProps". It is not a duplicate prop. Thank material-ui for the terrible naming.
        // eslint-disable-next-line
        inputProps={{
          mask: this.buildMask(this.props.blocks, this.props.delimiters)
        }}
        inputRef={this.textInput}
      />
    );
  }
}

export default withFormsy(AccountField);
