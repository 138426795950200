import React, { Component } from "react";
import { connect } from "react-redux";

import PasswordResetEmailForm from "./PasswordResetEmailForm.jsx";

import { requestPasswordResetEmail } from "./../../../api/actions/user-accounts/passwordReset";
import {
  showLoadingAction,
  hideLoadingAction
} from "./../../../api/actions/loading";
import {
  PASSWORD_RESET_EMAIL,
  FAILURE
} from "./../../../api/actions/actionTypes";

class PasswordResetEmail extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (!nextProps.pending && this.props.pending) {
      this.props.hideLoading();

      if (!nextProps.lastRequestFailed) {
        this.props.onNext();
      }
    }
  }
  onSubmit(model) {
    this.props.showLoading(this.props.t("Sending Password Reset Email..."));
    this.props.sendEmail(this.props.consortiumId, model.username, model.email);

    // submit token email request
    // show loading while backend does the email sending
    // go to next step when done
  }
  render() {
    return (
      <div>
        <p>
          {this.props.t(
            "Please provide the username and email address associated with your account."
          )}
        </p>
        <PasswordResetEmailForm
          t={this.props.t}
          onSkip={this.props.onNext}
          onSubmit={this.onSubmit}
          disabled={this.props.pending}
          linkColor={this.props.styles.get("accent3Color")}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pending: !!state.httpRequests.getIn([PASSWORD_RESET_EMAIL, "pending"]),
    lastRequestFailed:
      state.httpRequests.getIn([PASSWORD_RESET_EMAIL, "lastStatus"]) ===
      FAILURE,
    styles: state.style.get("palette")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showLoading: titleText => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: titleText => {
      dispatch(hideLoadingAction());
    },
    sendEmail: (consortiumId, username, email) => {
      dispatch(requestPasswordResetEmail(consortiumId, username, email));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetEmail);
