import { isImmutable, Map, fromJS } from "immutable";
import {
  GET_RECURRING_PAYMENTS,
  SELECT_RECURRING_PAYMENT_ACCOUNT,
  SUCCESS,
  FAILURE
} from "../../api/actions/actionTypes";
import FormatPaymentItem from "./../../utils/FormatPaymentItem";

const getDefaultState = () => {
  return Map({ items: [], pageNumber: 1, lastPage: true });
};

const actions = {};

actions[`${GET_RECURRING_PAYMENTS}_${SUCCESS}`] = function(state, action) {
  const recurringPaymentItems = fromJS(action.payload.body);
  const meta = action.meta;

  const formattedItems = recurringPaymentItems.map(FormatPaymentItem);

  let lastPage = false;
  if (formattedItems.size < 10) {
    lastPage = true; // todo2 - temporary sloppy fix
  }

  return state
    .set("items", formattedItems)
    .set("pageNumber", meta.pageNumber)
    .set("lastPage", lastPage);
};

actions[`${GET_RECURRING_PAYMENTS}_${FAILURE}`] = function(state, action) {
  if (state.get("items").length > 1) {
    return state.set("lastPage", true);
  }

  return state.set("items", []).set("lastPage", true);
};

actions[SELECT_RECURRING_PAYMENT_ACCOUNT] = function(state, action) {
  const bankNumber = action.meta.bankNumber;
  const companyNumber = action.meta.companyNumber;
  const selectedAccount = isImmutable(action.payload)
    ? action.payload
    : fromJS(action.payload);

  const accountWithBankCompany = selectedAccount
    .set("bankNumber", bankNumber)
    .set("companyNumber", companyNumber);
  return state.set("selectedAccount", accountWithBankCompany);
};

export default function(state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  return actions[action.type](state, action);
}
