import React, { Component } from "react";
import { connect } from "react-redux";
import { ResponsiveUtil } from "../utils/ResponsiveUtil";
import FocusableButton from "../components/FocusableButton.jsx";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import { closeNotificationAction } from "./../api/actions/notification";

import { _t } from "../text/locale";
const t = _t();

const mobileStyle = {
  width: "100%",
  maxWidth: "none"
};

const warningColor = "#FB4229";
const successColor = "#149844";
const isMobile = ResponsiveUtil.isMobile();

class NotificationModal extends Component {
  constructor(props) {
    super(props);

    this.closeOrRefresh = this.closeOrRefresh.bind(this);
  }
  closeOrRefresh() {
    if (this.props.sessionTimeout) {
      window.location = "";
    }

    this.props.focusCallback();
    this.props.close();
  }
  render() {
    const acknowledgeAction = [
      <FocusableButton
        key="notification-modal-ok"
        label="Ok"
        color="primary"
        onClick={this.closeOrRefresh}
        shouldFocusOnMount
      >
        Ok
      </FocusableButton>
    ];

    const yesOrNo = [
      <FocusableButton
        key="notification-modal-no"
        label="No"
        onClick={() => {
          this.props.no();
          this.closeOrRefresh();
        }}
        color="secondary"
        shouldFocusOnMount
      >
        No
      </FocusableButton>,
      <Button
        key="notification-modal-yes"
        label="Yes"
        onClick={() => {
          this.props.yes();
          this.closeOrRefresh();
        }}
        color="primary"
      >
        Yes
      </Button>
    ];

    const actions = this.props.notification.get("isChoice")
      ? yesOrNo
      : acknowledgeAction;

    let titleTextStyle;
    if (this.props.notification.get("level") === "SUCCESS") {
      titleTextStyle = { color: successColor };
    }
    if (this.props.notification.get("level") === "FAILURE") {
      titleTextStyle = { color: warningColor };
    }

    return (
      <Dialog open={!!this.props.show} disableEnforceFocus >
        <DialogTitle disableTypography>
          <Typography variant="h3" style={titleTextStyle}>
            {t(this.props.notification.get("title"))}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={isMobile ? mobileStyle : null}>
            {t(this.props.notification.get("message"))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    notification: state.notification.get("content"),
    show: state.notification.get("show"),
    sessionTimeout: state.session.get("sessionEnded"),
    yes: state.notification.getIn(["callbacks", "yes"]),
    no: state.notification.getIn(["callbacks", "no"])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    close: () => {
      closeNotificationAction(dispatch);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal);
