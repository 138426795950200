import React from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";
import Button from "@material-ui/core/Button";
import FormsyCreditCard from "./../../components/form-input/FormsyCreditCard.jsx";
import FormsyCardExpiration from "./../../components/form-input/FormsyCardExpiration.jsx";
import FormsyTextField from "./../../components/form-input/FormsyTextField.jsx";
import CreditCardLogo from "./CreditCardLogo.jsx";
import { ResponsiveUtil } from "./../../utils/ResponsiveUtil";

const mobileStyle = {
  width: "100%",
  maxWidth: "none",
  marginBottom: "10px"
};
const isMobile = ResponsiveUtil.isMobile();

class AddNewCardForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardNumber: "",
      cardExpiration: "",
      cardholderName: "",
      valid: false,
      currentCardType: ""
    };

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
    this.onInput = this.onInput.bind(this);
    this.setCardType = this.setCardType.bind(this);
    this.renderCreditCardLogos = this.renderCreditCardLogos.bind(this);
    this.getCardLogo = this.getCardLogo.bind(this);
  }
  static propTypes = {
    t: PropTypes.func.isRequired
  };
  onValidSubmit() {
    // Copy our form model and delete form-related properties
    // This is less ~effort~ than re-initializing an object with all the properties.
    const model = Object.assign({}, this.state);
    delete model.valid;

    this.props.onSubmit(model);
  }
  onValid() {
    this.setState({ valid: true });
  }
  onInvalid() {
    this.setState({ valid: false });
  }
  onInput(name) {
    return value => {
      let update = {};
      update[name] = value;
      this.setState(update);
    };
  }
  setCardType(type) {
    this.setState({ currentCardType: type });
  }
  renderCreditCardLogos() {
    const logos = this.props.companyPaymentTypes.map(paymentType => {
      if (CreditCardLogo.availableTypes.includes(paymentType)) {
        if (this.state.currentCardType === "maestro") {
          this.setState({ currentCardType: "mastercard" });
        }
        return this.getCardLogo(paymentType);
      }

      return null;
    });
    return logos.toArray();
  }
  getCardLogo(type) {
    const selected = this.state.currentCardType === type;
    return (
      <CreditCardLogo key={`logo-${type}`} type={type} selected={selected} />
    );
  }
  render() {
    return (
      <Formsy
        role="form"
        aria-label="New Credit or Debit Card"
        noValidate
        onValidSubmit={this.onValidSubmit}
        onValid={this.onValid}
        onInvalid={this.onInvalid}
      >
        <div style={{ marginTop: "15px" }}>
          <FormsyTextField
            name="cardholderName"
            floatingLabelText={this.props.t("Cardholder Name")}
            onChange={this.onInput("cardholderName")}
            value={this.state.cardholderName}
            validationErrors={{
              isDefaultRequiredValue: this.props.t(
                "Cardholder name is required"
              )
            }}
            required
            shouldFocusOnMount
          />
          <br />
          <FormsyCreditCard
            name="cardNumber"
            floatingLabelText={this.props.t("Card Number")}
            onChange={this.onInput("cardNumber")}
            value={this.state.cardNumber}
            setCardType={this.setCardType}
            acceptedCardTypes={this.props.companyPaymentTypes}
            required
            t={this.props.t}
          />
          {this.renderCreditCardLogos()}
          <br />
          <FormsyCardExpiration
            name="cardExpiration"
            floatingLabelText={this.props.t("Expiration (MM/YY)")}
            onChange={this.onInput("cardExpiration")}
            value={this.state.cardExpiration}
            validationErrors={{
              isDefaultRequiredValue: this.props.t(
                "Expiration date is required"
              )
            }}
            required
            t={this.props.t}
          />
          <br />
          <Button
            variant="contained"
            type="submit"
            disabled={!this.state.valid || this.props.disabled}
            // Apply mobile styles if we need to, but always include a top margin
            style={{ ...(isMobile ? mobileStyle : {}), marginTop: "8px" }}
            color="primary"
          >
            {this.props.submitAction}
          </Button>
        </div>
      </Formsy>
    );
  }
}

export default AddNewCardForm;
