import React from "react";
import { withFormsy } from "formsy-react";
import { TextField } from "@material-ui/core";

class FormsyTextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = { touched: false };

    this.textInput = React.createRef();
    this.focusInput = this.focusInput.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }
  componentDidMount() {
    if (this.props.shouldFocusOnMount) {
      this.focusInput();
    }
  }
  onBlur() {
    this.setState({ touched: true });
  }
  focusInput() {
    this.textInput.current.focus();
  }
  render() {
    return (
      <TextField
        id={this.props.name}
        type="password"
        name={this.props.name}
        fullWidth
        disabled={this.props.disabled}
        required={this.props.required}
        label={this.props.floatingLabelText}
        defaultValue={this.props.value}
        onChange={e => this.props.onChange(e.currentTarget.value)}
        error={this.state.touched && !this.props.isValid()}
        helperText={
          this.state.touched && !this.props.isValid()
            ? this.props.getErrorMessage()
            : null
        }
        onBlur={this.onBlur}
        maxLength={this.props.maxLength}
        style={{ bottom: "10px" }}
        inputRef={this.textInput}
      />
    );
  }
}

export default withFormsy(FormsyTextField);
