import React from "react";
import PropTypes from "prop-types";
import FormsyTextField from "./../../components/form-input/FormsyTextField.jsx";
import FormsyNewPasswordFields from "./../../components/form-input/FormsyNewPasswordFields.jsx";

class UserAccountFields extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onInput: PropTypes.func.isRequired
  };
  render() {
    return (
      <div>
        <div>
          <FormsyTextField
            name="username"
            floatingLabelText={this.props.t("Username")}
            onChange={this.props.onInput("username")}
            validations={{
              maxLength: 30
            }}
            value={this.props.formData.username}
            validationErrors={{
              isDefaultRequiredValue: this.props.t("Username is required"),
              maxLength: this.props.t("You cannot type more than 30 characters")
            }}
            required
            shouldFocusOnMount
          />
          <FormsyNewPasswordFields
            t={this.props.t}
            onPasswordInput={this.props.onInput("password")}
            onPasswordConfirmInput={this.props.onInput("passwordConfirm")}
            password={this.props.formData.password}
            passwordConfirm={this.props.formData.passwordConfirm}
          />
        </div>
      </div>
    );
  }
}

export default UserAccountFields;
