import Marked from "marked";

export default linkColor => {
  let renderer = new Marked.Renderer();

  // We need to inject a link color to the anchor tag,
  // so if one is provided, we override the link renderer
  // and include our style tag
  if (linkColor) {
    let styleString = 'style="color:' + linkColor + '"';

    renderer.link = function(href, title, text) {
      if (this.options.sanitize) {
        let prot;
        try {
          prot = decodeURIComponent(unescape(href))
            .replace(/[^\w:]/g, "")
            .toLowerCase();
        } catch (e) {
          return "";
        }

        if (
          // eslint-disable-next-line
          prot.indexOf("javascript:") === 0 ||
          prot.indexOf("vbscript:") === 0 ||
          prot.indexOf("data:") === 0
        ) {
          // eslint-disable-line no-script-url
          return "";
        }
      }
      let out = "<a " + styleString + ' href="' + href + '"';
      if (title) {
        out += ' title="' + title + '"';
      }

      out += ">" + text + "</a>";
      return out;
    };
  }

  return Marked.setOptions({
    renderer: renderer,
    gfm: true,
    tables: true,
    breaks: true,
    pedantic: false,
    sanitize: false,
    smartLists: true,
    smartypants: false
  });
};
