import http from "./../utils/http";

export const getConsortium = consortiumId => {
  return http
    .get(
      `/api/v1/consortiums/${consortiumId}/bcs?expand=blocked_bins,recurring_payment_frequencies,blocked_routing_numbers`
    )
    .end();
};

export const getConsortiumName = consortiumId => {
  return http.get(`/api/v1/consortiums/${consortiumId}`).end();
};

export const getBcHolidays = (bankNo, compNo) => {
  return http.get(`/api/v1/dates/holidays?bank_number=${bankNo}`).end();
};

export const getBcNextBusinessDay = (bankNo, compNo) => {
  return http
    .get(
      `/api/v1/dates/next-business-day?bank_number=${bankNo}&comp_number=${compNo}`
    )
    .end();
};
