// Reducer for tracking session information.
import { Map } from "immutable";

import {
  SESSION_BEGIN,
  SESSION_TIMEOUT,
  SESSION_TIMEOUT_WARN,
  RECORD_LOCATION,
  CONTINUE_AS_GUEST_SELECTED,
  SET_PAGE_INFO
} from "../../api/actions/actionTypes";

const actions = {};

function getDefaultState() {
  return Map({ location: "", continueAsGuestSelected: false });
}

actions[SESSION_BEGIN] = function(state, action) {
  return state.set("sessionStarted", new Date());
};

actions[SESSION_TIMEOUT_WARN] = function(state, action) {
  return state.set("sessionWarn", true);
};

actions[SESSION_TIMEOUT] = function(state, action) {
  state = state.set("sessionWarn", false).set("sessionEnded", true);

  return state;
};

actions[RECORD_LOCATION] = function(state, action) {
  if (
    action.payload &&
    (action.payload.includes("user/register") ||
      action.payload.includes("user/sign-in"))
  ) {
    return state;
  }

  return state.set("location", action.payload);
};

actions[CONTINUE_AS_GUEST_SELECTED] = function(state, action) {
  return state.set("continueAsGuestSelected", true);
};

actions[SET_PAGE_INFO] = function(state, action) {
  return state.set("pageData", action.payload);
};

export default function(state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  return actions[action.type](state, action);
}
