import { loadConfigAction } from "./config";
import {
  getConsortiumAction,
  getConsortiumNameAction,
  getBcHolidaysAction,
  getBcNextBusinessDayAction,
} from "./consortium";
import { getGuestAuthorizationAction, getSsoAuthorizationAction } from "./auth";
import { getDefaultLocaleCustomization } from "./text";
import { getStyleCustomization } from "./style";
import { getUserPaymentMethodsAction, getUserAccountsAction } from "./user";
import { getRecentPaymentsAction } from "./paymentHistory";
import {
  INIT_COMPLETE,
  AUTHENTICATE_SSO,
  SUCCESS,
  FAILURE,
} from "./actionTypes";
import Promise from "promise";

export const getCalendarInformation = (consortium) => (dispatch, getState) => {
  consortium.forEach((bc) => {
    getBcNextBusinessDayAction(
      bc.bankNumber,
      bc.companyNumber,
      bc.accessNumber
    )(dispatch, getState);
  });

  getBcHolidaysAction(consortium[0].bankNumber)(dispatch, getState);
};

export const getInitialState = (id) => (dispatch, getState) => {
  const configData = dispatch(loadConfigAction());

  // If we didn't properly load config data, bail.
  if (!configData) {
    dispatch({ type: INIT_COMPLETE, payload: FAILURE });
    return;
  }
  // Get access token - needs config information
  getGuestAuthorizationAction(id)(dispatch, getState)
    .then((result) => {
      // Get consortium - needs to be authorized
      const consortium = getConsortiumAction(id)(dispatch, getState);
      // Get custom text - needs to be authorized
      const locale = getDefaultLocaleCustomization(id)(dispatch, getState);
      const consortiumName = getConsortiumNameAction(id)(dispatch, getState);
      const style = getStyleCustomization(id)(dispatch, getState);
      return Promise.all([consortium, locale, style, consortiumName]);
    })
    .then((result) => {
      getCalendarInformation(result[0].body)(dispatch, getState);
      dispatch({ type: INIT_COMPLETE, payload: SUCCESS });
    })
    .catch((err) => {
      dispatch({ type: INIT_COMPLETE, payload: FAILURE });
    });
};

export const getSsoInitialState = (consortiumId, accessTokenId, ssoType) => (
  dispatch,
  getState
) => {
  const configData = dispatch(loadConfigAction());
  // If we didn't properly load config data, bail.
  if (!configData) {
    dispatch({ type: INIT_COMPLETE, payload: FAILURE });
    return;
  }
  // Get access token from the sso
  getSsoAuthorizationAction(
    consortiumId,
    accessTokenId,
    ssoType
  )(dispatch, getState)
    .then((result) => {
      const userId = result.body.user.id;

      const consortium = getConsortiumAction(consortiumId)(dispatch, getState);
      const locale = getDefaultLocaleCustomization(consortiumId)(
        dispatch,
        getState
      );
      const consortiumName = getConsortiumNameAction(consortiumId)(
        dispatch,
        getState
      );
      const userAccounts = getUserAccountsAction(consortiumId, userId)(
        dispatch,
        getState
      );
      const methods = getUserPaymentMethodsAction(consortiumId, userId)(
        dispatch,
        getState
      );
      const recentPayments = getRecentPaymentsAction(consortiumId, userId)(
        dispatch,
        getState
      );
      const style = getStyleCustomization(consortiumId)(dispatch, getState);
      return Promise.all([
        consortium,
        locale,
        style,
        consortiumName,
        userAccounts,
        methods,
        recentPayments,
      ]);
    })
    .then((result) => {
      getCalendarInformation(result[0].body)(dispatch, getState);
      dispatch({ type: INIT_COMPLETE, payload: SUCCESS });
    })
    .catch((err) => {
      this.props.router.push(`/c/${consortiumId}`);
      dispatch({ type: AUTHENTICATE_SSO, payload: FAILURE });
      dispatch({ type: INIT_COMPLETE, payload: FAILURE });
    });
};
