import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  GET_AUTO_PAY_ENROLLMENT,
  SUCCESS,
  FAILURE,
} from "./../../api/actions/actionTypes";
import {
  showLoadingAction,
  hideLoadingAction,
} from "./../../api/actions/loading";
import RouteHelper from "./../../utils/RouteHelper";
import SelectAccountList from "./../accounts/SelectAccountList.jsx";
import {
  getAutoPayEnrollmentAction,
} from "./../../api/actions/autoPay";
import AlertWarning from "./../errors/AlertWarning.jsx";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { setPageInfoAction } from "./../../api/actions/pageInfo";
import { _t } from "./../../text/locale";
import { List } from "immutable";

let t;
let pageTitle = "Manage Auto-Pay Enrollments";

class AutoPayAccountSelect extends React.Component {
  constructor(props) {
    super(props);

    this.routeToAutoPayEnrollment = this.routeToAutoPayEnrollment.bind(this);
    this.selectAccount = this.selectAccount.bind(this);
    this.shouldFocus = this.shouldFocus.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.loadRetrieving === true) {
      this.props.showLoading(t("Retrieving Auto-Pay enrollment..."));
    } else if ( 
      !nextProps.loadRetrieving && 
      this.props.loadRetrieving && 
      !nextProps.lastRetrievingFailed && 
      nextProps.lastRetrievingSuccess) {
        this.props.hideLoading();
        this.routeToAutoPayEnrollment();
    } else {
      this.props.hideLoading();
    }
  }
  selectAccount(account) {
    const businessCustomer = this.props.consortium.getIn([
      "customers",
      account.get("accessNumber"),
    ]);
    let bankNumber = businessCustomer.get("bankNumber");
    let companyNumber = businessCustomer.get("companyNumber");
    let billingAccountNumber = account.get("accountNumber");
    this.props.getAutoPayEnrollment(
      this.props.params.consortiumId,
      this.props.userId,
      bankNumber,
      companyNumber,
      billingAccountNumber
    );
  }
  routeToAutoPayEnrollment() {
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/auto-pay/view"
    );
    this.props.router.push(path);
  }
  warnIfNoAccounts(accounts) {
    if (accounts.length === 0) {
      return (
        <AlertWarning>
          <strong>{t("No accounts have been added.")}&nbsp;</strong>
          <br className="visible-xs" />
          {t("Please add an account.")}
        </AlertWarning>
      );
    }
    return "";
  }
  shouldFocus(bc, bcs) {
    const firstKey = Object.keys(bcs)[0];

    return bc === firstKey;
  }
  renderAccountsLists(accounts) {
    let bcs = {};
    let SelectAccountLists = [];
    accounts.forEach((account) => {
      let accessNumber = account.get("accessNumber");
      if (!(bcs[accessNumber] instanceof Array)) {
        bcs[accessNumber] = [];
      }
      bcs[accessNumber].push(account);
    });
    for (let bc in bcs) {
      if (bcs.hasOwnProperty(bc)) {
        SelectAccountLists.push(
          <div key={bc}>
            <h2>
              {this.props.consortium.getIn([
                "customers",
                bc,
                "serviceDescription",
              ])}
            </h2>
            <SelectAccountList
              t={t}
              accounts={List(bcs[bc])}
              onAccountSelected={this.selectAccount}
              shouldFocusOnMount={this.shouldFocus(bc, bcs)}
            />
          </div>
        );
      }
    }
    return <div>{SelectAccountLists}</div>;
  }
  render() {
    return (
      <div>
        <div className="flex-container">
          <div className="flex-content">
            <p>
              {t(
                "Select an account to view an Auto-Pay Enrollment. You have the following accounts:"
              )}
            </p>
            {this.renderAccountsLists(this.props.accounts)}
            {this.warnIfNoAccounts(this.props.accounts)}
          </div>
          <div
            className="flex-tipBox"
            aria-label="Auto Pay Tip Box"
            role="region"
          >
            <AlertInfo
              text={t("TIP_BOX_AUTO_PAY_ACCOUNT_SELECT")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const bcs = state.billing.get("accounts").toList();
  const consortium = state.consortium;
  let accounts = [];
  
  bcs.forEach(function (bc) {
    if (!(bc instanceof Array)) {
      bc.forEach(function (account) {
        let accessNumber = account.get("accessNumber");
        let billingProcessType = consortium.getIn([
          "customers",
          accessNumber,
          "billingProcessType",
        ]);

        if (billingProcessType === 98 || billingProcessType === 99) {
          accounts.push(account);
        }
      });
    }
  });

  return {
    accounts: accounts,
    consortium: consortium,
    userId: state.user.get("id"), 
    loadRetrieving: !!state.httpRequests.getIn([GET_AUTO_PAY_ENROLLMENT, "pending"]),
    lastRetrievingFailed:
      state.httpRequests.getIn([GET_AUTO_PAY_ENROLLMENT, "lastStatus"]) === FAILURE,
    lastRetrievingSuccess:
      state.httpRequests.getIn([GET_AUTO_PAY_ENROLLMENT, "lastStatus"]) === SUCCESS,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAutoPayEnrollment: (
      consortiumId,
      userId,
      bankNumber,
      companyNumber,
      billingAccountNumber
    ) => {
      dispatch(
        getAutoPayEnrollmentAction(
          consortiumId,
          userId,
          bankNumber,
          companyNumber,
          billingAccountNumber
        )
      );
    },
    showLoading: (titleText) => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AutoPayAccountSelect)
);
