import React from "react";
import PropTypes from "prop-types";

class PasswordResetForm extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };
  render() {
    return (
      <div>
        <p>
          {this.props.t(
            "Your password has been reset and you have been signed in."
          )}
        </p>
      </div>
    );
  }
}

export default PasswordResetForm;
