import {
  getPaymentHistory,
  getRecentPayments
} from "./../requests/paymentHistory";
import {
  GET_PAYMENT_HISTORY,
  GET_RECENT_PAYMENTS,
  SELECT_PAYMENT_HISTORY_ACCOUNT
} from "./actionTypes";

export const getPaymentHistoryAction = (
  consortiumId,
  userIdo,
  bankNumber,
  companyNumber,
  accountNumber,
  pageNumber
) => (dispatch, getState) => {
  const promise = getPaymentHistory(
    consortiumId,
    userIdo,
    bankNumber,
    companyNumber,
    accountNumber,
    pageNumber
  );

  dispatch({
    type: GET_PAYMENT_HISTORY,
    payload: { promise },
    meta: { pageNumber }
  });

  // By returning the promise, we can dispatch another action when this one ends
  return promise;
};

export const getRecentPaymentsAction = (consortiumId, userIdo) => (
  dispatch,
  getState
) => {
  const promise = getRecentPayments(consortiumId, userIdo);

  dispatch({
    type: GET_RECENT_PAYMENTS,
    payload: { promise }
  });

  // By returning the promise, we can dispatch another action when this one ends
  return promise;
};

export const selectPaymentHistoryAccountAction = account => (
  dispatch,
  getState
) => {
  const state = getState();
  const bc = state.consortium.getIn(["customers", account.get("accessNumber")]);
  const bankNumber = bc.get("bankNumber");
  const companyNumber = bc.get("companyNumber");

  dispatch({
    type: SELECT_PAYMENT_HISTORY_ACCOUNT,
    payload: account,
    meta: { bankNumber, companyNumber }
  });
};
