import { Button } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  AUTHENTICATE_USER,
  FAILURE,
  SUCCESS,
} from "./../../api/actions/actionTypes";
import {
  hideLoadingAction,
  showLoadingAction,
} from "./../../api/actions/loading";
import { setPageInfoAction } from "./../../api/actions/pageInfo";
import { recordLocationAction } from "./../../api/actions/session";
import { samlUserSignInAction, getStoredSamlPayloadAction } from "./../../api/actions/user";
import { _t } from "./../../text/locale";
import RouteHelper from "./../../utils/RouteHelper";
import AlertInfo from "./../errors/AlertInfo.jsx";
import SsoLinkForm from "./SsoLinkForm.jsx";
import queryString from "query-string";

let t;
let pageTitle = "Sign In";

export class SsoLink extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.routeToSsoRegister = this.routeToSsoRegister.bind(this);
    this.routeToForgotUsername = this.routeToForgotUsername.bind(this);
    this.routeToForgotPassword = this.routeToForgotPassword.bind(this);
  }
  UNSAFE_componentWillMount() {
    const queryParamValue = queryString.parse(this.props.location.search);
    
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));

    this.setState({ username: queryParamValue.user || "" });

    if (!this.props.storedSamlPayload) {
      this.props.dispatchGetStoredSamlPayload(
        this.props.params.consortiumId,
        queryParamValue.id
      );
    }
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.pending === true) {
      this.props.showLoading(t("Retrieving your account information..."));
    } else if (
      !nextProps.pending &&
      this.props.pending &&
      !nextProps.lastRequestFailed &&
      nextProps.getUserSuccess
    ) {
      this.props.hideLoading();

      const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
        "/"
      );
      this.props.router.push(path);
    } else {
      this.props.hideLoading();
    }
  }

  routeToSsoRegister() {
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/sso/register"
    );
    this.props.router.push(path);
  }

  routeToForgotUsername() {
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/username-reminder"
    );
    this.props.router.push(path);
  }
  routeToForgotPassword() {
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/password-reset"
    );
    this.props.router.push(path);
  }
  onSubmit(formData) {
    this.props.samlUserSignIn(
      formData.username,
      formData.password,
      this.props.params.consortiumId,
      this.props.storedSamlPayload
    );
  }
  render() {
    return (
      <div className="flex-container">
        <div className="flex-content">
          <br />
          <SsoLinkForm
            t={t}
            onSubmit={this.onSubmit}
            disabled={this.props.pending}
            username={this.state.username}
            onRegisterClick={this.routeToSsoRegister}
          />          
          <Button onClick={this.routeToForgotPassword} color="secondary">
            {t("Forgot Password?")}
          </Button>
        </div>
        <div className="flex-tipBox" aria-label="Sign in Tip Box" role="region">
          <AlertInfo
            text={t("TIP_BOX_SSO_SIGN_IN")}
            location={this.props.location}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pending: !!state.httpRequests.getIn([AUTHENTICATE_USER, "pending"]),
    getUserSuccess:
      state.httpRequests.getIn([AUTHENTICATE_USER, "lastStatus"]) === SUCCESS,
    lastRequestFailed:
      state.httpRequests.getIn([AUTHENTICATE_USER, "lastStatus"]) === FAILURE,
    previousLocation: state.session.get("location"),
    pageTitle: state.session.get("pageData"),
    linkColor: state.style.getIn(["palette", "accent3Color"]),
    storedSamlPayload: state.user.get("storedSamlPayload"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoading: (titleText) => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
    samlUserSignIn: (username, password, consortiumId, storedSamlPayload) => {
      dispatch(
        samlUserSignInAction(
          username,
          password,
          consortiumId,
          storedSamlPayload
        )
      );
    },
    clearLocation: () => {
      dispatch(recordLocationAction(""));
    },
    dispatchSetPageInfo: (pageData) => {
      dispatch(setPageInfoAction(pageData));
    },
    dispatchGetStoredSamlPayload: (consortiumId, ssoRedisKey) => {
      dispatch(getStoredSamlPayloadAction(consortiumId, ssoRedisKey));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SsoLink)
);
