import { Map } from "immutable";

import { PENDING, SUCCESS, FAILURE } from "./../../api/actions/actionTypes";

// TODO3: Fix instead of ignoring the warning
const regexShouldCapture = new RegExp(
  // eslint-disable-next-line
  `\_(${PENDING}|${SUCCESS}|${FAILURE})$`,
  "i"
);

function getDefaultState() {
  return Map();
}

function getDefaultRequestItem() {
  return Map({
    pending: 0,
    lastStatus: ""
  });
}

export default function(state = getDefaultState(), action) {
  if (!action.type || typeof action.type !== "string") {
    return state;
  }

  if (!regexShouldCapture.test(action.type)) {
    return state;
  }

  const actionType = action.type.replace(regexShouldCapture, "");
  let actionData = state.get(actionType);
  if (!actionData) {
    actionData = getDefaultRequestItem();
  }

  if (action.type.endsWith(`_${PENDING}`)) {
    actionData = actionData
      .set("lastStatus", PENDING)
      .set("pending", actionData.get("pending") + 1);
  } else if (action.type.endsWith(`_${SUCCESS}`)) {
    actionData = actionData
      .set("lastStatus", SUCCESS)
      .set("pending", actionData.get("pending") - 1);
  } else if (action.type.endsWith(`_${FAILURE}`)) {
    actionData = actionData
      .set("lastStatus", FAILURE)
      .set("pending", actionData.get("pending") - 1);
  }

  return state.set(actionType, actionData);
}
