import { GET_CONFIG } from "./actionTypes";

let loadConfigAction = () => (dispatch, getState) => {
  const configData = window.APPLICATION_CONFIGURATION;

  dispatch({ type: GET_CONFIG, data: configData });

  return configData;
};

export { loadConfigAction };
