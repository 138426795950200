import React from "react";
import Formsy from "formsy-react";
import Button from "@material-ui/core/Button";
import ContactNameFormFields from "./../contact-info/ContactNameFormFields.jsx";
import AddressFormFields from "./../contact-info/AddressFormFields.jsx";
import UserAccountFields from "./UserAccountFields.jsx";
import { ResponsiveUtil } from "./../../utils/ResponsiveUtil";

const isMobile = ResponsiveUtil.isMobile();

const buttonStyle = {
  width: "100%",
  maxWidth: "none",
  marginTop: "10px",
  marginBottom: "10px"
};

class RegisterUserAccountForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      phone: "(  )    -    ",
      cellPhone: "(  )    -    ",
      mpayTextRemindersOptIn: "",
      email: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      username: "",
      password: "",
      passwordConfirm: "",
      valid: false
    };

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);

    this.onInput = this.onInput.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (this.props.contactInfo.has("name")) {
      this.setState({
        name: this.props.contactInfo.get("name"),
        phone: this.props.contactInfo.get("phone"),
        cellPhone: this.props.contactInfo.get("cellPhone"),
        mpayTextRemindersOptIn: this.props.contactInfo.get("mpayTextRemindersOptIn"),
        email: this.props.contactInfo.get("email"),
        address: this.props.contactInfo.get("address"),
        city: this.props.contactInfo.get("city"),
        state: this.props.contactInfo.get("state"),
        zip: this.props.contactInfo.get("zip")
      });
    }
  }
  onValidSubmit() {
    // Copy our form model and delete form-related properties
    // This is less ~effort~ than re-initializing an object with all the properties.
    const model = Object.assign({}, this.state);
    delete model.valid;

    this.props.onSubmit(model);
  }
  onValid() {
    this.setState({ valid: true });
  }
  onInvalid() {
    this.setState({ valid: false });
  }
  onInput(name) {
    return value => {
      let update = {};
      update[name] = value;
      this.setState(update);
    };
  }
  render() {
    return (
      <Formsy
        noValidate
        onValidSubmit={this.onValidSubmit}
        onValid={this.onValid}
        onInvalid={this.onInvalid}
      >
        <div role="form" aria-label="Register Account">
          <div>
            <h2>Account Info</h2>
            <UserAccountFields
              t={this.props.t}
              onInput={this.onInput}
              formData={this.state}
            />
            <div>
              <h2>Contact Info</h2>
              <ContactNameFormFields
                t={this.props.t}
                onInput={this.onInput}
                formData={this.state}
                requireEmail
                {...this.props.required}
                shouldFocusOnMount={false}
              />
              <AddressFormFields
                t={this.props.t}
                onInput={this.onInput}
                formData={this.state}
                requireEmail
                {...this.props.required}
              />
              <Button
                variant="contained"
                type="submit"
                disabled={!this.state.valid || this.props.disabled}
                style={isMobile ? buttonStyle : {}}
                color="primary"
              >
                {this.props.t("Register Account")}
              </Button>
            </div>
          </div>
        </div>
      </Formsy>
    );
  }
}

export default RegisterUserAccountForm;
