import { isImmutable, Map, List, fromJS, OrderedMap } from "immutable";
import {
  GET_STATEMENT,
  SELECT_STATEMENT_ACCOUNT,
  SUCCESS,
  FAILURE,
} from "../../api/actions/actionTypes";

const getDefaultState = () => {
  return Map({
    summaryItems: Map(),
    activityItems: List(),
    pageNumber: 1,
    lastPage: true,
    recentPayments: Map(),
    pendingPayments: Map(),
    recentlyDeletedPayments: Map(),
    recurringPayments: Map(),
  });
};

const actions = {};

actions[`${GET_STATEMENT}_${SUCCESS}`] = function (state, action) {
  const statementData = action.payload.body;
  const page = action.meta.pageNumber;

  let activityItems = fromJS(statementData.activityItems);
  let showFee = fromJS(statementData.showFee);
  let showEscrow = fromJS(statementData.showEscrow);

  // If we have summary items already, we won't request them again, so just use the ones in state.
  let summaryItems = state.get("summaryItems");
  if (statementData.summaryItems) {
    summaryItems = OrderedMap(statementData.summaryItems);
  }

  let lastPage = false;
  if (activityItems.size < 10) {
    lastPage = true;
  }

  return state
    .set("showFee", showFee)
    .set("showEscrow", showEscrow)
    .set("summaryItems", summaryItems)
    .set("activityItems", activityItems)
    .set("pageNumber", page)
    .set("lastPage", lastPage);
};

actions[`${GET_STATEMENT}_${FAILURE}`] = function (state, action) {
  return state.set("lastPage", true);
};

actions[SELECT_STATEMENT_ACCOUNT] = function (state, action) {
  const bankNumber = action.meta.bankNumber;
  const companyNumber = action.meta.companyNumber;
  const selectedAccount = isImmutable(action.payload)
    ? action.payload
    : fromJS(action.payload);

  const accountWithBankCompany = selectedAccount
    .set("bankNumber", bankNumber)
    .set("companyNumber", companyNumber);

  return state
    .set("selectedAccount", accountWithBankCompany)
    .set("summaryItems", Map())
    .set("activityItems", List())
    .set("pageNumber", 1);
};

export default function (state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  return actions[action.type](state, action);
}
