import http from "./../utils/http";

export const getAccount = (
  bankNo,
  companyNo,
  accountNumber,
  secondaryValidation
) => {
  const req = http
    .get(`/api/v1/fis/${bankNo}/bcs/${companyNo}/accounts`)
    .query({ account_number: accountNumber, expand: "payment_cooldown" });

  if (secondaryValidation !== null) {
    return req.query({ secondary_validation: secondaryValidation }).end();
  }

  return req.end();
};

export const addUserAccountsRequest = (consortiumId, userId, account) => {
  return http
    .post(`/api/v1/consortiums/${consortiumId}/users/${userId}/accounts`)
    .send(account)
    .end();
};

export const deleteUserAccountsRequest = (consortiumId, userId, accountId) => {
  return http
    .delete(
      `/api/v1/consortiums/${consortiumId}/users/${userId}/user-accounts/${accountId}`
    )
    .end();
};
