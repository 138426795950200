import moment from "moment-timezone";

export function updateTimezoneFormatted(date) {
  return moment(date).tz("America/Detroit").format();
}

export function getCurrentDateTimeFormatted() {
  return moment().tz("America/Detroit").format();
}

export function getCurrentDateTime() {
  return moment().tz("America/Detroit");
}

export function isToday(date) {
  return moment().isSame(date, "d");
}

export function isWeekend(date) {
  return date.isoWeekday() === 6 || date.isoWeekday() === 7;
}

export const isHoliday = function (date, holidays) {
  return !!holidays.find((h) => date.isSame(h, "day"));
};

function isValidCcPaymentDate(paymentDate) {
  // You can always make a same day credit card payment - even on weekends and non-business days
  if (isToday(paymentDate)) {
    return true;
  }

  if (isWeekend(paymentDate)) {
    return false;
  }

  return true;
}

function isValidAchPaymentDate(paymentDate, nextPostDate, eodRunHour) {
  if (isWeekend(paymentDate)) {
    return false;
  }
  if (paymentDate.isBefore(nextPostDate)) {
    return false;
  }

  let currentTime = getCurrentDateTime();
  let eodRunTime = moment().set({
    year: currentTime.get("year"),
    month: currentTime.get("month"),
    date: currentTime.get("date"),
    hour: eodRunHour,
    minute: 0,
    second: 0,
  });

  // Users should not be able to make payments for the next available business day if the current time in our timezone is after the start
  // of their FIs EOD run. If it as after there EOD run then payment date needs to be bumped out two business days.
  // In order to mitigate/prevent this, there is a 15 minute cutoff on the front end that will prevent users from slecting the next business day for their payment if they
  // login to make a payment within 15 minutes of their run.
  // There is also a server side check that will prevent users from selecting the next business day for their payment if there run has already started.
  if (
    moment(currentTime).isBefore(eodRunTime) &&
    moment(paymentDate.format("YYYY-MM-DD")).isSame(
      nextPostDate.format("YYYY-MM-DD")
    ) &&
    !moment(currentTime).add(15, "minutes").isBefore(eodRunTime)
  ) {
    return false;
  }

  return true;
}

// Material UI has a "shouldDisableDate" callback available. This is the inverse - returns true if the provided date is valid.
// todo1 - should holidays be part of this check? Or a separate one?
export function isValidPaymentDate(
  paymentDate,
  nextPostDate,
  paymentType,
  eodRunHour
) {
  switch (paymentType) {
    case "cc":
      return isValidCcPaymentDate(paymentDate);
    case "ach":
      return isValidAchPaymentDate(paymentDate, nextPostDate, eodRunHour);
    default:
      console.log(`[${paymentType}] is not a supported payment type.`);
      return true;
  }
}
