import React from "react";
import AlertInfo from "./../errors/AlertInfo.jsx";
import Marked from "./../../utils/MarkedUtil";
import { Button } from "@material-ui/core";

export default class CheckoutConsent extends React.Component {
  render() {
    const style = { marginBottom: "0px" };

    return (
      <div aria-label="Confirm Payment Tip Box" role="region">
        <AlertInfo style={style}>
          <span
            dangerouslySetInnerHTML={{
              __html: Marked()(
                this.props.isAutoPay
                  ? this.props.t("TIP_BOX_CONFIRM_AUTO_PAY")
                  : this.props.t("TIP_BOX_CONFIRM_PAYMENT")
              ),
            }}
          />
          <div style={{ textAlign: "center" }}>
            <Button
              color="primary"
              onClick={() => this.props.onViewTermsClick()}
            >
              {this.props.t("Click here to view the Terms and Conditions")}
            </Button>
          </div>
        </AlertInfo>
      </div>
    );
  }
}
