import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  showLoadingAction,
  hideLoadingAction
} from "./../../api/actions/loading";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { registerUserAccountAction } from "./../../api/actions/user";
import { recordLocationAction } from "./../../api/actions/session";
import { PENDING, FAILURE } from "./../../api/actions/actionTypes";
import { setPageInfoAction } from "./../../api/actions/pageInfo";
import RegisterUserAccountForm from "./RegisterUserAccountForm.jsx";
import RouteHelper from "./../../utils/RouteHelper";
import { _t } from "./../../text/locale";
let t;
let pageTitle = "Register";

class ContactInfo extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.pending === true) {
      this.props.showLoading(t("Creating a user account..."));
    } else if (
      !nextProps.pending &&
      this.props.pending &&
      !nextProps.lastRequestFailed
    ) {
      this.props.hideLoading();

      let previousLocation = { pathname: this.props.previousLocation };

      if (this.props.previousLocation.includes("lookup-accounts")) {
        const accountsPath = RouteHelper.getRelativeCustomerRoute(
          previousLocation
        )("/accounts/select");
        this.props.router.push(accountsPath);
        return;
      } else if (
        this.props.previousLocation.includes("add-new-ach") ||
        this.props.previousLocation.includes("add-new-card")
      ) {
        const selectPaymentPath = RouteHelper.getRelativeCustomerRoute(
          previousLocation
        )("/payment/select-method");
        this.props.router.push(selectPaymentPath);
        return;
      } else if (this.props.previousLocation) {
        this.props.clearLocation();
        this.props.router.push(this.props.previousLocation);
        return;
      }

      const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
        "/"
      );
      this.props.router.push(path);
    } else {
      this.props.hideLoading();
    }
  }
  onSubmit(formData) {
    this.props.registerUser(formData, this.props.params.consortiumId);
  }
  render() {
    return (
      <div className="flex-container">
        <div className="flex-content">
          <RegisterUserAccountForm
            onSubmit={this.onSubmit}
            t={t}
            required={this.props.required}
            disabled={this.props.pending}
            contactInfo={this.props.contactInfo}
          />
        </div>
        <div
          className="flex-tipBox"
          aria-label="Register Account Tip Box"
          role="region"
        >
          <AlertInfo
            text={t("TIP_BOX_REGISTER")}
            location={this.props.location}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pending: state.user.get("registrationStatus") === PENDING,
    lastRequestFailed: state.user.get("registrationStatus") === FAILURE,
    required: state.consortium.get("newUserRequirements").toJS(),
    contactInfo: state.user.get("contact"),
    previousLocation: state.session.get("location")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    registerUser: (contactInfo, consortiumId) => {
      dispatch(registerUserAccountAction(contactInfo, consortiumId));
    },
    showLoading: titleText => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
    clearLocation: () => {
      dispatch(recordLocationAction(""));
    },
    dispatchSetPageInfo: pageData => {
      dispatch(setPageInfoAction(pageData));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContactInfo)
);
