import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow
} from "@material-ui/core";
import PaymentHistoryItem from "./PaymentHistoryItem.jsx";
import PaymentHistoryCard from "./PaymentHistoryCard.jsx";
import { ResponsiveUtil } from "./../../utils/ResponsiveUtil";

export default class PaymentHistoryTable extends React.Component {
  render() {
    let isMobile = ResponsiveUtil.isMobile();
    let hideCancelColumn = this.props.hideCancel;
    const paymentItems = this.props.paymentHistoryItems.map((p, index) => (
      <PaymentHistoryItem
        key={index}
        paymentItem={p}
        styles={this.props.styles}
        t={this.props.t}
        onCancelItem={this.props.onCancelItem}
        hideCancel={this.props.hideCancel}
      />
    ));

    const paymentItemCards = this.props.paymentHistoryItems.map((p, index) => {
      return (
        <PaymentHistoryCard
          key={index}
          paymentItem={p}
          t={this.props.t}
          styles={this.props.styles}
          onCancelItem={this.props.onCancelItem}
        />
      );
    });

    return (
      <div>
        {!isMobile ? (
          <Table aria-label="Payment History">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ color: this.props.styles.get("textColor") }}
                >
                  {this.props.t("Status")}
                </TableCell>
                <TableCell
                  style={{ color: this.props.styles.get("textColor") }}
                >
                  {this.props.t("Post Date")}
                </TableCell>
                <TableCell
                  style={{ color: this.props.styles.get("textColor") }}
                >
                  {this.props.t("Amount")}
                </TableCell>
                <TableCell
                  style={{ color: this.props.styles.get("textColor") }}
                >
                  {this.props.t("Fee")}
                </TableCell>
                <TableCell
                  style={{ color: this.props.styles.get("textColor") }}
                >
                  {this.props.t("Confirmation")}
                </TableCell>
                <TableCell
                  style={{ color: this.props.styles.get("textColor") }}
                >
                  {this.props.t("Frequency")}
                </TableCell>
                {hideCancelColumn ? null : (
                  <TableCell
                    aria-label="Cancel Pending Payment"
                    style={{ color: this.props.styles.get("textColor") }}
                  />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentItems}
              {/* a horizontal table looks bad on mobile, so a second vertically stacked layout is inserted while the horizontal layout above is hidden. */}
            </TableBody>
          </Table>
        ) : (
          <div>{paymentItemCards}</div>
        )}
      </div>
    );
  }
}
