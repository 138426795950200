import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '73fe6d0b-0209-4928-a180-f4297188b5d7',
    clientToken: 'pub99cc0f863bcd8ea472dcc7631f1e0311',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'mpay',
    env: 'prd',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

ReactDOM.render(<App />, document.getElementById("root"));
