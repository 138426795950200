import React from "react";
import Cleave from "cleave.js/dist/cleave-react.min";
import { withFormsy } from "formsy-react";
import { TextField } from "@material-ui/core";

class CreditCardFormatterInput extends React.Component {
  render() {
    const {
      inputRef,
      onCreditCardTypeChanged,
      onChange,
      value,
      ...otherProps
    } = this.props;
    return (
      <Cleave
        {...otherProps}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        options={{
          creditCard: true,
          creditCardStrictMode: true,
          onCreditCardTypeChanged: onCreditCardTypeChanged
        }}
        onChange={e => onChange(e.currentTarget.value)}
        value={value}
      />
    );
  }
}

class FormsyCreditCardInternal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { touched: false };
    this.onBlur = this.onBlur.bind(this);
  }
  onBlur() {
    this.setState({ touched: true });
  }
  render() {
    return (
      <TextField
        id={this.props.name}
        name="cardNumber"
        label={this.props.floatingLabelText}
        error={this.state.touched && !this.props.isValid()}
        helperText={
          this.state.touched && !this.props.isValid()
            ? this.props.getErrorMessage()
            : null
        }
        onBlur={this.onBlur}
        fullWidth
        style={{ bottom: "10px" }}
        value={this.props.value}
        InputProps={{ inputComponent: CreditCardFormatterInput }}
        // "inputProps" gives the props object to the inputComponent of "InputProps". It is not a duplicate prop. Thank material-ui for the terrible naming.
        // eslint-disable-next-line
        inputProps={{
          onChange: this.props.onChange,
          value: this.props.value,
          onCreditCardTypeChanged: type => {
            this.props.setCardType(type);
          }
        }}
      />
    );
  }
}

const HOCFormsyCreditCard = withFormsy(FormsyCreditCardInternal);

// The HOC function causes validations to exist on the component.
class FormsyCreditCard extends React.Component {
  render() {
    return (
      <HOCFormsyCreditCard
        {...this.props}
        validations={{
          isCreditCard: true,
          isAcceptedType: this.props.acceptedCardTypes
        }}
        validationErrors={{
          isDefaultRequiredValue: this.props.t("Card Number is required"),
          isCreditCard: this.props.t("Please enter a valid card number"),
          isAcceptedType: this.props.t(
            "We do not accept the provided card type"
          )
        }}
      />
    );
  }
}

export default FormsyCreditCard;
