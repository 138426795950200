import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router";
import { setPageInfoAction } from "./../../../api/actions/pageInfo";
import RouteHelper from "./../../../utils/RouteHelper";

import { List } from "immutable";

import { initPasswordReset } from "./../../../api/actions/user-accounts/passwordReset";

import PasswordResetEmail from "./PasswordResetEmail.jsx";
import PasswordToken from "./PasswordToken.jsx";
import PasswordChange from "./PasswordChange.jsx";
import PasswordResetComplete from "./PasswordResetComplete.jsx";

import { ResponsiveUtil } from "../../../utils/ResponsiveUtil";

import { Step, Stepper, StepLabel, StepConnector } from "@material-ui/core";

import { _t } from "./../../../text/locale";

let t;
let pageTitle = "Reset Password";

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.restart = this.restart.bind(this);
    this.jump = this.jump.bind(this);
    this.onNext = this.onNext.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.props.initPasswordReset();
    t = _t(this.props.params.accessNumber);

    this.props.dispatchSetPageInfo(t(pageTitle));
    if (this.props.params.resetToken) {
      this.jump(2);
      return;
    }

    if (
      !this.props.location.query.step &&
      this.props.location.query.step !== 0
    ) {
      this.jump(1);
      return;
    }
  }
  restart() {
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/password-reset"
    );
    this.props.router.push({
      pathname: path,
      query: {
        step: 1
      }
    });
  }
  jump(step) {
    this.props.router.push({
      ...this.props.location,
      query: {
        ...this.props.location.query,
        step: step
      }
    });
  }
  onNext() {
    this.props.router.push({
      ...this.props.location,
      query: {
        ...this.props.location.query,
        step: this.props.location.query.step * 1 + 1
      }
    });
  }
  render() {
    const steps = List.of(
      <PasswordResetEmail
        t={t}
        consortiumId={this.props.params.consortiumId}
        onNext={this.onNext}
      />,
      <PasswordToken
        t={t}
        resetToken={this.props.params.resetToken}
        consortiumId={this.props.params.consortiumId}
        onNext={this.onNext}
        onRestart={this.restart}
      />,
      <PasswordChange
        t={t}
        consortiumId={this.props.params.consortiumId}
        onNext={this.onNext}
      />,
      <PasswordResetComplete t={t} onSubmit={this.onNext} />
    );
    const stepLabels = [
      t("Send reset email"),
      t("Enter Reset Token"),
      t("Set a New Password"),
      t("Done")
    ];

    const step = this.props.location.query.step * 1;

    const isMobile = ResponsiveUtil.isMobile();
    return (
      <div className="flex-container">
        <div className="flex-content">
          <Stepper
            activeStep={step - 1}
            orientation={isMobile ? "vertical" : "horizontal"}
            connector={<StepConnector />}
          >
            {stepLabels.map(labelText => (
              <Step key={labelText}>
                <StepLabel>{labelText}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {steps.get(step - 1)}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    initPasswordReset: () => {
      dispatch(initPasswordReset());
    },
    dispatchSetPageInfo: pageInfoData => {
      dispatch(setPageInfoAction(pageInfoData));
    }
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(PasswordReset)
);
