import React from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";

import { TableRow, TableCell } from "@material-ui/core";
import { formatPaymentAmount } from "./../../utils/FormatUtil";

export default class ManageRecurringItem extends React.Component {
  render() {
    const paymentItem = this.props.paymentItem;
    let cancelColumn = null;

    const formattedAmount = formatPaymentAmount(paymentItem.get("amount"));
    const formattedFee = formatPaymentAmount(paymentItem.get("feeAmount"));
    const formattedDate = moment(paymentItem.get("postDate")).format("L");
    if (!this.props.hideCancel) {
      if (paymentItem.get("status") === "Pending") {
        cancelColumn = (
          <TableCell>  
            <Button
              onClick={() => this.props.deleteOnClick(paymentItem)}
              color="secondary"
              >{this.props.t("Cancel")}</Button>
          </TableCell>
        );
      } else {
        cancelColumn = <TableCell />;
      }
    }
    return (
      <TableRow>
        <TableCell>{paymentItem.get("frequency")}</TableCell>
        <TableCell>{formattedDate}</TableCell>
        <TableCell>${formattedAmount}</TableCell>
        <TableCell>${formattedFee}</TableCell>
        <TableCell>{paymentItem.get("transactionNumber")}</TableCell>
        {cancelColumn}
      </TableRow>
    );
  }
}
