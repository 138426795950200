import http from "./../utils/http";

export const getAuthorizationToken = consortiumId => {
  return http
    .post(`/api/v1/consortiums/${consortiumId}/authenticated-users`)
    .send({ username: "guest" })
    .end();
};

export const getSsoAuthorizationToken = (consortiumId, accessTokenId, ssoType) => {
  return http
    .get(`/api/v1/consortiums/${consortiumId}/authenticated-users/${encodeURI(accessTokenId)}/sso/${ssoType}`)
    .end();
};