import store from "./../../redux/store";
import http from "./../utils/http";

export const getCardFee = (accessNumber, isDebit, amount, cardNetwork) => {
  const state = store.getState();
  const bc = state.consortium.getIn(["customers", accessNumber]);
  const bankNumber = bc.get("bankNumber");
  const companyNumber = bc.get("companyNumber");
  const mpayConvenienceFee = {
    bankNumber: bankNumber,
    companyNumber: companyNumber,
    amount: amount,
    isDebit: isDebit,
    cardNetwork: cardNetwork,
    mpayPaymentType: "CreditCard",
  };

  return http
    .post(`/api/v1/fis/${bankNumber}/bcs/${companyNumber}/convenience-fees`)
    .send(mpayConvenienceFee)
    .end();
};

export const getAchFee = (accessNumber, amount) => {
  const state = store.getState();
  const bc = state.consortium.getIn(["customers", accessNumber]);
  const bankNumber = bc.get("bankNumber");
  const companyNumber = bc.get("companyNumber");
  const mpayConvenienceFee = {
    bankNumber: bankNumber,
    companyNumber: companyNumber,
    amount: amount,
    mpayPaymentType: "Ach",
  };

  return http
    .post(`/api/v1/fis/${bankNumber}/bcs/${companyNumber}/convenience-fees`)
    .send(mpayConvenienceFee)
    .end();
};

export const getCardToken = (accessNumber, cardNumber) => {
  const state = store.getState();
  const tokenUrl = state.config.get("tokenGatewayBaseUrl");
  const bc = state.consortium.getIn(["customers", accessNumber]);
  const bankNumber = bc.get("bankNumber");
  const companyNumber = bc.get("companyNumber");

  const authToken = state.auth.get("authToken");

  const formattedCardNumber = cardNumber.replace(/\s+/g, ""); // Remove spaces from card number

  const cardData = {
    value: formattedCardNumber,
    type: "creditcard",
  };

  // TODO3: For unknown reasons, using just http.post(url) doesn't work. It doesn't even send the request. We should eventually use that instead.
  return (
    http
      .rawAgent()
      .post(
        `${tokenUrl}/api/v1/fis/${bankNumber}/bcs/${companyNumber}/tokens?expand=metadata`
      )
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${authToken}`)
      // It's possible for us to get back a partial success from the token endpoint, where we fail to get card metadata (the metadata body will have an error on it).
      // In this case, we are going to treat the entered card as a credit card since there are rare cases where a card number can
      // exist without metadata.
      .ok((res) => res.status === 200 && res.body.metadata)
      .send(cardData)
      .end()
  );
};

export const getAchToken = (accessNumber, value, type) => {
  const state = store.getState();
  const tokenUrl = state.config.get("tokenGatewayBaseUrl");

  const bc = state.consortium.getIn(["customers", accessNumber]);
  const bankNumber = bc.get("bankNumber");
  const companyNumber = bc.get("companyNumber");

  const authToken = state.auth.get("authToken");

  const cardData = {
    value: value,
    type: type,
  };

  // TODO3: For unknown reasons, using just http.post(url) doesn't work. It doesn't even send the request. We should eventually use that instead.
  return http
    .rawAgent()
    .post(`${tokenUrl}/api/v1/fis/${bankNumber}/bcs/${companyNumber}/tokens`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${authToken}`)
    .send(cardData)
    .end();
};

export const submitPayment = (paymentData, bankNumber, companyNumber) => {
  return http
    .post(`/api/v1/fis/${bankNumber}/bcs/${companyNumber}/payments`)
    .send(paymentData)
    .end();
};
