import React, { Component } from "react";
import moment from "moment";
import { _t } from "./../../text/locale";
import { Table, TableCell, TableRow, TableBody } from "@material-ui/core";
import CircularProcess from "@material-ui/core/CircularProgress";

const t = _t();

class PaymentConfirmationTable extends Component {
  render() {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>{t("Account Number")}</b>
            </TableCell>
            <TableCell className="text-right">
              {this.props.account.get("accountNumber")}
            </TableCell>
          </TableRow>
          {this.props.depositAccountName ? (
            <TableRow>
              <TableCell>
                <b>{t("Pay To")}</b>
              </TableCell>
              <TableCell className="text-right">
                {this.props.depositAccountName}
              </TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableCell>
              <b>{t("Payment Date")}</b>
            </TableCell>
            <TableCell className="text-right">
              {moment(this.props.payment.get("date")).format("L")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>{t("Frequency")}</b>
            </TableCell>
            <TableCell>
              {this.props.frequency === "Never"
                ? "One-Time"
                : this.props.frequency}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>{t("Amount Due")}</b>
            </TableCell>
            <TableCell className="text-right">
              {this.props.amount.format("$0.00")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>{t("Convenience Fee")}</b>
            </TableCell>
            <TableCell className="text-right">
              {this.props.feePending ? (
                <span>
                  <CircularProcess
                    size={18}
                    thickness={1.5}
                    style={{ verticalAlign: "bottom" }}
                  />{" "}
                  {t("Calculating...")}
                </span>
              ) : (
                this.props.fee.format("$0.00")
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>{t("Total")}</b>
            </TableCell>
            <TableCell className="text-right">
              {this.props.total.format("$0.00")}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export default PaymentConfirmationTable;
