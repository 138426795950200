import http from "../utils/http";

export const getAutoPayEnrollment = (
  consortiumId,
  userIdo,
  bankNumber,
  companyNumber,
  billingAccountNumber
) => {
  return http
    .get(
      `/api/v1/consortiums/${consortiumId}/users/${userIdo}/auto-pay-enrollments/${billingAccountNumber}`
    )
    .query({ bankNumber: bankNumber, companyNumber: companyNumber })
    .end();
};

export const deactivateAutoPayEnrollment = (
  consortiumId,
  userIdo,
  bankNumber,
  companyNumber,
  billingAccountNumber
) => {
  return http
    .delete(
      `/api/v1/consortiums/${consortiumId}/users/${userIdo}/auto-pay-enrollments/${billingAccountNumber}`
    )
    .query({ bankNumber: bankNumber, companyNumber: companyNumber })
    .end();
};

export const submitAutoPayEnrollment = (autoPayEnrollmentData) => {
  const consortiumId = autoPayEnrollmentData.consortiumId;
  const userId = autoPayEnrollmentData.userId;
  const accountNumber = autoPayEnrollmentData.accountNumber;

  return http
    .post(
      `/api/v1/consortiums/${consortiumId}/users/${userId}/auto-pay-enrollments/${accountNumber}`
    )
    .send(autoPayEnrollmentData)
    .end();
};
