import React from "react";
import { withFormsy } from "formsy-react";
import { TextField } from "@material-ui/core";
import MaskedInput from "react-text-mask";

function PhoneFormatterInput(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
    />
  );
}

class InternalFormsyPhoneNumber extends React.Component {
  constructor(props) {
    super(props);

    this.state = { touched: false };

    this.onBlur = this.onBlur.bind(this);
  }
  onBlur() {
    this.setState({ touched: true });
  }
  render() {
    return (
      <TextField
        id={this.props.name}
        type="text"
        name={this.props.name}
        fullWidth={true}
        disabled={this.props.disabled}
        label={this.props.floatingLabelText}
        error={this.state.touched && !this.props.isValid()}
        helperText={
          this.state.touched && !this.props.isValid()
            ? this.props.getErrorMessage()
            : null
        }
        onBlur={this.onBlur}
        style={{ bottom: "10px" }}
        value={this.props.value}
        onChange={(e) => this.props.onChange(e.currentTarget.value)}
        required={this.props.required}
        InputProps={{
          inputComponent: PhoneFormatterInput,
        }}
      />
    );
  }
}

const HOCFormsyPhoneNumber = withFormsy(InternalFormsyPhoneNumber);

// We have to wrap our component if we to be able to force validations to always be "isMoney".
// The HOC function causes validations to exist on the component.
class FormsyPhoneNumber extends React.Component {
  render() {
    return (
      <HOCFormsyPhoneNumber
        {...this.props}
        validations="isPhoneNumber"
        validationErrors={{
          isDefaultRequiredValue: this.props.t("Phone Number is required"),
          isPhoneNumber: this.props.t("Please enter a valid phone number"),
        }}
      />
    );
  }
}
class FormsyMobileNumber extends React.Component {
  render() {
    return (
      <HOCFormsyPhoneNumber
        {...this.props}
        validations="isPhoneNumber"
        validationErrors={{
          isDefaultRequiredValue: false,
          isPhoneNumber: this.props.t("Please enter a valid phone number"),
        }}
      />
    );
  }
}

export { FormsyPhoneNumber, FormsyMobileNumber };

