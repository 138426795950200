import React from "react";
import { Button, Grid } from "@material-ui/core";
import ManageRecurringTable from "./ManageRecurringTable.jsx";
import {
  GET_RECURRING_PAYMENTS,
  CANCEL_PAYMENT,
  FAILURE
} from "./../../api/actions/actionTypes";
import {
  getRecurringPaymentsAction,
  cancelPaymentAndUpdateHistoryAction
} from "./../../api/actions/manageRecurring";
import { showChoiceNotificationAction } from "./../../api/actions/notification";
import {
  showLoadingAction,
  hideLoadingAction
} from "./../../api/actions/loading";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { _t } from "./../../text/locale";
import AlertWarning from "./../errors/AlertWarning.jsx";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { setPageInfoAction } from "./../../api/actions/pageInfo";

import BackButton from "../../components/BackButton.jsx";

let t;
let pageTitle = "Recurring Payments";
class RecurringPaymentAccount extends React.Component {
  constructor(props) {
    super(props);

    this.deletePaymentItem = this.deletePaymentItem.bind(this);
    this.moveToNextPage = this.moveToNextPage.bind(this);
    this.moveToPreviousPage = this.moveToPreviousPage.bind(this);
    this.loadRecurringPayments = this.loadRecurringPayments.bind(this);
    this.renderRecurringPaymentItems = this.renderRecurringPaymentItems.bind(
      this
    );
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
    this.props.showLoading(t("Retrieving recurring payments..."));
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.loadRetrieving === true) {
      this.props.showLoading(t("Retrieving recurring payments..."));
    } else if (
      !nextProps.loadDeleting &&
      this.props.loadDeleting &&
      !nextProps.lastDeletingFailed
    ) {
      this.loadRecurringPayments(
        this.props.selectedAccount,
        this.props.userId,
        this.props.params.consortiumId,
        1
      );
    } else if (nextProps.loadDeleting === true) {
      this.props.showLoading(t("Deleting payment..."));
    } else {
      this.props.hideLoading();
    }
  }
  deletePaymentItem(paymentItem) {
    this.props.showChoiceNotificationAction(
      {
        title: "Cancel Payment",
        message: `Do you want to cancel this recurring payment?`
      },
      {
        yes: () => {
          this.props.deleteRecurringPayment(
            paymentItem.get("bankNumber"),
            paymentItem.get("companyNumber"),
            paymentItem.get("transactionNumber")
          );
        },
        no: () => {
          /*noop*/
        }
      }
    );
  }
  loadRecurringPayments(account, userId, consortiumId, page) {
    this.props.getRecurringPayments(
      consortiumId,
      userId,
      account.get("bankNumber"),
      account.get("companyNumber"),
      account.get("accountNumber"),
      page
    );
  }
  moveToNextPage(account, userId, consortiumId, page) {
    this.props.getRecurringPayments(
      consortiumId,
      userId,
      account.get("bankNumber"),
      account.get("companyNumber"),
      account.get("accountNumber"),
      page + 1
    );
  }
  moveToPreviousPage(account, userId, consortiumId, page) {
    this.props.getRecurringPayments(
      consortiumId,
      userId,
      account.get("bankNumber"),
      account.get("companyNumber"),
      account.get("accountNumber"),
      page - 1
    );
  }
  renderRecurringPaymentItems(
    recurringPaymentItems,
    selectedAccount,
    userId,
    consortiumId,
    pageNumber,
    firstPage,
    lastPage
  ) {
    if (recurringPaymentItems.length === 0) {
      return (
        <Grid item xs={12} sm={8}>
          <div aria-label="Recurring Payments Warning Box" role="region">
            <AlertWarning>
              <strong>{t("No recurring payments were found.")}&nbsp;</strong>
              <br className="visible-xs" />
            </AlertWarning>
          </div>
        </Grid>
      );
    }

    return (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <div aria-label="Recurring Payments Warning Box" role="region">
            <AlertInfo
              text={t("TIP_BOX_RECURRING_PAYMENTS")}
              location={this.props.location}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <ManageRecurringTable
            paymentHistoryItems={recurringPaymentItems}
            t={t}
            deleteOnClick={this.deletePaymentItem}
            styles={this.props.styles}
          />
          <br />
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.moveToPreviousPage(
                  selectedAccount,
                  userId,
                  consortiumId,
                  pageNumber
                );
              }}
              disabled={firstPage}
            >
              {t("Previous")}
            </Button>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <h3 className="pagination">Page {pageNumber}</h3>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.moveToNextPage(
                  selectedAccount,
                  userId,
                  consortiumId,
                  pageNumber
                );
              }}
              disabled={lastPage}
            >
              {t("Next")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  render() {
    return (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <div role="navigation" aria-label="Go Back">
            <BackButton
              route="/user/recurring/select"
              currentLocation={this.props.location}
              backText={t("Account Select")}
              shouldFocusOnMount
            />
          </div>
          <h2>
            {t(
              `Recurring Payments for ${this.props.selectedAccount.get(
                "formattedAccountNumber"
              )}`
            )}
          </h2>
          {this.renderRecurringPaymentItems(
            this.props.recurringPaymentItems,
            this.props.selectedAccount,
            this.props.userId,
            this.props.params.consortiumId,
            this.props.pageNumber,
            this.props.firstPage,
            this.props.lastPage
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    recurringPaymentItems: state.manageRecurring.get("items"),
    firstPage: state.manageRecurring.get("pageNumber") === 1,
    lastPage: state.manageRecurring.get("lastPage"),
    pageNumber: state.manageRecurring.get("pageNumber"),
    selectedAccount: state.manageRecurring.get("selectedAccount"),
    loadDeleting: !!state.httpRequests.getIn([CANCEL_PAYMENT, "pending"]),
    lastDeletingFailed:
      state.httpRequests.getIn([CANCEL_PAYMENT, "lastStatus"]) === FAILURE,
    loadRetrieving: !!state.httpRequests.getIn([
      GET_RECURRING_PAYMENTS,
      "pending"
    ]),
    lastRetrievingFailed:
      state.httpRequests.getIn([GET_RECURRING_PAYMENTS, "lastStatus"]) ===
      FAILURE,
    userId: state.user.get("id"),
    styles: state.style.get("palette")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRecurringPayments: (
      consortiumId,
      userIdo,
      bankNumber,
      companyNumber,
      accountNumber,
      page
    ) => {
      dispatch(
        getRecurringPaymentsAction(
          consortiumId,
          userIdo,
          bankNumber,
          companyNumber,
          accountNumber,
          page
        )
      );
    },
    deleteRecurringPayment: (bankNumber, companyNumber, transactionNumber) => {
      dispatch(
        cancelPaymentAndUpdateHistoryAction(
          bankNumber,
          companyNumber,
          transactionNumber
        )
      );
    },
    showChoiceNotificationAction: (content, callbacks) => {
      dispatch(showChoiceNotificationAction(content, callbacks));
    },
    showLoading: titleText => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
    dispatchSetPageInfo: pageInfoData => {
      dispatch(setPageInfoAction(pageInfoData));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecurringPaymentAccount)
);
