import React, { Component } from "react";

import { _t } from "./../../text/locale";
const t = _t();

class LoadFailure extends Component {
  render() {
    return (
      <div>
        <h2>{t("Failed to retrieve customer information.")}</h2>
        <span>
          {t(
            "Please try reloading the webpage. If the issue persists, contact customer service at csr@magicwrighter.com"
          )}
        </span>
      </div>
    );
  }
}

export default LoadFailure;
