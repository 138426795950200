import { _t } from "./../text/locale";
import React from "react";
import { connect } from "react-redux";
import { ResponsiveUtil } from "../utils/ResponsiveUtil";
import sanitizeHtml from "sanitize-html";
import { setPageInfoAction } from "../api/actions/pageInfo";
import BackButton from "./../components/BackButton.jsx";

let pageTitle = "Privacy Policy";
const t = _t();

const mobileStyle = {
  width: "100%",
  maxWidth: "none"
};
const style = {
  marginTop: "10px"
};
const isMobile = ResponsiveUtil.isMobile();

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);

    this.getContent = this.getContent.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.props.dispatchSetPageInfo(pageTitle);
  }
  getContent() {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(t("PRIVACY_POLICY_CONTENT"))
        }}
      />
    );
  }
  render() {
    return (
      <div>
        <div role="navigation" aria-label="Go Back">
          <BackButton
            route="/"
            currentLocation={this.props.location}
            backText={t("Home")}
            shouldFocusOnMount
          />
        </div>
        <div className="flex-container" style={isMobile ? mobileStyle : style}>
          {this.getContent()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetPageInfo: pageData => {
      dispatch(setPageInfoAction(pageData));
    }
  };
};

export default connect(null, mapDispatchToProps)(PrivacyPolicy);
