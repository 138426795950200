import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AlertInfo from "./../../errors/AlertInfo.jsx";

import PasswordChangeForm from "./../password-reset/PasswordChangeForm.jsx";

import { changeUserPassword } from "./../../../api/actions/user-accounts/passwordChange";
import {
  showLoadingAction,
  hideLoadingAction
} from "./../../../api/actions/loading";
import {
  USER_PASSWORD_UPDATE,
  FAILURE
} from "./../../../api/actions/actionTypes";
import { setPageInfoAction } from "./../../../api/actions/pageInfo";

import RouteHelper from "./../../../utils/RouteHelper";

import { _t } from "./../../../text/locale";

let t;
let pageTitle = "Password Change";
class UserPasswordChange extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (!nextProps.pending && this.props.pending) {
      this.props.hideLoading();

      if (!nextProps.lastRequestFailed) {
        const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
          "/"
        );
        this.props.router.push(path);
      }
    }
  }
  onSubmit(model) {
    this.props.showLoading(t("Changing Password..."));
    this.props.submitPasswordChange(this.props.params.consortiumId, model);
  }
  render() {
    return (
      <div>
        <div className="flex-container">
          <div className="flex-content">
            <p style={{ marginTop: "10px" }}>
              {t(
                "Please enter a new password. Passwords must be at least 8 characters long and contain a lowercase letter, an uppercase letter, and a number or symbol."
              )}
            </p>
            <PasswordChangeForm
              t={t}
              includeCurrentPassword
              onSubmit={this.onSubmit}
              disabled={this.props.pending}
            />
          </div>
          <div
            className="flex-tipBox"
            aria-label="Password Tip Box"
            role="region"
          >
            <AlertInfo
              text={t("TIP_BOX_PASSWORD_CHANGE")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pending: !!state.httpRequests.getIn([USER_PASSWORD_UPDATE, "pending"]),
    lastRequestFailed:
      state.httpRequests.getIn([USER_PASSWORD_UPDATE, "lastStatus"]) === FAILURE
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showLoading: titleText => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: titleText => {
      dispatch(hideLoadingAction());
    },
    submitPasswordChange: (consortiumId, password) => {
      dispatch(changeUserPassword(consortiumId, password));
    },
    dispatchSetPageInfo: pageInfoData => {
      dispatch(setPageInfoAction(pageInfoData));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserPasswordChange)
);
