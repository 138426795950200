export function isValidRoutingNumber(value) {
  // accept only spaces, digits and dashes
  if (/[^0-9 -]+/.test(value)) {
    return false;
  }

  // First, remove any non-numeric characters.
  value = value.replace(/\D/g, "");

  // Check the length, it should be nine digits.
  if (value.length !== 9) {
    return false;
  }

  // Now run through each digit and calculate the total.
  let n = 0;
  for (let i = 0; i < value.length; i += 3) {
    n +=
      parseInt(value.charAt(i), 10) * 3 +
      parseInt(value.charAt(i + 1), 10) * 7 +
      parseInt(value.charAt(i + 2), 10);
  }

  // If the resulting sum is an even multiple of ten (but not zero),
  // the aba routing number is good.
  return n !== 0 && n % 10 === 0 ? true : false;
}
