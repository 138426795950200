import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";

class QuarterPicker extends React.Component {
  constructor(props) {
    super(props);

    this.onDateSelected = this.onDateSelected.bind(this);
    this.getLabelText = this.getLabelText.bind(this);
  }
  getLabelText() {
    return (
      "Q" +
      moment(this.props.value).quarter() +
      " " +
      moment(this.props.value).year()
    );
  }
  onDateSelected(date) {
    // Get the last day of a quarter
    let lastDayOfQuarter = moment(date).endOf("quarter").toDate();
    this.props.onChange(lastDayOfQuarter);
  }
  render() {
    return (
      <div>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <label hidden htmlFor="Quarter">
            {"Quarter"}
          </label>
          <DatePicker
            id="Quarter"
            name="Quarter"
            label={"Quarter"}
            views={["year", "month"]}
            fullWidth
            labelFunc={this.getLabelText}
            onChange={this.onDateSelected}
            value={this.props.value}
            KeyboardButtonProps={{
              title: "Quarter Picker",
            }}
            minDate={moment().subtract(3, "years").format("YYYY-MM-DD")}
            maxDate={moment().endOf("quarter").format("YYYY-MM-DD")}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuarterPicker)
);
