import { Map, fromJS } from "immutable";
import {
  GET_AUTO_PAY_ENROLLMENT,
  DEACTIVATE_AUTO_PAY_ENROLLMENT,
  SUCCESS,
  FAILURE
} from "../../api/actions/actionTypes";

const getDefaultState = () => {
  return Map({ enrollment: Map()});
};

const actions = {};

actions[`${GET_AUTO_PAY_ENROLLMENT}_${SUCCESS}`] = function(state, action) {
  if(action.payload.body === null){
    return state.set("enrollment", Map());
  }
  
  const autoPayEnrollment = fromJS(action.payload.body);
  return state
    .set("enrollment", autoPayEnrollment);
};

actions[`${GET_AUTO_PAY_ENROLLMENT}_${FAILURE}`] = function(state, action) {
  return state.set("enrollment", Map());
};

actions[`${DEACTIVATE_AUTO_PAY_ENROLLMENT}_${SUCCESS}`] = function (state, action) {
  return state.set("enrollment", Map());
};

export default function(state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  return actions[action.type](state, action);
}
