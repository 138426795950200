import React, { Component } from "react";
import { connect } from "react-redux";
import { List as ImmutableList } from "immutable";

import AccountsForm from "./../accounts/AccountsForm.jsx";
import AccountsList from "./../accounts/AccountsList.jsx";
import NoAccountsAlert from "./../accounts/NoAccountsAlert.jsx";

import { showChoiceNotificationAction } from "./../../api/actions/notification";
import {
  getAccountsAction,
  deleteAccountAction,
} from "./../../api/actions/accounts";
import {
  showLoadingAction,
  hideLoadingAction,
} from "./../../api/actions/loading";
import { GET_ACCOUNTS } from "./../../api/actions/actionTypes";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { setPageInfoAction } from "./../../api/actions/pageInfo";

import { withRouter } from "react-router";

import BackButton from "../../components/BackButton.jsx";

import { _t } from "./../../text/locale";

const t = _t();
let pageTitle = "Manage Accounts";

export class ManageUserAccounts extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.deleteUserAccount = this.deleteUserAccount.bind(this);
    this.confirmItemDelete = this.confirmItemDelete.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.pending === true) {
      this.props.showLoading(t("Fetching your account information..."));
    } else {
      this.props.hideLoading();
    }
  }
  confirmItemDelete() {
    return (accountId, accountNumber, accessNumber) => {
      this.props.showChoiceNotificationAction(
        {
          title: t("Remove Loan Account"),
          message: `${t("Remove")} ${accountNumber}?`,
        },
        {
          yes: () =>
            this.props.deleteUserAccount(
              this.props.params.consortiumId,
              this.props.userId,
              accountId,
              accountNumber,
              accessNumber
            ),
          no: () => {
            /* no op */
          },
        }
      );
    };
  }
  onSubmit(accountNumber, secondaryValidation) {
    this.props.getAccounts(
      this.props.params.accessNumber,
      accountNumber,
      secondaryValidation,
      this.props.params.consortiumId,
      this.props.loggedIn,
      this.props.userId
    );
  }
  deleteUserAccount(accountId, accountNumber, accessNumber) {
    this.props.deleteUserAccount(
      this.props.params.consortiumId,
      this.props.userId,
      accountId,
      accountNumber,
      accessNumber
    );
  }
  render() {
    const noAccounts = this.props.accounts.isEmpty();
    return (
      <div>
        <BackButton
          route="/user/accounts"
          currentLocation={this.props.location}
          backText={t("Account Select")}
          shouldFocusOnMount
        />
        <div className="flex-container">
          <div className="flex-content">
            {noAccounts ? (
              <NoAccountsAlert t={t} />
            ) : (
              <AccountsList
                t={t}
                accounts={this.props.accounts}
                loggedIn={this.props.loggedIn}
                onClickDelete={this.confirmItemDelete()}
                fromManagePage
                bcPaymentCooldown={this.props.bcPaymentCooldown}
              />
            )}
            <br />
          </div>
          <div
            className="flex-tipBox"
            aria-label="Manage Account Tip Box"
            role="region"
          >
            <AlertInfo
              text={t("TIP_BOX_MANAGE_ACCOUNTS")}
              location={this.props.location}
            />
            <AccountsForm
              t={t}
              onSubmit={this.onSubmit}
              showSecondaryValidation={this.props.showSecondaryValidation}
              accountFieldLengths={this.props.fieldLengths}
              accountFieldSeparators={this.props.fieldSeparators}
              lastRequestFailed={this.props.lastRequestFailed}
              disabled={this.props.pending}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    accounts:
      state.billing
        .getIn(["accounts", ownProps.params.accessNumber])
        .toList() || ImmutableList(),
    showSecondaryValidation: state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "requireSecondaryValdiation",
    ]),
    fieldLengths: state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "accountFieldLengths",
    ]),
    fieldSeparators: state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "accountFieldSeparators",
    ]),
    bcPaymentCooldown: state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "paymentCooldown",
    ]),
    loggedIn: state.auth.get("authenticated"),
    userId: state.user.get("id"),
    pending: !!state.httpRequests.getIn([GET_ACCOUNTS, "pending"]),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showChoiceNotificationAction: (content, callbacks) => {
      dispatch(showChoiceNotificationAction(content, callbacks));
    },
    getAccounts: (
      accessNumber,
      accountNumber,
      secondaryValidation,
      consortiumId,
      loggedIn,
      userId
    ) => {
      dispatch(
        getAccountsAction(
          accessNumber,
          accountNumber,
          secondaryValidation,
          consortiumId,
          loggedIn,
          userId
        )
      );
    },
    deleteUserAccount: (
      consortiumId,
      userId,
      accountId,
      accountNumber,
      accessNumber
    ) => {
      dispatch(
        deleteAccountAction(
          consortiumId,
          userId,
          accountId,
          accountNumber,
          accessNumber
        )
      );
    },
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
    showLoading: (titleText) => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageUserAccounts)
);
