import React from "react";
import AlertInfo from "../errors/AlertInfo.jsx";

export default class CvvWarning extends React.Component {
  render() {
    return (
      // This is spaced so the screen reader reads it as the letters not a word
      <div aria-label="C V V Tip Box" role="region">
        <AlertInfo
          text={this.props.t("TIP_BOX_CVV")}
          location={this.props.location}
        />
      </div>
    );
  }
}
