import React, { Component } from "react";
import { _t } from "../../text/locale";
import { Table, TableCell, TableRow, TableBody } from "@material-ui/core";

const t = _t();

class AutoPayConfirmationTable extends Component {
  render() {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>{t("Account Number")}</b>
            </TableCell>
            <TableCell className="text-right">
              {this.props.account.get("accountNumber")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>{t("Frequency")}</b>
            </TableCell>
            <TableCell>{t("Auto")}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export default AutoPayConfirmationTable;
