import React from "react";
import { connect } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import StatementViewTable from "./StatementViewTable.jsx";
import StatementViewSummary from "./StatementViewSummary.jsx";
import AlertWarning from "./../errors/AlertWarning.jsx";
import AlertInfo from "./../errors/AlertInfo.jsx";

import BackButton from "../../components/BackButton.jsx";

class StatementView extends React.Component {
  constructor(props) {
    super(props);

    this.renderStatementViewItems = this.renderStatementViewItems.bind(this);
  }
  renderStatementViewItems(
    activityItems,
    summaryItems,
    selectedAccount,
    userId,
    consortiumId,
    pageNumber,
    firstPage,
    lastPage
  ) {
    if (activityItems.size === 0) {
      return (
        <Grid item xs={12} sm={8}>
          <div aria-label="Statement View Warning Box" role="region">
            <AlertWarning>
              <strong>
                {this.props.t("No account activity was found.")}&nbsp;
              </strong>
              <br className="visible-xs" />
            </AlertWarning>
          </div>
        </Grid>
      );
    }
    return (
      <div>
        <Grid item xs={12}>
          <div aria-label="Statement View Tip Box" role="region">
            <AlertInfo
              text={this.props.t("TIP_BOX_STATEMENT_VIEW")}
              location={this.props.location}
            />
          </div>
        </Grid>
        <StatementViewSummary summaryItems={summaryItems} t={this.props.t} />
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <StatementViewTable
              statementViewItems={activityItems}
              t={this.props.t}
              styles={this.props.styles}
              showFee={this.props.showFee}
              showEscrow={this.props.showEscrow}
            />
            <br />
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={4} style={{ textAlign: "left" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.props.previousPage(
                    selectedAccount,
                    userId,
                    consortiumId,
                    pageNumber
                  );
                }}
                disabled={firstPage}
              >
                {this.props.t("Previous")}
              </Button>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "center" }}>
              <h3 className="pagination">Page {pageNumber}</h3>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.props.nextPage(
                    selectedAccount,
                    userId,
                    consortiumId,
                    pageNumber
                  );
                }}
                disabled={lastPage}
              >
                {this.props.t("Next")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
  render() {
    return (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <div role="navigation" aria-label="Go Back">
            <BackButton
              route="/user/statements/select"
              currentLocation={this.props.location}
              backText={this.props.t("Account Select")}
              shouldFocusOnMount
            />
          </div>
          <h2>
            {this.props.t(
              `Statement for ${this.props.selectedAccount.get(
                "formattedAccountNumber"
              )}`
            )}
          </h2>
          {this.renderStatementViewItems(
            this.props.activityItems,
            this.props.summaryItems,
            this.props.selectedAccount,
            this.props.userId,
            this.props.params.consortiumId,
            this.props.pageNumber,
            this.props.firstPage,
            this.props.lastPage
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const showFee = state.statement.get("showFee");
  const showEscrow = state.statement.get("showEscrow");

  return {
    showFee: showFee,
    showEscrow: showEscrow,
  };
};

export default connect(mapStateToProps)(StatementView);
