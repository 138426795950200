import { Map } from "immutable";

import { INIT_COMPLETE, PENDING } from "./../../api/actions/actionTypes";

const getDefaultState = () => {
  return Map({ state: PENDING });
};

const actions = {};

actions[INIT_COMPLETE] = function(state, action) {
  return state.set("state", action.payload);
};

export default function(state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  return actions[action.type](state, action);
}
