import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { ListItem, ListItemText } from "@material-ui/core";
import {
  continueAsGuestAction,
  recordLocationAction,
} from "./../../api/actions/session";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { setPageInfoAction } from "./../../api/actions/pageInfo";

import RouteHelper from "./../../utils/RouteHelper";
import { _t } from "./../../text/locale";
import FocusableListItem from "../../components/FocusableListItem.jsx";

let t;
let pageTitle = "Lookup Account";

class GuestButton extends React.Component {
  render() {
    return (
      <span>
        <ListItem button onClick={this.props.onClick}>
          <ListItemText
            primary={t("Continue as Guest")}
            secondary={t(
              "Make a one time payment, we will not remember your information"
            )}
          />
          <ChevronRight />
        </ListItem>
      </span>
    );
  }
}

class LoginButton extends React.Component {
  render() {
    return (
      <span>
        <FocusableListItem onClick={this.props.onClick} shouldFocusOnMount>
          <ListItemText
            primary={t("Sign in")}
            secondary={t("Sign in to retrieve my information.")}
          />
          <ChevronRight />
        </FocusableListItem>
      </span>
    );
  }
}

class RegisterButton extends React.Component {
  render() {
    return (
      <span>
        <ListItem button onClick={this.props.onClick}>
          <ListItemText
            primary={t("Register")}
            secondary={t(
              "Register as a new user to remember my information, and schedule recurring payments"
            )}
          />
          <ChevronRight />
        </ListItem>
      </span>
    );
  }
}

class LookupAccount extends Component {
  constructor(props) {
    super(props);

    this.onRegisterButtonClick = this.onRegisterButtonClick.bind(this);
    this.onGuestButtonClick = this.onGuestButtonClick.bind(this);
    this.onLoginButtonClick = this.onLoginButtonClick.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  onGuestButtonClick() {
    this.props.continueAsGuest();
    const path = RouteHelper.getRelativeCustomerRoute(this.props.location)(
      "/accounts"
    );
    this.props.router.push(path);
  }
  onLoginButtonClick() {
    this.props.recordLocation(this.props.location.pathname);
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/sign-in"
    );
    this.props.router.push(path);
  }
  onRegisterButtonClick() {
    this.props.recordLocation(this.props.location.pathname);
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/register"
    );
    this.props.router.push(path);
  }
  requireSignInPrompt() {
    // Update title
    this.props.dispatchSetPageInfo(t("Sign In To Continue"));

    return (
      <div className="flex-container">
        <div className="flex-content">
          <LoginButton
            t={t}
            onClick={this.onLoginButtonClick}
            shouldFocusOnMount
          />
          <RegisterButton t={t} onClick={this.onRegisterButtonClick} />
        </div>
        <div
          className="flex-tipBox"
          aria-label="Require Sign In Tip Box"
          role="region"
        >
          <AlertInfo
            text={t("TIP_BOX_REQUIRE_SIGN_IN")}
            location={this.props.location}
          />
        </div>
      </div>
    );
  }
  render() {
    if (this.props.requireSignIn) {
      return this.requireSignInPrompt();
    }

    return (
      <div className="flex-container">
        <div className="flex-content">
          <LoginButton
            t={t}
            onClick={this.onLoginButtonClick}
            shouldFocusOnMount
          />
          <RegisterButton t={t} onClick={this.onRegisterButtonClick} />
          <GuestButton t={t} onClick={this.onGuestButtonClick} />
        </div>
        <div
          className="flex-tipBox"
          aria-label="Account Lookup Tip Box"
          role="region"
        >
          <AlertInfo
            text={t("TIP_BOX_LOOKUP_ACCOUNT")}
            location={this.props.location}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const autoPayOnly =
    state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "billingProcessType",
    ]) === 98;

  const isSignedIn = state.auth.get("authenticated");
  return {
    requireSignIn: !isSignedIn && autoPayOnly,
    userId: state.user.get("id"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    continueAsGuest: () => {
      dispatch(continueAsGuestAction());
    },
    recordLocation: (location) => {
      dispatch(recordLocationAction(location));
    },
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LookupAccount)
);
