import React from "react";
import PropTypes from "prop-types";

import FormsyTextField from "./../../components/form-input/FormsyTextField.jsx";
import { FormsyPhoneNumber, FormsyMobileNumber } from "./../../components/form-input/FormsyPhoneNumber.jsx";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

class ContactFormFields extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onInput: PropTypes.func.isRequired
  };
  render() {
    return (
      <div>
        <FormsyTextField
          name="name"
          floatingLabelText={this.props.t("Name")}
          onChange={this.props.onInput("name")}
          value={this.props.formData.name}
          validations={{
            maxLength: 22
          }}
          validationErrors={{
            isDefaultRequiredValue: this.props.t("Name is required"),
            maxLength: this.props.t("You cannot type more than 22 characters")
          }}
          required
          shouldFocusOnMount={this.props.shouldFocusOnMount}
        />
        <FormsyTextField
          name="email"
          floatingLabelText={this.props.t("Email Address")}
          onChange={this.props.onInput("email")}
          value={this.props.formData.email}
          validations={{
            isEmail: "isEmail",
            maxLength: 50
          }}
          validationErrors={{
            isDefaultRequiredValue: this.props.t("Email address is required"),
            isEmail: this.props.t("Please enter a valid email address"),
            maxLength: this.props.t("You cannot type more than 50 characters")
          }}
          required
        />
        <FormsyPhoneNumber
          name="phone"
          floatingLabelText={this.props.t("Work / Home Phone")}
          onChange={this.props.onInput("phone")}
          value={this.props.formData.phone}
          t={this.props.t}
          required={this.props.requirePhone}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FormsyMobileNumber
            name="cellPhone"
            floatingLabelText={this.props.t("Mobile Phone")}
            onChange={(newValue) => {
              if (this.props.loggedIn) {
                const oldValue = this.props.formData.cellPhone;
                this.props.onInput("cellPhone")(newValue);
                if (newValue !== oldValue) {
                  this.props.onInput('mpayTextRemindersOptIn')(false);
                }
              } else {
                this.props.onInput("cellPhone")(newValue);
              }
            }}
            value={this.props.formData.cellPhone}
            t={this.props.t}
          />
          {/* Only show checkbox if the user is not a guest and 
          the business customer is opted in to allow text reminders */}
          {this.props.loggedIn && this.props.allowMpayTextReminders ? (
            <FormControlLabel
              style={{ marginLeft: '10px', width: '350px' }}
              control={
                <Checkbox
                  checked={this.props.formData.mpayTextRemindersOptIn}
                  onChange={(event) => {
                    this.props.onInput('mpayTextRemindersOptIn')(event.target.checked);
                  }}
                  // The checkbox is disabled if there's no cellPhone value
                  disabled={!this.props.formData.cellPhone}
                />
              }
              label="Check to Enable Mpay Text Reminders"
            />) : null}
        </div>
      </div>
    );
  }
}
export default ContactFormFields;
