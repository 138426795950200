// Base Components
import React, { Component } from "react";
import store from "./redux/store";
import { Provider } from "react-redux";
import Routes from "./containers/Routes.jsx";
import CustomThemeProvider from "./components/material/CustomThemeProvider.jsx";

import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import "./creditcard-icons.css";

// Needed by Material UI for detecting tap events
import FormsyUtil from "./utils/FormsyUtil";

FormsyUtil.initializeFormsyRules();

// We should only include the DevTools component in the page if we're running in dev mode.
const DEV_MODE = process.env.NODE_ENV === "development"; // eslint-disable-line no-process-env

// TODO4: While devtools are only used in dev_mode, they're always included in the build. Optimally they would not be in production builds.
let DevTools;
if (DEV_MODE) {
  DevTools = require("./components/dev/DevTools").default;
}

class App extends Component {
  render() {
    return (
      <div className="App">
        <Provider store={store}>
          <div>
            <CustomThemeProvider>
              <div>
                <Routes />
              </div>
            </CustomThemeProvider>
          </div>
        </Provider>
        {DEV_MODE ? <DevTools store={store} /> : null}
      </div>
    );
  }
}

export default App;
