import numeral from "numeral";

export function formatPaymentAmount(amount) {
  if (!amount) {
    return "0.00";
  }

  return numeral(amount).format("0.00");
}

export function RemoveSpecialCharactersFromPaymentAmount(amount) {
  if (amount.includes(","))
  {
    amount = amount.replace(",", "");
  }

  if (amount.includes("$"))
  {
    amount = amount.replace("$", "");
  }

  return amount;
}

export function formatExpirationDate(expiration) {
  let month = expiration.substring(0, 2);
  let year = expiration.substring(2);
  return month + "/" + year;
}

// This file is for generating account number mask strings
export const formatAccountNumber = (
  accountNumber,
  fieldLengths,
  fieldSeparators,
  stripSeparators
) => {
  let maskedAccount = "";
  let runningLength = 0;

  let lengths = fieldLengths;
  let separators = fieldSeparators;

  if (!stripSeparators) {
    return accountNumber;
  }

  while (lengths.size > 0) {
    let length = lengths.first();
    lengths = lengths.shift();

    let separator = separators.first();
    separators = separators.shift();

    if (typeof length !== "undefined") {
      maskedAccount += accountNumber.substring(
        runningLength,
        runningLength + length
      );
      runningLength += length;
    }

    // The slash character escapes the separator, so it will appear as is in the mask
    if (typeof separator !== "undefined" && separator !== "") {
      maskedAccount += separator;
    }
  }

  if (maskedAccount === "") {
    return accountNumber;
  }

  return maskedAccount;
};

export const capitalizeFirstLetter = input => {
  return input[0].toUpperCase() + input.slice(1);
};

export const removePunctuationFromAccountNumber = accountNumber => {
  return accountNumber.replace(/[^a-zA-Z0-9]+/g, "");
};
