import React, { Component } from "react";
import Reaptcha from "reaptcha";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { attachCaptchaToken } from "./../../api/actions/payments.js";

class GoogleReCaptcha extends Component {
  constructor(props, context) {
    super(props, context);

    this.onVerify = this.onVerify.bind(this);
  }
  onVerify(recaptchaToken) {
    this.props.dispatchAttachCaptchaToken(recaptchaToken);
  }
  render() {
    return (
      <div className="no-print">
        <Reaptcha sitekey={this.props.siteKey} onVerify={this.onVerify} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const accessNumber = props.params.accessNumber;
  const bc = state.consortium.getIn(["customers", accessNumber]);

  const realSiteKey = state.config.get("recaptchaSiteKey");
  const demoSiteKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

  return {
    siteKey: bc.get("isDemo") ? demoSiteKey : realSiteKey,
    token: state.payments.getIn(["current", "captchaToken"]),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchAttachCaptchaToken: (token) => {
      dispatch(attachCaptchaToken(token));
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GoogleReCaptcha)
);
