import React from "react";
import Button from "@material-ui/core/Button";

export default ({ t, onRegisterAccountClick }) => {
  return (
    <div>
      <h2>{t("Register")}</h2>
      {t("Registering for online access has the following benefits:")}
      <ul>
        <li>{t("Faster Checkout")}</li>
        <li>{t("Save your loan account(s)")}</li>
        <li>{t("Save your payment information")}</li>
      </ul>
      <Button
        variant="contained"
        onClick={onRegisterAccountClick}
        color="primary"
      >
        {t("Register")}
      </Button>
      <br />
    </div>
  );
};
