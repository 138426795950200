import React from "react";
import moment from "moment";
import {
  Card,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
import { formatPaymentAmount } from "../../utils/FormatUtil";

export default class ManageAutoPayCard extends React.Component {
  render() {
    const autoPayEnrollment = this.props.autoPayEnrollment;
    const formattedDate = moment(
      autoPayEnrollment.get("lastAutoPaymentDate")
    ).format("L");
    const formattedAmount = formatPaymentAmount(
      autoPayEnrollment.get("lastAutoPaymentAmount")
    );
    const formattedFee = formatPaymentAmount(
      autoPayEnrollment.get("lastAutoPaymentFeeAmount")
    );
    const noPaymentHistory = formattedAmount === "0.00" && formattedFee === "0.00";

    return (
      <Card style={{ margin: "0px 0px 8px 0px" }}>
        <CardContent>
          <Table>
            <TableBody>
              <TableRow className="flex-container">
                <TableCell className="flex-content">
                  {autoPayEnrollment.get("paymentMethod")}
                  {": "}
                </TableCell>
                <TableCell className="flex-content">
                  {autoPayEnrollment.get("maskedPaymentNumber")}
                </TableCell>
              </TableRow>
              <TableRow className="flex-container">
                <TableCell className="flex-content">
                  {this.props.t("Last Payment Date:")}
                </TableCell>
                <TableCell className="flex-content">{noPaymentHistory ? "N/A" : formattedDate}</TableCell>
              </TableRow>
              <TableRow className="flex-container">
                <TableCell className="flex-content">
                  {this.props.t("Last Payment Amount:")}
                </TableCell>
                <TableCell className="flex-content">
                  {noPaymentHistory ? "N/A" : "$" + formattedAmount}
                </TableCell>
              </TableRow>
              <TableRow className="flex-container">
                <TableCell className="flex-content">
                  {this.props.t("Last Payment Fee:")}
                </TableCell>
                <TableCell className="flex-content">
                  {noPaymentHistory ? "N/A" : "$" + formattedFee}
                  </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
        <CardActions>
          <Button
            onClick={() => {
              this.props.deleteOnClick(autoPayEnrollment);
            }}
            color="secondary"
          >
            {this.props.t("Deactivate")}
          </Button>
        </CardActions>
      </Card>
    );
  }
}
