import store from "./../redux/store";

const CONSORTIUM = "consortium";

const fillOverrideTemplate = (overrideText, overrideData) => {
  
  // If override data is undefined, we have no data to place in the override text and can return the text back out.
  if (typeof overrideData === "undefined") {
    return overrideText;
  }

  let populatedOverride = overrideText;
  
  for (let propName in overrideData) {
    if (Object.hasOwn(overrideData, propName)) {
      populatedOverride = populatedOverride.replace(`{{${propName}}}`, overrideData[propName]);
    }
  }

  return populatedOverride;
};

export const _t = accessNumber => (key, overrideData) => {
  const text = store.getState().text;
  const currentLocale = text.get("currentLocale");

  if (!accessNumber) {
    return _t(CONSORTIUM)(key, overrideData);
  }

  const result = text.getIn([currentLocale, accessNumber, key.toUpperCase()]);

  if (typeof result === "undefined") {
    if (accessNumber !== CONSORTIUM) {
      return _t(CONSORTIUM)(key, overrideData);
    }

    // If we couldn't find an entry, return the key, which should be the default english text.
    // Since we now support override templates, we need to check if override data was passed in
    // and if the template needs to be filled in
    return fillOverrideTemplate(key, overrideData);
  }
  
  return fillOverrideTemplate(result, overrideData);
};
