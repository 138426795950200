import http from "./../utils/http";

export const getPaymentHistory = (
  consortiumId,
  userIdo,
  bankNumber,
  companyNumber,
  accountNumber,
  page
) => {
  return http
    .get(
      `/api/v1/consortiums/${consortiumId}/users/${userIdo}/payments?bank_number=${bankNumber}&company_number=${companyNumber}&account_number=${accountNumber}&page=${page}&per_page=10`
    )
    .end();
};

export const getRecentPayments = (consortiumId, userIdo) => {
  return http
    .get(`/api/v1/consortiums/${consortiumId}/users/${userIdo}/payments/recent`)
    .end();
};
