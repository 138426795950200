import React from "react";
import AlertWarning from "./../errors/AlertWarning.jsx";

export default class NoDateSelectedAlert extends React.Component {
  render() {
    return (
      <AlertWarning>
        <strong>{this.props.t("Please select a payment date.")}</strong>
        <br className="visible-xs" />
      </AlertWarning>
    );
  }
}
