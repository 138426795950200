import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { List, Avatar, ListItemText } from "@material-ui/core";
import ContentAdd from "@material-ui/icons/Add";
import ContentBlock from "@material-ui/icons/Block";
import ChevronRight from "@material-ui/icons/ChevronRight";
import AlertInfo from "./errors/AlertInfo.jsx";
import UserSignInPrompt from "./user-accounts/UserSignInPrompt.jsx";
import RouteHelper from "./../utils/RouteHelper";
import { setPageInfoAction } from "./../api/actions/pageInfo";
import { clearAccountPaymentAction } from "./../api/actions/payments";
import { _t } from "./../text/locale";
import { ResponsiveUtil } from "../utils/ResponsiveUtil";
import { grey } from "@material-ui/core/colors";
import FocusableListItem from "../components/FocusableListItem.jsx";

const isMobile = ResponsiveUtil.isMobile();
const t = _t();

class Home extends Component {
  constructor(props) {
    super(props);

    this.shouldFocus = this.shouldFocus.bind(this);
  }
  UNSAFE_componentWillMount() {
    let pageTitle = isMobile ? this.props.title : "Welcome";
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  formatPrimaryText(isActive, text) {
    let style = {};

    if (!isActive) {
      style = { color: grey[600] };
    }

    return <div style={style}>{text}</div>;
  }
  shouldFocus(entry, businessCustomer) {
    const currentKey = entry.get("accessNumber");
    const firstKey = businessCustomer.first().get("accessNumber");

    return currentKey === firstKey;
  }
  render() {
    const makeListItem = (c) => {
      const lookUpPath = RouteHelper.getRelativeAppViewRoute(
        this.props.location
      )(`/business/${c.get("accessNumber")}/lookup-accounts`);

      const accountPath = RouteHelper.getRelativeAppViewRoute(
        this.props.location
      )(`/business/${c.get("accessNumber")}/accounts/select`);
      const isActive = c.get("isActive");

      let path = "";
      if (this.props.loggedIn || this.props.guestOnly) {
        path = accountPath;
      } else {
        path = lookUpPath;
      }

      // If the business customer has deposit accounts, route to the pay-to page to pick them
      if (c.get("depositAccounts") && c.get("depositAccounts").size > 0) {
        const payToPath = RouteHelper.getRelativeAppViewRoute(
          this.props.location
        )(`/business/${c.get("accessNumber")}/pay-to`);
        path = payToPath;
      }

      return (
        <li key={c}>
          <FocusableListItem
            key={c}
            onClick={() => {
              this.props.dispatchClearAccountPaymentAction();
              this.props.router.push(path);
            }}
            disabled={!isActive}
            shouldFocusOnMount={this.shouldFocus(c, this.props.customers)}
          >
            <Avatar
              style={{
                left: "4px",
                backgroundColor: this.props.styles.get("primary1Color"),
              }}
            >
              {!isActive ? <ContentBlock /> : <ContentAdd />}
            </Avatar>
            <ListItemText
              primary={this.formatPrimaryText(
                isActive,
                c.get("serviceDescription")
              )}
            />
            <ChevronRight />
          </FocusableListItem>
        </li>
      );
    };
    let customerList = this.props.customers.map((c) => makeListItem(c));
    return (
      <div>
        <div className="flex-container">
          <div className="flex-content">
            <List>{customerList}</List>
          </div>
          <div
            className="flex-tipBox"
            aria-label="Home Page Tip Box"
            role="region"
          >
            {this.props.customers.some((c) => c.get("isActive")) ? (
              <div />
            ) : (
              <AlertInfo
                text={t("COMPANIES_IN_MAINTENANCE_MODE")}
                location={this.props.location}
              />
            )}
            {this.props.loggedIn ? (
              <AlertInfo
                text={t("TIP_BOX_WELCOME")}
                location={this.props.location}
              />
            ) : (
              <UserSignInPrompt t={t} location={this.props.location} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customers: state.consortium.get("customers").toList(),
    loggedIn: state.auth.get("authenticated"),
    styles: state.style.get("palette"),
    title: state.consortium.get("consortiumName"),
    guestOnly: state.consortium.getIn(["settings", "guestOnly"]),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
    dispatchClearAccountPaymentAction: () => {
      dispatch(clearAccountPaymentAction());
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
