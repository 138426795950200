import {
  CLOSE_NOTIFICATION,
  SHOW_CONFIRMATION_DIALOG
} from "../../api/actions/actionTypes";

export const showChoiceNotificationAction = (
  content,
  callbacks
) => dispatch => {
  dispatch({
    type: SHOW_CONFIRMATION_DIALOG,
    payload: {
      content,
      callbacks
    }
  });
};

export const closeNotificationAction = dispatch => {
  dispatch({
    type: CLOSE_NOTIFICATION
  });
};
