import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Map } from "immutable";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { ListItemText } from "@material-ui/core";
import RouteHelper from "../../utils/RouteHelper";
import {
  setAccountPaymentAction,
  clearAccountPaymentAction,
} from "../../api/actions/payments";
import { setAutoPayEnrollmentAction } from "../../api/actions/autoPay.js";
import { _t } from "../../text/locale";
import AlertInfo from "../errors/AlertInfo.jsx";
import { setPageInfoAction } from "../../api/actions/pageInfo";
import PaymentStepper from "../../components/PaymentStepper.jsx";
import FocusableListItem from "../../components/FocusableListItem.jsx";

let t;
let pageTitle = "Payment Options";

class PaymentOptions extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));

    // Short circuit this whole page under specific conditions
    if (
      !this.props.isSignedIn ||
      (this.props.paymentOptions.count() === 1 &&
        this.props.paymentOptions.first().option === "oneTime")
    ) {
      // Guest users should only be allowed to make one time payments, so we assure they do so here.
      // Also skip this page if one time is the only option.
      this.onClick("oneTime");
    } else if (this.props.autopayOnly) {
      this.onClick("autoPay");
    }
  }
  onClick(value) {
    let routeTarget = "";
    switch (value) {
      case "ccRecurring":
      case "achRecurring":
        routeTarget = "/payment/recurring";
        break;
      case "autoPay":
        // The "set" method occurs on the related payment pages for other
        // payment types, but since Auto-Pay does not require the user to
        // enter a date or amount, we skip that page.
        this.props.setAutoPayEnrollment();
        routeTarget = "/checkout/contact-info";
        break;
      case "oneTime":
      default:
        routeTarget = "/payment/one-time";
        break;
    }
    const checkoutPath = RouteHelper.getRelativeCustomerRoute(
      this.props.location
    )(routeTarget);

    this.props.router.push(checkoutPath);
  }

  // Iterate through the list of payment options, displaying a radio button for each one.
  renderOptions(paymentOptions) {
    return paymentOptions.map((item) => {
      // There are two recurring entries, one for ach and one for cc. Skip the
      // one we don't have a selected payment method for
      if (
        item.option.endsWith("Recurring") &&
        !item.option.startsWith(this.props.paymentType)
      ) {
        return null;
      }

      return (
        <FocusableListItem
          key={`${item.option}-li`}
          onClick={() => this.onClick(item.option)}
          // If the item is the first, focus it.
          shouldFocusOnMount={item.option === paymentOptions.first().option}
        >
          <ListItemText
            key={`${item.option}-li-text`}
            primary={t(item.displayName)}
            secondary={t(item.description)}
          />
          <ChevronRight />
        </FocusableListItem>
      );
    });
  }

  render() {
    return (
      <div>
        <div className="stepper">
          <PaymentStepper
            step="payment-options"
            currentLocation={this.props.location}
          />
        </div>
        <div className="flex-container">
          <div className="flex-content">
            <div style={{ marginTop: "10px" }}>
              {this.renderOptions(this.props.paymentOptions)}
            </div>
          </div>
          <div
            className="flex-tipBox"
            aria-label="Select Payment Schedule Tip Box"
            role="region"
          >
            <AlertInfo
              text={t("TIP_BOX_SELECT_PAYMENT_OPTIONS")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const paymentType = state.payments.getIn(["method", "type"]);

  // Payment options and their descriptions come from the consortium reducer
  const paymentOptions = state.consortium.getIn([
    "customers",
    ownProps.params.accessNumber,
    "paymentOptions",
  ]);

  const billingProcessType = state.consortium.getIn([
    "customers",
    ownProps.params.accessNumber,
    "billingProcessType",
  ]);

  const isSignedIn = !!state.auth.get("authenticated");

  return {
    holidays: state.consortium.get("holidays") || Map(),
    paymentOptions: paymentOptions,
    paymentType: paymentType,
    autopayOnly: billingProcessType === 98,
    styles: state.style.get("palette"),
    isSignedIn: isSignedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
    setAccountPayment: (amount, date, frequency) => {
      dispatch(setAccountPaymentAction(amount, date, frequency));
    },
    setAutoPayEnrollment: () => {
      dispatch(setAutoPayEnrollmentAction());
    },
    clearAccountPayment: () => {
      dispatch(clearAccountPaymentAction());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentOptions)
);
