import { Map } from "immutable";

import { GET_CONFIG } from "../../api/actions/actionTypes";

const getDefaultState = () => {
  return Map();
};

const actions = {};

actions[`${GET_CONFIG}`] = function(state, action) {
  let data = action.data;
  return state.merge(data);
};

export default function(state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  return actions[action.type](state, action);
}
