import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  showLoadingAction,
  hideLoadingAction,
} from "./../../api/actions/loading";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { registerSsoUserAccountAction } from "./../../api/actions/user";
import { recordLocationAction } from "./../../api/actions/session";
import {
  REGISTER_SSO_USER,
  FAILURE,
  SUCCESS,
} from "./../../api/actions/actionTypes";
import { setPageInfoAction } from "./../../api/actions/pageInfo";
import RouteHelper from "./../../utils/RouteHelper";
import SsoRegisterForm from "./SsoRegisterForm.jsx";
import { _t } from "./../../text/locale";

let t;
let pageTitle = "Contact Confirmation";

class SsoRegister extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.pending === true) {
      this.props.showLoading(t("Confirming Contact Information..."));
    } else if (
      !nextProps.pending &&
      this.props.pending &&
      !nextProps.lastRequestFailed
    ) {
      this.props.hideLoading();
      const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
        "/"
      );
      this.props.router.push(path);
    } else {
      this.props.hideLoading();
    }
  }

  onSubmit(formData) {
    let yesNoCallback = {
      yes: () => {
        this.routeToSsoLink();
      },
      no: () => {
        /*noop*/
      },
    };

    this.props.registerUser(
      formData,
      this.props.params.consortiumId,
      this.props.ssoId,
      this.props.samlUserId,
      this.props.provider,
      yesNoCallback
    );
  }

  routeToSsoLink() {
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/sso/link"
    );
    this.props.router.push(path);
  }

  render() {
    return (
      <div className="flex-container">
        <div className="flex-content">
          <SsoRegisterForm
            onSubmit={this.onSubmit}
            t={t}
            required={this.props.required}
            disabled={this.props.pending}
            contactInfo={this.props.contact}
          />
        </div>
        <div
          className="flex-tipBox"
          aria-label="Register Account Tip Box"
          role="region"
        >
          <AlertInfo
            text={t("TIP_BOX_SSO_REGISTER")}
            location={this.props.location}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pending: !!state.httpRequests.getIn([REGISTER_SSO_USER, "pending"]),
    registerUserSuccess:
      state.httpRequests.getIn([REGISTER_SSO_USER, "lastStatus"]) === SUCCESS,
    lastRequestFailed:
      state.httpRequests.getIn([REGISTER_SSO_USER, "lastStatus"]) === FAILURE,
    required: state.consortium.get("newUserRequirements").toJS(),
    contact: state.user.getIn(["storedSamlPayload", "mpayContactInfo"]).toJS(),
    ssoId: state.user.getIn(["storedSamlPayload", "ssoID"]),
    samlUserId: state.user.getIn(["storedSamlPayload", "samlUserId"]),
    provider: state.user.getIn(["storedSamlPayload", "provider"]),
    previousLocation: state.session.get("location"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (
      contactInfo,
      consortiumId,
      ssoId,
      samlUserId,
      provider,
      yesNoCallback
    ) => {
      dispatch(
        registerSsoUserAccountAction(
          contactInfo,
          consortiumId,
          ssoId,
          samlUserId,
          provider,
          yesNoCallback
        )
      );
    },
    showLoading: (titleText) => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
    clearLocation: () => {
      dispatch(recordLocationAction(""));
    },
    dispatchSetPageInfo: (pageData) => {
      dispatch(setPageInfoAction(pageData));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SsoRegister)
);
