import { Map, fromJS } from "immutable";
import moment from "moment";

import {
  SUCCESS,
  GET_CONSORTIUM,
  GET_ACCOUNTS,
  SELECT_ACCOUNT,
  AUTHENTICATE_USER,
  AUTHENTICATE_SSO,
  ADD_USER_ACCOUNT,
  DELETE_USER_ACCOUNT,
  SUBMIT_TRANSACTION,
  GET_USER_ACCOUNTS,
} from "../../api/actions/actionTypes";

import { formatAccountNumber } from "./../../utils/FormatUtil";

const getDefaultState = () => {
  return Map({ accounts: Map() });
};

const actions = {};

actions[`${GET_CONSORTIUM}_${SUCCESS}`] = function (state, action) {
  const body = action.payload.body;
  const customerList = fromJS(body);
  const fieldLengths = Map(
    customerList.map((c) => [
      c.get("accessNumber"),
      c.get("accountFieldLengths"),
    ])
  );
  const fieldSeparators = Map(
    customerList.map((c) => [
      c.get("accessNumber"),
      c.get("accountFieldSeparators"),
    ])
  );
  const customerMap = Map(
    customerList.map((c) => [c.get("accessNumber"), Map()])
  );
  const stripSeparators = Map(
    customerList.map((c) => [c.get("accessNumber"), c.get("stripSeparators")])
  );
  const isNormalProcessing = Map(
    customerList.map((c) => [c.get("accessNumber"), c.get("billingProcessType") === 0])
  )
  return state
    .set("accounts", customerMap)
    .set("separators", fieldSeparators)
    .set("lengths", fieldLengths)
    .set("stripSeparators", stripSeparators)
    .set("isNormalProcessing", isNormalProcessing);
};

const handleAccount = (state, account) => {
  const accessNumber = account.get("accessNumber");
  const separators = state.getIn(["separators", accessNumber]);
  const lengths = state.getIn(["lengths", accessNumber]);
  const stripSeparators = state.getIn(["stripSeparators", accessNumber]);
  const isNormalProcessing = state.getIn(["isNormalProcessing", accessNumber]);
  const formattedAccountNumber = formatAccountNumber(
    account.get("accountNumber"),
    lengths,
    separators,
    stripSeparators
  );

  let paymentCooldown;
  if (account.has("paymentCooldownRemaining")) {
    paymentCooldown = moment.duration(account.get("paymentCooldownRemaining"));
  } else {
    paymentCooldown = moment.duration(0);
  }

  return state.setIn(
    ["accounts", account.get("accessNumber"), account.get("accountNumber")],
    account
      .set("formattedAccountNumber", formattedAccountNumber)
      .set("paymentCooldownRemaining", paymentCooldown)
      .set("isNormalProcessing", isNormalProcessing)
  );
};

actions[`${GET_USER_ACCOUNTS}_${SUCCESS}`] = function (state, action) {
  const accountList = action.payload.body.accounts;
  accountList.forEach((acc) => {
    const account = fromJS(acc);
    state = handleAccount(state, account);
  });

  return state;
};

actions[`${GET_ACCOUNTS}_${SUCCESS}`] = function (state, action) {
  const body = action.payload.body;
  const meta = action.meta;
  const account = fromJS(body).merge(meta);

  const isClaimed = account.get("userAccountId") !== 0;

  // If the account is already enrolled in autopay and is claimed by another user, then we exit prematurely
  if (account.get("hasActiveAutoPayEnrollment") && isClaimed) {
    return state;
  }

  return handleAccount(state, account);
};

actions[SELECT_ACCOUNT] = function (state, action) {
  const payload = action.payload;
  const account = fromJS(payload);
  return state.setIn(
    ["accounts", "current"],
    ["accounts", account.get("accessNumber"), account.get("accountNumber")]
  );
};

actions[`${AUTHENTICATE_USER}_${SUCCESS}`] = function (state, action) {
  const accountList = action.payload.body.user.accounts;
  accountList.forEach((acc) => {
    const account = fromJS(acc);
    state = handleAccount(state, account);
  });

  return state;
};

actions[`${AUTHENTICATE_SSO}_${SUCCESS}`] = function (state, action) {
  const accountList = action.payload.body.user.accounts;
  accountList.forEach((acc) => {
    const account = fromJS(acc);

    const accessNumber = account.get("accessNumber");
    const separators = state.getIn(["separators", accessNumber]);
    const lengths = state.getIn(["lengths", accessNumber]);
    const stripSeparators = state.getIn(["stripSeparators", accessNumber]);

    const formattedAccountNumber = formatAccountNumber(
      account.get("accountNumber"),
      lengths,
      separators,
      stripSeparators
    );

    let paymentCooldown;
    if (account.has("paymentCooldownRemaining")) {
      paymentCooldown = moment.duration(
        account.get("paymentCooldownRemaining")
      );
    } else {
      paymentCooldown = moment.duration(0);
    }

    state = state.setIn(
      ["accounts", account.get("accessNumber"), account.get("accountNumber")],
      account
        .set("formattedAccountNumber", formattedAccountNumber)
        .set("paymentCooldownRemaining", paymentCooldown)
    );
  });

  return state;
};

actions[`${ADD_USER_ACCOUNT}_${SUCCESS}`] = function (state, action) {
  const payload = action.payload.body;
  const body = fromJS(payload);
  return state.setIn(
    [
      "accounts",
      body.get("accessNumber"),
      body.get("accountNumber"),
      "userAccountId",
    ],
    body.get("userAccountId")
  );
};

actions[`${DELETE_USER_ACCOUNT}_${SUCCESS}`] = function (state, action) {
  const meta = action.meta;
  return state.deleteIn(["accounts", meta.accessNumber, meta.accountNumber]);
};

actions[`${SUBMIT_TRANSACTION}_${SUCCESS}`] = function (state, action) {
  const responseBody = action.payload.body;
  const meta = action.meta;

  // If there is a payment cooldown on the business customer, set the payment cooldown remaining
  //  on the account used to make a payment to the bc's cooldown duration
  if (
    meta.paymentCooldown &&
    moment.isDuration(meta.paymentCooldown) &&
    meta.paymentCooldown.as("seconds") > 0
  ) {
    return state.setIn(
      [
        "accounts",
        meta.accessNumber,
        responseBody.accountNumber,
        "paymentCooldownRemaining",
      ],
      meta.paymentCooldown
    );
  }

  return state;
};

export default function (state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  return actions[action.type](state, action);
}
