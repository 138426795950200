import {
  SESSION_TIMEOUT,
  SESSION_IDLE_WARN
} from "../../api/actions/actionTypes";

const idleMinutesUntilWarn = 10;
const idleMinutesUntilTimeout = 15;

var idleCounter = 0;

function resetIdleTimer() {
  idleCounter = 0;
}

function startUserActionListeners(func) {
  document.addEventListener("mousemove", func, false);
  document.addEventListener("keypress", func, false);
}

function stopUserActionListeners(func) {
  document.removeEventListener("mousemove", func, false);
  document.removeEventListener("keypress", func, false);
}

function updateIdleStatus(dispatch, getState) {
  // Idle counter is how many times this method has been called since a user has moved their mouse or pressed a key.
  // What the timer actually is depends on what the interval is set to, usually it is one minute.
  idleCounter++;
  if (idleCounter === idleMinutesUntilWarn) {
    dispatch({
      type: SESSION_IDLE_WARN
    });
  }

  if (idleCounter === idleMinutesUntilTimeout) {
    // If we don't stop resetting the idle timer when a users session timeouts, they could come back,
    // reset their timeout without closing the "Session Expired" notification, and it would be overwritten by
    // the warning modal, which would be confusing.
    stopUserActionListeners(resetIdleTimer);
    dispatch({
      type: SESSION_TIMEOUT
    });
  }
}

export const startIdleTimeoutAction = () => (dispatch, getState) => {
  // If a user is pressing keys or moving their mouse, reset the idle counter.
  startUserActionListeners(resetIdleTimer);

  // Every minute, check whether or not the user has been idle and take appropriate action.
  setInterval(() => updateIdleStatus(dispatch, getState), 60000); // 60 seconds
};
