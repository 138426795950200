import React from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";
import Button from "@material-ui/core/Button";

import FormsyTextField from "./../../components/form-input/FormsyTextField.jsx";
import FormsySelect from "./../../components/form-input/FormsyRadio.jsx";
import { ResponsiveUtil } from "./../../utils/ResponsiveUtil";
import { fromJS } from "immutable";

const mobileStyle = {
  width: "100%",
  maxWidth: "none",
  marginBottom: "10px"
};
const isMobile = ResponsiveUtil.isMobile();

class NewAchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountNumber: "",
      routingNumber: "",
      name: "",
      accountType: 0,
      valid: false
    };

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
    this.onInput = this.onInput.bind(this);
  }
  static propTypes = {
    t: PropTypes.func.isRequired
  };
  onValidSubmit() {
    const model = Object.assign({}, this.state);
    delete model.valid;

    this.props.onSubmit(model);
  }
  onValid() {
    this.setState({ valid: true });
  }
  onInvalid() {
    this.setState({ valid: false });
  }
  onInput(name) {
    return value => {
      let update = {};
      update[name] = value;
      this.setState(update);
    };
  }
  render() {
    return (
      <Formsy
        role="form"
        aria-label="Add new ACH account"
        noValidate
        onValidSubmit={this.onValidSubmit}
        onValid={this.onValid}
        onInvalid={this.onInvalid}
      >
        <div>
          <FormsyTextField
            name="name"
            floatingLabelText={this.props.t("Account Holder's Name")}
            onChange={this.onInput("name")}
            value={this.state.name}
            validationErrors={{
              isDefaultRequiredValue: this.props.t(
                "Account Holder's Name is required"
              )
            }}
            required
            shouldFocusOnMount
          />
          <FormsyTextField
            name="accountNumber"
            floatingLabelText={this.props.t(
              "Checking / Savings Account Number"
            )}
            onChange={this.onInput("accountNumber")}
            value={this.state.accountNumber}
            validations={{
              matchRegexp: /^([a-zA-Z0-9-.\s]){4,17}$/,
              maxLength: 17
            }}
            validationErrors={{
              matchRegexp:
                "Checking / Savings Account Number can contain only letters, number, dashes, spaces, periods, and must be between 4 and 17 characters.",
              isDefaultRequiredValue: this.props.t(
                "Checking / Savings Account Number is required"
              ),
              maxLength: this.props.t("You cannot type more than 17 characters")
            }}
            required
          />
          <FormsyTextField
            name="routingNumber"
            floatingLabelText={this.props.t("Routing Number")}
            onChange={this.onInput("routingNumber")}
            value={this.state.routingNumber}
            validations={{
              matchRegexp: /^\d{9}$/,
              isRoutingNumber: "isRoutingNumber",
              isNotBlocked: this.props.blockedRoutingNumbers,
              maxLength: 9
            }}
            validationErrors={{
              matchRegexp: this.props.t("Routing number must be 9 digits"),
              isRoutingNumber: this.props.t("Must be a valid routing number"),
              isDefaultRequiredValue: this.props.t(
                "Routing Number is required"
              ),
              isNotBlocked: this.props.t("Routing number is not allowed."),
              maxLength: this.props.t("You cannot type more than 9 characters")
            }}
            required
          />
          <br />
          <br />
          <FormsySelect
            name="accountType"
            onChange={this.onInput("accountType")}
            value={this.state.accountType}
            validations={{ matchRegexp: /37|27/ }}
            choices={fromJS([
              { value: "27", label: this.props.t("Checking Account") },
              { value: "37", label: this.props.t("Savings Account") }
            ])}
            style={isMobile ? mobileStyle : null}
            required
          />
          <br />
          <Button
            variant="contained"
            type="submit"
            disabled={!this.state.valid || this.props.disabled}
            style={isMobile ? mobileStyle : null}
            color="primary"
          >
            {this.props.submitAction}
          </Button>
        </div>
      </Formsy>
    );
  }
}
export default NewAchForm;
