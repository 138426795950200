import {
  getAccount,
  addUserAccountsRequest,
  deleteUserAccountsRequest,
} from "./../requests/accounts";
import {
  GET_ACCOUNTS,
  SELECT_ACCOUNT,
  ADD_USER_ACCOUNT,
  DELETE_USER_ACCOUNT,
} from "./actionTypes";
import { removePunctuationFromAccountNumber } from "./../../utils/FormatUtil";

export const getAccountsAction =
  (
    accessNumber,
    accountNumber,
    secondaryValidation,
    consortiumId,
    loggedIn,
    userId
  ) =>
  (dispatch, getState) => {
    const state = getState();
    const bc = state.consortium.getIn(["customers", accessNumber]);
    const bankNumber = bc.get("bankNumber");
    const companyNumber = bc.get("companyNumber");
    const isNotNormalProcessing = bc.get("billingProcessType") !== 0;
    const stripPunctuation = bc.get("stripSeparators");
    let accountNumberWithoutPunctuation = "";

    if (stripPunctuation) {
      accountNumberWithoutPunctuation =
        removePunctuationFromAccountNumber(accountNumber);
    }

    const getAccountPromise = stripPunctuation
      ? getAccount(
          bankNumber,
          companyNumber,
          accountNumberWithoutPunctuation,
          secondaryValidation
        )
      : getAccount(
          bankNumber,
          companyNumber,
          accountNumber,
          secondaryValidation
        );

    dispatch({
      type: GET_ACCOUNTS,
      // We want to keep the account number the user entered, as it is formatted nicely for display purposes
      meta: {
        formattedAccountNumber: accountNumber,
        shouldDisplayNonErrorText: isNotNormalProcessing,
        bankNumber,
        companyNumber,
      },
      payload: {
        promise: getAccountPromise,
      },
    }).then((result) => {
      if (
        result.value.body.hasActiveAutoPayEnrollment &&
        result.value.body.userAccountId !== 0
      ) {
        console.log(
          "User tried to add a claimed account with an active Auto-Pay enrollment."
        );
      } else {
        if (loggedIn) {
          const acctObj = result.value.body;
          const newUserAccount = {
            accountNumber: acctObj.accountNumber,
            accessNumber: acctObj.accessNumber,
            companyNumber: companyNumber,
            bankNumber: bankNumber,
            userId: userId,
          };
          const promise = addUserAccountsRequest(
            consortiumId,
            userId,
            newUserAccount
          );
          dispatch({
            type: ADD_USER_ACCOUNT,
            payload: promise,
          });
          return promise;
        }
      }
    });
  };

export const deleteAccountAction =
  (consortiumId, userId, accountId, accountNumber, accessNumber) =>
  (dispatch, getState) => {
    const promise = deleteUserAccountsRequest(consortiumId, userId, accountId);

    dispatch({
      type: DELETE_USER_ACCOUNT,
      meta: { accountNumber, accessNumber },
      payload: promise,
    });

    return promise;
  };

export const selectAccountAction = (account) => (dispatch, getState) => {
  dispatch({
    type: SELECT_ACCOUNT,
    payload: account,
  });
};
