import React from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";
import FormsyTextField from "./../../../components/form-input/FormsyTextField.jsx";
import Button from "@material-ui/core/Button";
import { ResponsiveUtil } from "../../../utils/ResponsiveUtil";

const mobileStyle = {
  width: "100%",
  maxWidth: "none",
  marginBottom: "10px"
};
const isMobile = ResponsiveUtil.isMobile();

class UsernameReminderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      valid: false
    };

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
    this.onInput = this.onInput.bind(this);
  }
  static propTypes = {
    t: PropTypes.func.isRequired
  };
  onValidSubmit() {
    // Copy our form model and delete form-related properties
    // This is less ~effort~ than reinitializing an object with all the properties.
    const model = Object.assign({}, this.state);
    delete model.valid;
    this.props.onSubmit(model);
  }
  onValid() {
    this.setState({ valid: true });
  }
  onInvalid() {
    this.setState({ valid: false });
  }
  onInput(name) {
    return value => {
      let update = {};
      update[name] = value;
      this.setState(update);
    };
  }

  render() {
    return (
      <Formsy
        noValidate
        onValidSubmit={this.onValidSubmit}
        onValid={this.onValid}
        onInvalid={this.onInvalid}
        role="form"
        aria-label="Username Reminder"
      >
        <div style={{ marginTop: "16px" }}>
          <FormsyTextField
            name="email"
            floatingLabelText={this.props.t("Email Address")}
            onChange={this.onInput("email")}
            value={this.state.email}
            validations={"isEmail"}
            validationErrors={{
              isDefaultRequiredValue: this.props.t("Email Address is required"),
              isEmail: this.props.t("Please enter a valid email address")
            }}
            required
            shouldFocusOnMount
          />
        </div>
        <div>
          <br />
          <Button
            variant="contained"
            label={this.props.t("Send Reminder")}
            type="submit"
            disabled={!this.state.valid || this.props.disabled}
            style={isMobile ? mobileStyle : null}
            color="primary"
          >
            {this.props.t("Send Reminder")}
          </Button>
        </div>
      </Formsy>
    );
  }
}

export default UsernameReminderForm;
