import React from "react";
import ManageRecurringTable from "./ManageRecurringTable.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { _t } from "./../../text/locale";

let t;
let pageTitle = "Recurring Payments";

class PrintableManageRecurring extends React.Component {
  constructor(props) {
    super(props);
    this.showLogo = this.showLogo.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
  }
  showLogo() {
    if (this.props.logoPath) {
      return (
        <div>
          <img src={this.props.logoPath} alt={t(this.props.title)} />
        </div>
      );
    }
    return <div />;
  }

  render() {
    return (
      <div className="row">
        {this.showLogo()}
        <h1>{t(this.props.title)}</h1>
        <h2>
          {t("")}
          {this.props.selectedAccount.get("formattedAccountNumber")} -{" "}
          {t(pageTitle)}
        </h2>
        <ManageRecurringTable
          paymentHistoryItems={this.props.recurringPaymentItems}
          t={t}
          styles={this.props.styles}
          hideCancel={true}
        />
        <div style={{ textAlign: "center" }}>
          <h3 className="pagination">Page {this.props.pageNumber}</h3>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const logoPath = state.style.get("logoPath");
  const title = state.consortium.get("consortiumName");
  return {
    recurringPaymentItems: state.manageRecurring.get("items"),
    pageNumber: state.manageRecurring.get("pageNumber"),
    selectedAccount: state.manageRecurring.get("selectedAccount"),
    userId: state.user.get("id"),
    styles: state.style.get("palette"),
    logoPath,
    title
  };
};

export default withRouter(connect(mapStateToProps)(PrintableManageRecurring));
