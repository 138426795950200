export class ResponsiveUtil {
  static isMobile() {
    return this.isExtraSmall();
  }

  static isExtraSmall() {
    return document.documentElement.clientWidth < 768;
  }

  static isSmall() {
    return document.documentElement.clientWidth >= 768;
  }

  static isMedium() {
    return document.documentElement.clientWidth >= 992;
  }

  static isLarge() {
    return document.documentElement.clientWidth >= 1200;
  }

  static getViewHeight() {
    return document.body.clientHeight;
  }

  static getViewWidth() {
    return document.body.clientWidth;
  }
}
