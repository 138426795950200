import React from "react";
import Formsy from "formsy-react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import numeral from "numeral";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Map } from "immutable";
import {
  getCurrentDateTime,
  isHoliday,
  isWeekend,
  isValidPaymentDate,
} from "../../utils/DateUtils";
import { Button, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import PaymentAmountField from "../accounts/PaymentAmountField.jsx";
import AccountDetailItem from "../accounts/AccountDetailItem.jsx";
import RouteHelper from "../../utils/RouteHelper";
import { setAccountPaymentAction } from "../../api/actions/payments";
import { formatPaymentAmount, RemoveSpecialCharactersFromPaymentAmount } from "../../utils/FormatUtil";
import { _t } from "../../text/locale";
import PreviousBusinessDayAlert from "../accounts/PreviousBusinessDayAlert.jsx";
import UserSignInPrompt from "../user-accounts/UserSignInPrompt.jsx";
import AlertInfo from "../errors/AlertInfo.jsx";
import { ResponsiveUtil } from "../../utils/ResponsiveUtil";
import NoDateSelectedAlert from "../accounts/NoDateSelectedAlert.jsx";
import { setPageInfoAction } from "../../api/actions/pageInfo";
import PaymentStepper from "../../components/PaymentStepper.jsx";
import FocusableButton from "../../components/FocusableButton.jsx";
import AccountActivity from "./AccountActivity.jsx";

let t;
let pageTitle = "Make A Payment";

const mobileStyle = {
  width: "100%",
  maxWidth: "none",
  marginBottom: "10px",
};
const isMobile = ResponsiveUtil.isMobile();

class AccountPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentAmount: formatPaymentAmount(this.props.account.get("amountDue")),
      paymentDate: getCurrentDateTime().toDate(),
      formValid: false,
      frequency: "None",
    };

    this.onDateSelected = this.onDateSelected.bind(this);
    this.onPaymentAmountChange = this.onPaymentAmountChange.bind(this);
    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
    this.routeToPaymentHistory = this.routeToPaymentHistory.bind(this);
    this.renderAccountActivity = this.renderAccountActivity.bind(this);
    this.onRecurringChecked = this.onRecurringChecked.bind(this);
    this.renderRecurringSection = this.renderRecurringSection.bind(this);
    this.renderWarningBox = this.renderWarningBox.bind(this);
    this.calculateNextValidAchDate = this.calculateNextValidAchDate.bind(this);
    this.renderNoDateSelectedAlertBox = this.renderNoDateSelectedAlertBox.bind(
      this
    );
    this.invalidLimit = this.invalidLimit.bind(this);
  }
  calculateNextValidAchDate() {
    let now = getCurrentDateTime();
    let nextPostDate = moment(this.props.nextAvailablePostingDate);
    const getNextBusinessDay = (c, h) => {
      if (
        isHoliday(c, h) ||
        !isValidPaymentDate(
          c,
          nextPostDate,
          this.props.paymentType,
          this.props.endOfDayRunHour
        )
      ) {
        return getNextBusinessDay(c.add(1, "days"), h);
      }

      return c;
    };

    const nextBusinessDay = getNextBusinessDay(now, this.props.holidays);
    return nextBusinessDay;
  }
  onDateSelected(date) {
    this.setState({ paymentDate: date });
  }
  onPaymentAmountChange(value) {
    this.setState({ paymentAmount: RemoveSpecialCharactersFromPaymentAmount(value) });
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));

    // Set the default payment date to the next available ACH payment time, instead of "today", which is the default for credit cards.
    if (this.props.paymentType === "ach") {
      this.setState({
        paymentDate: this.calculateNextValidAchDate().toDate(),
      });
    }
  }
  onValidSubmit() {
    this.props.setAccountPayment(
      this.state.paymentAmount,
      this.state.paymentDate,
      this.state.frequency
    );
    const checkoutPath = RouteHelper.getRelativeCustomerRoute(
      this.props.location
    )("/checkout/contact-info");
    this.props.router.push(checkoutPath);
    return;
  }
  onValid() {
    this.setState({ formValid: true });
  }
  onInvalid() {
    this.setState({ formValid: false });
  }
  onRecurringChecked(e, isChecked) {
    if (
      this.state.paymentDate !== null &&
      getCurrentDateTime().isSame(moment(this.state.paymentDate), "day") &&
      this.props.userCanChangePaymentDate
    ) {
      this.setState({ paymentDate: null });
    } else if (
      !this.props.userCanChangePaymentDate &&
      this.props.paymentType === "cc"
    ) {
      this.setState({
        paymentDate: this.props.nextAvailablePostingDate,
      });
    }
  }

  renderWarningBox(paymentDate, holidays) {
    var date = moment(paymentDate);
    if (isHoliday(date, holidays) || isWeekend(date)) {
      return <PreviousBusinessDayAlert t={t} />;
    }

    return null;
  }

  invalidLimit() {
    if (
      this.props.maxPayAmount !== 0 &&
      this.state.paymentAmount > this.props.maxPayAmount
    ) {
      return t(
        "Maximum payment amount is " +
          numeral(this.props.maxPayAmount).format("$0.00")
      );
    }
    if (
      this.props.minPayAmount !== 0 &&
      this.state.paymentAmount < this.props.minPayAmount
    ) {
      return t(
        "Minimum payment amount is " +
          numeral(this.props.minPayAmount).format("$0.00")
      );
    }

    return null;
  }
  renderNoDateSelectedAlertBox(paymentDate) {
    if (paymentDate === null) {
      return <NoDateSelectedAlert t={t} />;
    }

    return null;
  }
  renderRecurringSection(frequency, availableFrequencies) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
        }}
      >
        <InputLabel htmlFor="recurring-payment-frequency-select">
          {t("Payment Frequency")} &nbsp;
        </InputLabel>
        <Select
          value={frequency}
          onChange={(e) => this.setState({ frequency: e.target.value })}
          inputProps={{ id: "recurring-payment-frequency-select" }}
        >
          <MenuItem value={"None"}>{t("Please select a frequency")}</MenuItem>
          {availableFrequencies.map((f, index) => (
            <MenuItem key={index} value={f}>
              {t(f)}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }
  routeToPaymentHistory() {
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/history/select"
    );
    this.props.router.push(path);
  }
  renderAccountActivity(
    loggedIn,
    account,
    recentPayments,
    pendingPayments,
    recurringPayments,
    deletedPayments
  ) {
    const accountNumber = account.get("accountNumber");
    const recent = recentPayments.get(accountNumber);
    const pending = pendingPayments.get(accountNumber);
    const deleted = deletedPayments.get(accountNumber);
    const recurring = recurringPayments.get(accountNumber);

    if (
      loggedIn &&
      (recentPayments ||
        pendingPayments ||
        deletedPayments ||
        recurringPayments)
    ) {
      const oneWeekAgo = getCurrentDateTime()
        .subtract(7, "d")
        .format("MM/DD/YYYY");
      return (
        <AlertInfo>
          {t("Account activity since")} {oneWeekAgo}
          <ul>
            {pending && pending.count > 0 ? (
              <li>
                {pending.count} {t("payment(s) pending. Total amount: ")}{" "}
                {numeral(pending.total).format("$0,0.00")}
              </li>
            ) : (
              ""
            )}
            {recurring && recurring.count > 0 ? (
              <li>
                {recurring.count}{" "}
                {t("recurring payment(s) pending. Total amount: ")}{" "}
                {numeral(recurring.total).format("$0,0.00")}
              </li>
            ) : (
              ""
            )}
            {deleted && deleted.count > 0 ? (
              <li>
                {deleted.count} {t("payment(s) cancelled. Total amount: ")}{" "}
                {numeral(deleted.total).format("$0,0.00")}
              </li>
            ) : (
              ""
            )}
            {recent && recent.count > 0 ? (
              <li>
                {recent.count} {t("payment(s) processed. Total amount: ")}{" "}
                {numeral(recent.total).format("$0,0.00")}
              </li>
            ) : (
              ""
            )}
          </ul>
          <Button color="primary" onClick={this.routeToPaymentHistory}>
            {t("Click here to view your payment history.")}
          </Button>
        </AlertInfo>
      );
    }

    return <div />;
  }
  render() {
    return (
      <div>
        <div className="stepper">
          <PaymentStepper
            step="payment-amount"
            currentLocation={this.props.location}
          />
        </div>
        <div className="flex-container">
          <div className="flex-content">
            <h2 style={{ fontSize: "18px" }}>
              <AccountDetailItem
                account={this.props.account}
                t={this.props.t}
              />
            </h2>
            <Formsy
              role="form"
              aria-label="Payment details"
              noValidate
              onValidSubmit={this.onValidSubmit}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
            >
              <div style={{ marginTop: "10px" }}>
                <PaymentAmountField
                  name="PaymentAmount"
                  label={t("Payment Amount")}
                  t={t}
                  value={this.state.paymentAmount}
                  onChange={this.onPaymentAmountChange}
                  allowChange={this.props.allowPartialPayment}
                  defaultValue={
                    this.props.minAmountPreloadedFlg
                      ? this.props.account.get("amountDue")
                      : this.props.paymentAmount
                  }
                  minPayAmount={
                    this.props.minAmountPreloadedFlg
                      ? this.props.account.get("amountDue")
                      : this.props.minPayAmount
                  }
                  maxPayAmount={this.props.maxPayAmount}
                  error={this.invalidLimit()}
                  required
                  shouldFocusOnMount
                />
                <br />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <label hidden htmlFor="Payment Date">
                    {t("Payment Date")}
                  </label>
                  <DatePicker
                    id="Payment Date"
                    name="Payment Date"
                    label={t("Payment Date")}
                    keyboard
                    fullWidth
                    format={"MM/DD/YYYY"}
                    onChange={this.onDateSelected}
                    value={this.state.paymentDate}
                    disabled={!this.props.userCanChangePaymentDate}
                    minDate={
                      this.props.paymentType === "cc"
                        ? getCurrentDateTime().toDate()
                        : this.props.nextBusinessDay.toDate()
                    }
                    shouldDisableDate={(d) => {
                      let day = moment(d);
                      return (
                        isHoliday(day, this.props.holidays) ||
                        !isValidPaymentDate(
                          day,
                          moment(this.props.nextAvailablePostingDate),
                          this.props.paymentType,
                          this.props.endOfDayRunHour
                        )
                      );
                    }}
                    KeyboardButtonProps={{
                      title: "Date Picker Modal",
                    }}
                  />
                </MuiPickersUtilsProvider>
                {this.renderWarningBox(
                  this.state.paymentDate,
                  this.props.holidays
                )}
                {this.renderNoDateSelectedAlertBox(this.state.paymentDate)}
                <br />
                <br />
                {this.renderRecurringSection(
                  this.state.frequency,
                  this.props.availablePaymentFrequencies
                )}
                <br />
                <FocusableButton
                  variant="contained"
                  type="submit"
                  color="primary"
                  default
                  disabled={
                    !this.state.formValid ||
                    this.state.paymentDate === null ||
                    this.invalidLimit() !== null ||
                    this.state.frequency === "None"
                  }
                  style={isMobile ? mobileStyle : null}
                >
                  {t("Continue Checkout")}
                </FocusableButton>
              </div>
            </Formsy>
          </div>
          <div
            className="flex-tipBox"
            aria-label="Account Activity Tip Box"
            role="region"
          >
            <AccountActivity
              loggedIn={this.props.loggedIn}
              account={this.props.account}
              recentPayments={this.props.recentPayments}
              pendingPayments={this.props.pendingPayments}
              recurringPayments={this.props.recurringPayments}
              deletedPayments={this.props.deletedPayments}
              t={t}
            />
            <UserSignInPrompt t={t} location={this.props.location} />
            {this.state.autoPaySelected || this.props.autopayOnly ? (
              <div />
            ) : (
              <AlertInfo
                text={t("TIP_BOX_MAKE_A_PAYMENT")}
                location={this.props.location}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const accountPath = state.billing.getIn(["accounts", "current"]);
  const paymentType = state.payments.getIn(["method", "type"]);

  const maxPayAmount =
    paymentType === "ach"
      ? state.consortium.getIn([
          "customers",
          ownProps.params.accessNumber,
          "maxAchPayAmount",
        ])
      : state.consortium.getIn([
          "customers",
          ownProps.params.accessNumber,
          "maxCcPayAmount",
        ]);
  const minPayAmount =
    paymentType === "ach"
      ? state.consortium.getIn([
          "customers",
          ownProps.params.accessNumber,
          "minAchPayAmount",
        ])
      : state.consortium.getIn([
          "customers",
          ownProps.params.accessNumber,
          "minCcPayAmount",
        ]);

  const availableFrequencies =
    paymentType === "cc"
      ? state.consortium.getIn([
          "customers",
          ownProps.params.accessNumber,
          "ccPaymentFrequencyList",
        ])
      : state.consortium.getIn([
          "customers",
          ownProps.params.accessNumber,
          "achPaymentFrequencyList",
        ]);

  return {
    minPayAmount,
    maxPayAmount,
    holidays: state.consortium.get("holidays") || Map(),
    nextBusinessDay:
      state.consortium.getIn([
        "customers",
        ownProps.params.accessNumber,
        "nextBusinessDay",
      ]) || getCurrentDateTime(),
    availablePaymentFrequencies: availableFrequencies,
    minAmountPreloadedFlg: state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "minAmountPreloadedFlg",
    ]),
    nextAvailablePostingDate: state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "nextAvailablePostingDate",
    ]),
    account: state.billing.getIn(accountPath) || Map(),
    allowPartialPayment: state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "allowPartialPayment",
    ]),
    userCanChangePaymentDate: state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "userCanChangePaymentDate",
    ]),
    endOfDayRunHour: state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "endOfDayRunHour",
    ]),
    loggedIn: state.auth.get("authenticated"),
    recentPayments: state.paymentHistory.get("recentPayments"),
    pendingPayments: state.paymentHistory.get("pendingPayments"),
    recurringPayments: state.paymentHistory.get("recurringPayments"),
    deletedPayments: state.paymentHistory.get("recentlyDeletedPayments"),
    continueAsGuestSelected: state.session.get("continueAsGuestSelected"),
    paymentType: paymentType,
    guestOnly: state.consortium.getIn(["settings", "guestOnly"]),
    styles: state.style.get("palette"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
    setAccountPayment: (amount, date, frequency) => {
      dispatch(setAccountPaymentAction(amount, date, frequency));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountPayment)
);
