import { _t } from "./../text/locale";
import React from "react";
import { connect } from "react-redux";
import sanitizeHtml from "sanitize-html";
import { ResponsiveUtil } from "../utils/ResponsiveUtil";
import { setPageInfoAction } from "../api/actions/pageInfo";
import BackButton from "./../components/BackButton.jsx";

let pageTitle = "Terms and Conditions";
const t = _t();

const mobileStyle = {
  width: "100%",
  maxWidth: "none",
};
const style = {
  marginTop: "10px",
};
const isMobile = ResponsiveUtil.isMobile();

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);

    this.getContent = this.getContent.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.props.dispatchSetPageInfo(pageTitle);
  }
  getContent() {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(t("TERMS_AND_CONDITIONS_CONTENT")),
        }}
      />
    );
  }
  render() {
    return (
      <div>
        <div role="navigation" aria-label="Go Back">
          <BackButton
            route={
              this.props.shouldRouteToConfirmation
                ? this.props.confirmationLocation
                : "/"
            }
            currentLocation={this.props.location}
            backText={
              this.props.shouldRouteToConfirmation
                ? t("Payment Confirmation")
                : t("Home")
            }
            shouldFocusOnMount
          />
        </div>
        <div className="flex-container" style={isMobile ? mobileStyle : style}>
          {this.getContent()}
        </div>
      </div>
    );
  }
}

// shouldRouteToConfirmation is a bool to determine if the ToC was navigated to from the payment confirmation page.
// getRelativeAppViewRoute is used once shouldRouteToConfirmation had been determined since BackButton uses getRelativeAppViewRoute.
// The route needs to have everything stripped before "/v2/" before being supplied to the button to function properly.
const mapStateToProps = (state, ownProps) => {
  return {
    shouldRouteToConfirmation: state.session
      .get("location")
      .includes("confirm-payment"),
    confirmationLocation: state.session
      .get("location")
      .replace(new RegExp("(/v2)?/c/(\\d+)"), ""),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetPageInfo: (pageData) => {
      dispatch(setPageInfoAction(pageData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
