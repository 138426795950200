import {
  getAutoPayEnrollment,
  deactivateAutoPayEnrollment,
  submitAutoPayEnrollment,
} from "../requests/autoPay";
import {
  GET_AUTO_PAY_ENROLLMENT,
  DEACTIVATE_AUTO_PAY_ENROLLMENT,
  SUBMIT_AUTO_PAY_ENROLLMENT,
  SET_AUTO_PAY_ENROLLMENT,
} from "./actionTypes";
import { getCurrentDateTimeFormatted } from "./../../utils/DateUtils.js";

const generatePaymentMethodPayload = (method, type, id, cvv) => {
  if (!!id && type === "cc") {
    return { cvv, id };
  }

  if (type === "cc") {
    return {
      cardToken: method.get("cardNumber"),
      cvv: cvv,
      cardholderName: method.get("cardholderName"),
      cardExpiration: method.get("cardExpiration"),
      cardMeta: {
        network: method.get("cardNetwork"),
        isDebit: method.get("isDebit"),
      },
    };
  }

  if (!!id && type === "ach") {
    return { id };
  }

  if (type === "ach") {
    return {
      name: method.get("name"),
      accountNumber: method.get("accountNumber"),
      routingNumber: method.get("routingNumber"),
      accountType: method.get("accountType"),
    };
  }

  return {};
};

const determinePaymentType = (type, isSaved) => {
  const prependSave = isSaved ? "Saved" : "";
  const typeDesc = type === "cc" ? "CreditCard" : "Ach";
  return `${prependSave}${typeDesc}`;
};

export const setAutoPayEnrollmentAction = () => (dispatch) => {
  dispatch({
    type: SET_AUTO_PAY_ENROLLMENT,
  });
};

export const getAutoPayEnrollmentAction = (
  consortiumId,
  userIdo,
  bankNumber,
  companyNumber,
  billingAccountNumber
) => (dispatch, getState) => {
  const promise = getAutoPayEnrollment(
    consortiumId,
    userIdo,
    bankNumber,
    companyNumber,
    billingAccountNumber
  );
  dispatch({
    type: GET_AUTO_PAY_ENROLLMENT,
    payload: promise,
  });

  return promise;
};

export const deactivateAutoPayEnrollmentAction = (
  consortiumId,
  userIdo,
  bankNumber,
  companyNumber,
  billingAccountNumber
) => (dispatch, getState) => {
  const promise = deactivateAutoPayEnrollment(
    consortiumId,
    userIdo,
    bankNumber,
    companyNumber,
    billingAccountNumber
  );
  dispatch({
    type: DEACTIVATE_AUTO_PAY_ENROLLMENT,
    payload: promise,
  });

  return promise;
};

export const submitAutoPayEnrollmentAction = (data) => (dispatch, getState) => {
  const state = getState();

  const billing = state.billing;
  const accountPath = billing.getIn(["accounts", "current"]);
  const account = billing.getIn(accountPath);
  const accessNumber = account.get("accessNumber");

  const bc = state.consortium.getIn(["customers", accessNumber]);
  const bankNumber = bc.get("bankNumber");
  const companyNumber = bc.get("companyNumber");
  const consortiumId = bc.get("consortiumId");

  const paymentsStore = state.payments;
  const userStore = state.user;
  const payment = paymentsStore.get("current");

  const userId = userStore.get("id");

  const contact = userStore.get("contact");

  const method = paymentsStore.get("method");
  const methodId = method.get("id");
  const paymentMethod = generatePaymentMethodPayload(
    method,
    method.get("type"),
    methodId,
    data.cvv
  );
  const paymentType = determinePaymentType(method.get("type"), !!methodId);

  const payload = {
    bankNumber: bankNumber,
    companyNumber: companyNumber,
    consortiumId: consortiumId,
    accountNumber: account.get("accountNumber"),
    sessionTimeStamp: payment.get("sessionTimeStamp"),
    submissionTimeStamp: getCurrentDateTimeFormatted(),
    paymentType: paymentType,
    paymentSource: "Web",
    method: paymentMethod,
    userId: userId,
    contact: contact.toJS(),
    captchaToken: payment.get("captchaToken"),
  };

  dispatch({
    type: SUBMIT_AUTO_PAY_ENROLLMENT,
    payload: submitAutoPayEnrollment(payload),
    meta: {
      paymentCooldown: bc.get("paymentCooldown"),
      paymentCooldownRemaining: account.get("paymentCooldownRemaining"),
    },
  });
};
