import {
  PASSWORD_RESET_EMAIL,
  PASSWORD_RESET_VERIFICATION,
  USER_PASSWORD_UPDATE,
  INIT_PASSWORD_RESET
} from "./../actionTypes";

import { userSignInAction } from "./../user";

import http from "./../../utils/http";

export const initPasswordReset = () => dispatch => {
  dispatch({
    type: INIT_PASSWORD_RESET
  });
};

export const requestPasswordResetEmail = (
  consortiumId,
  username,
  email
) => dispatch => {
  // Getting tired of having actions and requests in separate files.
  // Let's try keeping them in the same file for passwordResets.
  // If it seems ok we can keep it this way, or only put complicated requests in their own file.
  var promise = http
    .post(`/api/v1/consortiums/${consortiumId}/password-resets`)
    .send({ username, email })
    .end();

  dispatch({
    type: PASSWORD_RESET_EMAIL,
    payload: { promise }
  });
};

export const validatePasswordResetToken = (
  consortiumId,
  pinAndToken
) => dispatch => {
  var promise = http
    .post(`/api/v1/consortiums/${consortiumId}/password-reset-verifications`)
    .send(pinAndToken)
    .end();

  dispatch({
    type: PASSWORD_RESET_VERIFICATION,
    meta: pinAndToken,
    payload: { promise }
  });
};

export const applyPasswordReset = (consortiumId, model) => (
  dispatch,
  getState
) => {
  const resetState = getState().passwordReset;
  const pin = resetState.get("pin");
  const resetToken = resetState.get("resetToken");
  const password = model.password;

  // The React model stores this as passwordConfirm, but the web api expects confirmPassword
  // We may want to make this consistent, time permitting.
  const confirmPassword = model.passwordConfirm;

  const changeRequest = http
    .patch(`/api/v1/consortiums/${consortiumId}/users`)
    .send({
      password: password,
      confirmPassword: confirmPassword,
      challengeType: "PinAndToken",
      challenge: { pin, resetToken }
    })
    .end();

  var promise = changeRequest.then(r => {
    dispatch(userSignInAction(r.body.username, password, consortiumId));
  });

  dispatch({
    type: USER_PASSWORD_UPDATE,
    payload: { promise }
  });
};
