import React from "react";
import numeral from "numeral";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getCurrentDateTime } from "../../utils/DateUtils";
import { Button } from "@material-ui/core";
import RouteHelper from "../../utils/RouteHelper";
import { formatPaymentAmount } from "../../utils/FormatUtil";
import AlertInfo from "../errors/AlertInfo.jsx";

class AccountActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentAmount: formatPaymentAmount(this.props.account.get("amountDue")),
      paymentDate: getCurrentDateTime().toDate(),
      formValid: false,
      frequency: "None", // None defaults to one time payments
    };

    this.routeToPaymentHistory = this.routeToPaymentHistory.bind(this);
    this.renderAccountActivity = this.renderAccountActivity.bind(this);
  }

  routeToPaymentHistory() {
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/history/select"
    );
    this.props.router.push(path);
  }
  renderAccountActivity(
    loggedIn,
    account,
    recentPayments,
    pendingPayments,
    recurringPayments,
    deletedPayments
  ) {
    const accountNumber = account.get("accountNumber");
    const recent = recentPayments.get(accountNumber);
    const pending = pendingPayments.get(accountNumber);
    const deleted = deletedPayments.get(accountNumber);
    const recurring = recurringPayments.get(accountNumber);

    if (
      loggedIn &&
      (recentPayments ||
        pendingPayments ||
        deletedPayments ||
        recurringPayments)
    ) {
      const oneWeekAgo = getCurrentDateTime()
        .subtract(7, "d")
        .format("MM/DD/YYYY");
      return (
        <AlertInfo>
          {this.props.t("Account activity since")} {oneWeekAgo}
          <ul>
            {pending && pending.count > 0 ? (
              <li>
                {pending.count}{" "}
                {this.props.t("payment(s) pending. Total amount: ")}{" "}
                {numeral(pending.total).format("$0,0.00")}
              </li>
            ) : (
              ""
            )}
            {recurring && recurring.count > 0 ? (
              <li>
                {recurring.count}{" "}
                {this.props.t("recurring payment(s) pending. Total amount: ")}{" "}
                {numeral(recurring.total).format("$0,0.00")}
              </li>
            ) : (
              ""
            )}
            {deleted && deleted.count > 0 ? (
              <li>
                {deleted.count}{" "}
                {this.props.t("payment(s) cancelled. Total amount: ")}{" "}
                {numeral(deleted.total).format("$0,0.00")}
              </li>
            ) : (
              ""
            )}
            {recent && recent.count > 0 ? (
              <li>
                {recent.count}{" "}
                {this.props.t("payment(s) processed. Total amount: ")}{" "}
                {numeral(recent.total).format("$0,0.00")}
              </li>
            ) : (
              ""
            )}
          </ul>
          <Button color="primary" onClick={this.routeToPaymentHistory}>
            {this.props.t("Click here to view your payment history.")}
          </Button>
        </AlertInfo>
      );
    }

    return <div />;
  }
  render() {
    return (
      <div>
        {this.renderAccountActivity(
          this.props.loggedIn,
          this.props.account,
          this.props.recentPayments,
          this.props.pendingPayments,
          this.props.recurringPayments,
          this.props.deletedPayments
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountActivity)
);
