import React, { Component } from "react";
import { connect } from "react-redux";

import { _t } from "./../../text/locale";
const t = _t();

class NotFound extends Component {
  UNSAFE_componentWillMount() {
    // If we use this in a third place make it a function in an external file
    document.getElementById("loading-spinner").style.display = "none";
  }
  render() {
    return (
      <div>
        <h1>{t("Page not found")}</h1>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(NotFound);
