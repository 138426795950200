import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import RouteHelper from "./../../utils/RouteHelper";
import SelectAccountList from "./../accounts/SelectAccountList.jsx";
import {
  selectRecurringPaymentAccountAction,
  getRecurringPaymentsAction,
} from "./../../api/actions/manageRecurring";
import AlertWarning from "./../errors/AlertWarning.jsx";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { setPageInfoAction } from "./../../api/actions/pageInfo";
import { _t } from "./../../text/locale";
import { List } from "immutable";

let t;
let pageTitle = "Manage Recurring Payments";

class ManageRecurringAccountSelect extends React.Component {
  constructor(props) {
    super(props);

    this.routeToRecurringPayments = this.routeToRecurringPayments.bind(this);
    this.selectRecurringPaymentAccount = this.selectRecurringPaymentAccount.bind(
      this
    );
    this.shouldFocus = this.shouldFocus.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  selectRecurringPaymentAccount(account) {
    this.props.selectRecurringPaymentAccount(account);
    const businessCustomer = this.props.consortium.getIn([
      "customers",
      account.get("accessNumber"),
    ]);
    this.props.getRecurringPayments(
      this.props.params.consortiumId,
      this.props.userId,
      businessCustomer.get("bankNumber"),
      businessCustomer.get("companyNumber"),
      account.get("accountNumber"),
      this.props.pageNumber
    );
    this.routeToRecurringPayments();
  }
  routeToRecurringPayments() {
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/recurring/view"
    );
    this.props.router.push(path);
  }
  warnIfNoAccounts(accounts) {
    if (accounts.length === 0) {
      return (
        <AlertWarning>
          <strong>{t("No loan accounts have been added.")}&nbsp;</strong>
          <br className="visible-xs" />
          {t("Please add an account.")}
        </AlertWarning>
      );
    }
    return "";
  }
  shouldFocus(bc, bcs) {
    const firstKey = Object.keys(bcs)[0];

    return bc === firstKey;
  }
  renderAccountsLists(accounts) {
    let bcs = {};
    let SelectAccountLists = [];
    accounts.forEach((account) => {
      let accessNumber = account.get("accessNumber");
      if (!(bcs[accessNumber] instanceof Array)) {
        bcs[accessNumber] = [];
      }
      bcs[accessNumber].push(account);
    });
    for (let bc in bcs) {
      if (bcs.hasOwnProperty(bc)) {
        SelectAccountLists.push(
          <div key={bc}>
            <h2>
              {this.props.consortium.getIn([
                "customers",
                bc,
                "serviceDescription",
              ])}
            </h2>
            <SelectAccountList
              t={t}
              accounts={List(bcs[bc])}
              onAccountSelected={this.selectRecurringPaymentAccount}
              shouldFocusOnMount={this.shouldFocus(bc, bcs)}
            />
          </div>
        );
      }
    }
    return <div>{SelectAccountLists}</div>;
  }
  render() {
    return (
      <div>
        <div className="flex-container">
          <div className="flex-content">
            <p>
              {t(
                "Select an account to view recurring payments. You have the following accounts:"
              )}
            </p>
            {this.renderAccountsLists(this.props.accounts)}
            {this.warnIfNoAccounts(this.props.accounts)}
          </div>
          <div
            className="flex-tipBox"
            aria-label="Recurring Payments Tip Box"
            role="region"
          >
            <AlertInfo
              text={t("TIP_BOX_MANAGE_RECURRING_PAYMENTS")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const bcs = state.billing.get("accounts").toList();
  const consortium = state.consortium;
  let accounts = [];
  bcs.forEach(function (bc) {
    if (!(bc instanceof Array)) {
      bc.forEach(function (account) {
        let accessNumber = account.get("accessNumber");
        let achFreq = consortium.getIn([
          "customers",
          accessNumber,
          "achPaymentFrequency",
        ]);
        let ccFreq = consortium.getIn([
          "customers",
          accessNumber,
          "ccPaymentFrequency",
        ]);
        if (achFreq !== "None" || ccFreq !== "None") {
          accounts.push(account);
        }
      });
    }
  });

  return {
    accounts: accounts,
    consortium: consortium,
    pageNumber: state.manageRecurring.get("pageNumber"),
    userId: state.user.get("id"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRecurringPayments: (
      consortiumId,
      userIdo,
      bankNumber,
      companyNumber,
      accountNumber,
      page
    ) => {
      dispatch(
        getRecurringPaymentsAction(
          consortiumId,
          userIdo,
          bankNumber,
          companyNumber,
          accountNumber,
          page
        )
      );
    },
    selectRecurringPaymentAccount: (account) => {
      dispatch(selectRecurringPaymentAccountAction(account));
    },
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageRecurringAccountSelect)
);
