//=== http module I copied from dv-arch SPA into this SPA!!! ====================
//- What is this?
//    This is a module that is designed to make the HTTP request process for the
//    SPA a lot simpler than just using superagent + superagent-promise.
//- Wow, neat! Why is it better?
//    This significanlly reduces the number of things that are required to be
//    known about the request by the action making the request. Things like
//    access tokens and base URLs are only this module's concern rather than the
//    action being aware of these things.
//- How do I use it?
//    import http from './../utils/http';
//    export const getSchemas = (baseUrl) => {
//      return http
//        .get('/api/v1/schemas')
//        .end();
//    };
//- What if I need to send data in the request?
//    import http from './../utils/http';
//    export const getSchemas = (data) => {
//      return http
//        .get('/api/v1/schemas')
//        .send(data)
//        .end();
//    };
//==============================================================================
import promise from "promise";
import superagent from "superagent";
import superagentPromise from "superagent-promise";
import store from "./../../redux/store";
import uuidV4 from "uuid/v4";

function getAgent() {
  return superagentPromise(superagent, promise);
}

function getBaseUrl() {
  return store.getState().config.get("apiBaseUrl");
}

function setCommonHeaders(request) {
  const accessToken = store.getState().auth.get("authToken");

  request = request
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Mwi-Correlation-Id", uuidV4());

  // eslint-disable-next-line
  if (!!accessToken) {
    request = request.set("Authorization", `Bearer ${accessToken}`);
  }

  return request;
}

export default {
  // We need a plain instance of superagent-promise for requesting the config.
  rawAgent() {
    return getAgent();
  },

  get(url) {
    let baseUrl = getBaseUrl();
    let request = getAgent().get(`${baseUrl}${url}`);
    return setCommonHeaders(request);
  },

  post(url) {
    let baseUrl = getBaseUrl();
    let request = getAgent().post(`${baseUrl}${url}`);
    return setCommonHeaders(request);
  },

  patch(url) {
    let baseUrl = getBaseUrl();
    let request = getAgent().patch(`${baseUrl}${url}`);
    return setCommonHeaders(request);
  },

  put(url) {
    let baseUrl = getBaseUrl();
    let request = getAgent().put(`${baseUrl}${url}`);
    return setCommonHeaders(request);
  },

  delete(url) {
    let baseUrl = getBaseUrl();
    let request = getAgent().del(`${baseUrl}${url}`);
    return setCommonHeaders(request);
  }
};
