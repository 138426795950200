import React from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";
import FormsyTextField from "./../../../components/form-input/FormsyTextField.jsx";
import Button from "@material-ui/core/Button";

class PasswordTokenForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pin: "",
      resetToken: "",
      valid: false
    };

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);

    this.onInput = this.onInput.bind(this);
  }
  static propTypes = {
    t: PropTypes.func.isRequired
  };
  onValidSubmit() {
    const model = Object.assign({}, this.state);
    delete model.valid;

    this.props.onSubmit(model);
  }
  onValid() {
    this.setState({ valid: true });
  }
  onInvalid() {
    this.setState({ valid: false });
  }
  onInput(name) {
    return value => {
      let update = {};
      update[name] = value;
      this.setState(update);
    };
  }
  render() {
    return (
      <Formsy
        noValidate
        onValidSubmit={this.onValidSubmit}
        onValid={this.onValid}
        onInvalid={this.onInvalid}
        role="form"
        aria-label="Password Reset"
      >
        <div>
          {this.props.havePin ? null : (
            <FormsyTextField
              name="pin"
              floatingLabelText={this.props.t("Pin")}
              onChange={this.onInput("pin")}
              value={this.state.pin}
              validationErrors={{
                isDefaultRequiredValue: this.props.t("Pin is required")
              }}
              required
              shouldFocusOnMount
            />
          )}
          {this.props.haveToken ? null : (
            <FormsyTextField
              name="resetToken"
              floatingLabelText={this.props.t("Reset Token")}
              onChange={this.onInput("resetToken")}
              value={this.state.resetToken}
              validationErrors={{
                isDefaultRequiredValue: this.props.t("Reset Token is required")
              }}
              required
              shouldFocusOnMount={this.props.havePin}
            />
          )}
        </div>
        <div>
          <br />
          <Button
            variant="contained"
            type="submit"
            disabled={!this.state.valid || this.props.disabled}
            color="primary"
          >
            {this.props.t("Submit Reset Token")}
          </Button>
        </div>
      </Formsy>
    );
  }
}

export default PasswordTokenForm;
