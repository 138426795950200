import {Map} from 'immutable';

import { INIT_PASSWORD_RESET, PASSWORD_RESET_EMAIL, PASSWORD_RESET_VERIFICATION, SUCCESS } from './../../api/actions/actionTypes';

const getDefaultState = () => {
  return Map({pin: ''});
};

const actions = {};

actions[INIT_PASSWORD_RESET] = function(state, action) {  
  return getDefaultState();
};

actions[`${PASSWORD_RESET_EMAIL}_${SUCCESS}`] = function(state, action) {  
  const body = action.payload.body;
  return state
    .set('pin', body);
};

actions[`${PASSWORD_RESET_VERIFICATION}_${SUCCESS}`] = function(state, action) {  
  const meta = action.meta;  
  return state
    .set('pin', meta.pin)
    .set('resetToken', meta.resetToken);
};

export default function(state = getDefaultState(), action) {
  if (typeof actions[action.type] !== 'function') {
    return state;
  }

  return actions[action.type](state, action);
}

