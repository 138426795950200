import React from "react";
import moment from "moment";
import { TableRow, TableCell } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { formatPaymentAmount } from "./../../utils/FormatUtil";

export default class StatementViewItem extends React.Component {
  render() {
    const item = this.props.item;
    const MonetaryCell = withStyles({
      root: {
        textAlign: "right",
      },
    })(TableCell);

    return (
      <TableRow key={`item-${item.get("key")}`}>
        <TableCell>{moment(item.get("date")).format("L")}</TableCell>
        <TableCell>{item.get("description")}</TableCell>
        <MonetaryCell>${formatPaymentAmount(item.get("amount"))}</MonetaryCell>
        <MonetaryCell>
          ${formatPaymentAmount(item.get("principal"))}
        </MonetaryCell>
        <MonetaryCell>
          ${formatPaymentAmount(item.get("interest"))}
        </MonetaryCell>
        {this.props.showFee ? (
          <MonetaryCell>${formatPaymentAmount(item.get("fee"))}</MonetaryCell>
        ) : null}
        {this.props.showEscrow ? (
          <MonetaryCell>
            ${formatPaymentAmount(item.get("escrow"))}
          </MonetaryCell>
        ) : null}
      </TableRow>
    );
  }
}
