import React from "react";
import ManageRecurring from "./ManageRecurring.jsx";
import PrintableManageRecurring from "./PrintableManageRecurring.jsx";

export const ManageRecurringContainer = (props, context) => {
  return (
    <div>
      <div className="no-print">
        <ManageRecurring {...props} />
      </div>
      <div className="only-print">
        <PrintableManageRecurring {...props} />
      </div>
    </div>
  );
};

export default ManageRecurringContainer;
