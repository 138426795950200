import React from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";
import FormsyTextField from "./../../../components/form-input/FormsyTextField.jsx";
import Button from "@material-ui/core/Button";

class PasswordResetEmailForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      username: "",
      valid: false,
    };

    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);

    this.onInput = this.onInput.bind(this);
  }
  static propTypes = {
    t: PropTypes.func.isRequired,
  };
  onValidSubmit() {
    const model = Object.assign({}, this.state);
    delete model.valid;

    this.props.onSubmit(model);
  }
  onValid() {
    this.setState({ valid: true });
  }
  onInvalid() {
    this.setState({ valid: false });
  }
  onInput(name) {
    return (value) => {
      let update = {};
      update[name] = value;
      this.setState(update);
    };
  }
  render() {
    return (
      <Formsy
        noValidate
        onValidSubmit={this.onValidSubmit}
        onValid={this.onValid}
        onInvalid={this.onInvalid}
      >
        <div role="form" aria-label="Reset Password">
          <div style={{ height: "110px", marginBottom: "10px" }}>
            <FormsyTextField
              name="username"
              floatingLabelText={this.props.t("Username")}
              onChange={this.onInput("username")}
              value={this.state.username}
              validationErrors={{
                isDefaultRequiredValue: this.props.t("Username is required"),
              }}
              required
              shouldFocusOnMount
            />
            <FormsyTextField
              name="email"
              floatingLabelText={this.props.t("Email Address")}
              onChange={this.onInput("email")}
              value={this.state.email}
              validations={"isEmail"}
              validationErrors={{
                isDefaultRequiredValue: this.props.t(
                  "Email Address is required"
                ),
                isEmail: this.props.t("Please enter a valid email address"),
              }}
              required
            />
          </div>
          <Button
            variant="contained"
            type="submit"
            disabled={!this.state.valid || this.props.disabled}
            color={"primary"}
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            {this.props.t("Send Reset Email")}
          </Button>
        </div>
        <Button
          onClick={this.props.onSkip}
          style={{
            color: this.props.linkColor,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          {this.props.t("I already have a pin and token")}
        </Button>
      </Formsy>
    );
  }
}

export default PasswordResetEmailForm;
