import { Button } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setPageInfoAction } from "../../api/actions/pageInfo";
import { getStoredSamlPayloadAction } from "../../api/actions/user";
import { _t } from "../../text/locale";
import RouteHelper from "../../utils/RouteHelper";
import AlertInfo from "../errors/AlertInfo.jsx";
import queryString from "query-string";

let t;
let pageTitle = "Welcome";

export class SsoLanding extends Component {
  constructor(props) {
    super(props);

    this.routeToSsoLink = this.routeToSsoLink.bind(this);
    this.routeToSsoRegister = this.routeToSsoRegister.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
    const queryParamValue = queryString.parse(this.props.location.search);

    this.props.dispatchGetStoredSamlPayload(
      this.props.params.consortiumId,
      queryParamValue.id
    );
  }

  routeToSsoLink(){
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/sso/link"
    );
    this.props.router.push(path);
  }

  routeToSsoRegister() {
    const path = RouteHelper.getRelativeAppViewRoute(this.props.location)(
      "/user/sso/register"
    );
    this.props.router.push(path);
  }

  render() {
    return (
      <div className="flex-container">
        <div className="flex-content">
          <h3>{t("Are you an existing or new user of LoanPay Xpress?")}</h3>
          <Button
            variant="contained"
            onClick={this.routeToSsoLink}
            color="primary"
          >
            {t("Existing User")}
          </Button>{" "}
          <Button
            variant="contained"
            onClick={this.routeToSsoRegister}
            color="primary"
          >
            {t("New User")}
          </Button>
        </div>
        <div className="flex-tipBox" aria-label="Sign in Tip Box" role="region">
          <AlertInfo
            text={t("TIP_BOX_SSO_LANDING")}
            location={this.props.location}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pageTitle: state.session.get("pageData"),
    linkColor: state.style.getIn(["palette", "accent3Color"])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetPageInfo: pageData => {
      dispatch(setPageInfoAction(pageData));
    },
    dispatchGetStoredSamlPayload: (consortiumId, ssoRedisKey) => {
      dispatch(getStoredSamlPayloadAction(consortiumId, ssoRedisKey));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SsoLanding)
);
