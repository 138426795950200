import { Map, List, fromJS } from "immutable";

import {
  RETRIEVE_STORED_SAML_PAYLOAD,
  SET_CONTACT_INFO,
  REGISTER_USER,
  REGISTER_SSO_USER,
  AUTHENTICATE_USER,
  AUTHENTICATE_SSO,
  GET_USER_PAYMENT_METHODS,
  ADD_USER_PAYMENT_METHOD,
  DELETE_USER_PAYMENT_METHOD,
  UPDATE_CONTACT_INFO,
  REGISTER_NEW_USER_CHAIN,
  CLAIM_PAYMENT_ITEM,
  SUCCESS,
} from "../../api/actions/actionTypes";

const getDefaultState = () => {
  return Map({
    contact: Map(),
    paymentMethods: Map({ cc: List(), ach: List() }),
  });
};

const actions = {};

actions[SET_CONTACT_INFO] = function (state, action) {
  let payload = action.payload;
  return state.set("contact", Map(payload));
};

actions[`${RETRIEVE_STORED_SAML_PAYLOAD}_${SUCCESS}`] = function (
  state,
  action
) {
  let body = action.payload.body;
  return state.set("storedSamlPayload", fromJS(body));
};

actions[`${REGISTER_USER}_${SUCCESS}`] = function (state, action) {
  let body = action.payload.body;
  return state.merge(fromJS(body));
};

actions[`${AUTHENTICATE_USER}_${SUCCESS}`] = function (state, action) {
  let body = action.payload.body.user;
  return state.merge(fromJS(body)).delete("accounts");
};

actions[`${AUTHENTICATE_SSO}_${SUCCESS}`] = function (state, action) {
  let body = action.payload.body.user;
  return state.merge(fromJS(body)).delete("accounts");
};

actions[`${REGISTER_SSO_USER}_${SUCCESS}`] = function (state, action) {
  let body = action.payload.body.user;
  return state.merge(fromJS(body)).delete("accounts");
};

actions[`${GET_USER_PAYMENT_METHODS}_${SUCCESS}`] = function (state, action) {
  let body = action.payload.body;
  return state.set("paymentMethods", fromJS(body));
};

actions[`${ADD_USER_PAYMENT_METHOD}_${SUCCESS}`] = function (state, action) {
  // Add the item we created to state
  const type = action.meta.type;
  const method = fromJS(action.payload.body);

  const methods = state.getIn(["paymentMethods", type]);
  return state.setIn(["paymentMethods", type], methods.concat(List.of(method)));
};

actions[`${DELETE_USER_PAYMENT_METHOD}_${SUCCESS}`] = function (state, action) {
  // Remove the item we deleted locally so we don't have to requery the database
  const type = action.meta.type;
  const id = action.meta.methodId;

  const methods = state.getIn(["paymentMethods", type]);

  return state.setIn(
    ["paymentMethods", type],
    methods.filter((m) => m.get("id") !== id)
  );
};

actions[`${UPDATE_CONTACT_INFO}_${SUCCESS}`] = function (state, action) {
  let body = action.payload.body;
  return state.set("contact", Map(body));
};

actions[REGISTER_NEW_USER_CHAIN] = function (state, action) {
  return state.set("registrationStatus", action.payload);
};

actions[`${CLAIM_PAYMENT_ITEM}_${SUCCESS}`] = function (state, action) {
  return state.set("paymentItem", action.payload);
};

export default function (state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  try {
    return actions[action.type](state, action);
  } catch (error) {
    return state;
  }
}
