import { Map, fromJS } from "immutable";
import {
  GET_STYLE_CUSTOMIZATION,
  SUCCESS
} from "../../api/actions/actionTypes";

import * as Colors from "@material-ui/core/colors";

const afiBlue = "#346C82";
const afiOrange = "#B05E11";
const afiLightBlue = "#5c899b";
const white = "#ffffff";
const darkBlack = "#000000";

const defaultStyle = {
  palette: {
    primary1Color: afiBlue, //appbar, buttons
    primary2Color: afiLightBlue, //datepicker
    primary3Color: Colors.blue[50], //sidebar, tipbox, input text box underline
    accent1Color: afiOrange, //warning box
    accent2Color: Colors.grey[100],
    accent3Color: afiBlue, //sidebar text
    textColor: darkBlack, //sidebar title, tipbox text, main text
    alternateTextColor: white, //appbar text, button text
    canvasColor: white,
    borderColor: Colors.grey[300],
    pickerHeaderColor: Colors.cyan[500],
    mobileSpinnerColor: Colors.cyan[500]
  }
};

const getDefaultState = () => {
  return fromJS(defaultStyle);
};

const actions = {};

actions[`${GET_STYLE_CUSTOMIZATION}_${SUCCESS}`] = function(state, action) {
  if (!action.payload.body || action.payload.body.length <= 0) {
    return state;
  }

  const addHashIfDefined = str => (str ? "#" + str : "");

  const styles = Map(action.payload.body[0]);
  const mappedStyles = Map({
    primary1Color: addHashIfDefined(styles.get("primary1Color")),
    primary2Color: addHashIfDefined(styles.get("primary2Color")),
    primary3Color: addHashIfDefined(styles.get("primary3Color")),
    accent1Color: addHashIfDefined(styles.get("accent1Color")),
    accent2Color: addHashIfDefined(styles.get("accent2Color")),
    accent3Color: addHashIfDefined(styles.get("accent3Color")),
    textColor: addHashIfDefined(styles.get("primaryTextColor")),
    alternateTextColor: addHashIfDefined(styles.get("alternateTextColor")),
    pickerHeaderColor: addHashIfDefined(styles.get("primary1Color")),
    borderColor: addHashIfDefined(styles.get("borderColor"))
  });

  return state
    .updateIn(["palette"], p =>
      p.mergeWith((oldVal, newVal) => (newVal ? newVal : oldVal), mappedStyles)
    )
    .set("logoPath", styles.get("logoPath"));
};

export default function(state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }
  return actions[action.type](state, action);
}
