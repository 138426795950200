import React from "react";
import { ListItemText, ListItemAvatar, Avatar } from "@material-ui/core";
import ContentAdd from "@material-ui/icons/Add";
import FocusableListItem from "../../../components/FocusableListItem.jsx";

class NewPaymentMethod extends React.Component {
  render() {
    return (
      <FocusableListItem
        onClick={this.props.onClick}
        shouldFocusOnMount={this.props.shouldFocusOnMount}
      >
        <ListItemAvatar>
          <Avatar style={this.props.avatarStyle}>
            <ContentAdd />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={this.props.title} />
      </FocusableListItem>
    );
  }
}

export default NewPaymentMethod;
