import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import RouteHelper from "./../../../utils/RouteHelper";

import {
  PaymentConfirmation,
  PaymentFollowUpActions,
} from "./PaymentReceipt.jsx";
import {
  AutoPayEnrollmentConfirmation,
  AutoPayEnrollmentFollowUpActions,
} from "./AutoPayEnrollmentReceipt.jsx";
import RegisterAccountPitch from "./RegisterAccountPitch.jsx";

import AlertInfo from "./../../errors/AlertInfo.jsx";

import { getRecentPaymentsAction } from "./../../../api/actions/paymentHistory";
import { recordLocationAction } from "./../../../api/actions/session";
import { _t } from "./../../../text/locale";
import { setPageInfoAction } from "./../../../api/actions/pageInfo";
import PaymentStepper from "../../../components/PaymentStepper.jsx";

let t;

export class ThankYou extends React.Component {
  constructor(props) {
    super(props);

    this.routeToRegisterAccount = this.routeToRegisterAccount.bind(this);
    this.routeToNewPayment = this.routeToNewPayment.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);

    let pageTitle = this.props.isAutoPay
      ? "Your enrollment has been accepted"
      : "Your payment has been accepted";

    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  routeToRegisterAccount() {
    this.props.clearLocation();
    this.props.recordLocation(this.props.location.pathname);
    const registerAccountPath = RouteHelper.getRelativeAppViewRoute(
      this.props.location
    )("/user/register");
    this.props.router.push(registerAccountPath);
  }
  routeToNewPayment() {
    const newPaymentPath = RouteHelper.getRelativeAppViewRoute(
      this.props.location
    )("/");
    this.props.router.push(newPaymentPath);
  }

  render() {
    return (
      <div>
        <div className="stepper">
          <PaymentStepper
            step="payment-receipt"
            currentLocation={this.props.location}
          />
        </div>
        <div className="flex-container">
          <div className="flex-content">
            {this.props.isAutoPay ? (
              <AutoPayEnrollmentConfirmation t={t} {...this.props} />
            ) : (
              <PaymentConfirmation t={t} {...this.props} />
            )}
            <br />
            {this.props.isAutoPay ? (
              <AutoPayEnrollmentFollowUpActions
                t={t}
                {...this.props}
                makeAnotherPaymentClick={this.routeToNewPayment}
              />
            ) : (
              <PaymentFollowUpActions
                t={t}
                {...this.props}
                makeAnotherPaymentClick={this.routeToNewPayment}
              />
            )}
          </div>
          <div
            className="flex-tipBox"
            aria-label="Thank you Tip Box"
            role="region"
          >
            {this.props.loggedIn || this.props.guestOnly ? (
              <AlertInfo
                text={
                  this.props.isAutoPay
                    ? t("TIP_BOX_AUTO_PAY_ENROLLMENT_ACCEPTED")
                    : t("TIP_BOX_PAYMENT_ACCEPTED")
                }
                location={this.props.location}
              />
            ) : (
              <RegisterAccountPitch
                t={t}
                onRegisterAccountClick={this.routeToRegisterAccount}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
    getRecentPayments: (consortiumId, userId) => {
      dispatch(getRecentPaymentsAction(consortiumId, userId));
    },
    clearLocation: () => {
      dispatch(recordLocationAction(""));
    },
    recordLocation: (location) => {
      dispatch(recordLocationAction(location));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const currentPayment = state.payments.get("current");
  const total = state.payments.getIn(["current", "total"]);
  const loggedIn = state.auth.get("authenticated");
  const currentAccountPath = state.billing.getIn(["accounts", "current"]);
  const account = state.billing.getIn(currentAccountPath);

  const accountNumber = account.get("formattedAccountNumber");
  const confirmationNumber = state.payments.getIn([
    "current",
    "confirmationNumber",
  ]);
  const processDate = state.payments.getIn(["current", "date"]);
  const frequency = state.payments.getIn(["current", "frequency"]);

  const accessNumber = state.billing.getIn(["accounts", "current"])[1];
  const bc = state.consortium.getIn(["customers", accessNumber]);
  const companyName = bc.get("companyName");
  const website = bc.get("companyWebsite");

  const style = state.style;
  const userIdo = state.user.get("id");
  const guestOnly = state.consortium.getIn(["settings", "guestOnly"]);

  const isAutoPay = state.payments.getIn(["current", "isAutoPay"]);
  const billingProcessType = state.consortium.getIn([
    "customers",
    ownProps.params.accessNumber,
    "billingProcessType",
  ]);

  // If a deposit account is selected, get the name
  let depositAccountName = "";
  if (currentPayment.has("depositAccountReferenceId")) {
    const depositAccountReferenceId = currentPayment.get(
      "depositAccountReferenceId"
    );

    const depositAccountList = state.consortium.getIn([
      "customers",
      ownProps.params.accessNumber,
      "depositAccounts",
    ]);

    depositAccountName = depositAccountList
      .find((da) => da.get("referenceId") === depositAccountReferenceId)
      .get("name");
  }

  return {
    companyName,
    website,
    paymentAmount: total,
    billingAccount: accountNumber,
    confirmationNumber: confirmationNumber,
    frequency: frequency,
    processDate: processDate,
    style: style,
    loggedIn: loggedIn,
    userIdo: userIdo,
    guestOnly: guestOnly,
    isAutoPay: isAutoPay,
    isAutoPayOnly: billingProcessType === 98,
    depositAccountName: depositAccountName,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ThankYou)
);
