import React from "react";
import StatementView from "./StatementView.jsx";

import { GET_STATEMENT, FAILURE } from "./../../api/actions/actionTypes";
import { getStatementAction } from "./../../api/actions/statementView";
import {
  showLoadingAction,
  hideLoadingAction
} from "./../../api/actions/loading";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { _t } from "./../../text/locale";

import { setPageInfoAction } from "./../../api/actions/pageInfo";

let t;
let pageTitle = "View Statement";

class ConnectedStatementView extends React.Component {
  constructor(props) {
    super(props);

    this.moveToNextPage = this.moveToNextPage.bind(this);
    this.moveToPreviousPage = this.moveToPreviousPage.bind(this);
    this.loadStatementView = this.loadStatementView.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.pending === true) {
      this.props.showLoading(t("Retrieving statement..."));
    } else if (
      !nextProps.loadDeleting &&
      this.props.loadDeleting &&
      !nextProps.lastDeletingFailed
    ) {
      this.loadStatementView(
        this.props.selectedAccount,
        this.props.userId,
        this.props.params.consortiumId,
        this.props.pageNumber
      );
    } else if (nextProps.loadDeleting) {
      this.props.showLoading(t("Deleting payment..."));
    } else {
      this.props.hideLoading();
    }
  }
  loadStatementView(account, userId, consortiumId, page) {
    this.props.getStatementView(
      account.get("bankNumber"),
      account.get("companyNumber"),
      account.get("accountNumber"),
      page
    );
  }
  moveToNextPage(account, userId, consortiumId, page) {
    this.props.getStatementView(
      account.get("bankNumber"),
      account.get("companyNumber"),
      account.get("accountNumber"),
      page + 1
    );
  }
  moveToPreviousPage(account, userId, consortiumId, page) {
    this.props.getStatementView(
      account.get("bankNumber"),
      account.get("companyNumber"),
      account.get("accountNumber"),
      page - 1
    );
  }

  render() {
    return (
      <StatementView
        {...this.props}
        t={t}
        nextPage={this.moveToNextPage}
        previousPage={this.moveToPreviousPage}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activityItems: state.statement.get("activityItems"),
    summaryItems: state.statement.get("summaryItems"),
    firstPage: state.statement.get("pageNumber") === 1,
    lastPage: state.statement.get("lastPage"),
    pageNumber: state.statement.get("pageNumber"),
    pending: !!state.httpRequests.getIn([GET_STATEMENT, "pending"]),
    lastRequestFailed:
      state.httpRequests.getIn([GET_STATEMENT, "lastStatus"]) === FAILURE,
    selectedAccount: state.statement.get("selectedAccount"),
    userId: state.user.get("id"),
    styles: state.style.get("palette")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getStatementView: (bankNumber, companyNumber, accountNumber, page) => {
      dispatch(
        getStatementAction(bankNumber, companyNumber, accountNumber, page)
      );
    },
    showLoading: titleText => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
    dispatchSetPageInfo: pageInfoData => {
      dispatch(setPageInfoAction(pageInfoData));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConnectedStatementView)
);
