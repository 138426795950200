import React from "react";

import { connect } from "react-redux";
import {
  Table,
  TableRow,
  TableCell,
  Button,
  TableBody,
} from "@material-ui/core";
import { _t } from "../../../text/locale";
import { ResponsiveUtil } from "../../../utils/ResponsiveUtil";
import FocusableButton from "../../../components/FocusableButton.jsx";

export const AutoPayEnrollmentReceipt = (props, context) => {
  const t = props.t;
  const confirmationNumber = props.confirmationNumber;
  const billingAccount = props.billingAccount;
  const columnStyles = { wordWrap: "break-word", whiteSpace: "normal" };
  return (
    <div>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={columnStyles}>
              <b>{t("Account Number")}</b>
            </TableCell>
            <TableCell style={columnStyles}>{billingAccount}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={columnStyles}>
              <b>{t("Confirmation Number")}</b>
            </TableCell>
            <TableCell style={columnStyles}>{confirmationNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ ...columnStyles, borderBottom: "none" }}>
              <b>{t("Frequency")}</b>
            </TableCell>
            <TableCell style={{ ...columnStyles, borderBottom: "none" }}>
              {t("Auto")}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export const AutoPayEnrollmentFollowUpActions = (props, context) => {
  const isMobile = ResponsiveUtil.isMobile();
  const mobileStyle = {
    width: "100%",
    marginBottom: "10px",
  };

  var returnMessage = props.t("BUTTON_RETURN_TO_COMPANY");
  // If there's no override, generate a reasonable default based on the consortium name
  if (returnMessage === "BUTTON_RETURN_TO_COMPANY") {
    returnMessage = `RETURN TO ${props.companyName}`;
  }

  return (
    <div className="flex-container">
      <div className="flex-content">
        {props.isAutoPayOnly ? (
          <div />
        ) : (
          <FocusableButton
            variant="contained"
            onClick={props.makeAnotherPaymentClick}
            style={isMobile ? mobileStyle : null}
            color="primary"
            shouldFocusOnMount
          >
            {props.t("Make A One Time Payment")}
          </FocusableButton>
        )}
      </div>
      <div className="flex-tipBox">
        {props.website !== "" ? (
          <Button
            variant="contained"
            href={props.website}
            style={isMobile ? mobileStyle : null}
            color="primary"
          >
            <span
              style={{
                color: props.style.getIn(["palette", "alternateTextColor"]),
              }}
            >
              {returnMessage}
            </span>
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export const AutoPayEnrollmentConfirmation = (props, context) => {
  return <AutoPayEnrollmentReceipt {...props} />;
};

export const PrintAutoPayEnrollmentReceipt = (props, context) => {
  const t = props.t;
  const gutter = props.style.getIn(["spacing", "desktopGutter"]);
  const padding = { paddingLeft: gutter, paddingRight: gutter };
  return (
    <div>
      <h2 style={padding}>
        {props.companyName} - {props.description}
      </h2>
      <h2 style={padding}>{t("Your Enrollment has been Accepted")}</h2>{" "}
      {/*All the text should be aligned*/}
      <AutoPayEnrollmentReceipt {...props} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const noEnrollmentToPrint = !state.payments.getIn(["current", "response"]);
  const style = state.style;
  if (noEnrollmentToPrint) {
    return {
      t: _t(),
      style: style,
    };
  }

  const currentAccountPath = state.billing.getIn(["accounts", "current"]);
  const account = state.billing.getIn(currentAccountPath);

  const accountNumber = account.get("formattedAccountNumber");
  const confirmationNumber = state.payments.getIn([
    "current",
    "confirmationNumber",
  ]);

  const accessNumber = state.billing.getIn(["accounts", "current"])[1];
  const bc = state.consortium.getIn(["customers", accessNumber]);
  const companyName = bc.get("companyName").trim();
  const description = bc.get("serviceDescription").trim();

  return {
    companyName,
    description,
    billingAccount: accountNumber,
    confirmationNumber: confirmationNumber,
    t: _t(accessNumber),
    style: style,
  };
};

export const ConnectedAutoPayEnrollmentReceipt = connect(mapStateToProps)(
  PrintAutoPayEnrollmentReceipt
);
