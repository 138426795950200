import { Map } from "immutable";

import { GET_LOCALE_CUSTOMIZATION, SUCCESS } from "../../api/actions/actionTypes";

const getDefaultState = () => {
  return Map({
    currentLocale: "en-US",
    locales: ["en-US"],
    "en-US": Map({
      consortium: Map()
    }),
    termsAndConditions: Map({
      text: "",
      show: false
    }),
    privacyPolicy: Map({
      text: "",
      show: false
    })
  });
};

const actions = {};

actions[`${GET_LOCALE_CUSTOMIZATION}_${SUCCESS}`] = function(state, action) {
  const payload = action.payload;
  const locale = action.meta.type;
  return state.mergeDeepIn([locale], payload.body);
};

export default function(state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  return actions[action.type](state, action);
}
