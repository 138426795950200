import { Map, fromJS } from "immutable";

import {
  AUTHENTICATE_GUEST,
  AUTHENTICATE_USER,
  AUTHENTICATE_SSO,
  REGISTER_SSO_USER,
  SUCCESS
} from "./../../api/actions/actionTypes";

const getDefaultState = () => {
  return Map();
};

const actions = {};

const handleAuthBody = (state, action) => {
  const body = action.payload.body;
  const accessToken = fromJS(body).getIn(["accessToken", "accessToken"]);
  return state.set("authToken", accessToken).set("authenticated", true);
};

actions[`${AUTHENTICATE_GUEST}_${SUCCESS}`] = function (state, action) {
  /* Read the access token out of the body, where the body is shaped like 
  {
    "accessToken":
    {
      "accessToken":"token-is-here",
      "expiresIn":"01:00:00",
      "tokenType":"Bearer"
    },
    "user":null
  }
  */
  const body = action.payload.body;
  const accessToken = fromJS(body).getIn(["accessToken", "accessToken"]);
  return state.set("authToken", accessToken);
};

export default function (state = getDefaultState(), action) {
  if (typeof actions[action.type] !== "function") {
    return state;
  }

  return actions[action.type](state, action);
}

actions[`${AUTHENTICATE_USER}_${SUCCESS}`] = function (state, action) {
  return handleAuthBody(state, action);
};

actions[`${AUTHENTICATE_SSO}_${SUCCESS}`] = function (state, action) {
  return handleAuthBody(state, action);
};

actions[`${REGISTER_SSO_USER}_${SUCCESS}`] = function (state, action) {
  return handleAuthBody(state, action);
};
