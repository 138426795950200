// ASYNC POSTFIXES
export const PENDING = "PENDING";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";

// ACTION TYPES
export const INIT_COMPLETE = "INIT_COMPLETE";

export const RETRIEVE_STORED_SAML_PAYLOAD = "RETRIEVE_STORED_SAML_PAYLOAD";

export const GET_CONFIG = "GET_CONFIG";
export const GET_LOCALE = "GET_LOCALE";
export const GET_LOCALE_CUSTOMIZATION = "GET_LOCALE_CUSTOMIZATION";
export const GET_STYLE_CUSTOMIZATION = "GET_STYLE_CUSTOMIZATION";
export const GET_EXAMPLES = "GET_EXAMPLES";
export const GET_CONSORTIUM = "GET_CONSORTIUM";
export const GET_CONSORTIUM_NAME = "GET_CONSORTIUM_NAME";

export const GET_BC_HOLIDAYS = "GET_BC_HOLIDAYS";
export const GET_BC_NEXT_BUSINESS_DAY = "GET_BC_NEXT_BUSINESS_DAY";

export const GET_USER_ACCOUNTS = "GET_USER_ACCOUNTS";
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const SELECT_ACCOUNT = "SELECT_ACCOUNT";
export const SHOW_CONFIRMATION_DIALOG = "SHOW_CONFIRMATION_DIALOG";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

export const LOADING_SHOW = "LOADING_SHOW";
export const LOADING_HIDE = "LOADING_HIDE";

export const SET_CONTACT_INFO = "SET_CONTACT_INFO";
export const SET_ACCOUNT_PAYMENT = "SET_ACCOUNT_PAYMENT";
export const CLEAR_ACCOUNT_PAYMENT = "CLEAR_ACCOUNT_PAYMENT";
export const SET_AUTO_PAY_ENROLLMENT = "SET_AUTO_PAY_ENROLLMENT";
export const SET_CARD_INFO = "SET_CARD_INFO";
export const GET_CARD_TOKEN = "GET_CARD_TOKEN";
export const GET_ACH_TOKENS = "GET_ACH_TOKENS";
export const SUBMIT_TRANSACTION = "SUBMIT_TRANSACTION";
export const GET_CARD_FEE = "GET_CARD_FEE";

export const AUTHENTICATE_GUEST = "AUTHENTICATE_GUEST";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_SSO = "AUTHENTICATE_SSO";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_SSO_USER = "REGISTER_SSO_USER";
export const ADD_USER_ACCOUNT = "ADD_USER_ACCOUNT";
export const DELETE_USER_ACCOUNT = "DELETE_USER_ACCOUNT";
export const GET_USER_PAYMENT_METHODS = "GET_USER_PAYMENT_METHODS";
export const DELETE_USER_PAYMENT_METHOD = "DELETE_USER_PAYMENT_METHOD";
export const ADD_USER_PAYMENT_METHOD = "ADD_USER_PAYMENT_METHOD";
export const UPDATE_CONTACT_INFO = "UPDATE_CONTACT_INFO";
export const USERNAME_REMINDER = "USERNAME_REMINDER";
export const CLAIM_PAYMENT_ITEM = "CLAIM_PAYMENT_ITEM";
export const TOKENIZE_AND_ADD_USER_PAYMENT_METHOD =
  "TOKENIZE_AND_ADD_USER_PAYMENT_METHOD";
export const VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD =
  "VALIDATE_TOKENIZE_AND_SELECT_PAYMENT_METHOD";
export const ATTACH_CAPTCHA = "ATTACH_CAPTCHA";

export const SET_DEPOSIT_ACCOUNT_REFERENCE = "SET_DEPOSIT_ACCOUNT_REFERENCE";

// Used to determine if the chain of requests after registering a user has resolved.
export const REGISTER_NEW_USER_CHAIN = "REGISTER_NEW_USER_CHAIN";

// Password Reset
export const INIT_PASSWORD_RESET = "INIT_PASSWORD_RESET";
export const PASSWORD_RESET_EMAIL = "PASSWORD_RESET_EMAIL";
export const PASSWORD_RESET_VERIFICATION = "PASSWORD_RESET_VERIFICATION";
export const USER_PASSWORD_UPDATE = "USER_PASSWORD_UPDATE";

// Payment History
export const GET_PAYMENT_HISTORY = "GET_PAYMENT_HISTORY";
export const SELECT_PAYMENT_HISTORY_ACCOUNT = "SELECT_PAYMENT_HISTORY_ACCOUNT";
export const GET_RECENT_PAYMENTS = "GET_RECENT_PAYMENTS";

// Statement View
export const GET_STATEMENT = "GET_STATEMENT";
export const SELECT_STATEMENT_ACCOUNT = "SELECT_STATEMENT_ACCOUNT";

export const GET_RECURRING_PAYMENTS = "GET_RECURRING_PAYMENTS";
export const SELECT_RECURRING_PAYMENT_ACCOUNT =
  "SELECT_RECURRING_PAYMENT_ACCOUNT";
export const CANCEL_PAYMENT = "CANCEL_PAYMENT";

// AutoPay
export const GET_AUTO_PAY_ENROLLMENT = "GET_AUTO_PAY_ENROLLMENT";
export const DEACTIVATE_AUTO_PAY_ENROLLMENT = "DEACTIVATE_AUTO_PAY_ENROLLMENT";
export const SUBMIT_AUTO_PAY_ENROLLMENT = "SUBMIT_AUTO_PAY_ENROLLMENT";

// SESSION ACTIONS
export const SESSION_BEGIN = "SESSION_BEGIN";
export const SESSION_TIMEOUT_WARN = "SESSION_TIMEOUT_WARN";
export const SESSION_TIMEOUT = "SESSION_TIMEOUT";
export const SESSION_END = "SESSION_END";
export const SESSION_IDLE_WARN = "SESSION_IDLE_WARN";
export const SET_PAGE_INFO = "SET_PAGE_INFO";

// Sign-In Prompts
export const RECORD_LOCATION = "RECORD_LOCATION";
export const CONTINUE_AS_GUEST_SELECTED = "CONTINUE_AS_GUEST_SELECTED";

export const BLOCKED_BIN = "BLOCKED_BIN";
export const INVALID_CARD = "INVALID_CARD";
