import {
  SESSION_BEGIN,
  SESSION_TIMEOUT,
  SESSION_TIMEOUT_WARN,
  RECORD_LOCATION,
  CONTINUE_AS_GUEST_SELECTED
} from "./actionTypes";

var sessionTimeout;
var sessionTimeoutWarning;

function handleSessionTimedOut(dispatch, getState) {
  dispatch({
    type: SESSION_TIMEOUT
  });

  sessionTimeout = null;
}

function handleSessionTimeOutWarn(dispatch, getState) {
  dispatch({
    type: SESSION_TIMEOUT_WARN
  });

  sessionTimeoutWarning = null;
}

export const sessionStartedAction = () => (dispatch, getState) => {
  dispatch({
    type: SESSION_BEGIN
  });

  // 3570000 <-- 59:30 (in ms)
  // Will give us a 30 second gap between when we say
  // the token expires to when it actually will.
  if (!sessionTimeout) {
    sessionTimeout = setTimeout(() => {
      handleSessionTimedOut(dispatch, getState);
    }, 3570000);
  }

  if (!sessionTimeoutWarning) {
    sessionTimeoutWarning = setTimeout(() => {
      handleSessionTimeOutWarn(dispatch, getState);
    }, 3270000);
  }
};

export const recordLocationAction = location => (dispatch, getState) => {
  dispatch({
    type: RECORD_LOCATION,
    payload: location
  });
};

export const continueAsGuestAction = () => (dispatch, getState) => {
  dispatch({
    type: CONTINUE_AS_GUEST_SELECTED
  });
};
