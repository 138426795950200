import React from "react";
import { connect } from "react-redux";
import { ResponsiveUtil } from "./../../utils/ResponsiveUtil";

import TipContent from "./../../components/TipContent.jsx";

const isMobile = ResponsiveUtil.isMobile();

class AlertInfo extends React.Component {
  render() {
    const baseStyle = {
      backgroundColor: this.props.backgroundColor,
      color: this.props.textColor,
      borderColor: this.props.backgroundColor,
      marginTop: "10px",
      marginBottom: "10px"
    };

    const style = {
      padding: "10px",
      ...baseStyle
    };

    const mobileStyle = {
      width: "100%",
      maxWidth: "none",
      ...baseStyle
    };

    if (this.props.text) {
      return (
        <div
          className="alert alert-info"
          style={isMobile ? mobileStyle : style}
        >
          <TipContent
            text={this.props.text}
            styles={this.props.styles}
            location={this.props.location}
          />
        </div>
      );
    }

    return (
      <div className="alert alert-info" style={isMobile ? mobileStyle : style}>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // The hideous default styles are here to make it so the component won't crash if we aren't supplying state correctly,
    //    while somewhat notifying the dev that something is probably wrong here.
    backgroundColor: state.style.getIn(["palette", "primary3Color"], "Pink"),
    textColor: state.style.getIn(["palette", "textColor"], "DarkBlack"),
    styles: state.style.get("palette")
  };
};

export default connect(mapStateToProps)(AlertInfo);
