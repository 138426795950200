import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  MuiThemeProvider,
  createMuiTheme,
  // spacing
} from "@material-ui/core/styles";

class CustomThemeProvider extends Component {
  render() {
    const palette = {
      primary: {
        main: this.props.palette.get("primary1Color"),
        light: this.props.palette.get("primary2Color"),
        dark: this.props.palette.get("primary3Color")
      },
      secondary: {
        main: this.props.palette.get("accent1Color"),
        light: this.props.palette.get("accent2Color"),
        dark: this.props.palette.get("accent3Color")
      },
      error: {
        main: "#c70000"
      }
    };

    const theme = createMuiTheme({
      typography: {
        // spacing: spacing,
        fontFamily: "Droid Sans, sans-serif",
        fontSize: "medium",
        useNextVariants: true
      },
      // Manually override the font size in the step icons on the reset password workflow because otherwise it is too large for the icon
      overrides: {
        MuiStepIcon: {
          text: {
            fontSize: "small"
          },
          root: {
            color: "#757575"
          }
        },
        MuiStepLabel: {
          label: {
            color: "#757575"
          }
        },
        MuiFormLabel: {
          root: {
            "&$focused": {
              color: this.props.palette.get("primary1Color")
            }
          }
        },
        MuiButton: {
          root: {
            "&:hover": {
              color: this.props.palette.get("accent3Color"),
            }
          }
        },
        MuiInput: {
          underline: {
            "&:after": {
              borderBottomColor: "#757575"
            },
            "&$focused:after": {
              borderBottomColor: "#757575"
            },
            "&:before": {
              borderBottomColor: "#757575"
            },
            "&:hover:not($disabled):before": {
              borderBottomColor: "#757575"
            },
            "&$disabled:before": {
              borderBottomColor: "#757575"
            }
          }
        }
      },
      palette
    });
    return (
      <MuiThemeProvider theme={theme}>{this.props.children}</MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    palette: state.style.get("palette")
  };
}

export default withRouter(connect(mapStateToProps, null)(CustomThemeProvider));
