import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import NewPaymentMethod from "./NewPaymentMethod.jsx";
import MaximumPaymentMethodsWarning from "./MaximumPaymentMethodsWarning.jsx";
import EditPaymentOption from "./EditPaymentOption.jsx";
import { formatCardNetwork } from "./FormatCardNetwork.js";
import { formatExpirationDate } from "../../../utils/FormatUtil.js";
import moment from "moment-timezone";

const StyledListHeader = withStyles({
  root: {
    color: "rgba(0, 0, 0, 0.54)",
    padding: 16,
  },
})(Typography);

class PaymentMethodList extends React.Component {
  constructor(props) {
    super(props);

    this.shouldFocusCC = this.shouldFocusCC.bind(this);
    this.shouldFocusAch = this.shouldFocusAch.bind(this);
  }
  creditCardDescription(c) {
    const cardNetwork = c.get("network");
    let formattedNetwork = formatCardNetwork(cardNetwork);
    let formattedExpirationDate = formatExpirationDate(c.get("expiration"));
    return `${formattedNetwork} ${this.props.t("ending in")} ${c.get(
      "creditCardAbbr"
    )} Exp ${formattedExpirationDate}`;
  }
  achDescription(a) {
    const accountType = a.get("accountType");
    let accountDesc = "Bank Account";

    if (accountType === 27) {
      accountDesc = "Checking Account";
    }

    if (accountType === 37) {
      accountDesc = "Savings Account";
    }

    return `${accountDesc} ${this.props.t("ending in")} ${a.get(
      "accountAbbr"
    )}`;
  }
  isExpired(c) {
    const expirationDate = formatExpirationDate(c.get("expiration"));
    return moment()
      .tz("America/Detroit")
      .isAfter(
        moment(expirationDate, "MM/YY").tz("America/Detroit").endOf("month")
      );
  }
  creditCardTypeBlocked(c) {
    const network = c.get("network");
    if (
      this.props.visaCreditCardBlocked &&
      network === "VISN" &&
      !c.get("isDebit")
    ) {
      return true;
    }
    if (
      this.props.mastercardCreditCardBlocked &&
      network === "MCRD" &&
      !c.get("isDebit")
    ) {
      return true;
    }
    return false;
  }
  shouldFocusCC(entry, paymentMethod) {
    const currentKey = entry.get("id");
    const firstKey = paymentMethod.first().get("id");

    return currentKey === firstKey;
  }
  shouldFocusAch(hasCc, entry, paymentMethod) {
    if (hasCc.size) {
      return false;
    }
    const currentKey = entry.get("id");
    const firstKey = paymentMethod.first().get("id");

    return currentKey === firstKey;
  }
  shouldFocusAddCc(hasCc, hasAch) {
    if (hasCc.size || hasAch.size) {
      return false;
    }
    return true;
  }
  shouldFocusAddAch(hasCc, hasAch, acceptsCcPayments) {
    if (hasCc.size || hasAch.size || acceptsCcPayments) {
      return false;
    }
    return true;
  }
  showCreditCardOption() {
    if (this.props.acceptsCreditCardPayments) {
      return (
        <div>
          <header>
            <StyledListHeader>{this.props.t("Cards")}</StyledListHeader>
          </header>
          {this.showNewPaymentMethod(
            this.props.t("New Credit or Debit Card"),
            this.props.onTapNewCard,
            this.props.creditCards,
            this.shouldFocusAddCc(this.props.creditCards, this.props.ach)
          )}
          {this.props.creditCards.map((c) => (
            <EditPaymentOption
              key={c}
              isEdit={this.props.isEdit}
              isExpired={this.isExpired(c)}
              title={this.props.t(this.creditCardDescription(c))}
              isBlocked={c.get("isBlocked")}
              network={c.get("network")}
              creditCardTypeBlocked={this.creditCardTypeBlocked(c)}
              onClick={() =>
                this.props.onTapCard(c, this.creditCardDescription(c))
              }
              t={this.props.t}
              avatarStyle={this.props.avatarStyle}
              shouldFocusOnMount={this.shouldFocusCC(c, this.props.creditCards)}
            />
          ))}
        </div>
      );
    }
    return null;
  }
  showAchOption() {
    if (!this.props.isCreditCardOnly) {
      return (
        <div>
          <header>
            <StyledListHeader>{this.props.t("ACH")}</StyledListHeader>
          </header>
          {this.showNewPaymentMethod(
            this.props.t("New Checking or Savings Account"),
            this.props.onTapNewAch,
            this.props.ach,
            this.shouldFocusAddAch(
              this.props.creditCards,
              this.props.ach,
              this.props.acceptsCreditCardPayments
            )
          )}
          {this.props.ach.map((a) => (
            <EditPaymentOption
              key={a}
              isEdit={this.props.isEdit}
              isExpired={false}
              bankAccount={true}
              title={this.props.t(this.achDescription(a))}
              onClick={() => this.props.onTapAch(a, this.achDescription(a))}
              isBlocked={a.get("blocked") !== "None"}
              blockedMessage={a.get("blocked")}
              t={this.props.t}
              avatarStyle={this.props.avatarStyle}
              shouldFocusOnMount={this.shouldFocusAch(
                this.props.creditCards,
                a,
                this.props.ach
              )}
            />
          ))}
        </div>
      );
    } else if (!this.props.ach.isEmpty()) {
      return (
        <div>
          <p style={{ marginTop: 10 }}>
            {this.props.t(
              "*This business customer does not accept ach payments."
            )}
          </p>
        </div>
      );
    }
    return null;
  }
  showNewPaymentMethod(
    title,
    onClickCallback,
    existingMethods,
    shouldFocusOnAdd
  ) {
    if (existingMethods && existingMethods.size >= 2) {
      return (
        <MaximumPaymentMethodsWarning
          title={this.props.t("Payment method limit reached")}
        />
      );
    }

    return (
      <NewPaymentMethod
        title={title}
        onClick={onClickCallback}
        avatarStyle={this.props.avatarStyle}
        shouldFocusOnMount={shouldFocusOnAdd}
      />
    );
  }
  render() {
    return (
      <div>
        {this.showCreditCardOption()}
        {this.showAchOption()}
      </div>
    );
  }
}

export default PaymentMethodList;
