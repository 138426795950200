import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { setContactInfoAction } from "./../../api/actions/user";
import ContactForm from "./ContactForm.jsx";
import RouteHelper from "./../../utils/RouteHelper";
import AlertInfo from "./../errors/AlertInfo.jsx";
import { setPageInfoAction } from "./../../api/actions/pageInfo";

import { _t } from "./../../text/locale";
import PaymentStepper from "../../components/PaymentStepper.jsx";
let t;
let pageTitle = "Contact Information";

class ContactInfo extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  onSubmit(formData) {
    this.props.setContactInfo(formData);
    const path = RouteHelper.getRelativeCustomerRoute(this.props.location)(
      "/payment/confirm-payment"
    );
    this.props.router.push(path);
  }
  render() {
    return (
      <div>
        <div className="stepper">
          <PaymentStepper
            step="contact-information"
            currentLocation={this.props.location}
          />
        </div>
        <div className="flex-container">
          <div className="flex-content">
            <ContactForm
              onSubmit={this.onSubmit}
              t={t}
              {...this.props.required}
              nextAction={t("Confirm and Continue")}
              contactInfo={this.props.contactInfo}
              shouldFocusOnMount
              allowMpayTextReminders={this.props.allowMpayTextReminders}
              loggedIn={this.props.loggedIn}
            />
          </div>
          <div
            className="flex-tipBox"
            aria-label="Contact Information Tip Box"
            role="region"
          >
            <AlertInfo
              text={t("TIP_BOX_CONTACT_INFORMATION")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const accessNumber = ownProps.params.accessNumber;
  return {
    required: {
      requireEmail: state.consortium.getIn([
        "customers",
        accessNumber,
        "requireEmail"
      ]),
      requirePhone: state.consortium.getIn([
        "customers",
        accessNumber,
        "requirePhone"
      ]),
      requireAddress: state.consortium.getIn([
        "customers",
        accessNumber,
        "requireAddress"
      ])
    },
    contactInfo: state.user.get("contact"),
    allowMpayTextReminders: state.consortium.get("customers").some(c => c.get("allowMpayTextReminders")),
    loggedIn: state.auth.get("authenticated")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetPageInfo: pageInfoData => {
      dispatch(setPageInfoAction(pageInfoData));
    },
    setContactInfo: contactInfo => {
      dispatch(setContactInfoAction(contactInfo));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactInfo)
);
