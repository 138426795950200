import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  GET_ACH_TOKENS,
  ADD_USER_PAYMENT_METHOD,
  TOKENIZE_AND_ADD_USER_PAYMENT_METHOD,
  FAILURE,
} from "./../../api/actions/actionTypes";
import {
  showLoadingAction,
  hideLoadingAction,
} from "./../../api/actions/loading";

import { setGuestAchInfoAction } from "./../../api/actions/payments";
import {
  addUserAchPaymentMethodFromPaymentWorkflowAction,
  addUserAchPaymentMethodAction,
} from "./../../api/actions/user";

import NewAchForm from "./NewAchForm.jsx";
import UserSignInPrompt from "./../user-accounts/UserSignInPrompt.jsx";
import { _t } from "./../../text/locale";
import AlertInfo from "./../errors/AlertInfo.jsx";
import RouteHelper from "./../../utils/RouteHelper";
import { setPageInfoAction } from "./../../api/actions/pageInfo";
import PaymentStepper from "../../components/PaymentStepper.jsx";
import BackButton from "../../components/BackButton.jsx";

let t;
let pageTitle = "New Checking or Savings Account";

class AddNewAch extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }
  UNSAFE_componentWillMount() {
    t = _t(this.props.params.accessNumber);
    this.props.dispatchSetPageInfo(t(pageTitle));
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.pending === true) {
      this.props.showLoading(t("Securely storing bank account information..."));
    } else if (
      !nextProps.pending &&
      this.props.pending &&
      !nextProps.lastRequestFailed
    ) {
      this.props.hideLoading();

      const confirmPaymentPath = this.props.params.accessNumber
        ? RouteHelper.getRelativeCustomerRoute(this.props.location)(
            "/payment/options"
          )
        : RouteHelper.getRelativeAppViewRoute(this.props.location)(
            "/user/payment-methods"
          );

      this.props.router.push(confirmPaymentPath);
    } else {
      this.props.hideLoading();
    }
  }
  onSubmit(formData) {
    if (this.props.params.accessNumber) {
      if (this.props.loggedIn) {
        this.props.addUserPaymentMethodFromPaymentWorkflow(
          this.props.params.consortiumId
        )(formData);
      } else {
        this.props.saveAchInfo(this.props.accessNumber)(formData);
      }
    } else {
      this.props.addUserPaymentMethodAction(this.props.params.consortiumId)(
        formData
      );
    }
  }
  render() {
    const nextAction = this.props.params.accessNumber
      ? "Continue to checkout"
      : "Add Bank Account";

    return (
      <div>
        <div className="stepper">
          {this.props.params.accessNumber ? (
            <PaymentStepper
              step="payment-method"
              currentLocation={this.props.location}
            />
          ) : (
            <BackButton
              route="/user/payment-methods"
              currentLocation={this.props.location}
              backText={t("Manage Payment Methods")}
            />
          )}
        </div>
        <div className="flex-container">
          <div className="flex-content">
            <NewAchForm
              onSubmit={this.onSubmit}
              t={t}
              submitAction={t(nextAction)}
              disabled={this.props.pending}
              blockedRoutingNumbers={this.props.blockedRoutingNumbers}
            />
          </div>
          <div
            className="flex-tipBox"
            aria-label="New Direct Debit Tip Box"
            role="region"
          >
            <UserSignInPrompt t={t} location={this.props.location} />
            <AlertInfo
              text={t("TIP_BOX_NEW_DIRECT_DEBIT")}
              location={this.props.location}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loggedIn: state.auth.get("authenticated"),
    accessNumber: ownProps.params.accessNumber,
    blockedRoutingNumbers: state.consortium.get("blockedRoutingNumbers"),
    pending:
      !!state.httpRequests.getIn([GET_ACH_TOKENS, "pending"]) ||
      !!state.httpRequests.getIn([
        TOKENIZE_AND_ADD_USER_PAYMENT_METHOD,
        "pending",
      ]),
    lastRequestFailed:
      state.httpRequests.getIn([GET_ACH_TOKENS, "lastStatus"]) === FAILURE ||
      state.httpRequests.getIn([ADD_USER_PAYMENT_METHOD, "lastStatus"]) ===
        FAILURE,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveAchInfo: (accessNumber) => (achData) => {
      dispatch(setGuestAchInfoAction(accessNumber, achData));
    },
    addUserPaymentMethodFromPaymentWorkflow: (consortium) => (accountData) => {
      dispatch(
        addUserAchPaymentMethodFromPaymentWorkflowAction(
          consortium,
          accountData
        )
      );
    },
    addUserPaymentMethodAction: (consortium) => (accountData) => {
      dispatch(addUserAchPaymentMethodAction(consortium, accountData));
    },
    showLoading: (titleText) => {
      dispatch(showLoadingAction(titleText));
    },
    hideLoading: () => {
      dispatch(hideLoadingAction());
    },
    dispatchSetPageInfo: (pageInfoData) => {
      dispatch(setPageInfoAction(pageInfoData));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddNewAch)
);
