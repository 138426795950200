export const formatCardNetwork = function(network) {
  switch (network) {
    case "AMEX":
      return "AMEX";
    case "AMX1":
      return "AMEX";
    case "MCRD":
      return "MCRD";
    case "DISC":
      return "DISC";
    case "VISN":
      return "VISA";
    default:
      return "Card";
  }
};

export const formatCardNetworkForDatabase = function(network) {
  switch (network) {
    case "amex":
      return "AMEX";
    case "mastercard":
      return "MCRD";
    case "discover":
      return "DISC";
    case "visa":
      return "VISN";
    default:
      return null;
  }
};

export const getEnglishName = function(network) {
  switch (network) {
    case "AMEX":
      return "Amex";
    case "AMX1":
      return "Amex";
    case "MCRD":
      return "Mastercard";
    case "DISC":
      return "Discover";
    case "VISN":
      return "Visa";
    default:
      return "";
  }
};
