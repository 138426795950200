import React from "react";
import PropTypes from "prop-types";

class CreditCardLogo extends React.Component {
  static availableTypes = [
    "visa",
    "visa debit card",
    "mastercard",
    "mastercard debit card",
    "discover",
    "amex"
  ];
  static propTypes = {
    type: PropTypes.string,
    selected: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      cardName: "N/A",
      cardType: "unknown"
    };
  }

  //+---------------------------------------------------------------+
  //| Render / Render Assit Methods                                 |
  //+---------------------------------------------------------------+
  getLogoClass(cardType) {
    let className = `cc-icon ${cardType}-icon`;

    if (!this.props.selected) {
      className += "-highlight";
    }
    return className;
  }
  //+---------------------------------------------------------------+
  render() {
    let cardName = "Unknown";
    let cardType = "unknown";

    switch (this.props.type.toLowerCase()) {
      case "visa":
        cardName = "Visa";
        cardType = "visa";
        break;
      case "mc":
      case "mastercard":
        cardName = "Master Card";
        cardType = "mc";
        break;
      case "discover":
      case "disc":
        cardName = "Discover";
        cardType = "discover";
        break;
      case "amex":
        cardName = "American Express";
        cardType = "amex";
        break;
      default:
        break;
    }

    return (
      <div
        className={this.getLogoClass(cardType)}
        style={{ margin: "5px" }}
        title={cardName}
      />
    );
  }
}

CreditCardLogo.defaultProps = {
  type: "unknown",
  selected: false
};
export default CreditCardLogo;
