import React from "react";
import { withFormsy } from "formsy-react";
import AmountFieldWithValidation from "./../../components/form-input/AmountFieldWithValidation.jsx";
import { formatPaymentAmount } from "./../../utils/FormatUtil";
import LockedTextField from "./../../components/form-input/LockedTextField.jsx";

class PaymentAmountFieldInternal extends React.Component {
  constructor(props) {
    super(props);
    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);

    this.getReadonlyAmountField = this.getReadonlyAmountField.bind(this);
    this.getAmountFieldWithValidation = this.getAmountFieldWithValidation.bind(
      this
    );
  }

  onValidSubmit() {
    // Copy our form model and delete form-related properties
    // This is less ~effort~ than re-initializing an object with all the properties.
    const model = Object.assign({}, this.state);
    delete model.valid;

    this.props.onSubmit(model);
  }
  onValid() {
    this.setState({ valid: true });
  }
  onInvalid() {
    this.setState({ valid: false });
  }
  getAmountFieldWithValidation() {
    return (
      <div>
        <AmountFieldWithValidation
          id={this.props.name}
          name={this.props.name}
          label={this.props.label}
          onChange={this.props.onChange}
          defaultValue={this.props.defaultValue}
          value={this.props.value}
          validations={{ isAboveMinimum: this.props.minPayAmount, isBelowMaximum: this.props.maxPayAmount }}
          validationErrors={{
            isDefaultRequiredValue: this.props.t(
              "A payment amount is required."
            ),
            isAboveMinimum: this.props.t(
              "The minimum amount due is {{MIN_AMOUNT}}",
              {
                MIN_AMOUNT: "$" + formatPaymentAmount(this.props.minPayAmount)
              }
            ),
            isBelowMaximum: this.props.t(
              "The maximum payable amount is {{MAX_AMOUNT}}",
              {
                MAX_AMOUNT: "$" + formatPaymentAmount(this.props.maxPayAmount)
              }
            )
          }}
          required
          shouldFocusOnMount={this.props.shouldFocusOnMount}
        />
      </div>
    );
  }
  getReadonlyAmountField() {
    return (
      <LockedTextField
        id={this.props.name}
        name={this.props.name}
        fullWidth
        readOnly
        label={this.props.label}
        defaultValue={this.props.value}
        onChange={e => this.props.onChange(e.currentTarget.value)}
        shouldFocusOnMount
      />  
    );
  }

  render() {
    return this.props.allowChange
        ? this.getAmountFieldWithValidation() 
        : this.getReadonlyAmountField();
  }
}

const HOCPaymentAmountField = withFormsy(PaymentAmountFieldInternal);

// We have to wrap our component if we to be able to force validations to always be "isMoney".
// The HOC function causes validations to exist on the component.
class PaymentAmountField extends React.Component {
  render() {
    return <HOCPaymentAmountField {...this.props} validations="isMoney" />;
  }
}

export default PaymentAmountField;
