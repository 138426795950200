import React from "react";
import PaymentHistory from "./PaymentHistory.jsx";
import PrintablePaymentHistory from "./PrintablePaymentHistory.jsx";

export const PaymentHistoryContainer = (props, context) => {
  return (
    <div>
      <div className="no-print">
        <PaymentHistory {...props} />
      </div>
      <div className="only-print">
        <PrintablePaymentHistory {...props} />
      </div>
    </div>
  );
};

export default PaymentHistoryContainer;
