import { combineReducers } from "redux";

import auth from "./reducers/auth";
import billing from "./reducers/billing";
import config from "./reducers/config";
import consortium from "./reducers/consortium";
import httpRequests from "./reducers/httpRequests";
import initialize from "./reducers/initialize";
import loading from "./reducers/loadingDisplay";
import manageRecurring from "./reducers/manageRecurring";
import autoPay from "./reducers/autoPay";
import notification from "./reducers/notification";
import payments from "./reducers/payments";
import passwordReset from "./reducers/passwordReset";
import paymentHistory from "./reducers/paymentHistory";
import session from "./reducers/session";
import statement from "./reducers/statement";
import style from "./reducers/style";
import text from "./reducers/text";
import user from "./reducers/user";

export default combineReducers({
  auth,
  billing,
  config,
  consortium,
  httpRequests,
  initialize,
  loading,
  manageRecurring,
  autoPay,
  notification,
  payments,
  passwordReset,
  paymentHistory,
  session,
  statement,
  style,
  text,
  user
});
