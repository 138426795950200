import { GET_LOCALE_CUSTOMIZATION } from "./actionTypes";

import { getCustomText } from "./../requests/text";

const defaultLocale = "en-US";

export const getDefaultLocaleCustomization = accessNumber => (
  dispatch,
  getState
) => {
  const promise = getCustomText(accessNumber, defaultLocale);

  dispatch({
    type: GET_LOCALE_CUSTOMIZATION,
    meta: { type: defaultLocale },
    payload: { promise }
  });

  return promise;
};
