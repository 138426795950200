import React from "react";
import AlertWarning from "./../errors/AlertWarning.jsx";

export default class PreviousBusinessDayAlert extends React.Component {
  render() {
    return (
      <AlertWarning>
        <strong>
          {this.props.t(
            "Date selected is not a business day. Payment will be processed on preceding business day."
          )}{" "}
          {this.props.date}&nbsp;
        </strong>
        <br className="visible-xs" />
      </AlertWarning>
    );
  }
}
