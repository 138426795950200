import React from "react";
import moment from "moment";

import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Avatar
} from "@material-ui/core";

import { formatPaymentAmount } from "./../../utils/FormatUtil";

export default class ManageRecurringCard extends React.Component {
  render() {
    const paymentItem = this.props.paymentItem;

    const formattedAmount = formatPaymentAmount(paymentItem.get("amount"));
    const formattedFee = formatPaymentAmount(paymentItem.get("feeAmount"));
    const formattedDate = moment(paymentItem.get("postDate")).format("L");
    return (
      <Card style={{ margin: "0px 0px 8px 0px" }}>
        <CardHeader
          title={paymentItem.get("frequency")}
          avatar={
            <Avatar
              color={this.props.styles.get("alternateTextColor")}
              style={{
                backgroundColor: this.props.styles.get("primary1Color"),
                width: 30,
                height: 30,
              }}
            >
              {paymentItem.get("frequency").charAt(0)}
            </Avatar>
          }
        />
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{this.props.t("Post Date:")}</TableCell>
                <TableCell>{formattedDate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{this.props.t("Amount:")}</TableCell>
                <TableCell>${formattedAmount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{this.props.t("Fee:")}</TableCell>
                <TableCell>${formattedFee}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{this.props.t("Confirmation:")}</TableCell>
                <TableCell>{paymentItem.get("transactionNumber")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
        <CardActions>
          <Button
            onClick={() => {
              this.props.deleteOnClick(paymentItem);
            }}
            color="secondary"
          >
            {this.props.t("Cancel")}
          </Button>
        </CardActions>
      </Card>
    );
  }
}
