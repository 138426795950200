import React, { Component } from "react";
import { connect } from "react-redux";
import CircularProcess from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import { ResponsiveUtil } from "../utils/ResponsiveUtil";
import { DialogContent } from "@material-ui/core";

const customContentStyle = {
  width: "100%",
  maxWidth: "none"
};

class LoadingModal extends Component {
  render() {
    let isMobile = ResponsiveUtil.isMobile();
    return (
      <Dialog
        open={!!this.props.show}
        style={{
          textAlign: "center",
          ...(isMobile ? customContentStyle : null)
        }}
      >
        <DialogContent>
          {isMobile ? null : (
            <CircularProcess className="pull-left" size={60} color="primary" />
          )}
          <div style={{ fontSize: "30px" }}>{this.props.title}</div>
          {isMobile ? (
            <LinearProgress mode="indeterminate" color="primary" />
          ) : null}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    title: state.loading.get("title"),
    show: state.loading.get("show"),
    styles: state.style.get("palette")
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadingModal);
