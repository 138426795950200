import React from "react";
import numeral from "numeral";
import moment from "moment";

import { connect } from "react-redux";
import {
  Table,
  TableRow,
  TableCell,
  Button,
  TableBody,
} from "@material-ui/core";
import { _t } from "./../../../text/locale";
import { ResponsiveUtil } from "../../../utils/ResponsiveUtil";
import FocusableButton from "../../../components/FocusableButton.jsx";

export const PaymentReceipt = (props, context) => {
  const t = props.t;
  const amount = numeral(props.paymentAmount || 0).format("$0,0.00");
  const confirmationNumber = props.confirmationNumber;
  const billingAccount = props.billingAccount;
  const depositAccountName = props.depositAccountName;
  const processDate = moment(props.processDate).format("L");
  const columnStyles = { wordWrap: "break-word", whiteSpace: "normal" };
  const frequency = props.frequency;
  return (
    <div>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={columnStyles}>
              <b>{t("Account Number")}</b>
            </TableCell>
            <TableCell style={columnStyles}>{billingAccount}</TableCell>
          </TableRow>
          {depositAccountName ? (
            <TableRow>
              <TableCell style={columnStyles}>
                <b>{t("Pay To")}</b>
              </TableCell>
              <TableCell style={columnStyles}>{depositAccountName}</TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableCell style={columnStyles}>
              <b>{t("Amount")}</b>
            </TableCell>
            <TableCell style={columnStyles}>{amount}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={columnStyles}>
              <b>{t("Process Date")}</b>
            </TableCell>
            <TableCell style={columnStyles}>{processDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={columnStyles}>
              <b>{t("Confirmation Number")}</b>
            </TableCell>
            <TableCell style={columnStyles}>{confirmationNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ ...columnStyles, borderBottom: "none" }}>
              <b>{t("Frequency")}</b>
            </TableCell>
            <TableCell style={{ ...columnStyles, borderBottom: "none" }}>
              {frequency === "Never" ? "One-Time" : frequency}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export const PaymentFollowUpActions = (props, context) => {
  const isMobile = ResponsiveUtil.isMobile();
  const mobileStyle = {
    width: "100%",
    marginBottom: "10px",
  };

  var returnMessage = props.t("BUTTON_RETURN_TO_COMPANY");
  // If there's no override, generate a reasonable default based on the consortium name
  if (returnMessage === "BUTTON_RETURN_TO_COMPANY") {
    returnMessage = `RETURN TO ${props.companyName}`;
  }

  return (
    <div className="flex-container">
      <div className="flex-content">
        <FocusableButton
          variant="contained"
          onClick={props.makeAnotherPaymentClick}
          style={isMobile ? mobileStyle : null}
          color="primary"
          shouldFocusOnMount
        >
          {props.t("Make Another Payment")}
        </FocusableButton>
      </div>
      <div className="flex-tipBox">
        {props.website !== "" ? (
          <Button
            variant="contained"
            href={props.website}
            style={isMobile ? mobileStyle : null}
            color="primary"
          >
            <span
              style={{
                color: props.style.getIn(["palette", "alternateTextColor"]),
              }}
            >
              {returnMessage}
            </span>
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export const PaymentConfirmation = (props, context) => {
  return <PaymentReceipt {...props} />;
};

export const PrintPaymentReceipt = (props, context) => {
  const t = props.t;
  const gutter = props.style.getIn(["spacing", "desktopGutter"]);
  const padding = { paddingLeft: gutter, paddingRight: gutter };
  return (
    <div>
      <h2 style={padding}>
        {props.companyName} - {props.description}
      </h2>
      <h2 style={padding}>{t("Your Payment has been Accepted")}</h2>{" "}
      {/*All the text should be aligned*/}
      <PaymentReceipt {...props} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const noPaymentToPrint = !state.payments.getIn(["current", "response"]);
  const style = state.style;
  if (noPaymentToPrint) {
    return {
      t: _t(),
      style: style,
    };
  }

  const total = state.payments.getIn(["current", "total"]);

  const currentAccountPath = state.billing.getIn(["accounts", "current"]);
  const account = state.billing.getIn(currentAccountPath);

  const accountNumber = account.get("formattedAccountNumber");
  const confirmationNumber = state.payments.getIn([
    "current",
    "confirmationNumber",
  ]);
  const processDate = state.payments.getIn(["current", "date"]);
  const frequency = state.payments.getIn(["current", "frequency"]);

  const accessNumber = state.billing.getIn(["accounts", "current"])[1];
  const bc = state.consortium.getIn(["customers", accessNumber]);
  const companyName = bc.get("companyName").trim();
  const description = bc.get("serviceDescription").trim();

  return {
    companyName,
    description,
    paymentAmount: total,
    billingAccount: accountNumber,
    confirmationNumber: confirmationNumber,
    processDate: processDate,
    frequency: frequency,
    t: _t(accessNumber),
    style: style,
  };
};

export const ConnectedPaymentReceipt =
  connect(mapStateToProps)(PrintPaymentReceipt);
